import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    ListItem,
    Typography,
    Card,
    CardContent,
    CardActions,
    CardActionArea,
    CardMedia,
    IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MethodologiesProgress from './Methodologies/MethodologiesProgress'
import PlaceholderBuilding from '../../assets/placeholderBuilding.jpg'
import PlaceholderBridge from '../../assets/placeholderBridge.jpg'
import Bridge from '../../assets/bridge.png'
import Building from '../../assets/building.png'

import { backend_url } from '../../constants'

const useStyles = makeStyles((theme) => ({
    card: {
        height: 100,
        display: 'flex',
        flex: 1,
        background: theme.palette.background.paper,
    },
    activeCard: {
        height: 100,
        display: 'flex',
        flex: 1,
        background: theme.palette.background.paperLight,
    },
    cardBody: {
        flex: 1,
        paddingTop: theme.spacing(1.3),
        paddingBottom: theme.spacing(1.3),
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 600,
    },
    img: {
        width: 80,
        height: 66,
        alignSelf: 'center',
        marginLeft: theme.spacing(),
        borderRadius: theme.shape.borderRadius,
    },
    optionsButton: {
        alignSelf: 'flex-start',
        marginLeft: 'auto',
        padding: 0,
    },
    typeIcon: {
        height: 40,
        width: 40,
        position: 'absolute',
        bottom: -7,
        right: 12,
        filter: 'brightness(0.7)',
    },
}))

export default function ProcedureCard({
    procedure,
    setActiveId,
    activeId,
    openMenu,
    style,
    mapRefStore,
    isSuperAdmin,
    doesUserHaveWritePermission,
}) {
    const classes = useStyles()
    const history = useHistory()

    const placeholderImg = {
        bridge: PlaceholderBridge,
        building: PlaceholderBuilding,
    }
    const procedureTypeImg = {
        bridge: Bridge,
        building: Building,
    }

    function goToProcedure(procedureId) {
        history.push(`/procedures/${procedureId}`)
    }

    function handleCardClick(procedureId) {
        if (activeId === procedureId) {
            goToProcedure(procedureId)
        } else {
            setActiveId(procedureId)
            mapRefStore?.setMapTypeId('satellite')
            mapRefStore?.setZoom(22)
        }
    }

    return (
        <ListItem key={procedure.id} style={style}>
            <Card className={activeId === procedure.id ? classes.activeCard : classes.card}>
                <CardActionArea
                    style={{ display: 'flex' }}
                    onClick={() => handleCardClick(procedure.id)}
                >
                    <CardMedia
                        className={classes.img}
                        image={
                            procedure.image
                                ? new URL(procedure.image?.depot_url, backend_url).toString()
                                : placeholderImg[procedure.type]
                        }
                    />
                    <CardContent className={classes.cardBody}>
                        <Typography component="h2" className={classes.title}>
                            {procedure.name}
                        </Typography>
                        <MethodologiesProgress methodologies={procedure.methodologies} />
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.optionsButton}>
                    <IconButton
                        color="secondary"
                        onClick={openMenu}
                        disabled={!isSuperAdmin && !doesUserHaveWritePermission(procedure.id)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </CardActions>
                <div
                    style={{
                        background: `url('${procedureTypeImg[procedure.type]}') no-repeat`,
                    }}
                    className={classes.typeIcon}
                />
            </Card>
        </ListItem>
    )
}
