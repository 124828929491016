import { textareaType } from './utils'

export const sections_to_render_stato_edificio = {
    MASONRY: [
        'horizontal_links',
        'anomalies',
        'heavy_roof',
        'roof_base_ratio',
        'significant_cladding_contribution',
    ],
    REINFORCED_CONCRETE: ['significant_cladding_contribution'],
    PREFABRICATED: [
        'claddings_are_prefabricated_panels',
        'internal_pillars_influence_area',
        'claddings_influence_area',
    ],
}

export const resisto_stato_edificio = {
    horizontal_links: {
        _type: 'number',
        // variant: 'outsideLabel',
    },
    anomalies: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    heavy_roof: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    roof_base_ratio: {
        _type: 'number',
        // variant: 'outsideLabel',
    },
    significant_cladding_contribution: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    claddings_are_prefabricated_panels: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    internal_pillars_influence_area: {
        _type: 'numberUnit',
        // variant: 'outsideLabel',
        unit: 'm²',
    },
    claddings_influence_area: {
        _type: 'numberUnit',
        // variant: 'outsideLabel',
        unit: 'm²',
    },
    notes: textareaType,
}

export const reinforcedConcreteBuildingStatusModel = {
    significant_cladding_contribution: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
}

export const prefabricatedBuildingStatusModel = {
    claddings_are_prefabricated_panels: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    internal_pillars_influence_area: {
        _type: 'number',
        // variant: 'outsideLabel',
        unit: 'm²',
    },
    claddings_influence_area: {
        _type: 'number',
        // variant: 'outsideLabel',
        unit: 'm²',
    },
}

export const masonryBuildingStatusModel = {
    horizontal_links: {
        _type: 'number',
        // variant: 'outsideLabel',
    },
    anomalies: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    heavy_roof: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
    roof_base_ratio: {
        _type: 'number',
        // variant: 'outsideLabel',
    },
    significant_cladding_contribution: {
        _type: 'boolean',
        // variant: 'columnInlineWide',
    },
}