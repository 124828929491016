import React from 'react'
import {IconButton, InputAdornment, TextField, Tooltip, Typography} from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useShouldLabelCut, useWatchForAdditionalProps } from '../../FormConfigs/utils'
import AppTooltip from '../AppTooltip'

export default function StatefulTextField({
    onChange,
    value,
    label,
    watchConfig,
    disabled,
    required,
    autofocus = false,
    styleVariant,
    unit,
    info,
    onClickInfo,
    ...props
}) {
    /**
     * This component exists because it's sometimes useful to have a controlled text input, which keeps an updated internal state
     * as opposed to triggering the {onChange} like an uncontrolled input would, which sometimes causes performance issues.
     * (E.g. react-hook-form with formProvider would refresh the whole form!)
     *
     * This comes with the drawback of calling {onChange} on input BLUR, not the actual change.
     * React.useEffect() shouldn't be needed to update the component as it gets re-rendered completely on {value} change.
     */
    const [localValue, setLocalValue] = React.useState(value || null)
    const { t } = useTranslation()
    const additionalProps = useWatchForAdditionalProps(watchConfig)
    const labelToShow = useShouldLabelCut(label)

    function handleChange({ target: { value } }) {
        setLocalValue(value)
    }

    function handleBlur() {
        if (props?.type === 'number') {
            onChange(parseFloat(localValue))
        } else {
            onChange(localValue)
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter' && !props.multiline) {
            handleBlur()
        }
    }

    React.useEffect(() => {
        setLocalValue(value)
    }, [value])

    return labelToShow === label && !label.includes('&rarr') ? (
        <>
            <TextField
                {...props}
                disabled={disabled}
                {...additionalProps}
                required={required}
                InputLabelProps={{
                    shrink: true,
                    // shrink: !localValue && localValue != 0 ? false : true,
                    component: ({ children, ...props }) => (
                        <label
                            {...props}
                            dangerouslySetInnerHTML={{
                                __html: (label || undefined) + (required ? ' *' : ''),
                            }}
                        />
                    ),
                }}
                InputProps={{

                    endAdornment: (
                        <>
                            {unit && <InputAdornment
                                position="end"
                                dangerouslySetInnerHTML={{ __html: `${unit}` }}
                                style={{ fontSize: 14 }}
                            />}
                            {info && <Tooltip placement="top-end" title={info}>
                                <IconButton size="small" onClick={onClickInfo}>
                                    <HelpOutlineIcon/>
                                </IconButton>
                            </Tooltip>}
                        </>
                    ),

                }}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onBlur={handleBlur}
                label={styleVariant === 'outsideLabel' ? undefined : label}
                value={localValue ?? null}
                // Since chrome intentionally ignores autocomplete="off", this is the only way to disable auto-filling
                autoComplete="new-password"
                autoFocus={false}
            />
        </>
    ) : label.includes('&rarr') ? (
        <Typography>{label.replaceAll('&rarr;', '→')}</Typography>
    ) : (
        <AppTooltip
            placement="top"
            title={
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                />
            }
        >

                <TextField
                    id={label === 'Eventuali note' ? t('eventuali_note') : ''}
                    {...props}
                    disabled={disabled}
                    {...additionalProps}
                    required={required}
                    InputLabelProps={{
                        shrink: true,
                        // shrink: !localValue && localValue != 0 ? false : true,
                        component: ({ children, ...props }) => (
                            <label
                                {...props}
                                dangerouslySetInnerHTML={{
                                    __html: (labelToShow || undefined) + (required ? ' *' : ''),
                                }}
                            />
                        ),
                    }}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={styleVariant === 'outsideLabel' ? undefined : label}
                    value={localValue ?? null}
                    autoComplete="new-password"
                    autoFocus={false}
                    InputProps={{
                        endAdornment: (
                            <>
                                {unit && <InputAdornment
                                    position="end"
                                    dangerouslySetInnerHTML={{ __html: `${unit}` }}
                                    style={{ fontSize: 14 }}
                                />}
                                {info && <Tooltip placement="top-end" title={info}>
                                    <IconButton size="small" onClick={onClickInfo}>
                                        <HelpOutlineIcon/>
                                    </IconButton>
                                </Tooltip>}
                            </>
                        )
                    }}
                />

        </AppTooltip>
    )
}
