import React from 'react'
import _ from 'lodash'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import DynamicFormFields from '../FormItems/DynamicFormFields'
import AppForm from '../AppForm'

export default function FormDialog({
    open,
    setIsOpen,
    title,
    button,
    formConfig,
    fieldsKey,
    onSubmitCb,
    defaultValues = {},
}) {
    const { t } = useTranslation()
    const methods = useForm({
        defaultValues: {},
        shouldUnregister: false,
    })
    const { isDirty } = methods.formState

    React.useEffect(() => {
        if (open && !_.isEmpty(defaultValues)) {
            methods.reset(defaultValues)
        }
    }, [open])

    function sendData(data) {
        onSubmitCb(data)
    }

    function handleCancel() {
        setIsOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => setIsOpen(false)} maxWidth="lg">
            <DialogTitle>{t(title)}</DialogTitle>
            <DialogContent>
                <AppForm onSubmit={sendData} methods={methods}>
                    <DynamicFormFields formFields={formConfig} fieldsKey={fieldsKey} />
                    <DialogActions>
                        <Button onClick={handleCancel}>{t('annulla')}</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty}
                        >
                            {t(button)}
                        </Button>
                    </DialogActions>
                </AppForm>
            </DialogContent>
        </Dialog>
    )
}
