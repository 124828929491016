import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    summary: {
        justifyContent: 'center',
    },
}))

export default function Avvertenze() {
    const classes = useStyles()
    return (
        <Accordion style={{ marginBottom: 15 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ content: classes.summary }}
            >
                <Typography color="secondary"> AVVERTENZE </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <p>
                        La procedura di valutazione L3 implementata nell’applicativo può essere
                        applicata a ponti in semplice appoggio con qualsiasi numero di campate e a
                        ponti incastrati ad una campata.
                    </p>
                    <p>
                        Ponti a travate con sezione aperta o chiusa e ponti a cassone sono
                        analizzabili dal software.
                    </p>
                    <p>
                        La procedura si compone dei seguenti steps:
                        <ul>
                            <li>
                                Valutazione delle massime azioni flettenti e taglianti considerando
                                le azioni variabili (carichi mobili) della normativa dell’epoca di
                                progettazione e secondo la categoria di ponte di progetto.
                            </li>
                            <li>
                                Valutazione delle massime azioni flettenti e taglianti considerando
                                le azioni variabili (carichi mobili) della normativa attuale.
                            </li>
                            <li>
                                Se le caratteristiche di sollecitazione dell’attuale normativa
                                risultano inferiori a quelle dell’epoca di progettazione, la
                                procedura terminerà con indicazione del rapporto.
                            </li>
                            <li>
                                Se le caratteristiche di sollecitazione dell’attuale normativa
                                risultano superiori a quelle dell’epoca di progettazione, si
                                eseguirà il calcolo secondo le azioni variabili da codice della
                                strada, considerando effettivamente mezzi reali che possano
                                transitare sull’opera.
                            </li>
                            <li>
                                Se le caratteristiche di sollecitazione risultano nuovamente
                                superiori a quelle di progetto si procederà a ridurre il carico fino
                                ad ottenere un valore unitario del rapporto tra le sollecitazioni e
                                definire una riduzione di carico transitabile sul ponte.
                            </li>
                        </ul>
                    </p>
                    <p>
                        I carichi concentrati delle varie normative risultano essere stati resi
                        equivalenti a carichi uniformemente distribuiti agenti per la lunghezza del
                        mezzo utilizzato.
                    </p>
                    <p>
                        Si sottolinea che l’eventuale limitazione di carico deve essere indicata,
                        mediante apposita cartellonistica, in entrambi gli ingressi all’opera e
                        devono essere posizionati elementi che non permettano il transito alla
                        categoria di veicoli a cui risulta interdetto il passaggio.
                    </p>
                    <p>
                        La presente valutazione considera solo i carichi variabili agenti sull’opera
                        e si basa sull’assunzione che la progettazione iniziale fosse conforme alle
                        normative dell’epoca. Non si eseguiranno verifiche delle solette, dei
                        traversi, delle sottostrutture (pile e spalle) o delle fondazioni, ci si
                        limiterà, pertanto, alle verifiche delle strutture orizzontali principali
                        (travi, cassoni, ecc.).
                    </p>
                    <p>
                        Si sottolinea, ulteriormente, che nel caso di gravi riduzioni di resistenza
                        dovute a danneggiamenti, degradi, urti, ecc., bisogna procedere con una
                        valutazione accurata di Livello 4, così come nel caso di degradi diffusi
                        nella struttura.
                    </p>
                    <p>
                        I calcoli svolti devono servire solo come indicazione preliminare, così come
                        eventuali limitazioni di traffico, in quanto solo a seguito di analisi
                        accurata si possono appurare tali limitazioni.
                    </p>
                    <p>
                        Gli autori declinano ogni responsabilità sull’utilizzo dei risultati del
                        software.
                    </p>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}
