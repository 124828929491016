import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Chip, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AppSelect from './AppSelect'
import FieldsGroup from './FieldsGroup'
import CoordinatesField from './CoordinatesField'
import YearField from './YearField'
import DateField from './DateField'
import UnitMeasureField from './UnitMeasureField'
import FieldsArray from './FieldsArray'
import AppToggleButtonGroup from './AppToggleButtonGroup'
import { useTranslation } from 'react-i18next'
import StatefulTextField from './StatefulTextField'
import { RequiredLA2Badge } from './RequiredLA2'
import AttachmentDropzone from './AttachmentDropzone'
import _ from 'lodash'
import FieldInfoDialog from '../Dialogs/FieldInfoDialog'
import SearchableTextField from './SearchableTextField'
import LinkField from './LinkField'
import DateTimeField from "./DateTimeField";

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(),
    },
    hideElement: {
        display: 'none',
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        padding: theme.spacing(),
        marginBottom: theme.spacing(),
        marginLeft: 0,
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(0.5),
        maxWidth: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    textareaContainer: {
        flex: 1,
    },
    fieldsArray: {
        width: '100%',
    },
}))

function layoutPoints(field) {
    switch (field?._type) {
        case 'coordinates':
            return field.__meta?.advancedSearch ? { xs: 12, sm: 6, md: 4 } : { xs: 12 }
        case 'year':
            return {
                xs: 12,
            }
        case 'group':
        case 'onlyUIGroup':
        case 'fieldsArray':
            return {
                xs: 12,
                style: {
                    width: '100%',
                    maxWidth: '100%',
                },
            }
        case 'text':
            return field.multiline
                ? { xs: 12 }
                : {
                      xs: 12,
                      sm: 6,
                      md: 4,
                  }
        default:
            return {
                xs: 12,
                sm: 6,
                md: 4,
            }
    }
}

export default function DynamicFormFields({
    formFields = {},
    fieldsKey,
    path = [],
    gridSpacing = 3,
    flexDirection = 'row',
    flexWrap = 'wrap',
    disableAll = false,
    drawCoordinatesArea = false,
    isInBmsComparison = false,
    editableInfos = false,
    formId = '',
    ...props
}) {
    const { t } = useTranslation()
    const { register, control, watch } = useFormContext()
    const classes = useStyles()
    const [infoDialogProps, setInfoDialogProps] = React.useState(null)
    const pathString = path.join('__') // utile come dependency per innescare gli effetti solo quando path cambia 'davvero'
    const formFieldsString = JSON.stringify(formFields)
    function deleteChip(name, chipValue) {
        control.setValue(
            name,
            control.getValues(name).filter((value) => value !== chipValue),
            { shouldDirty: true }
        )
    }
    function isThisAConditionallyLA2RequiredField(formField) {
        // Some fields are required only depending on some other field's value
        let watchedValue
        if (typeof formField?.la2_required === 'object') {
            watchedValue = watch(formField.la2_required.dependsOn)
            if (typeof watchedValue === 'object') {
                // it's a numberUnit type of field
                watchedValue = watchedValue?.value
            }
            return formField.la2_required.cases[watchedValue] || false
        }
    }
    function adaptLabel(formFieldWithoutMeta, objKey) {
        if (
            isInBmsComparison &&
            (_.get(formFieldWithoutMeta, 'label', '').split('.').length > 2 ||
                objKey?.split('.').length > 2)
        ) {
            return t(
                _.get(formFieldWithoutMeta, 'label', '').split('.').slice(2).join('.') ||
                    objKey.split('.').slice(2).join('.')
            )
        } else {
            return t(formFieldWithoutMeta.label || objKey)
        }
    }
    const fieldInfoByFieldName = React.useMemo(() => {
        const _formFields = formFields._type ? { [fieldsKey]: formFields } : formFields
        const fieldInfoByFieldName = {}
        Object.keys(_formFields).forEach(
            (fieldName) =>
                (fieldInfoByFieldName[fieldName] = t([formId, ...path, fieldName].join('__'), {
                    ns: 'form_info',
                    defaultValue: '',
                }))
        )

        Object.keys(_formFields)
            .filter((fieldName) => !Boolean(fieldInfoByFieldName[fieldName]))
            .forEach(
                (fieldName) => (fieldInfoByFieldName[fieldName] = t(_formFields[fieldName]?.info))
            )

        if (editableInfos) {
            Object.keys(_formFields)
                .filter((fieldName) => !Boolean(fieldInfoByFieldName[fieldName]))
                .forEach(
                    (fieldName) =>
                        (fieldInfoByFieldName[fieldName] = t(
                            'Clicca per aggiungere una descrizione'
                        ))
                )
        }
        return fieldInfoByFieldName
    }, [editableInfos, formId, fieldsKey, t, pathString, formFieldsString])
    const clickInfoHandlerByFieldName = React.useMemo(() => {
        const _formFields = formFields._type ? { [fieldsKey]: formFields } : formFields
        const clickInfoHandlersByFieldName = {}
        if (editableInfos) {
            Object.keys(_formFields).forEach((fieldName) => {
                clickInfoHandlersByFieldName[fieldName] = () => {
                    const translationKey = [formId, ...path, fieldName].join('__')
                    setInfoDialogProps({
                        translationKey,
                        initialText: fieldInfoByFieldName[fieldName],
                    })
                }
            })
        }
        return clickInfoHandlersByFieldName
    }, [editableInfos, formId, fieldsKey, pathString, formFieldsString, fieldInfoByFieldName])
    const handleCloseEditInfoDialog = React.useCallback(() => setInfoDialogProps(null), [])

    function renderFormFieldByType(formField, objKey, formNamePrefix) {
        const { __meta = {}, ...formFieldWithoutMeta } = formField
        const { renderExtra, ...otherProps } = props

        switch (formFieldWithoutMeta._type) {
            case 'group': {
                // {} are needed to block-scope _type
                const { _type, _extra, ...fields } = formFieldWithoutMeta
                return (
                    <FieldsGroup
                        groupLabel={objKey}
                        key={objKey}
                        hideUI={__meta.hideGroupUI}
                        renderExtra={renderExtra}
                        extra={_extra}
                        info={fieldInfoByFieldName[objKey]}
                        onClickInfo={clickInfoHandlerByFieldName[objKey]}
                    >
                        <DynamicFormFields
                            disableAll={disableAll}
                            formFields={fields}
                            fieldsKey={formNamePrefix}
                            path={[...path, objKey]}
                            register={register}
                            control={control}
                            editableInfos={editableInfos}
                            formId={formId}
                            {...otherProps}
                        />
                    </FieldsGroup>
                )
            }
            case 'onlyUIGroup': {
                // {} are needed to block-scope _type
                // use this for fields that need to be grouped only in UI and flattened for be purposes
                const { _type, _extra, ...fields } = formFieldWithoutMeta
                const flattenedFormNamePrefix = formNamePrefix.replace(`.${objKey}`, '')
                return (
                    <FieldsGroup
                        groupLabel={objKey}
                        key={objKey}
                        renderExtra={renderExtra}
                        extra={_extra}
                    >
                        <DynamicFormFields
                            formFields={fields}
                            fieldsKey={flattenedFormNamePrefix}
                            path={[...path, objKey]}
                            register={register}
                            control={control}
                            editableInfos={editableInfos}
                            formId={formId}
                            {...otherProps}
                        />
                    </FieldsGroup>
                )
            }
            case 'fieldsArray':
                return __meta.hideGroupUI ? (
                    <FieldsArray
                        {...props}
                        key={objKey}
                        formNamePrefix={formNamePrefix}
                        path={[...path, objKey]}
                        control={control}
                        register={register}
                        addNewDisabled={formFieldWithoutMeta.disabled}
                        disableAll={disableAll}
                        watchConfig={formFieldWithoutMeta.watchConfig}
                        formFields={formField}
                        editableInfos={editableInfos}
                        formId={formId}
                        onClickInfo={clickInfoHandlerByFieldName[objKey]}
                    />
                ) : (
                    <FieldsGroup
                        key={objKey}
                        groupLabel={__meta.groupLabel || objKey}
                        info={fieldInfoByFieldName[objKey]}
                        onClickInfo={clickInfoHandlerByFieldName[objKey]}
                    >
                        <FieldsArray
                            {...props}
                            formNamePrefix={formNamePrefix}
                            path={[...path, objKey]}
                            control={control}
                            register={register}
                            addNewDisabled={formFieldWithoutMeta.disabled}
                            watchConfig={formFieldWithoutMeta.watchConfig}
                            disableAll={disableAll}
                            formFields={formField}
                            editableInfos={editableInfos}
                            formId={formId}
                            onClickInfo={clickInfoHandlerByFieldName[objKey]}
                        />
                    </FieldsGroup>
                )
            case 'text': // text or textarea
                return 'autoCompleteValues' in __meta ? (
                    <Controller
                        name={formNamePrefix}
                        control={control}
                        key={objKey}
                        render={({ ref, ...props }) => (
                            <SearchableTextField
                                {...props}
                                {...formFieldWithoutMeta.props}
                                className={classes.fullWidth}
                                freeSolo={!!__meta?.freeSolo}
                                type={'text'}
                                label={adaptLabel(formFieldWithoutMeta, objKey)}
                                multiline={formFieldWithoutMeta.multiline}
                                disabled={formFieldWithoutMeta.disabled || disableAll}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                required={__meta?.required}
                                autofocus={__meta?.autofocus}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                                autoCompleteValues={__meta.autoCompleteValues}
                            />
                        )}
                    />
                ) : (
                    <Controller
                        // {...props}
                        name={formNamePrefix}
                        control={control}
                        key={objKey}
                        // Avremmo voluto usare control.getValues, ma funziona solo in sviluppo.
                        // Dopo aver fatto la build non funziona più.
                        // defaultValue={control.getValues(formNamePrefix) || null}
                        // allora abbiamo usato lodash che non funziona nè in sviluppo nè con la build
                        // defaultValue={_.get(
                        //     props.defaultValue,
                        //     formNamePrefix.split('.').at(-1),
                        //     null
                        // )}
                        // prima usavamo render, adesso usiamo as
                        // ma abbiamo tolto le prop sopra perchè altrimenti non funziona più
                        as={({ ref, ...props }) => (
                            <StatefulTextField
                                {...props}
                                variant="outlined"
                                {...formFieldWithoutMeta.props}
                                className={classes.fullWidth}
                                label={adaptLabel(formFieldWithoutMeta, objKey)}
                                multiline={formFieldWithoutMeta.multiline}
                                disabled={formFieldWithoutMeta.disabled || disableAll}
                                minRows={formFieldWithoutMeta.multiline ? 3 : null}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                required={__meta?.required}
                                autofocus={__meta?.autofocus}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'number':
                return 'autoCompleteValues' in __meta ? (
                    <Controller
                        name={formNamePrefix}
                        control={control}
                        key={objKey}
                        render={({ ref, ...props }) => (
                            <SearchableTextField
                                {...props}
                                {...formFieldWithoutMeta.props}
                                className={classes.fullWidth}
                                freeSolo={!!__meta?.freeSolo}
                                type={'number'}
                                label={adaptLabel(formFieldWithoutMeta, objKey)}
                                multiline={formFieldWithoutMeta.multiline}
                                disabled={formFieldWithoutMeta.disabled || disableAll}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                required={__meta?.required}
                                autofocus={__meta?.autofocus}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                                autoCompleteValues={__meta.autoCompleteValues}
                            />
                        )}
                    />
                ) : (
                    <Controller
                        key={objKey}
                        name={formNamePrefix}
                        control={control}
                        // defaultValue={control.getValues(formNamePrefix) || null}
                        render={({ onChange, value }) => (
                            <StatefulTextField
                                variant="outlined"
                                {...formFieldWithoutMeta.props}
                                className={classes.fullWidth}
                                type="number"
                                styleVariant={formFieldWithoutMeta.variant || 'default'}
                                label={adaptLabel(formFieldWithoutMeta, objKey)}
                                onChange={onChange}
                                value={value ?? ''}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                disabled={formFieldWithoutMeta.disabled || disableAll}
                                inputProps={formField}
                                unit={formField?.unit}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'boolean':
                return (
                    <Controller
                        key={objKey}
                        control={control}
                        name={formNamePrefix}
                        // defaultValue={formFieldWithoutMeta.defaultValue || null}
                        as={({ ref, ...props }) => (
                            <AppToggleButtonGroup
                                {...props}
                                disableAll={disableAll}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                buttons={[
                                    { value: true, label: 'SI' },
                                    { value: false, label: 'NO' },
                                ]}
                                label={adaptLabel(formFieldWithoutMeta, objKey)}
                                styleVariant={formFieldWithoutMeta.variant || 'default'}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'select':
                // dropdown with checkboxes
                if (formFieldWithoutMeta.multiple) {
                    return (
                        <Controller
                            key={objKey}
                            control={control}
                            name={formNamePrefix}
                            // defaultValue={
                            //     formFieldWithoutMeta.defaultValue || null
                            // }
                            as={({ onChange, onBlur, value, name, ref }) => (
                                <AppSelect
                                    onChange={onChange}
                                    style={formFieldWithoutMeta.style}
                                    value={value}
                                    defaultValue={value || []}
                                    // defaultValue={
                                    //     formFieldWithoutMeta.defaultValue || []
                                    // }
                                    fieldLabel={adaptLabel(formFieldWithoutMeta, objKey)}
                                    optionsArray={formFieldWithoutMeta.options}
                                    multiple
                                    disableAll={disableAll}
                                    info={fieldInfoByFieldName[objKey]}
                                    onClickInfo={clickInfoHandlerByFieldName[objKey]}
                                    renderValue={(selected) => (
                                        <div className={classes.chipContainer}>
                                            {selected.map((value) => {
                                                const option = formFieldWithoutMeta.options.find(
                                                    (option) => option.value === value
                                                )
                                                return (
                                                    <Chip
                                                        key={value}
                                                        color="secondary"
                                                        className={classes.chip}
                                                        label={t(option.label) || t(option.value)}
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onDelete={() =>
                                                            deleteChip(formNamePrefix, value)
                                                        }
                                                    />
                                                )
                                            })}
                                        </div>
                                    )}
                                />
                            )}
                        />
                    )
                } else {
                    // standard dropdown
                    return (
                        <Controller
                            key={objKey}
                            control={control}
                            name={formNamePrefix}
                            // defaultValue={control.getValues(formNamePrefix) || null}
                            as={({ onChange, onBlur, value, name, ref }) => (
                                // don't translate text here, it's handled in AppSelect
                                <AppSelect
                                    className={classes.fullWidth}
                                    style={formFieldWithoutMeta.style}
                                    watchConfig={formFieldWithoutMeta.watchConfig}
                                    onChange={onChange}
                                    defaultValue={value || ''}
                                    value={value}
                                    fieldLabel={adaptLabel(formFieldWithoutMeta, objKey)}
                                    optionsArray={formFieldWithoutMeta.options}
                                    disableAll={disableAll}
                                    info={fieldInfoByFieldName[objKey]}
                                    onClickInfo={clickInfoHandlerByFieldName[objKey]}
                                    required={__meta?.required}
                                />
                            )}
                        />
                    )
                }
            case 'date':
                return (
                    <Controller
                        key={objKey}
                        control={control}
                        name={formNamePrefix}
                        // defaultValue={control.getValues(formNamePrefix) || null}
                        as={({ ref, ...props }) => (
                            <DateField
                                {...props}
                                disableAll={disableAll}
                                className={classes.fullWidth}
                                label={formFieldWithoutMeta.label || objKey} // dont translate text here
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'dateTime':
                return (
                    <Controller
                        key={objKey}
                        control={control}
                        name={formNamePrefix}
                        // defaultValue={control.getValues(formNamePrefix) || null}
                        as={({ ref, ...props }) => (
                            <DateTimeField
                                {...props}
                                disableAll={disableAll}
                                className={classes.fullWidth}
                                label={formFieldWithoutMeta.label || objKey} // dont translate text here
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'email':
                return (
                    <Controller
                        name={formNamePrefix}
                        control={control}
                        key={objKey}
                        {...props}
                        render={({ ref, ...props }) => (
                            <StatefulTextField
                                variant="outlined"
                                type="email"
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                                className={classes.fullWidth}
                                label={t(formFieldWithoutMeta.label || objKey)}
                                disabled={formFieldWithoutMeta.disabled}
                                required={__meta?.required}
                                {...props}
                            />
                        )}
                    />
                )
            case 'attachment':
                // const overriddenHandleChange = (file, isActualChange) => {
                //     control.setValue(
                //         formNamePrefix,
                //         file,
                //         { shouldDirty: true } // distinguish from backend sending image to set and user set image
                //     )
                // }
                // register(formNamePrefix)
                return __meta.hideGroupUI ? (
                    <Controller
                        key={objKey}
                        control={control}
                        name={formNamePrefix}
                        defaultValue={control.getValues(formNamePrefix)}
                        as={({ onChange, ...props }) => (
                            <AttachmentDropzone
                                value={props.value}
                                name={props.name}
                                defaultValue={formFieldWithoutMeta.defaultValue}
                                useDialog={formFieldWithoutMeta.useDialog}
                                filesToAccept={formFieldWithoutMeta.acceptedFiles}
                                control={control}
                                handleChange={onChange}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                disableAll={disableAll}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                ) : (
                    <FieldsGroup
                        groupLabel={objKey}
                        key={objKey}
                        info={fieldInfoByFieldName[objKey]}
                        onClickInfo={clickInfoHandlerByFieldName[objKey]}
                    >
                        <Controller
                            key={objKey}
                            control={control}
                            name={formNamePrefix}
                            defaultValue={control.getValues(formNamePrefix)}
                            as={({ onChange, ...props }) => (
                                <AttachmentDropzone
                                    value={props.value}
                                    name={props.name}
                                    defaultValue={formFieldWithoutMeta.defaultValue}
                                    useDialog={formFieldWithoutMeta.useDialog}
                                    filesToAccept={formFieldWithoutMeta.acceptedFiles}
                                    control={control}
                                    handleChange={onChange}
                                    watchConfig={formFieldWithoutMeta.watchConfig}
                                    disableAll={disableAll}
                                />
                            )}
                        />
                    </FieldsGroup>
                )
            case 'buttonGroup':
                return (
                    <Controller
                        control={control}
                        name={formNamePrefix}
                        // defaultValue={formFieldWithoutMeta.defaultValue || null}
                        as={({ ref, ...props }) => (
                            <AppToggleButtonGroup
                                {...props}
                                disableAll={disableAll}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                buttons={formFieldWithoutMeta.buttons}
                                label={adaptLabel(formFieldWithoutMeta, objKey)}
                                styleVariant={formFieldWithoutMeta.variant}
                                required={__meta?.required}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'numberUnit':
                return (
                    <Controller
                        key={objKey}
                        control={control}
                        name={`${formNamePrefix}.value`}
                        // defaultValue={{
                        //     value: null,
                        //     unit: formFieldWithoutMeta.unit,
                        // }}
                        render={({ ref, onChange, ...props }) => (
                            <UnitMeasureField
                                {...props}
                                {...formFieldWithoutMeta.props}
                                className={classes.fullWidth}
                                unit={formFieldWithoutMeta.unit}
                                defaultValue={formFieldWithoutMeta.defaultValue}
                                watchConfig={formFieldWithoutMeta.watchConfig}
                                label={formFieldWithoutMeta.label || objKey}
                                step={formFieldWithoutMeta.step || null}
                                min={formFieldWithoutMeta.min || null}
                                disabled={disableAll || formFieldWithoutMeta.disabled}
                                onChange={onChange}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'coordinates':
                return (
                    <Controller
                        key={objKey}
                        control={control}
                        name={formNamePrefix}
                        defaultValue={formFieldWithoutMeta.defaultValue}
                        as={({ ref, ...props }) => (
                            <CoordinatesField
                                {...props}
                                drawCoordinatesArea={drawCoordinatesArea}
                                meta={__meta}
                                disableAll={disableAll}
                                info={fieldInfoByFieldName[objKey]}
                                onClickInfo={clickInfoHandlerByFieldName[objKey]}
                            />
                        )}
                    />
                )
            case 'year':
                return (
                    <FieldsGroup
                        groupLabel={objKey}
                        key={objKey}
                        hideUI={__meta.hideGroupUI}
                        info={fieldInfoByFieldName[objKey]}
                    >
                        <Controller
                            control={control}
                            name={formNamePrefix}
                            as={({ value, ref, ...props }) => (
                                <>
                                    <YearField
                                        {...props}
                                        disableAll={disableAll}
                                        className={classes.fullWidth}
                                        value={
                                            value || {
                                                anno: null,
                                                anno_tipo: null,
                                            }
                                        }
                                        // defaultValue={
                                        //     formFieldWithoutMeta.value || {
                                        //         anno: null,
                                        //         anno_tipo: null,
                                        //     }
                                        // }
                                        selectLabel={t('yearfield.tipo')}
                                        selectOptions={formFieldWithoutMeta.anno_tipo.options}
                                    />
                                    {/*<DynamicFormFields />*/}
                                </>
                            )}
                        />
                    </FieldsGroup>
                )
            case 'hidden':
                return (
                    <Controller
                        key={objKey}
                        control={control}
                        name={formNamePrefix}
                        defaultValue={formFieldWithoutMeta?.defaultValue}
                        as={({ ref, ...props }) => (
                            <input
                                {...props}
                                type="hidden"
                                value={props?.value ?? formFieldWithoutMeta?.defaultValue}
                            />
                        )}
                    />
                )
            // return <input {...control.register(formNamePrefix)} type="hidden" />
            case 'linkField':
                return <LinkField formNamePrefix={formNamePrefix} />
            default:
                return <p key={objKey}>Questo formfield ({objKey}) non è ancora supportato</p>
        }
    }
    return (
        <Grid
            item
            container
            spacing={gridSpacing}
            direction={flexDirection}
            wrap={flexWrap}
            alignItems={flexDirection === 'row' ? 'flex-end' : 'flex-start'}
        >
            {formFields._type ? (
                <Grid
                    {...layoutPoints(formFields)} // set breakpoints
                    item
                    className={`${formFields.multiline && classes.textareaContainer} ${
                        classes.marginBottom
                    }`}
                    style={{
                        position: 'relative',
                        alignSelf: formFields._type === 'attachment' ? 'center' : 'unset',
                    }}
                >
                    {formFields?.la2_required === true && <RequiredLA2Badge />}
                    {renderFormFieldByType(formFields, fieldsKey, fieldsKey)}
                </Grid>
            ) : (
                Object.keys(formFields).map((objKey) => {
                    const formField = formFields[objKey]
                    const formNamePrefix = fieldsKey ? fieldsKey + '.' + objKey : objKey
                    const isRequiredField =
                        formField?.la2_required === true ||
                        isThisAConditionallyLA2RequiredField(formField)

                    if (formField._type === 'hidden')
                        return renderFormFieldByType(formField, objKey, formNamePrefix)

                    return (
                        <Grid
                            {...layoutPoints(formField)} //set breakpoints
                            key={objKey}
                            item
                            className={`${formField.multiline && classes.textareaContainer} ${
                                classes.marginBottom
                            }`}
                            style={{
                                position: 'relative',
                                alignSelf: 'center',
                            }}
                        >
                            {isRequiredField && <RequiredLA2Badge />}
                            {renderFormFieldByType(formField, objKey, formNamePrefix)}
                        </Grid>
                    )
                })
            )}
            {editableInfos && infoDialogProps && (
                <FieldInfoDialog {...infoDialogProps} onClose={handleCloseEditInfoDialog} />
            )}
        </Grid>
    )
}
