import MethodologyPage from '../MethodologyPage'
import { Button, Chip, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '@material-ui/data-grid'
import { useQueryProcedureAnalyses } from '../../customHooks/queryHooks/analysisQueries'
import { AppChip } from '../../components/QuickFilterChip'
import { AnalysisVersionDisplayDialog } from '../../components/Dialogs/AnalysisVersionDisplayDialog'
import { AnalysisCreationDialog } from '../../components/Dialogs/AnalysisCreationDialog'
import { AnalysisVersionCreationDialog } from "../../components/Dialogs/AnalysisVersionCreationDialog";

function useProcedureAnalysesTable(
    onVersionClick = (analysis, majorMinorPatch) => {
        console.log(analysis, majorMinorPatch)
    },
    onCreateVersionClick = (analysis) => {
        console.log(analysis)
    }
) {
    const { t } = useTranslation()
    const { data: analyses } = useQueryProcedureAnalyses()
    return React.useMemo(
        () => ({
            rows: analyses ?? [],
            columns: [
                {
                    field: 'template_name',
                    headerName: 'Name',
                    width: 150,
                    editable: false,
                },
                {
                    field: 'versions',
                    headerName: 'Versions',
                    width: 500,
                    renderCell: (params) => {
                        return (
                            <Grid container spacing={1}>
                                {params.value.map((version) => {
                                    const isActiveVersion =
                                        params.row?.active_version?.version_major_minor_patch ===
                                        version?.version_major_minor_patch
                                    return (
                                        <Grid item key={version.version_major_minor_patch}>
                                            <AppChip
                                                label={version.version_major_minor_patch}
                                                isActive={isActiveVersion}
                                                onClick={() =>
                                                    onVersionClick(
                                                        params?.row,
                                                        version?.version_major_minor_patch
                                                    )
                                                }
                                                // variant="outlined"
                                            />
                                        </Grid>
                                    )
                                })}
                                <Grid item>
                                    <Chip
                                        variant={'outlined'}
                                        label={t('create_new_version')}
                                        onClick={() =>
                                            onCreateVersionClick(params.row)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )
                    },
                },
            ],
        }),
        [analyses]
    )
}



const useStyles = makeStyles((theme) => ({}))

export default function ConfigureAnalysis({children, maxWidth = 'md'}) {
    const classes = useStyles()
    const { t } = useTranslation()

    const [newAnalysisModalIsOpen, setNewAnalysisModalIsOpen] = React.useState(false)
    
    function handleOpenNewAnalysisModal() {
        setNewAnalysisModalIsOpen(true)
    }
    const displayAnalysisVersionDialogRef = React.useRef(null)
    const {open: openDisplayAnalysisVersionDialog} = displayAnalysisVersionDialogRef?.current || {}
    
    const createAnalysisVersionDialogRef = React.useRef(null)
    const {open: openCreateAnalysisVersionDialogRef} = createAnalysisVersionDialogRef?.current || {}

    
    const tableProps = useProcedureAnalysesTable(openDisplayAnalysisVersionDialog, openCreateAnalysisVersionDialogRef)

    return (
        <MethodologyPage methodology="dynamics" >
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.overlay}
            >
                <Grid item xs={12} md={12} >
                    <Button variant="contained" color="secondary" onClick={handleOpenNewAnalysisModal}>
                        {t('aggiungi_analisi')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            {...tableProps}
                            // pageSize={5}
                            // rowsPerPageOptions={[5]}
                            // checkboxSelection
                            // disableSelectionOnClick
                        />
                    </div>
                </Grid>
            </Grid>
            {/*Dialog di creazione di una nuova analisi*/}
            <AnalysisCreationDialog open={newAnalysisModalIsOpen} onClose={() => setNewAnalysisModalIsOpen(false)}/>
            <AnalysisVersionDisplayDialog ref={displayAnalysisVersionDialogRef} />
            <AnalysisVersionCreationDialog ref={createAnalysisVersionDialogRef} />
        </MethodologyPage>
    );
}
