import { CircularProgress, Fab, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import AppForm from '../../components/AppForm'
import LoadingSpinner from '../../components/LoadingSpinner'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'
import SaveIcon from '@material-ui/icons/Save'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { levelsQueries, proceduresQueries } from '../../networking'
import { resisto_vulnerabilità_locali_fields } from '../../FormConfigs/vulnerabilità_locali_config'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import { replaceEmptyStringsWithNullWithin } from '../../FormConfigs/utils'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

export const Vulnerabilità_locali = () => {
    const procedureId = useParams().procedureId
    const classes = useStyles()
    const { t } = useTranslation()

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const { isDirty, isValid } = methods.formState

    const { data: procedure, isLoading: isProcedureLoading } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )

    const { isLoading } = useQuery(
        [levelsQueries.getResistoLocalVulnerabilities.name, procedureId],
        () => levelsQueries.getResistoLocalVulnerabilities.fn(procedureId),
        {
            onSuccess: (data) => methods.reset(data),
        }
    )

    const updateLevelMutation = useMutation((data) =>
        levelsQueries.updateLevel.fn(
            procedureId,
            'resisto',
            'local_vulnerabilities',
            replaceEmptyStringsWithNullWithin(data)
        )
    )

    const { isUpdateLivelloLoading } = updateLevelMutation

    const sendData = (data) => {
        updateLevelMutation.mutate(data)
    }
    return (
        <MethodologyPage methodology="resisto">
            {isProcedureLoading ? (
                <LoadingSpinner />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {capitalizeFirstLetter(t('vulnerabilità_locali'))}
                    </Typography>
                    <AppForm onSubmit={sendData} methods={methods}>
                        <Grid container justifyContent="center" spacing={3}>
                            {Object.keys(resisto_vulnerabilità_locali_fields).map((section, i) => (
                                <Grid item xs={12}>
                                    <DynamicFormFields
                                        fieldsKey={section}
                                        formFields={resisto_vulnerabilità_locali_fields[section]}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={isLoading || isUpdateLivelloLoading || !isDirty || !isValid}
                            className={classes.fab}
                        >
                            {isUpdateLivelloLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    </AppForm>
                </Paper>
            )}
        </MethodologyPage>
    )
}
