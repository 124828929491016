import React from 'react'
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Grid,
    Paper,
    Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import _ from 'lodash'
import SearchBar from '../FormItems/SearchBar'

const useStyles = makeStyles((theme) => ({
    searchBarContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        flex: 0,
    },
    list: {
        height: 197,
        overflow: 'auto',
        margin: theme.spacing(2),
        background: theme.palette.background.contrast,
    },
    avatar: {
        color: theme.palette.primary.light,
        background: theme.palette.background.disabledAlpha60,
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    noResults: {
        marginTop: 10,
        color: theme.palette.text.disabled,
    },
}))

export default function SearchDialog({
    open,
    setIsOpen,
    title,
    subtitle,
    data = [],
    icon,
    button,
    handleButtonClick,
    handleResultClick,
    renderResult = null,
    onSearchChange = undefined,
    useLocalSearch = true,
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [searchInput, setSearchInput] = React.useState('')
    const [filteredResults, setFilteredResults] = React.useState([])

    const fuseOptions = {
        keys: ['name', 'first_name', 'last_name'],
        threshold: 0.5,
    }

    const memoizedFuse = React.useMemo(() => {
        if (Array.isArray(data)) {
            return useLocalSearch && new Fuse(data, fuseOptions)
        } else {
            console.warn('Fuse instance received non-Array data[]', { data })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, useLocalSearch])

    React.useEffect(() => {
        if (_.isEmpty(data) && !_.isEmpty(filteredResults)) {
            setFilteredResults([])
        } else if (_.isEmpty(data) && _.isEmpty(filteredResults)) {
            return
        } else if (searchInput && useLocalSearch) {
            setFilteredResults(memoizedFuse.search(searchInput))
        } else {
            setFilteredResults(
                data.map((result) => ({
                    item: result,
                }))
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput, data])

    function handleSearch(value) {
        setSearchInput(value)
        if (typeof onSearchChange === 'function') {
            onSearchChange(value)
        }
    }

    return (
        <Dialog open={open} onClose={() => setIsOpen(false)} maxWidth="xs">
            <Grid container direction="column" wrap="nowrap">
                <Grid item xs={12} style={{ flex: 0 }}>
                    <DialogTitle disableTypography>
                        <Typography variant="h6" component="h2" align="center">
                            {t(title)}
                        </Typography>
                        {subtitle && (
                            <Typography
                                variant="caption"
                                display="block"
                                align="center"
                                color="textSecondary"
                            >
                                {t(subtitle)}
                            </Typography>
                        )}
                    </DialogTitle>
                </Grid>
                {button && (
                    <Grid item style={{ alignSelf: 'center' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            className={classes.button}
                            onClick={handleButtonClick}
                        >
                            {t(button)}
                        </Button>
                    </Grid>
                )}
                <SearchBar
                    item
                    xs={12}
                    fullWidth
                    autoFocus
                    className={classes.searchBarContainer}
                    input={searchInput}
                    setInput={handleSearch}
                    onEnterPress={() => handleSearch(searchInput)}
                />
                <Grid item xs={12}>
                    <List disablePadding>
                        <Paper elevation={0} className={classes.list}>
                            {filteredResults.length === 0 && (
                                <Typography
                                    variant="caption"
                                    component="p"
                                    align="center"
                                    className={classes.noResults}
                                >
                                    {t('no_results')}
                                </Typography>
                            )}
                            {filteredResults.map((result) =>
                                renderResult ? (
                                    renderResult(result)
                                ) : (
                                    <ListItem
                                        key={result.item.id || result.item.name}
                                        button
                                        divider
                                        onClick={() =>
                                            handleResultClick(result.item.id || result.item.name)
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar src={null} className={classes.avatar}>
                                                {icon}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                result.item.name ||
                                                `${result.item.first_name} ${result.item.last_name}`
                                            }
                                            primaryTypographyProps={{
                                                color: 'primary',
                                            }}
                                        />
                                    </ListItem>
                                )
                            )}
                        </Paper>
                    </List>
                </Grid>
            </Grid>
        </Dialog>
    )
}
