import { useTranslation } from 'react-i18next'
import {
    AppBar,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import {
    booleanType,
    createFieldArray,
    createSelect,
    replaceEmptyStringsWithNullWithin,
    textareaType,
    textType,
} from '../../../FormConfigs/utils'
import { useForm } from 'react-hook-form'
import AppForm from '../../../components/AppForm'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, useQuery } from 'react-query'
import { analysisQueries, queryClient } from '../../../networking'
import { usePendingOperationsStore } from '../../../stateManagement'
import { DataGrid } from '@material-ui/data-grid'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import { yellow } from '@material-ui/core/colors'
import DisplayValidationErrors from '../DisplayValidationErrors'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    hide: {
        display: 'none',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    analysis_catalog_table: {
        height: 400,
        width: '100%',
        backgroundColor: 'white',
    },
    default_analysis: {
        backgroundColor: yellow[700],
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function AnalysisCatalog({ children, maxWidth = 'md' }) {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()

    // New AnalysisCatalog - Start
    const [isOpenNewAnalysisCatalogForm, setIsOpenNewAnalysisCatalogForm] = React.useState(false)
    const handleClickOpenNewAnalysisCatalogForm = () => {
        setIsOpenNewAnalysisCatalogForm(true)
    }
    const handleCloseNewAnalysisCatalogForm = () => {
        setIsOpenNewAnalysisCatalogForm(false)
    }
    // New AnalysisCatalog - End

    // New Analysis Version - Start
    const [selectedAnalysisCatalogId, setSelectedAnalysisCatalogId] = React.useState(null)
    const [modalNewAnalysisVersionIsOpen, setModalNewAnalysisVersionIsOpen] = React.useState(false)
    const handleClickNewAnalysisVersion = (analysis_catalog_id) => {
        setSelectedAnalysisCatalogId(analysis_catalog_id)
        setModalNewAnalysisVersionIsOpen(true)
    }
    const handleCloseNewModalNewAnalysisVersion = () => {
        setSelectedAnalysisCatalogId(null)
        setModalNewAnalysisVersionIsOpen(false)
    }
    // New Analysis Version - End

    // Change Active Version - Start
    const [selectedAnalysisCatalog, setSelectedAnalysisCatalog] = React.useState(null)
    const [changeActiveVersionModalIsOpen, setChangeActiveVersionModalIsOpen] =
        React.useState(false)
    const handleClickChangeActiveVersionModal = (analysis_catalog_id) => {
        setSelectedAnalysisCatalog(analysisData.find((item) => item.id === analysis_catalog_id))
        setChangeActiveVersionModalIsOpen(true)
    }
    const handleCloseChangeActiveVersionModal = () => {
        setSelectedAnalysisCatalog(null)
        setChangeActiveVersionModalIsOpen(false)
    }
    // Change Active Version - End

    const { data: analysisData = [] } = useQuery(
        [analysisQueries.getAnalysisCatalog.name],
        analysisQueries.getAnalysisCatalog.fn
    )

    useEffect(() => {
        console.log('analysisData update: ', analysisData)
    }, [analysisData])

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })
    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState
    const [analysisCatalogValidationErrors, setAnlysisCatalogValidationErrors] = useState([])

    const analysisCatalogMutation = useMutation(
        (data) => analysisQueries.createAnalysisCatalog.fn(replaceEmptyStringsWithNullWithin(data)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(analysisQueries.getAnalysisCatalog.name)
                setAnlysisCatalogValidationErrors([])
                handleCloseNewAnalysisCatalogForm()
            },
            onError: (error) => {
                setAnlysisCatalogValidationErrors(error.response.data)
            },
        }
    )

    const [analysisCatalogVersionValidationErrors, setAnlysisCatalogVersionValidationErrors] =
        useState([])
    const analysisCatalogVersionMutation = useMutation(
        (data) =>
            analysisQueries.createAnalysisCatalogVersion.fn(
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(analysisQueries.getAnalysisCatalog.name)
                setAnlysisCatalogVersionValidationErrors([])
                handleCloseNewModalNewAnalysisVersion()
            },
            onError: (error) => {
                setAnlysisCatalogVersionValidationErrors(error.response.data)
            },
        }
    )

    const saveActiveAnalysisCatalogVersionMutation = useMutation(
        (data) =>
            analysisQueries.saveAnalysisActiveVersion.fn(replaceEmptyStringsWithNullWithin(data)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(analysisQueries.getAnalysisCatalog.name)
            },
        }
    )

    function submitNewAnalysisCatalog(data) {
        // console.log('sendData', data)
        /*
        Example
        {
            "analysis": {
            "name": "sdasdsa",
                "type": "modale",
                "available_for_infrastructure_type": [
                    "building",
                    "bridge",
                    "windmill"
                ]
            }
        }*/
        // console.log(
        //     'available_for_infrastructure_type: ',
        //     data['analysis']['available_for_infrastructure_type']
        // )
        if (data['analysis']['available_for_infrastructure_type'].length === 0) {
            alert('Select analysis for infrastructure type!')
            return
        }
        if (data['analysis']['available_for_execution_type'].length === 0) {
            alert('Select analysis execution type for infrastructure!')
            return
        }
        if (isDirty && isValid) {
            runPendingOps().then(() => {
                analysisCatalogMutation.mutate(data['analysis'])
            })
        }
    }

    function submitNewAnalysisCatalogVersion(data) {
        /*
        {
            "analysis_version": {
                "version_major_minor_patch": "0.0.1",
                "set_active_version": true,
                "docker_image_url": "692353507435.dkr.ecr.eu-central-1.amazonaws.com/it.builti.analysis.calolo1:latest",
                "schedule_type": "time_interval",
                "input_files": [
                    {
                        "name": "a.json",
                        "content": "{\n\"1\":2\n}"
                    },
                    {
                        "name": "b.json",
                        "content": "{\n\"key\":\"ciao\"\n}"
                    }
                ]
            }
        }
        */
        // console.log('submitNewAnalysisCatalogVersion: ', data['analysis_version'])
        // Converto gli input_files.content a JSON
        let sanitizedInputFiles = []
        let form_data = data['analysis_version']
        if (!selectedAnalysisCatalogId) {
            alert('Select the analysis you want to version!')
            return
        }

        form_data['analysis_version_id'] = selectedAnalysisCatalogId
        if (form_data['input_files'] && form_data['input_files'].length > 0) {
            // If file end with .json try to validate
            try {
                for (let i = 0; i < form_data['input_files'].length; i++) {
                    if (form_data['input_files'][i]['name'].endsWith('.json')) {
                        // Parse the 'content' attribute to check if it's valid JSON
                        sanitizedInputFiles.push({
                            name: form_data['input_files'][i]['name'],
                            content: JSON.parse(form_data['input_files'][i]['content']),
                        })
                    } else {
                        // Other file
                        sanitizedInputFiles.push({
                            name: form_data['input_files'][i]['name'],
                            content: form_data['input_files'][i]['content'],
                        })
                    }
                }
            } catch (e) {
                console.error('Invalid JSON content detected: ', e)
                alert('Invalid JSON: ', e)
                return // Exit the function if invalid JSON content was found
            }
            form_data['input_files'] = sanitizedInputFiles
        }

        if (isDirty && isValid) {
            runPendingOps().then(() => {
                analysisCatalogVersionMutation.mutate(form_data)
            })
        }
    }

    const columns_analysis_catalog = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
        },
        {
            field: 'active_version',
            headerName: 'Active Version',
            type: 'string',
            width: 200,
            renderCell: (params) => {
                return (
                    <Chip
                        key={params.version_major_minor_patch}
                        label={params.value?.version_major_minor_patch || ''}
                        variant="outlined"
                    />
                )
            },
        },
        {
            field: 'available_for_infrastructure_type',
            headerName: 'Available for infrastructure',
            width: 250,
            renderCell: (params) => {
                return (
                    <>
                        {params.value.map((type) => (
                            <Chip key={type} label={type} variant="outlined" />
                        ))}
                    </>
                )
            },
        },
        {
            field: 'available_for_execution_type',
            headerName: 'Execution',
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        {params.value.map((type) => (
                            <Chip key={type} label={type} variant="outlined" />
                        ))}
                    </>
                )
            },
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleClickNewAnalysisVersion(params.row.id)}
                        >
                            New Version
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleClickChangeActiveVersionModal(params.row.id)}
                        >
                            Info
                        </Button>
                    </>
                )
            },
        },
    ]
    const handleSetAsActiveVersionClick = (data) => {
        runPendingOps().then(() => {
            saveActiveAnalysisCatalogVersionMutation.mutate({
                analysis_catalog_id: selectedAnalysisCatalog.id,
                active_version_id: data.version_major_minor_patch,
            })
            handleCloseChangeActiveVersionModal()
        })
    }

    return (
        <>
            <Grid item container direction="column" justify-conte="space-around" spacing={3}>
                <Grid item>
                    <Typography variant="h4" component="h1" align="center">
                        {t('admin.section_title')}
                    </Typography>
                    {t('admin.analysis.description')}
                </Grid>
                <Grid item align="right">
                    <Button variant="contained" onClick={handleClickOpenNewAnalysisCatalogForm}>
                        {t('admin.analysis.create_new_analysis')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    Analisi Configurate
                    <div className={classes.analysis_catalog_table}>
                        <DataGrid
                            rows={analysisData}
                            columns={columns_analysis_catalog}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    </div>
                </Grid>
            </Grid>
            {/* New Analysis Catalog Form */}
            <Dialog
                open={isOpenNewAnalysisCatalogForm}
                onClose={handleCloseNewAnalysisCatalogForm}
                aria-labelledby="form-analysis-catalog"
                fullWidth={true}
                maxWidth="md"
            >
                <AppForm onSubmit={submitNewAnalysisCatalog} methods={methods}>
                    <DialogTitle id="form-analysis-catalog">Analysis Catalog</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Configure new analysis catalog.</DialogContentText>
                        <Grid item>
                            <DisplayValidationErrors
                                validationErrors={analysisCatalogValidationErrors}
                            />
                            <DynamicFormFields
                                disableAll={false}
                                fieldsKey={'analysis'}
                                formFields={{
                                    name: { ...textType, __meta: { required: true } },
                                    type: {
                                        ...createSelect(['modale', 'soglie']),
                                        __meta: { required: true },
                                    },
                                    available_for_infrastructure_type: {
                                        ...createSelect(['building', 'bridge', 'windmill'], true),
                                    },
                                    available_for_execution_type: {
                                        ...createSelect(['time_interval', 'fixed_time'], true),
                                    },
                                }}
                                formId={`form_analysis`}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty || !isValid}
                        >
                            {analysisCatalogMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <>
                                    <SaveIcon />
                                    {t('salva')}
                                </>
                            )}
                        </Fab>
                    </DialogActions>
                </AppForm>
            </Dialog>
            {/* New Analysis Version Form */}
            <Dialog
                fullScreen
                open={modalNewAnalysisVersionIsOpen}
                onClose={handleCloseNewModalNewAnalysisVersion}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseNewModalNewAnalysisVersion}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            New Version
                        </Typography>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={handleCloseNewModalNewAnalysisVersion}
                        >
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <AppForm onSubmit={submitNewAnalysisCatalogVersion} methods={methods}>
                    <DialogTitle id="form-analysis-catalog">Analysis version</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Configure new analysis version.</DialogContentText>
                        <Grid item>
                            <DisplayValidationErrors
                                validationErrors={analysisCatalogVersionValidationErrors}
                            />
                            <DynamicFormFields
                                disableAll={false}
                                fieldsKey={'analysis_version'}
                                formFields={{
                                    version_major_minor_patch: {
                                        ...textType,
                                        __meta: { required: true },
                                    },
                                    configuration: {
                                        _type: 'group',
                                        docker_image_url: { ...textType, __meta: { required: true } },
                                        scheduled_execution_type: {
                                            ...createSelect(['time_interval', 'fixed_time']),
                                            __meta: { required: true },
                                        },
                                        input_files: {
                                            ...createFieldArray({
                                                name: textType,
                                                content: textareaType,
                                            }),
                                        },
                                    },
                                    // docker_tag: { ...textType, __meta: { required: true } },
                                    set_active_version: booleanType,
                                }}
                                formId={`form_analysis_version`}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty || !isValid}
                        >
                            {analysisCatalogMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <>
                                    <SaveIcon />
                                    {t('salva')}
                                </>
                            )}
                        </Fab>
                    </DialogActions>
                </AppForm>
            </Dialog>
            {/* Analysis Catalog Detail */}
            <Dialog
                fullScreen
                open={changeActiveVersionModalIsOpen}
                onClose={handleCloseChangeActiveVersionModal}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseChangeActiveVersionModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AppForm onSubmit={submitNewAnalysisCatalogVersion} methods={methods}>
                    <DialogTitle id="form-analysis-catalog">Analysis version</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Configure default analysis version active.
                        </DialogContentText>
                        <Grid item>
                            {selectedAnalysisCatalog &&
                                selectedAnalysisCatalog.versions.map((version) => (
                                    <Chip
                                        key={version.version_major_minor_patch}
                                        label={version.version_major_minor_patch}
                                        className={
                                            selectedAnalysisCatalog.active_version
                                                .version_major_minor_patch ===
                                            version.version_major_minor_patch
                                                ? classes.default_analysis
                                                : ''
                                        }
                                        variant="outlined"
                                        clickable={
                                            selectedAnalysisCatalog.active_version
                                                .version_major_minor_patch !==
                                            version.version_major_minor_patch
                                        }
                                        onClick={() => handleSetAsActiveVersionClick(version)}
                                    />
                                ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions></DialogActions>
                </AppForm>
            </Dialog>
        </>
    )
}
