import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core'

export function RequiredLA2Badge() {
    const theme = useTheme()

    return (
        <div
            style={{
                position: 'absolute',
                top: 2,
                left: 5,
                zIndex: 1000,
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
                fontSize: '1.3rem',
                textShadow: '0 0 black',
            }}
        >
            *
        </div>
    )
}

export function RequiredLA2Info() {
    const { t } = useTranslation()

    return (
        <Typography variant="body2" align="center" color="textSecondary" gutterBottom paragraph>
            {t('required_LA2_field')}
        </Typography>
    )
}
