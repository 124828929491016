import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        height: '100%',
    },
    paper: {
        padding: theme.spacing(6),
    },
    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
}))

export default function CookiesPage() {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom paragraph>
                    {t('cookie_policy')}
                </Typography>
                <Typography paragraph variant="body2">
                    Questo documento contiene informazioni in merito alle tecnologie che consentono
                    a questa Applicazione di raggiungere gli scopi descritti di seguito. Tali
                    tecnologie permettono al Titolare di raccogliere e salvare informazioni (per
                    esempio tramite l’utilizzo di Cookie) o di utilizzare risorse (per esempio
                    eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo
                    interagisce con questa Applicazione.
                </Typography>
                <Typography paragraph variant="body2">
                    Per semplicità, in questo documento tali tecnologie sono sinteticamente definite
                    “Strumenti di Tracciamento”, salvo vi sia ragione di differenziare. Per esempio,
                    sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe
                    fuori luogo parlare di Cookie nel contesto di applicazioni per dispositivi
                    mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la
                    presenza di un browser. Per questo motivo, all’interno di questo documento il
                    temine Cookie è utilizzato solo per indicare in modo specifico quel particolare
                    tipo di Strumento di Tracciamento.
                </Typography>
                <Typography paragraph variant="body2">
                    Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento
                    potrebbero, inoltre richiedere il consenso dell’Utente. Se viene prestato il
                    consenso, esso può essere revocato liberamente in qualsiasi momento seguendo le
                    istruzioni contenute in questo documento.
                </Typography>
                <Typography paragraph variant="body2">
                    Questa Applicazione utilizza Strumenti di Tracciamento gestiti direttamente dal
                    Titolare (comunemente detti Strumenti di Tracciamento “di prima parte”) e
                    Strumenti di Tracciamento che abilitano servizi forniti da terzi (comunemente
                    detti Strumenti di Tracciamento “di terza parte”). Se non diversamente
                    specificato all’interno di questo documento, tali terzi hanno accesso ai
                    rispettivi Strumenti di Tracciamento.
                </Typography>
                <Typography paragraph variant="body2">
                    Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento simili
                    possono variare a seconda di quanto impostato dal Titolare o da ciascun
                    fornitore terzo. Alcuni di essi scadono al termine della sessione di navigazione
                    dell’Utente.
                </Typography>
                <Typography paragraph variant="body2">
                    In aggiunta a quanto specificato nella descrizione di ciascuna delle categorie
                    di seguito riportate, gli Utenti possono ottenere informazioni più dettagliate
                    ed aggiornate sulla durata, così come qualsiasi altra informazione rilevante -
                    quale la presenza di altri Strumenti di Tracciamento - nelle privacy policy dei
                    rispettivi fornitori terzi (tramite i link messi a disposizione) o contattando
                    il Titolare.
                </Typography>
                <Typography variant="h6" component="h2">
                    Attività strettamente necessarie a garantire il funzionamento di questa
                    Applicazione e la fornitura del Servizio
                </Typography>
                <Typography paragraph variant="body2">
                    Questa Applicazione utilizza Cookie comunemente detti “tecnici” o altri
                    Strumenti di Tracciamento analoghi per svolgere attività strettamente necessarie
                    a garantire il funzionamento o la fornitura del Servizio.
                </Typography>
                <Typography variant="body1">Strumenti di Tracciamento di terza parte</Typography>
                <Typography paragraph variant="body2">
                    - Protezione dallo SPAM
                </Typography>
                <Typography variant="h6" component="h2">
                    Altre attività che prevedono l’utilizzo di Strumenti di Tracciamento
                </Typography>
                <Typography variant="body1">Interazioni e funzionalità semplici</Typography>
                <Typography variant="body2">
                    Questa Applicazione utilizza Strumenti di Tracciamento per consentire semplici
                    interazioni e attivare funzionalità che permettono agli Utenti di accedere a
                    determinate risorse del Servizio e semplificano la comunicazione con il
                    Titolare.
                </Typography>
                <Typography paragraph variant="body2">
                    - Gestione dei tag
                </Typography>
                <Typography variant="body1">Misurazione</Typography>
                <Typography variant="body2">
                    Questa Applicazione utilizza Strumenti di Tracciamento per misurare il traffico
                    e analizzare il comportamento degli Utenti con l'obiettivo di migliorare il
                    Servizio.
                </Typography>
                <Typography paragraph variant="body2">
                    - Servizi di statistica anonimizzata
                </Typography>
                <Typography variant="h6" component="h2">
                    Come gestire le preferenze e prestare o revocare il consenso
                </Typography>
                <Typography paragraph variant="body2">
                    Esistono vari modi per gestire le preferenze relative agli Strumenti di
                    Tracciamento e per prestare o revocare il consenso, ove necessario: Gli Utenti
                    possono gestire le preferenze relative agli Strumenti di Tracciamento
                    direttamente tramite le impostazioni dei propri dispositivi - per esempio,
                    possono impedire l’uso o l’archiviazione di Strumenti di Tracciamento.
                </Typography>
                <Typography paragraph variant="body2">
                    In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da
                    consenso, l’Utente può prestare o revocare tale consenso impostando le proprie
                    preferenze all’interno dell’informativa sui cookie o aggiornando tali preferenze
                    tramite il widget delle impostazioni di tracciamento, se presente. Grazie ad
                    apposite funzioni del browser o del dispositivo è anche possibile rimuovere
                    Strumenti di Tracciamento precedentemente salvati. Altri Strumenti di
                    Tracciamento presenti nella memoria locale del browser possono essere rimossi
                    cancellando la cronologia di navigazione. Per quanto riguarda Strumenti di
                    Tracciamento di terza parte, gli Utenti possono gestire le preferenze e revocare
                    il consenso visitando il relativo link di opt out (qualora disponibile),
                    utilizzando gli strumenti descritti nella privacy policy della terza parte o
                    contattandola direttamente.
                </Typography>
                <Typography paragraph variant="body1">
                    Individuare le impostazioni relative agli Strumenti di Tracciamento
                </Typography>
                <Typography paragraph variant="body2">
                    Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie
                    in alcuni dei browser più diffusi ai seguenti indirizzi:
                </Typography>
                <ul>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Google Chrome
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Mozilla Firefox
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Apple Safari
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Microsoft Internet Explorer
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="https://support.microsoft.com/it-it/help/4027947"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Microsoft Edge
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Brave
                            </a>
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.link,
                            }}
                        >
                            <a
                                href="https://help.opera.com/latest/web-preferences/#cookies"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Opera
                            </a>
                        </Typography>
                    </li>
                </ul>
                <Typography paragraph variant="body2">
                    Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per
                    applicazioni mobili disattivandoli tramite le apposite impostazioni del
                    dispositivo, quali le impostazioni di pubblicità per dispositivi mobili o le
                    impostazioni relative al tracciamento in generale (gli Utenti possono consultare
                    le impostazioni del dispositivo per individuare quella pertinente).
                </Typography>
                <Typography variant="h6" component="h2">
                    Titolare del Trattamento dei Dati
                </Typography>
                <Typography variant="body2">BUILTI S.R.L.</Typography>
                <Typography variant="body2">Via Guelfa 5</Typography>
                <Typography variant="body2">40138 Bologna (BO)</Typography>
                <Typography paragraph variant="body2">
                    Indirizzo email del Titolare: support@builti.it
                </Typography>
                <Typography paragraph variant="body2">
                    Dal momento che l’uso di Strumenti di Tracciamento di terza parte su questa
                    Applicazione non può essere completamente controllato dal Titolare, ogni
                    riferimento specifico a Strumenti di Tracciamento di terza parte è da
                    considerarsi indicativo. Per ottenere informazioni complete, gli Utenti sono
                    gentilmente invitati a consultare la privacy policy dei rispettivi servizi terzi
                    elencati in questo documento.
                </Typography>
                <Typography paragraph variant="body2">
                    Data l'oggettiva complessità di identificazione delle tecnologie di
                    tracciamento, gli Utenti sono invitati a contattare il Titolare qualora
                    volessero ricevere ulteriori informazioni in merito all'utilizzo di tali
                    tecnologie su questa Applicazione.
                </Typography>
                <Typography variant="body1">Definizioni e riferimenti legali</Typography>
                <Typography variant="body2">Ultima modifica: 13 dicembre 2021</Typography>
            </Paper>
        </Container>
    )
}
