import React from 'react'
import { Typography, Card, Grid, CardContent, CardActionArea, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../../utils'
import {
    mit_la_1_model_fenomeni,
    sections_to_render_fenomeni_scheda,
    sections_to_render_fenomeni_frane,
    sections_to_render_fenomeni_idraulico,
} from '../../../FormConfigs/mit_la_1_config'

const useStyles = makeStyles((theme) => ({
    card: {
        width: 300,
        height: 100,
        background: theme.palette.background.paper,
    },
    cardDisabled: {
        width: 300,
        height: 100,
        color: theme.palette.text.disabled,
        background: theme.palette.background.default,
    },
    avatar: {
        height: 35,
        width: 35,
        background: theme.palette.secondary.main,
        marginLeft: 'auto',
    },
}))

export default function MenuFenomeni({ onSectionSelection }) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid
            container
            direction="column"
            spacing={3}
            alignContent="center"
            style={{ marginTop: 50 }}
        >
            <Grid item>
                <CardActionArea>
                    <Card
                        className={classes.card}
                        onClick={() =>
                            onSectionSelection({
                                currentSection: 'level1.frane_e_fenomeni_idraulici.scheda',
                                levelName: 'level1',
                                sections_to_render: sections_to_render_fenomeni_scheda,
                                model: mit_la_1_model_fenomeni,
                            })
                        }
                    >
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {capitalizeFirstLetter(t('scheda_descrittiva'))}
                            </Typography>
                            <Avatar className={classes.avatar}>
                                <ArrowForwardIcon color="primary" />
                            </Avatar>
                        </CardContent>
                    </Card>
                </CardActionArea>
            </Grid>
            <Grid item>
                <CardActionArea>
                    <Card
                        className={classes.card}
                        onClick={() =>
                            onSectionSelection({
                                currentSection: 'level1.frane_e_fenomeni_idraulici.rischio-frane',
                                levelName: 'level1.rischio_frane',
                                sections_to_render: sections_to_render_fenomeni_frane,
                                model: mit_la_1_model_fenomeni.rischio_frane,
                            })
                        }
                    >
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {t('rischio_frane')}
                            </Typography>
                            <Avatar className={classes.avatar}>
                                <ArrowForwardIcon color="primary" />
                            </Avatar>
                        </CardContent>
                    </Card>
                </CardActionArea>
            </Grid>
            <Grid item>
                <CardActionArea>
                    <Card
                        className={classes.card}
                        onClick={() =>
                            onSectionSelection({
                                currentSection:
                                    'level1.frane_e_fenomeni_idraulici.rischio-idraulico',
                                levelName: 'level1.rischio_idraulico',
                                sections_to_render: sections_to_render_fenomeni_idraulico,
                                model: mit_la_1_model_fenomeni.rischio_idraulico,
                            })
                        }
                    >
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {t('rischio_idraulico')}
                            </Typography>
                            <Avatar className={classes.avatar}>
                                <ArrowForwardIcon color="primary" />
                            </Avatar>
                        </CardContent>
                    </Card>
                </CardActionArea>
            </Grid>
        </Grid>
    )
}
