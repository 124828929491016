import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography, Card, Grid, CardContent, CardActionArea, Avatar } from '@material-ui/core'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { capitalizeFirstLetter } from '../../../utils'
import { useQuery } from 'react-query'
import { levelsQueries } from '../../../networking'
import NetworkErrorOverlay from '../../../components/NetworkErrorOverlay'
import MethodologyPage from '../../MethodologyPage'

const useStyles = makeStyles((theme) => ({
    card: {
        width: 300,
        height: 100,
        background: theme.palette.background.paper,
    },
    cardDisabled: {
        width: 300,
        height: 100,
        color: theme.palette.text.disabled,
        background: theme.palette.background.default,
    },
    avatar: {
        height: 35,
        width: 35,
        background: theme.palette.secondary.main,
        marginLeft: 'auto',
    },
}))

export default function LGP_LA1_fenomeni() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const procedureId = useParams().procedureId

    const { data, isLoading, error, refetch } = useQuery(
        [levelsQueries.getMitLevel1.name, procedureId],
        () => levelsQueries.getMitLevel1.fn(procedureId)
    )

    const rischioFraneDisabled = _.get(data, 'presenza_rischio.frana') === false
    const rischioIdraulicoDisabled = _.get(data, 'presenza_rischio.idraulico') === false

    return (
        <MethodologyPage methodology="lgp">
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                {t('fenomeni_frana_fenomeni_idraulici')}
            </Typography>
            {error ? (
                <NetworkErrorOverlay
                    actions={[{ onClick: refetch, label: t('retry') }]}
                    areActionsPending={isLoading}
                />
            ) : (
                <Grid
                    container
                    direction="column"
                    spacing={3}
                    alignContent="center"
                    style={{ marginTop: 50 }}
                >
                    <Grid item>
                        <CardActionArea>
                            <Card
                                className={classes.card}
                                onClick={() => history.push('./frane-e-fenomeni-idraulici/scheda')}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {capitalizeFirstLetter(t('scheda_descrittiva'))}
                                    </Typography>
                                    <Avatar className={classes.avatar}>
                                        <ArrowForwardIcon color="primary" />
                                    </Avatar>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                    <Grid item>
                        {!rischioFraneDisabled ? (
                            <CardActionArea>
                                <Card
                                    className={classes.card}
                                    onClick={() =>
                                        history.push('./frane-e-fenomeni-idraulici/rischio-frane')
                                    }
                                >
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            {t('rischio_frane')}
                                        </Typography>
                                        <Avatar className={classes.avatar}>
                                            <ArrowForwardIcon color="primary" />
                                        </Avatar>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        ) : (
                            <Card className={classes.cardDisabled}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {t('rischio_frane')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                    <Grid item>
                        {!rischioIdraulicoDisabled ? (
                            <CardActionArea>
                                <Card
                                    className={classes.card}
                                    onClick={() =>
                                        history.push(
                                            './frane-e-fenomeni-idraulici/rischio-idraulico'
                                        )
                                    }
                                >
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            {t('rischio_idraulico')}
                                        </Typography>
                                        <Avatar className={classes.avatar}>
                                            <ArrowForwardIcon color="primary" />
                                        </Avatar>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        ) : (
                            <Card className={classes.cardDisabled}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {t('rischio_idraulico')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </Grid>
            )}
        </MethodologyPage>
    )
}
