export const googleMapsStyle = [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
    // {
    //     featureType: 'traffic',
    //     elementType: 'labels',
    //     stylers: [{ visibility: 'off' }],
    // },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
    },
    {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
    },
]

export const colorsMapping = {
    0: '#8a929a9e',
    1: '#058241',
    2: '#98ca3b',
    3: '#e2cd20',
    4: '#ec8a22',
    5: '#c9202e',
    6: '#8A8A8A',
}

export const lightPalette = {
    primary: {
        light: '#323D4C',
        main: '#3d5374',
        dark: '#1b2533',
        contrastText: '#FCFCFC',
    },
    secondary: {
        main: '#FFBE45',
        dark: '#EC9200',
        contrastText: '#323D4C',
    },
    error: {
        main: '#b93422',
        dark: '#902517',
        contrastText: '#FCFCFC',
    },
    success: {
        main: '#00a651',
    },
    text: {
        primary: 'rgba(0,0,0,0.75)',
        secondary: 'rgba(0,0,0,0.5)',
        disabled: '#656565',
        notClickable: '#778aa1',
    },
    background: {
        disabled: '#a7b1ba',
        disabledAlpha60: '#8a929a9a',
        contrast: '#212e4033',
        paperLight: '#c2c8d0',
        paper: '#fff',
        default: '#eeeeee',
        defaultAlpha40: '#bcc2cb66',
        page: '#eaeaea',
        disabledModulePill: 'rgba(61,83,116,0.5)', // primary.main + alpha=0.5
    },
    common: {
        grey: '#bbbbbb',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
}

export const darkPalette = {
    primary: {
        light: '#323D4C',
        main: '#212E40',
        dark: '#1b2533',
        contrastText: '#FCFCFC',
    },
    secondary: {
        main: '#FFBE45',
        dark: '#EC9200',
        contrastText: '#323D4C',
    },
    error: {
        main: '#b93422',
        dark: '#902517',
        contrastText: '#FCFCFC',
    },
    success: {
        main: '#00a651',
    },
    text: {
        primary: '#FCFCFC',
        secondary: '#C2C2C2',
        disabled: '#656565',
        notClickable: '#5f6f82',
    },
    background: {
        disabled: '#a7b1ba',
        disabledAlpha60: '#8a929a9a',
        contrast: '#F0F0F0CC',
        paperLight: '#57606C',
        paper: '#323D4C',
        default: '#212E40',
        defaultAlpha40: 'rgba(33, 46, 64, 0.4)',
        page: '#1b2533',
        disabledModulePill: 'rgb(87, 96, 108, 0.8)', // background.paperLight + alpha=0.8
    },
    common: {
        grey: 'rgba(255,255,255,0.23)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
}

export const lightOverrides = {
    MuiOutlinedInput: {
        root: {
            backgroundColor: lightPalette.background.default,
            '&$focused $notchedOutline': {
                borderColor: lightPalette.primary.main,
            },
        },
    },
    MuiFormLabel: {
        root: {
            '&.Mui-focused': {
                color: lightPalette.primary.main,
            },
        },
    },
    MuiCardActionArea: {
        root: {
            borderRadius: '20px',
        },
    },
    MuiPaper: {
        rounded: {
            borderRadius: '20px',
        },
    },
    MuiButton: {
        root: {
            borderRadius: '20px',
        },
    },
    MuiAlert: {
        root: {
            borderRadius: '20px',
        },
    },
}

export const darkOverrides = {
    MuiOutlinedInput: {
        root: {
            backgroundColor: darkPalette.primary.main,
            '&$focused $notchedOutline': {
                borderColor: darkPalette.secondary.main,
            },
        },
    },
    MuiFormLabel: {
        root: {
            '&.Mui-focused': {
                color: darkPalette.secondary.main,
            },
        },
    },
    MuiCardActionArea: {
        root: {
            borderRadius: '20px',
        },
    },
    MuiPaper: {
        rounded: {
            borderRadius: '20px',
        },
    },
    MuiButton: {
        root: {
            borderRadius: '20px',
        },
    },
    MuiAlert: {
        root: {
            borderRadius: '20px',
        },
    },
}
