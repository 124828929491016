import { mit_la_0_model } from './mit_la_0_config'
import { mit_la_1_model_fenomeni, mit_la_1_model_scheda_descrittiva } from './mit_la_1_config'
import {
    booleanType,
    coordinateType,
    createFieldArray,
    createSelect,
    dateType,
    numberType,
    textType,
} from './utils'
import {
    mit_la_3_model_documents,
    mit_la_3_model_form,
    mit_la_3_model_results,
} from './mit_la_3_config'
// import { link_field } from "./procedure_config";

const procedureModel = Object.freeze({
    // REMINDME these must map 1:1 with backend logic, so edit only if you know what you're doing
    address: textType,
    gps_position: coordinateType,
    type: createSelect(['bridge', 'building']),
    methodologies: {
        mit_guidelines: {
            workspace: {
                level0: mit_la_0_model,
                level1: {
                    ...mit_la_1_model_scheda_descrittiva,
                    ...mit_la_1_model_fenomeni,
                    n_difetti_ps: numberType,
                },
                level3: {
                    ...mit_la_3_model_documents,
                    ...mit_la_3_model_form,
                    ...mit_la_3_model_results,
                    errors: textType,
                },
                result: textType,
                prevalutazione_result: textType,
            },
            latest_result: textType,
            latest_prevalutazione_result: textType,
            progress: {
                phase_name: textType,
                phase_changed_at: dateType,
            },
        },
        resisto: {
            latest_result: textType,
            workspace: {
                result: textType,
            },
            progress: {
                phase_name: textType,
                phase_changed_at: dateType,
            },
        },
    },
    next_inspection_date: dateType,
    // external_links: createFieldArray(link_field),
    informazioni_organizzative: {
        necessita_drone: booleanType,
        necessita_sfalcio: booleanType,
    },
})

export default procedureModel
