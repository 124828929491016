import { dateType, createSelect } from './utils'

export const resisto_sopralluogo_config = {
    status: { ...createSelect(['TO_DO', 'SCHEDULED', 'DONE']), label: 'inspection_status' },
    date: {
        ...dateType,
        label: 'planned_date',
        watchConfig: {
            name: 'status',
            cases: {
                TO_DO: { disabled: true },
                DONE: { disabled: true },
            },
        },
    },
}
