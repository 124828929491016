import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default function LoadingSpinner() {
    return (
        <CircularProgress
            color="secondary"
            size={50}
            style={{ display: 'block', margin: '0 auto', marginTop: '50px' }}
        />
    )
}
