import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function AlertDialog({
    open,
    setIsOpen,
    title,
    text,
    primaryButton,
    secondaryButton,
    secondaryButtonAction = () => setIsOpen(false),
    onSubmitCb,
    children,
    primaryButtonDisabled = false,
    maxWidth = 'sm',
    disableEscapeKeyDown = false,
}) {
    const { t } = useTranslation()
    return (
        <Dialog
            open={open}
            maxWidth={maxWidth}
            onClose={() => setIsOpen(false)}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            <DialogTitle>{t(title)}</DialogTitle>
            {(text || children) && (
                <DialogContent>
                    {text ? <DialogContentText>{t(text)}</DialogContentText> : children}
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={secondaryButtonAction}>{t(secondaryButton || 'annulla')}</Button>
                {primaryButton && (
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={primaryButtonDisabled}
                        onClick={onSubmitCb}
                    >
                        {t(primaryButton)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
