import React, { useEffect, useState } from 'react'
import { Paper, List, ListItem, ListItemText, Collapse, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useCurrentProcedureStore } from '../../../stateManagement'
import { useQueryProcedure } from '../../../customHooks/queryHooks/procedureQueries'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.primary.main,
        width: 240,
    },
    list: {
        background: theme.palette.primary.main,
    },
    levelBtn: {
        // textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
        },
    },
    // material-ui override
    root: {
        color: theme.palette.primary.contrastText,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {},
    },
    selected: {},
}))

export default function DynamicsToolbar() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const paramsProcedureId = useParams().procedureId
    const currentUrl = useLocation().pathname
    const { data: currentProcedure } = useQueryProcedure()

    const [openAccordion, setOpenAccordion] = useState(null)

    useEffect(() => {
        if (currentUrl.includes('/dynamics')) {
            setOpenAccordion('3SPlatform')
        } else {
            setOpenAccordion(null)
        }

        if (currentUrl.includes('/dynamics/configure')) {
            setOpenAccordion('Config')
        }
    }, [currentUrl])

    const handleSubMenu = (accordionId) => {
        setOpenAccordion(accordionId === openAccordion ? null : accordionId)
    }

    return (
        <Paper className={classes.toolbar} square elevation={3}>
            <List className={classes.list}>
                {/* 3S Platform */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    onClick={() => handleSubMenu('3SPlatform')}
                >
                    <ListItemText
                        primary={t('dynamics_title')}
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                    {openAccordion === '3SPlatform' ? (
                        <ExpandLess style={{ position: 'absolute' }} />
                    ) : (
                        <ExpandMore style={{ position: 'absolute' }} />
                    )}
                </ListItem>
                <Divider light variant="middle" />
                <Collapse in={openAccordion === '3SPlatform'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            selected={_.includes(currentUrl, 'overview/index')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/dynamics/overview/index`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('dynamics_index'))}
                            />
                        </ListItem>
                        {/*<ListItem*/}
                        {/*    button*/}
                        {/*    selected={_.includes(currentUrl, 'overview/analysis_list')}*/}
                        {/*    onClick={() => {*/}
                        {/*        history.push(*/}
                        {/*            `/procedures/${paramsProcedureId}/dynamics/overview/analysis_list`*/}
                        {/*        )*/}
                        {/*    }}*/}
                        {/*    classes={{*/}
                        {/*        root: classes.root,*/}
                        {/*        selected: classes.selected,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <ListItemText*/}
                        {/*        style={{ textAlign: 'center' }}*/}
                        {/*        primary={_.capitalize(t('analysis_list'))}*/}
                        {/*    />*/}
                        {/*</ListItem>*/}
                        <ListItem
                            button
                            selected={_.includes(currentUrl, 'overview/analysis_result')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/dynamics/overview/analysis_result`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('analysis_result'))}
                            />
                        </ListItem>
                    </List>
                </Collapse>

                {/* Configurations */}
                <ListItem
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    button
                    onClick={() => handleSubMenu('Config')}
                >
                    <ListItemText
                        primary={_.capitalize(t('dynamics_configuration'))}
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                    {openAccordion === 'Config' ? (
                        <ExpandLess style={{ position: 'absolute' }} />
                    ) : (
                        <ExpandMore style={{ position: 'absolute' }} />
                    )}
                </ListItem>
                <Divider light variant="middle" />
                <Collapse in={openAccordion === 'Config'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/*<ListItem*/}
                        {/*    style={{ textAlign: 'center' }}*/}
                        {/*    button*/}
                        {/*    selected={_.includes(currentUrl, 'configure/infrastructure')}*/}
                        {/*    onClick={() => {*/}
                        {/*        history.push(*/}
                        {/*            `/procedures/${paramsProcedureId}/dynamics/configure/infrastructure`*/}
                        {/*        )*/}
                        {/*    }}*/}
                        {/*    classes={{*/}
                        {/*        root: classes.root,*/}
                        {/*        selected: classes.selected,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <ListItemText*/}
                        {/*        style={{ textAlign: 'center' }}*/}
                        {/*        primary={_.capitalize(t('analysis_infrastructure'))}*/}
                        {/*    />*/}
                        {/*</ListItem>*/}
                        <ListItem
                            style={{ textAlign: 'center' }}
                            button
                            selected={_.includes(currentUrl, 'configure/analysis')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/dynamics/configure/analysis`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('analysis_analysis'))}
                            />
                        </ListItem>
                        <ListItem
                            style={{ textAlign: 'center' }}
                            button
                            selected={_.includes(currentUrl, 'configure/plc')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/dynamics/configure/plc`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={t('admin.plc')}
                            />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Paper>
    )
}
