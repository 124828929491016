import React from 'react'
import MapScreen from './MapScreen'


export default function Homepage() {
    // const { companyId } = useParams()
    // const history = useHistory()
    // const setWriteCompanies = useWriteCompaniesStore((state) => state.setWriteCompanies)
    // const { isSuperAdmin, profile } = useUserProfileStore((state) => ({
    //     isSuperAdmin: state.isSuperAdmin,
    //     profile: state.profile,
    // }))
    // const setSelectedCompany = useProceduresFiltersStore((state) => state.setCompanySelect)

    // // restituisce le company su cui hai i permessi di lettura, quindi quelle in cui si è admin, member o reader
    // const { data: companies = [], isFetching: isCompaniesLoading } = useQuery(
    //     companiesQueries.getCompanies.name,
    //     companiesQueries.getCompanies.fn,
    //     {
    //         enabled: Boolean(!companyId),
    //     }
    // )
    //
    // // restituisce le company su cui hai i permessi di scrittura, quindi solo quelle in cui si è admin
    // const { data: userCompanies = [], isFetching: isUserCompaniesLoading } = useQuery(
    //     availableCompaniesQueries.getAvailableCompanies.name,
    //     availableCompaniesQueries.getAvailableCompanies.fn,
    //     {
    //         enabled: Boolean(!companyId),
    //         onSuccess: (data) => setWriteCompanies(data),
    //     }
    // )

    // useEffect(() => {
    //     if (Boolean(!companyId) && !isCompaniesLoading && !isUserCompaniesLoading) {
    //         if (userCompanies && userCompanies.length > 0) {
    //             // Go to analytics
    //             history.replace(`/dashboard/${userCompanies[0].id}`)
    //         } else if (companies && companies.length > 0) {
    //             // Go to map
    //             history.replace(`/dashboard/${companies[0].id}`)
    //         } else {
    //             // L'utente non è membro di nessuna company, ma ha dei permessi su singole pratiche
    //             history.replace(`/dashboard/tutti_gli_studi`)
    //         }
    //     }
    // }, [companyId, isUserCompaniesLoading, userCompanies, isCompaniesLoading, companies, history])

    // const {
    //     data: company,
    //     isLoading: isLoadingCompany,
    //     // refetch: refetchCompany,
    // } = useQuery(
    //     [companiesQueries.getCompany.name, companyId],
    //     () => companiesQueries.getCompany.fn(companyId),
    //     {
    //         enabled: Boolean(companyId) && companyId !== 'tutti_gli_studi',
    //         onSuccess: (data) => {
    //             setSelectedCompany(data.id)
    //         },
    //     }
    // )
    //
    // if (!companyId || isUserCompaniesLoading || isCompaniesLoading || isLoadingCompany) {
    //     return <LoadingSpinner />
    // }
    //
    // const isUserAdminOfCompany = (userId, c) => {
    //     return _.findIndex(c.admins, (admin) => admin.id === userId) !== -1
    // }
    //
    // const isUserMemberOfCompany = (userId, c) => {
    //     return _.findIndex(c.members, (admin) => admin.id === userId) !== -1
    // }
    //
    // const isUserAdminOrMemberOfCompany =
    //     companyId !== 'tutti_gli_studi' &&
    //     (isSuperAdmin ||
    //         (company &&
    //             (isUserAdminOfCompany(profile.id, company) ||
    //                 isUserMemberOfCompany(profile.id, company))))

    return <MapScreen />

    // if (isUserAdminOrMemberOfCompany) {
    //     return <AnalitycsDashboard company={company} companies={companies} />
    // } else {
    //     return <MapScreen companies={companies} isCompaniesLoading={isCompaniesLoading} />
    // }
}
