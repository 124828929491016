import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { Fab, Paper, Typography, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useDisableForm from '../../../customHooks/useDisableForm'
import { queryClient, levelsQueries, proceduresQueries } from '../../../networking'
import { usePendingOperationsStore, useSnackBarStore } from '../../../stateManagement'
import { capitalizeFirstLetter, isL3Enabled } from '../../../utils'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import AppForm from '../../../components/AppForm'
import SaveIcon from '@material-ui/icons/Save'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import { mit_la_3_model_form } from '../../../FormConfigs/mit_la_3_config'
import { Redirect } from 'react-router-dom'
import AlertDialog from '../../../components/Dialogs/AlertDialog'
import MethodologyPage from '../../MethodologyPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

export default function LGP_LA3_dati() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })
    const showSnackBar = useSnackBarStore((state) => state.show)
    const [invalidData, setInvalidData] = React.useState([])
    const [isErrorsDialogOpen, setIsErrorsDialogOpen] = React.useState(false)

    const { data: procedure = {} } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )
    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])

    const { isLoading } = useQuery(
        [levelsQueries.getMitLevel3.name, procedureId],
        () => levelsQueries.getMitLevel3.fn(procedureId),
        {
            onSuccess: (data) => methods.reset(data),
        }
    )

    const updateLevelMutation = useMutation(
        (data) => {
            const invalid = []
            if ((data.data.n_anime < 2 || isNaN(data.data.n_anime)) && data.data.cassone) {
                invalid.push({
                    label: 'n_anime',
                    description: 'Il numero di anime deve essere maggiore di uno',
                })
            }
            if (invalid.length) {
                showSnackBar({
                    message: t('saving_not_allowed'),
                    severity: 'error',
                })
                setInvalidData(invalid)
                setIsErrorsDialogOpen(true)
                return
            }
            return levelsQueries.updateLevel.fn(
                procedureId,
                'mit_guidelines',
                'level3',
                replaceEmptyStringsWithNullWithin(data)
            )
        },
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getMitLevel3.name),
        }
    )

    React.useEffect(() => {
        clearPendingOps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    function sendData(data) {
        if (isDirty && isValid) {
            runPendingOps().then(() => {
                updateLevelMutation.mutate(data)
            })
        }
    }
    if (
        procedure?.id &&
        !isL3Enabled(procedure) &&
        !(
            procedure.methodologies?.mit_guidelines.workspace.level3.results ||
            procedure.methodologies?.mit_guidelines.workspace.level3.errors
        )
    ) {
        return <Redirect to={{ pathname: `/procedures/${procedureId}/lgp/livello-3` }} />
    }
    return (
        <MethodologyPage procedure={procedure} methodology="lgp" alert={alert}>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('dati'))}
                </Typography>
                <AppForm onSubmit={sendData} methods={methods}>
                    <DynamicFormFields
                        fieldsKey={'data'}
                        formFields={mit_la_3_model_form}
                        disableAll={isNotAllowedToWrite || !isL3Enabled(procedure)}
                    />
                    {!isNotAllowedToWrite && isL3Enabled(procedure) && (
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={
                                !isDirty || !isValid || isLoading || updateLevelMutation.isLoading
                            }
                            className={classes.fab}
                        >
                            {updateLevelMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    )}
                </AppForm>
            </Paper>
            <AlertDialog
                open={isErrorsDialogOpen}
                setIsOpen={setIsErrorsDialogOpen}
                title={t('invalid_data')}
                secondaryButton="close"
                maxWidth="lg"
            >
                {invalidData.map((data, index) => (
                    <Grid container key={index} alignItems="center">
                        <Typography style={{ marginRight: 10 }}>{t(data.label)} -</Typography>
                        <Typography color="error" variant="body2">
                            {t(data.description)}
                        </Typography>
                    </Grid>
                ))}
            </AlertDialog>
        </MethodologyPage>
    )
}
