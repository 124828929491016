import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles({
    card: {
        maxWidth: 300,
        borderRadius: '12px',
        backgroundColor: 'white'
    },
    media: {
        maxWidth: 300,
        height: 200,
    },
    title: {
        fontSize: '20px',
        textAlign: 'center',
        color: '#202D40',
        marginBottom: '24px'
    },
    text: {
        textAlign: 'center',
        color: '#202D40',
        marginBottom: '16px'
    },
    button: {
        width: '328px',
        height: '40px',
        background: '#202D40',
        borderRadius: '100px',
        transition: '0.3s',
        marginBottom: '8px',
        '&:hover': {
            backgroundColor: '#202D40',
            filter: 'opacity(0.8)',
            transition: '0.3s',
        },
    },
    buttonText: {
        fontWeight: 600,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white'
    }
})

export default function LandingPageCard({ image, title, text, externalLink }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardMedia
                className={classes.media}
                image={image}
            />
            <CardContent>
                <Typography variant="h5" component="h2" className={classes.title}>
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                    {text}
                </Typography>
            </CardContent>
            <CardActions>
                <Button component={Link} to={{ pathname: externalLink }} target='_top' size="small" variant='contained' color="secondary" className={classes.button}>
                    <Typography className={classes.buttonText}>
                        Accedi
                    </Typography>
                </Button>
            </CardActions>
        </Card>
    );
}