import React from 'react'
import { Grid, IconButton, TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import Link from '@material-ui/core/Link'
import EditIcon from '@material-ui/icons/Edit'
import { OpenInNew } from '@material-ui/icons'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

export default function LinkField(props) {
    // const { clearErrors, setError } = useFormContext()
    const [editMode, setEditMode] = React.useState(false)
    const { register, watch } = useFormContext()

    // console.log(_.get(watch(), props.formNamePrefix))

    return (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
            <Grid
                item
                container
                direction={'row'}
                style={{ ...(!editMode && { display: 'none' }) }}
            >
                <Grid item>
                    <input {...register(`${props.formNamePrefix}.description`)} />
                </Grid>
                <Grid item>
                    <input {...register(`${props.formNamePrefix}.link`)} />
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => {
                            setEditMode(false)
                        }}
                    >
                        <CheckIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid item container direction={'row'} style={{ ...(editMode && { display: 'none' }) }}>
                <Grid item>
                    <Link to={'https://www.google.com'}>
                        <TextField disabled={true} value={''} />
                    </Link>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => console.log('clicked')}>
                        <OpenInNew />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => setEditMode(true)}>
                        <EditIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )

    return null
}
