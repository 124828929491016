import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Container, Grid, Typography, Breadcrumbs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { companiesQueries } from '../networking'
import { DotsLoader } from '../components/DotsLoader/DotsLoader'
import Alert from '@material-ui/lab/Alert'
import { capitalizeFirstLetter, removeDashFromString } from '../utils'
import LGPToolbar from '../components/Procedures/Methodologies/LGPToolbar'
import ResistoToolbar from '../components/Procedures/Methodologies/ResistoToolbar'
import { useQueryProcedure } from '../customHooks/queryHooks/procedureQueries'
import useProcedureId from '../customHooks/useProcedureId'
import DynamicsToolbar from '../components/Procedures/Methodologies/DynamicsToolbar'

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
    },
    rightContainer: {
        flex: 1,
        overflow: 'auto',
    },
    levelContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    breadcrumbs: {
        'min-height': 32,
        marginBottom: 20,
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        '& a:hover': {
            color: theme.palette.text.primary,
        },
    },
    currentBreadcrumb: {
        color: theme.palette.text.primary,
        cursor: 'default',
    },
    disabledBreadcrumb: {
        cursor: 'not-allowed',
    },
    permissionInfo: {
        marginBottom: '20px',
    },
}))

export default function MethodologyPage({ children, maxWidth = 'md', methodology, alert = false }) {
    const [isUserAllowed, setIsUserAllowed] = React.useState(false)
    const [breadcrumbs, setBreadcrumbs] = React.useState([])
    const classes = useStyles()
    const { procedureId: paramsProcedureId } = useProcedureId()

    const { pathname } = useLocation()
    const { t } = useTranslation()

    const { data: procedure, isLoading } = useQueryProcedure()
    const companyId = procedure?.owner?.id

    //try to make the call, if it is successful it means the user can access the company page (render link), if not render span in breadcrumb
    useQuery(
        [companiesQueries.headCompany.name, companyId],
        () => companiesQueries.headCompany.fn(companyId),
        {
            enabled: !!companyId,
            retry: 0,
            onSuccess: () => {
                setIsUserAllowed(true)
            },
            onError: () => {
                setIsUserAllowed(false)
            },
        }
    )

    const isUltimoElementoNumero = (array) => {
        if (array.length === 0) {
            return false
        }
        const lastElement = array[array.length - 1]
        return !isNaN(parseFloat(lastElement)) && isFinite(lastElement)
    }

    React.useEffect(() => {
        const path = pathname.split('/')

        let methodology
        let methodologyHomepage

        if (path.includes('lgp')) {
            methodology = 'lgp'
            methodologyHomepage = {
                name: methodology.toUpperCase(),
                link: `/procedures/${paramsProcedureId}/${methodology}`,
            }
        } else if (path.includes('resisto')) {
            methodology = 'resisto'
            methodologyHomepage = {
                name: methodology.toUpperCase(),
                link: `/procedures/${paramsProcedureId}/${methodology}/panoramica`,
            }
        } else if (path.includes('dynamics')) {
            methodology = 'dynamics'
            methodologyHomepage = {
                name: methodology.toUpperCase(),
            }
        }

        let breadcrumbs_temp = [
            {
                name: procedure?.owner?.name,
                link: isUserAllowed ? `/companies/${companyId}` : undefined,
                forbidden: !isUserAllowed,
            },
            { name: procedure?.name, link: `/procedures/${paramsProcedureId}` },
            methodologyHomepage,
        ]

        if (methodology === 'lgp') {
            let level_index = path.findIndex((i) => RegExp(/^(livello-)\d$/).test(i))
            let level
            if (level_index !== -1) {
                level = path[level_index].replace(/^(livello-)(\d)$/, 'LA$2')
            } else if (path[path.length - 1] === 'bms') {
                level = 'BMS'
                level_index = path[path.length - 2]
            } else {
                level = 'LA2'
            }
            if (level !== 'LA2') {
                breadcrumbs_temp.push({ name: 'Bozza' })
            }

            breadcrumbs_temp.push({ name: level })

            const prepareName = (name) => _.capitalize(t(name.replaceAll('-', '_')))

            const rest_path = path.slice(level_index + 1)
            switch (rest_path.length) {
                case 1:
                    breadcrumbs_temp.push({ name: prepareName(rest_path[0]) })
                    break
                case 2:
                    if (
                        rest_path[0] === 'frane-e-fenomeni-idraulici' &&
                        rest_path[1] === 'scheda'
                    ) {
                        rest_path[1] = 'scheda-descrittiva'
                    }
                    breadcrumbs_temp.push({
                        name: prepareName(rest_path[0]),
                        link: `/procedures/${paramsProcedureId}/lgp/${path
                            .slice(level_index, level_index + 2)
                            .join('/')}`,
                    })
                    breadcrumbs_temp.push({
                        name: prepareName(rest_path[1]),
                    })
                    break
                case 3:
                    breadcrumbs_temp.push({
                        name: prepareName(rest_path[0]),
                        link: `/procedures/${paramsProcedureId}/lgp/${path
                            .slice(level_index, level_index + 2)
                            .join('/')}`,
                    })
                    breadcrumbs_temp.push({
                        name: prepareName(rest_path[1]),
                        link: `/procedures/${paramsProcedureId}/lgp/${path
                            .slice(level_index, level_index + 3)
                            .join('/')}`,
                    })
                    breadcrumbs_temp.push({ name: rest_path[2] })
                    break
                default:
            }

            setBreadcrumbs(breadcrumbs_temp)
        } else if (methodology === 'resisto') {
            if (path.includes('livelli') && isUltimoElementoNumero(path)) {
                breadcrumbs_temp.push(
                    {
                        name: capitalizeFirstLetter(removeDashFromString(path[path.length - 2])),
                        link: `/procedures/${paramsProcedureId}/resisto/livelli`,
                    },
                    {
                        name: capitalizeFirstLetter(removeDashFromString(path[path.length - 1])),
                    }
                )
            } else {
                breadcrumbs_temp.push({
                    name: capitalizeFirstLetter(removeDashFromString(path[path.length - 1])),
                })
            }
            setBreadcrumbs(breadcrumbs_temp)
        } else if (methodology === 'dynamics') {
            let page = path.pop()
            let section = path.pop()
            // Verifico se è nella sezione Overview o in Configurations
            if (section === 'overview') {
                breadcrumbs_temp.push({
                    name: t('dynamics_title'),
                })
                if (page === 'index') {
                    breadcrumbs_temp.push({
                        name: t('dynamics_index'),
                        link: `/procedures/${paramsProcedureId}/dynamics/overview/index`,
                    })
                }
                if (page === 'analysis_list') {
                    breadcrumbs_temp.push({
                        name: t('analysis_list'),
                        link: `/procedures/${paramsProcedureId}/dynamics/overview/analysis_list`,
                    })
                }
                if (page === 'analysis_result') {
                    breadcrumbs_temp.push({
                        name: t('analysis_result'),
                        link: `/procedures/${paramsProcedureId}/dynamics/overview/analysis_result`,
                    })
                }
            } else if (section === 'configure') {
                breadcrumbs_temp.push({
                    name: t('dynamics_configuration'),
                })
                if (page === 'infrastructure') {
                    breadcrumbs_temp.push({
                        name: t('analysis_infrastructure'),
                        link: `/procedures/${paramsProcedureId}/dynamics/configure/infrastructure`,
                    })
                }
                if (page === 'analysis') {
                    breadcrumbs_temp.push({
                        name: t('analysis_analysis'),
                        link: `/procedures/${paramsProcedureId}/dynamics/configure/analysis`,
                    })
                }
            }
            setBreadcrumbs(breadcrumbs_temp)
        }
    }, [t, isUserAllowed, pathname, procedure?.owner, procedure?.name, paramsProcedureId])

    return (
        <Grid container wrap="nowrap" className={classes.outerContainer}>
            <Grid item style={{ overflow: 'auto' }}>
                {methodology === 'lgp' && <LGPToolbar />}
                {methodology === 'resisto' && <ResistoToolbar />}
                {methodology === 'dynamics' && <DynamicsToolbar />}
            </Grid>
            <Grid item className={classes.rightContainer}>
                <Container maxWidth={maxWidth} className={classes.levelContainer}>
                    {!isLoading && (
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            className={classes.breadcrumbs}
                        >
                            {breadcrumbs.map((item, index) =>
                                item.name ? (
                                    item.link ? (
                                        <Link
                                            key={item.name}
                                            to={item.link}
                                            className={[
                                                index === breadcrumbs.length - 1
                                                    ? classes.currentBreadcrumb
                                                    : undefined,
                                            ].join(' ')}
                                        >
                                            {item.name}
                                        </Link>
                                    ) : (
                                        <Typography
                                            key={item.name}
                                            className={[
                                                item.forbidden
                                                    ? classes.disabledBreadcrumb
                                                    : undefined,
                                                index === breadcrumbs.length - 1
                                                    ? classes.currentBreadcrumb
                                                    : undefined,
                                            ].join(' ')}
                                        >
                                            {item.name}
                                        </Typography>
                                    )
                                ) : (
                                    <DotsLoader key={index} />
                                )
                            )}
                        </Breadcrumbs>
                    )}
                    {alert && (
                        <Grid item className={classes.permissionInfo}>
                            <Alert severity="info">{t('missing_write_permission')}</Alert>
                        </Grid>
                    )}
                    {children}
                </Container>
            </Grid>
        </Grid>
    )
}
