import React from 'react'
import { Paper, Typography, Fab, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import MethodologyPage from '../MethodologyPage'
import AppForm from '../../components/AppForm'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import { capitalizeFirstLetter } from '../../utils'
import { levelsQueries, queryClient } from '../../networking'
import { replaceEmptyStringsWithNullWithin } from '../../FormConfigs/utils'
import { resisto_sopralluogo_config } from '../../FormConfigs/resisto_sopralluogo_config'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
    },
    section: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: '20px',
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

export default function Resisto_sopralluogo() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
    })

    const { isLoading } = useQuery(
        [levelsQueries.getResistoSopralluogo.name, procedureId],
        () => levelsQueries.getResistoSopralluogo.fn(procedureId),
        {
            onSuccess: (data) => methods.reset(data),
        }
    )

    const updateLevelMutation = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'resisto',
                'inspection',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(levelsQueries.getResistoSopralluogo.name),
        }
    )

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    function sendData(data) {
        if (isDirty && isValid) {
            updateLevelMutation.mutate(data)
        }
    }

    return (
        <MethodologyPage methodology="resisto">
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('sopralluogo'))}
                </Typography>
                <AppForm onSubmit={sendData} methods={methods}>
                    <div className={classes.section}>
                        <DynamicFormFields fieldsKey="" formFields={resisto_sopralluogo_config} />
                    </div>
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={
                            !isDirty || !isValid || isLoading || updateLevelMutation.isLoading
                        }
                        className={classes.fab}
                    >
                        {updateLevelMutation.isLoading ? (
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                        ) : (
                            <SaveIcon />
                        )}
                        {t('salva')}
                    </Fab>
                </AppForm>
            </Paper>
        </MethodologyPage>
    )
}
