import { Button, CircularProgress, Dialog, DialogActions, Grid, Typography } from "@material-ui/core";
import AppForm from "./AppForm";
import ExportsTable from "./ExportsTable";
import DynamicFormFields from "./FormItems/DynamicFormFields";
import { createSelect, textType } from "../FormConfigs/utils";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useCompanyId from "../customHooks/useCompanyId";
import { useMutation } from "react-query";
import axios from "axios";
import { queryClient } from "../networking";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FieldsTree } from "./AdvancedSearchTool";
import { AppChip } from "./QuickFilterChip";
import useIsCompanyAdmin from "../customHooks/useIsCompanyAdmin";
import { useSnackBarStore } from "../stateManagement";

const useStyles = makeStyles((theme) => ({
    advancedFiltersContainer: {
        padding: theme.spacing(2),
        height: '90%',
    },
}))

const extractionTypeOptions = [
    "tutti_i_campi",
    "statistiche_difetti",
    "campi_selezionati",
    "anagrafica_base_ainop"
]


export default function CompanyMethodologyExports() {
    const { t } = useTranslation()
    const classes = useStyles()
    const { companyId, beMethodology } = useCompanyId()
    const { isCompanyAdmin } = useIsCompanyAdmin()
    const showSnackBar = useSnackBarStore((state) => state.show);
    
    const postTask = useMutation(async (data) => {
        const resp = await axios.post(`/companies/${companyId}/exports/${beMethodology}`, data)
        return resp.data
    }, {onSuccess: () => queryClient.invalidateQueries(['getCompanyMethodologyExports', companyId, beMethodology])})
    const methods = useForm({ mode: 'onBlur', defaultValues: {
        task_handler_kwargs: {
            name: "",
            export_type: "campi_selezionati",
            company_id: companyId,
            methodology: beMethodology,
            export_config: "{}",
        }
    }})
    const formValues = methods.getValues()
    const { isDirty, isValid } = methods.formState
    function addField(fieldPath) {
        const oldValue = JSON.parse(methods.getValues()?.task_handler_kwargs?.export_config || '{}')
        
        const fields = new Set(oldValue?.fields || [])
        fields.add(fieldPath)
        const newValue = JSON.stringify({fields: [...fields]})
        methods.setValue('task_handler_kwargs.export_config', newValue)
    }
    function removeField(fieldPath) {
        const oldValue = JSON.parse(methods.getValues()?.task_handler_kwargs?.export_config || '{}')
        
        const fields = new Set(oldValue?.fields || [])
        fields.delete(fieldPath)
        const newValue = JSON.stringify({fields: [...fields]})
        methods.setValue('task_handler_kwargs.export_config', newValue)
    }
    const fields = JSON.parse(formValues?.task_handler_kwargs?.export_config || "{}")?.fields || []
    
    const [isAddFieldsDialogOpen, setIsAddFieldsDialogOpen] = React.useState(false)
    const onSubmitPostTask = (data) => {
        if (!(isDirty && isValid)) {
            showSnackBar({ message: t('fill_in_all_fields'), severity: 'error' })
            return
        }
        data.task_handler_kwargs.export_config = JSON.parse(data.task_handler_kwargs?.export_config || "{}")
        postTask.mutate({ ...data })
    }
    return (
        <AppForm methods={methods} onSubmit={onSubmitPostTask}>
            <Grid container spacing={3} direction={'column'}>
                <Grid item>
                    <Typography variant={'h5'}>Estrazioni</Typography>
                </Grid>
                <Grid item>
                    <ExportsTable />
                </Grid>
                {isCompanyAdmin && (
                    <>
                        <Grid item>
                            <Typography variant={'h5'}>Configura estrazione</Typography>
                        </Grid>
                        <Grid item>
                            <DynamicFormFields
                                methods={methods}
                                fieldsKey={''}
                                formFields={{
                                    task_handler_kwargs: {
                                        _type: 'group',
                                        __meta: { hideGroupUI: true },
                                        name: { ...textType, required: true },
                                        export_type: {
                                            ...createSelect(extractionTypeOptions),
                                            required: true,
                                        },
                                        company_id: { _type: 'hidden' },
                                        methodology: { _type: 'hidden' },
                                        export_config: {
                                            _type: 'hidden',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        {formValues?.task_handler_kwargs?.export_type === 'campi_selezionati' && (
                            <>
                                <Grid item>
                                    <Grid
                                        container
                                        direction={'row'}
                                        spacing={1}
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <Grid item>
                                            <Typography>{t('selected_fields')}:</Typography>
                                        </Grid>
                                        <PathChips fields={fields} removeField={removeField} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item>
                            <Grid container direction={'row'} spacing={1}>
                                {formValues?.task_handler_kwargs?.export_type ===
                                    'campi_selezionati' && (
                                    <>
                                        <Grid item>
                                            <Button
                                                variant={'contained'}
                                                color={'secondary'}
                                                onClick={() => setIsAddFieldsDialogOpen(true)}
                                            >
                                                {t('add_fields')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant={'contained'}
                                                color={'secondary'}
                                                onClick={() =>
                                                    fields.forEach((field) => removeField(field))
                                                }
                                            >
                                                {t('remove_all')}
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        // size={'small'}
                                        color="secondary"
                                        type="submit"
                                        disabled={!(isDirty && isValid)}
                                        // disabled={!(isDirty || isValid)}
                                    >
                                        {postTask.isLoading && (
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        )}
                                        {t('estrai')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Dialog
                open={isAddFieldsDialogOpen}
                onClose={() => setIsAddFieldsDialogOpen(false)}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    className: classes.advancedFiltersContainer,
                    elevation: 0,
                    variant: 'outlined',
                }}
            >
                <Grid
                    container
                    spacing={2}
                    direction={'row'}
                    justifyContent={'space-between'}
                    display={'flex'}
                    style={{ overflowY: 'scroll' }}
                >
                    <Grid item>
                        <Grid container direction={'row'} spacing={1}>
                            <PathChips fields={fields} removeField={removeField} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FieldsTree onLeafClick={(fieldPath, fieldType) => addField(fieldPath)} />
                    </Grid>
                </Grid>
                <DialogActions style={{ marginTop: 'auto' }}>
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => setIsAddFieldsDialogOpen(false)}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </AppForm>
    )
}


function PathChips({fields, removeField}) {
    const {t} = useTranslation()
    return (
        <>
            <Grid item>
                <AppChip isActive={true} label={"id"} />
            </Grid>
            <Grid item>
                <AppChip isActive={true} label={t("name")} />
            </Grid>
            {(fields || []).map(field => (
                <Grid item key={field}>
                    <AppChip label={field} isActive={true} onDelete={() => removeField(field)}/>
                </Grid>
            ))}
        </>
    )
}
