import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import InputDialog from './Dialogs/InputDialog'
import { colorsMapping } from '../customTheme'

export default function FulfillAbsenceButtons({
    difettiPossibili = [],
    formPath,
    methods,
    disableAll = false,
}) {
    const { t } = useTranslation()
    const { getValues, setValue } = methods
    const [areButtonsDisabled, setAreButtonsDisabled] = React.useState(false)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [giustificazione, setGiustificazione] = React.useState(null)
    const [pressedButton, setPressedButton] = React.useState()

    // Check in the latest data image if all elements exist and are filled in
    // If so, disable the autocomplete buttons, otherwise enable them
    React.useEffect(() => {
        const currentDifetti = _.get(getValues(), formPath + '.difetti')
        if (!_.isEmpty(currentDifetti)) {
            if (Object.keys(currentDifetti).length === difettiPossibili.length) {
                const isAtLeastOneEmpty = _.some(
                    currentDifetti,
                    (difetto) =>
                        !difetto.estensione && !difetto.intensita && !difetto.motivo_assenza
                )
                setAreButtonsDisabled(!isAtLeastOneEmpty)
            }
        }
    }, [getValues, difettiPossibili, formPath])

    // Set the desired motivo_assenza value with React-hook-form setValue function at the requested id
    function setReactFormValues(id, value) {
        setValue(
            formPath + '.difetti.' + id,
            { motivo_assenza: value, note: giustificazione || null },
            { shouldDirty: true }
        )
    }

    // Look through all the difetti and set the value of the difetto to the button value,
    // if empty or not yet existent
    function autocompleteEmptyData(buttonValue) {
        difettiPossibili.forEach((difetto) => {
            const currentDifetto = getValues(formPath + '.difetti.' + difetto.id)

            if (currentDifetto) {
                if (
                    currentDifetto.estensione === null &&
                    currentDifetto.intensita === null &&
                    currentDifetto.motivo_assenza === null
                ) {
                    setReactFormValues(difetto.id, buttonValue)
                }
            } else {
                setReactFormValues(difetto.id, buttonValue)
            }
        })
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                marginBottom={1}
                marginTop={2}
            >
                <Typography noWrap>{t('applica_su_tutti') + ':'} </Typography>
                {['NA', 'NR', 'NP'].map((button, idx) => {
                    let color
                    if (idx === 0) {
                        color = colorsMapping[4]
                    } else if (idx === 1) {
                        color = colorsMapping[6]
                    } else {
                        color = colorsMapping[2]
                    }
                    return (
                        <Button
                            disabled={areButtonsDisabled || disableAll}
                            key={idx}
                            style={{
                                marginLeft: '25px',
                                minWidth: 'unset',
                                width: '32px',
                                height: '32px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                color: 'white',
                            }}
                            variant="contained"
                            // color="secondary"
                            size="small"
                            value={button}
                            onClick={() => {
                                if (button === 'NP') {
                                    autocompleteEmptyData(button)
                                } else {
                                    setPressedButton(button)
                                    setIsDialogOpen(true)
                                }
                            }}
                        >
                            {button}
                        </Button>
                    )
                })}
            </Box>
            <InputDialog
                open={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                title="giustificazione_assenza_dialog_title"
                button="applica"
                inputType="text"
                multiline
                inputLabel="giustificazione_assenza"
                value={giustificazione}
                setValue={setGiustificazione}
                onSubmitCb={() => autocompleteEmptyData(pressedButton)}
            />
        </>
    )
}
