import {
    positiveNumberType,
    createSelect,
    booleanType,
    textareaType,
    attachmentType,
    createOptions,
    createFieldArray,
} from './utils'

export const sections_to_render = [
    'stato_pavimentazione',
    'cordoli',
    'convogliamento_acque',
    'marciapiedi',
    'pali_illuminazione',
    'sottoservizi',
]

const difetto_modello = {
    _type: 'group',
    visto: booleanType,
    foto: createFieldArray({
        ...attachmentType,
        useDialog: false,
        acceptedFiles: ['image/*'],
    }),
    note: textareaType,
}

export const elemento_accessorio_model = {
    dati_generali: {
        n_corsie: positiveNumberType,
    },
    dati_marciapiede: {
        marciapiede: createSelect(['SI_UNO', 'SI_ENTRAMBI', 'NO'], false, {
            name: 'schede_ispezione.elementi_accessori.barriere_laterali',
            cases: {
                CASO1: { options: createOptions(['', 'NO']) },
                CASO2: {
                    options: createOptions(['', 'SI_UNO', 'SI_ENTRAMBI']),
                },
                CASO3: {
                    options: createOptions(['', 'SI_UNO', 'SI_ENTRAMBI']),
                },
            },
        }),
        barriere_laterali: createSelect(['CASO1', 'CASO2', 'CASO3'], false, {
            name: 'schede_ispezione.elementi_accessori.marciapiede',
            cases: {
                NO: { options: createOptions(['', 'CASO1']) },
                SI_UNO: { options: createOptions(['', 'CASO2', 'CASO3']) },
                SI_ENTRAMBI: { options: createOptions(['', 'CASO2', 'CASO3']) },
            },
        }),
        larghezza_marciapiede: {
            _type: 'numberUnit',
            unit: 'm',
            optional: true,
            watchConfig: {
                name: 'schede_ispezione.elementi_accessori.marciapiede',
                cases: {
                    NO: { disabled: true },
                },
            },
        },
        altezza_marciapiede: {
            _type: 'numberUnit',
            unit: 'cm',
            optional: true,
            watchConfig: {
                name: 'schede_ispezione.elementi_accessori.marciapiede',
                cases: {
                    NO: { disabled: true },
                },
            },
        },
    },
    stato_pavimentazione: {
        El_Acc_1_dislivello_rilevato_impalcato: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
        El_Acc_1_presenza_dossi: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
        El_Acc_1_fessure_anomalie: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
    },
    cordoli: {
        El_Acc_2_assenti: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
        El_Acc_2_degradati: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
    },
    convogliamento_acque: {
        El_Acc_3_assenti: {
            ...difetto_modello,
            _extra: {
                weight: 3,
            },
        },
        El_Acc_4_pozzetti_intasati: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
        El_Acc_5_scarichi_corti: {
            ...difetto_modello,
            _extra: {
                weight: 2,
            },
        },
        El_Acc_6_scarichi_ostruiti: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
        El_Acc_7_scarichi_danneggiati: {
            ...difetto_modello,
            _extra: {
                weight: 2,
            },
        },
    },
    marciapiedi: {
        El_Acc_8_assenti: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
        El_Acc_8_cattiva_pavimentazione: {
            ...difetto_modello,
            _extra: {
                weight: 1,
            },
        },
    },
    pali_illuminazione: {
        assenti: difetto_modello,
        El_Acc_9_mal_ancorati: {
            ...difetto_modello,
            _extra: {
                weight: 2,
            },
        },
        El_Acc_9_danneggiati: {
            ...difetto_modello,
            _extra: {
                weight: 2,
            },
        },
        El_Acc_10_arrugginiti: {
            ...difetto_modello,
            _extra: {
                weight: 2,
            },
        },
    },
    sottoservizi: {
        El_Acc_11_assenti: difetto_modello,
        El_Acc_11_mal_ancorati: {
            ...difetto_modello,
            _extra: {
                weight: 2,
            },
        },
    },
    guardiavia_parapetto: {
        presenza: {
            _type: 'group',
            _extra: {
                weight: 4,
            },
            presente: booleanType,
            funzione: createSelect(['COME_GUARDIAVIA', 'COME_PARAPETTO'], false, {
                name: 'schede_ispezione.elementi_accessori.barriere_laterali',
                cases: {
                    CASO1: { disabled: true },
                    CASO2: { disabled: true },
                    CASO3: { disabled: false },
                },
            }),
            foto: createFieldArray({
                ...attachmentType,
                useDialog: false,
                acceptedFiles: ['image/*'],
            }),
        },
        tipologia: {
            _type: 'group',
            value: createSelect(
                ['MURATURA', 'RINGHIERA', 'METALLICO', 'ALTRO'],
                false,
                null,
                'tipo'
            ),
            foto: createFieldArray({
                ...attachmentType,
                useDialog: false,
                acceptedFiles: ['image/*'],
            }),
        },
        danneggiati: {
            _type: 'group',
            _extra: {
                weight: 2,
            },
            value: createSelect(['LIEVEMENTE', 'GRAVEMENTE'], false, null, 'danneggiati'),
            foto: createFieldArray({
                ...attachmentType,
                useDialog: false,
                acceptedFiles: ['image/*'],
            }),
        },
        ossidati: {
            _type: 'group',
            value: createSelect(['LIEVEMENTE', 'GRAVEMENTE'], false, null, 'ossidati'),
            _extra: {
                weight: 2,
            },
            foto: createFieldArray({
                ...attachmentType,
                useDialog: false,
                acceptedFiles: ['image/*'],
            }),
        },
        altezza: {
            _type: 'numberUnit',
            unit: 'cm',
        },
        mal_ancorati: {
            _type: 'group',
            _extra: {
                weight: 2,
            },
            value: {
                ...booleanType,
                label: 'mal_ancorati',
            },
            foto: createFieldArray({
                ...attachmentType,
                useDialog: false,
                acceptedFiles: ['image/*'],
            }),
        },
        elementi_ancoraggio: {
            _type: 'group',
            _extra: {
                weight: 2,
            },
            value: createSelect(['DANNEGGIATI', 'OSSIDATI'], false, null, 'elementi_ancoraggio'),
            foto: createFieldArray({
                ...attachmentType,
                useDialog: false,
                acceptedFiles: ['image/*'],
            }),
        },
    },
}
