const READ = 'read'
const WRITE = 'write'
const USER = 'user'
const COMPANY = 'company'
const PROCEDURE = 'procedure'

const permissions = {
    READ,
    WRITE,
    USER,
    PROCEDURE,
    COMPANY,
}

export default permissions
