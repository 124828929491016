import React from 'react'
import _ from 'lodash'
import { Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { UserCard } from './UserCard'

export default function UsersTeamSection({
    sectionTitleLabel,
    users,
    invitations,
    admins = false,
    members = false,
    readers = false,
    classes,
    isSuperAdmin,
    isCompanyAdmin,
    onAdminDialogConfirm,
    setSelectedUser,
    selectedUser,
    handleDeleteInvitation,
    uniqueAdmin,
    refetchCompany,
}) {
    const { t } = useTranslation()

    return (
        <>
            <Grid item className={classes.sectionDividerContainer}>
                <Divider className={classes.divider} />
                <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    className={classes.sectionTitle}
                >
                    {t(sectionTitleLabel)}
                </Typography>
            </Grid>
            <Grid item xs={12} container spacing={3} className={classes.teamContainer}>
                {_.sortBy(users || invitations, ['last_name', 'first_name']).map((user, index) => (
                    <Grid sm={6} xs={12} item key={user.id + index} className={classes.cardBody}>
                        <UserCard
                            user={invitations ? user.user : user}
                            isAdmin={admins}
                            isMember={members}
                            isReader={readers}
                            isSuperAdmin={isSuperAdmin}
                            isCompanyAdmin={isCompanyAdmin}
                            onAdminDialogConfirm={onAdminDialogConfirm}
                            setSelectedUser={setSelectedUser}
                            selectedUser={selectedUser}
                            uniqueAdmin={uniqueAdmin}
                            invitationId={invitations ? user.id : undefined}
                            handleDeleteInvitation={handleDeleteInvitation}
                            refetchCompany={refetchCompany}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
