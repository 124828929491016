import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, useQuery } from 'react-query'
import { groupsQueries, queryClient } from '../../networking'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import ProceduresGroup from './ProceduresGroup'
import ProcedureCard from './ProcedureCard'
import SearchDialog from '../Dialogs/SearchDialog'
import { useHistory } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { areEqual, VariableSizeList } from 'react-window'
import { Dialog, DialogContent, DialogTitle, ListItem, Menu, MenuItem } from '@material-ui/core'

import LoadingSpinner from '../LoadingSpinner'
import AlertDialog from '../Dialogs/AlertDialog'
import { CompanyListDialog } from '../Companies/CompanyListDialog'
import { companiesQueries, duplicateProcedureQueries } from '../../networking'
import {
    useCompanyStore,
    useUserProfileStore,
    useProcedureSelectionOnMapStore,
    useMapStore,
    useSnackBarStore,
} from '../../stateManagement'
import InputDialog from '../Dialogs/InputDialog'

const useStyles = makeStyles((theme) => ({
    option: {
        color: theme.palette.text.secondary,
    },
    loadingDialog: {
        height: '250px',
    },
}))

const Row = React.memo(({ index, style, data }) => {
    const el = data.elements[index]
    if (!el) {
        return null
    }
    if (el.procedures)
        return (
            <ProceduresGroup
                key={el.id}
                style={style}
                groupId={el.id}
                groupName={el.name}
                deleteGroup={data.deleteGroupMutation.mutate}
                canUserDeleteGroup={() => data.canUserDeleteGroup(el.owner?.id)}
            >
                {el.procedures.map((procedure) => (
                    <ProcedureCard
                        key={procedure.id}
                        procedure={procedure}
                        activeId={data.activeId}
                        setActiveId={data.setActiveId}
                        openMenu={(e) => data.openMenu(e, procedure)}
                        isSuperAdmin={data.isSuperAdmin}
                        doesUserHaveWritePermission={data.doesUserHaveWritePermission}
                    />
                ))}
            </ProceduresGroup>
        )
    if (index >= data.procedures?.length) return null
    return (
        <ProcedureCard
            style={style}
            key={el.id}
            procedure={el}
            activeId={data.activeId}
            setActiveId={data.setActiveId}
            mapRefStore={data.mapRef}
            openMenu={(e) => data.openMenu(e, el)}
            isSuperAdmin={data.isSuperAdmin}
            doesUserHaveWritePermission={data.doesUserHaveWritePermission}
        />
    )
}, areEqual)

export default function ProceduresList({ procedures, getProcedures }) {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const listRef = React.useRef(null)
    const [isSelectCompanyDialogOpen, setIsSelectCompanyDialogOpen] = React.useState(false)
    const [anchorElement, setAnchorElement] = React.useState(null)
    const [isSearchDialogOpen, setIsSearchDialogOpen] = React.useState(false)
    const [isFormDialogOpen, setIsFormDialogOpen] = React.useState(false)
    const [groupName, setGroupName] = React.useState(null)
    const [selectedProcedureId, setSelectedProcedureId] = React.useState(null)
    const [selectedProcedureOwner, setSelectedProcedureOwner] = React.useState(null)
    const [selectedProcedureGroup, setSelectedProcedureGroup] = React.useState(null)
    const [elementsToRender, setElementsToRender] = React.useState([])
    const [isLoadingDialogOpen, setIsLoadingDialogOpen] = React.useState(false)
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const writeCompanies = useCompanyStore((state) => state.writeCompanies)
    const profile = useUserProfileStore((state) => state.profile)
    const userSelectedProcedureId = useProcedureSelectionOnMapStore(
        (state) => state.selectedProcedureId
    )
    const showSnackBar = useSnackBarStore((state) => state.show)
    const mapRef = useMapStore((state) => state.mapRef)
    const setUserSelectedProcedureId = useProcedureSelectionOnMapStore(
        (state) => state.setSelectedProcedureId
    )

    const setProceduresListRef = useProcedureSelectionOnMapStore(
        (state) => state.setProceduresListRef
    )

    // questa è la funzione che causava lo scroll della proceduresList al click
    // React.useEffect(() => {
    // if (userSelectedProcedureId) {
    //     const index = procedures.findIndex((p) => p.id === userSelectedProcedureId)
    //     if (index >= 0) {
    //         listRef.current?.scrollToItem(index, 'center')
    //     }
    // }
    // }, [procedures, userSelectedProcedureId])

    const isAdmin = (selectedCompany?.admins || []).map((user) => user.id).includes(profile?.id)

    const { data: companies = [] } = useQuery(
        companiesQueries.getCompanies.name,
        companiesQueries.getCompanies.fn,
        {
            enabled: isSelectCompanyDialogOpen,
            onSuccess: () => {
                setIsLoadingDialogOpen(false)
            },
        }
    )

    const { data: groups = [] } = useQuery(groupsQueries.getGroups.name, groupsQueries.getGroups.fn)

    const createGroupMutation = useMutation(groupsQueries.createGroup.fn, {
        onSuccess: async (data) => {
            await addProcedureToGroupMutation.mutateAsync(data.data.id, selectedProcedureId)
            queryClient.invalidateQueries(groupsQueries.getGroups.name)
            getProcedures()
        },
    })

    const addProcedureToGroupMutation = useMutation(
        (groupId) => groupsQueries.addProcedureToGroup.fn(groupId, selectedProcedureId),
        {
            onSuccess: () => {
                getProcedures()
            },
            onSettled: () => {
                setIsSearchDialogOpen(false)
            },
        }
    )

    const deleteGroupMutation = useMutation((groupId) => groupsQueries.deleteGroup.fn(groupId), {
        onSuccess: () => {
            getProcedures()
            queryClient.invalidateQueries(groupsQueries.getGroups.name)
        },
    })

    function handleDuplicateProcedureMenu() {
        closeMenu()
        setIsSelectCompanyDialogOpen(true)
    }

    const duplicateProcedure = useMutation(
        (destination_company_id = null) =>
            duplicateProcedureQueries.createDuplicateProcedure.fn(
                selectedProcedureId,
                destination_company_id
            ),
        {
            onSuccess: () => {
                getProcedures()
                setIsLoadingDialogOpen(false)
            },
            onError: (e) => {
                setIsLoadingDialogOpen(false)
                showSnackBar({
                    message: e,
                    severity: 'error',
                })
            },
        }
    )

    function handleDuplicateProcedure(companyId) {
        setIsConfirmationDialogOpen(false)
        setIsSelectCompanyDialogOpen(false)
        setIsLoadingDialogOpen(true)
        duplicateProcedure.mutate(companyId)
    }

    React.useEffect(() => {
        // divide procedures into grouped and ungrouped
        // const groupsMapping = {}
        // const ungroupedProcedures = []
        // procedures.forEach((procedure) => {
        //     if (!procedure.group) {
        //         ungroupedProcedures.push(procedure)
        //     } else {
        //         const groupId = procedure.group
        //         const { name: groupName, owner: groupOwner } =
        //             groups.find((group) => group.id === groupId) || {}
        //         if (!groupsMapping[groupId]) {
        //             groupsMapping[groupId] = {
        //                 name: groupName,
        //                 procedures: [],
        //                 id: groupId,
        //                 owner: groupOwner,
        //             }
        //         }
        //         groupsMapping[groupId].procedures.push(procedure)
        //     }
        // })
        // transform groups into array and merge everything
        // setElementsToRender([...Object.values(groupsMapping), ...ungroupedProcedures])  // commentato e sostituito con la riga sotto perché i gruppi rompono il layout. TODO: aggiustare i gruppi
        setElementsToRender([...procedures])
        listRef.current?.resetAfterIndex(0)
    }, [groups, procedures])

    function openMenu(e, procedure) {
        e.stopPropagation()
        setAnchorElement(e.currentTarget)
        setSelectedProcedureId(procedure.id)
        setSelectedProcedureOwner(procedure.owner)
        setSelectedProcedureGroup(procedure.group)
    }

    function closeMenu() {
        setAnchorElement(null)
    }

    // function openSearchDialog() {
    //     closeMenu()
    //     setIsSearchDialogOpen(true)
    // }

    function calcItemSize(index) {
        const currentEl = elementsToRender[index]
        let height
        if (!currentEl?.procedures) {
            height = 110
        } else {
            height = currentEl.procedures.length * 92 + 100 // add space for groupName
        }
        return height
    }

    function canUserDeleteGroup(groupOwnerID) {
        return writeCompanies.some((company) => company.owner === groupOwnerID)
    }

    function doesUserHaveWritePermission(procedureId) {
        return writeCompanies.find((company) => {
            if (company.owned_procedures) {
                return company.owned_procedures.includes(procedureId)
            } else {
                return false
            }
        })
    }

    React.useEffect(() => {
        setProceduresListRef(listRef)
    }, [listRef, setProceduresListRef])

    const onCompanyClick = (company) => {
        setIsConfirmationDialogOpen(true)
        setSelectedCompany(company)
    }

    const onCompanyDialogConfirm = (companyId) => {
        duplicateProcedure.mutate(companyId)
    }

    return (
        <>
            {elementsToRender.length > 0 ? (
                <AutoSizer>
                    {({ height, width }) => (
                        <VariableSizeList
                            ref={listRef}
                            className="List"
                            height={height}
                            itemCount={elementsToRender.length + 1}
                            itemSize={calcItemSize}
                            // decimal widths get ceiled causing horizontal overflow
                            width={Math.floor(width)}
                            itemData={{
                                activeId: userSelectedProcedureId,
                                elements: elementsToRender,
                                setActiveId: setUserSelectedProcedureId,
                                deleteGroupMutation,
                                mapRef,
                                openMenu,
                                canUserDeleteGroup,
                                isSuperAdmin,
                                doesUserHaveWritePermission,
                            }}
                        >
                            {Row}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            ) : (
                <ListItem>{t('no_results')}</ListItem>
            )}

            {/* OPTIONS MENU */}
            <Menu
                open={Boolean(anchorElement)}
                onClose={closeMenu}
                anchorEl={anchorElement}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {(isSuperAdmin || isAdmin) && (
                    <MenuItem className={classes.option} onClick={handleDuplicateProcedureMenu}>
                        {t('duplicate')}
                    </MenuItem>
                )}
                {/* user can edit groups only if he has write permissions on the company that owns the procedure */}
                <MenuItem
                    className={classes.option}
                    onClick={() => history.push(`/procedures/${selectedProcedureId}/access`)}
                >
                    {t('permessi_accesso')}
                </MenuItem>
                {/* TODO: aggiustare modifica complesso di strutture */}
                {/*<MenuItem className={classes.option} onClick={openSearchDialog}>*/}
                {/*    {t('cambia_gruppo')}*/}
                {/*</MenuItem>*/}
            </Menu>

            {/* MANAGE GROUPS DIALOG */}
            <SearchDialog
                open={isSearchDialogOpen}
                setIsOpen={setIsSearchDialogOpen}
                title="gruppo_selezione_titolo"
                button="gruppo_selezione_pulsante"
                handleButtonClick={() => setIsFormDialogOpen(true)}
                data={_.filter(groups, (group) => {
                    return group.id !== selectedProcedureGroup && canUserDeleteGroup(group.owner.id)
                })}
                handleResultClick={addProcedureToGroupMutation.mutate}
                icon={<ScatterPlotIcon />}
            />

            <CompanyListDialog
                companies={companies}
                isOpen={isSelectCompanyDialogOpen}
                setIsOpen={setIsSelectCompanyDialogOpen}
                onCompanyClick={onCompanyClick}
                confirmButtonText={t('duplicate_procedure_company_selection_source_company')}
                onConfirm={onCompanyDialogConfirm}
            />

            <AlertDialog
                open={isConfirmationDialogOpen}
                setIsOpen={setIsConfirmationDialogOpen}
                title={t('duplicate_procedure_alert_title', {
                    companyName: selectedCompany?.name,
                })}
                primaryButton="duplicate"
                onSubmitCb={() => {
                    handleDuplicateProcedure(selectedCompany.id)
                }}
            />
            <Dialog
                open={isLoadingDialogOpen}
                aria-labelledby="loading-dialog-title"
                scroll="paper"
                onClose={() => {
                    setIsLoadingDialogOpen(false)
                }}
            >
                <DialogContent className={classes.loadingDialog}>
                    <DialogTitle id="loading-dialog-title">
                        {t('operation_in_progress')}
                    </DialogTitle>
                    <LoadingSpinner />
                </DialogContent>
            </Dialog>

            <InputDialog
                open={isFormDialogOpen}
                setIsOpen={setIsFormDialogOpen}
                title="gruppo_creazione_titolo"
                inputLabel="nome"
                value={groupName}
                setValue={setGroupName}
                required
                button={'crea'}
                onSubmitCb={() =>
                    createGroupMutation.mutate({
                        name: groupName,
                        owner: selectedProcedureOwner,
                    })
                }
            />
        </>
    )
}
