import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    Typography,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import { useForm } from 'react-hook-form'
import { usePendingOperationsStore } from '../../stateManagement'
import { plcQueries, proceduresQueries, queryClient } from '../../networking'
import { createSelect, replaceEmptyStringsWithNullWithin } from '../../FormConfigs/utils'
import AppForm from '../../components/AppForm'
import DisplayValidationErrors from '../admin/DisplayValidationErrors'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import { useParams } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    hide: {
        display: 'none',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    analysis_catalog_table: {
        height: 400,
        width: '100%',
        backgroundColor: 'white',
    },
    default_analysis: {
        backgroundColor: yellow[700],
    },
    configuration_active: {
        color: 'black',
    },
    configuration_not_active: {
        color: 'gray'
    },

}))
export default function ConfigurePLC({ children, maxWidth = 'md' }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const procedureId = useParams().procedureId

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })
    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])

    const { data: allPlcData = [] } = useQuery(
        [plcQueries.getPLC.name],
        plcQueries.getPLC.fn
    )

    const { data: plcAssignedToProcedure = [] } = useQuery(
        [proceduresQueries.getProcedurePLC.name],
        () => proceduresQueries.getProcedurePLC.fn(procedureId)
    )

    // useEffect(() => {
    //     console.log("plcAssignedToProcedure: ")
    //     console.log(plcAssignedToProcedure)
    // }, [plcAssignedToProcedure])
    //
    // useEffect(() => {
    //     console.log("allPlcData: ")
    //     console.log(allPlcData)
    // }, [allPlcData])

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'identifier',
            headerName: 'Identifier',
            width: 300,
        },
        {
            field: 'field_pc',
            headerName: 'Field PC',
            width: 150,
            renderCell: (params) => {
                return params.value.name
            },
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 400,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleClickOpenPlcConfigurationsModal(params.row.id)}
                        >
                            {t('plc_configuration.configurations')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleClickOpenPlcConfigurationFormModal(params.row.id)}
                        >
                            {t('admin.plc.new_configuration')}
                        </Button>
                    </>
                )
            },
        },
    ]

    const [isOpenNewPLCAssociationForm, setIsOpenNewPLCAssociationForm] = React.useState(false)
    const [plcAssociationValidationErrors, setPlcAssociationValidationErrors] = React.useState()
    const procedureMutation = useMutation(
        (data) => proceduresQueries.assignPLCToProcedure.fn(replaceEmptyStringsWithNullWithin(data)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(proceduresQueries.getProcedurePLC.name)
                setPlcAssociationValidationErrors([])
                handleCloseNewPlcAssociationForm()
            },
            onError: (error) => {
                setPlcAssociationValidationErrors(error.response.data)
            },
        }
    )

    function handleClickOpenNewPlcAssociationForm() {
        setIsOpenNewPLCAssociationForm(true)
    }

    function handleCloseNewPlcAssociationForm() {
        setIsOpenNewPLCAssociationForm(false)
    }

    function submitNewPlcAssociation(data) {
        // console.log("submitNewPlcAssociation: ", data["plc_association"])
        if (isDirty && isValid) {
            runPendingOps().then(() => {
                procedureMutation.mutate(data['plc_association'])
            })
        }
    }

    const [isOpenPLCConfigurationsModal, setIsOpenPLCConfigurationsModal] = React.useState(false)
    const plcGetConfigurationMutation = useMutation(
        (data) => proceduresQueries.getProcedurePLCConfigurations.fn(data),
        {
            onSuccess: (response) => {
                setSelectedPlcConfigurations(response)
                setIsOpenPLCConfigurationsModal(true)
            },
            onError: (error) => {
                setSelectedPlcConfigurations([])
            },
        }
    )
    function handleClickOpenPlcConfigurationsModal(plc_id) {
        // Call api and after open modal
        plcGetConfigurationMutation.mutate({
            procedure_id: procedureId,
            plc_id: plc_id,
        })
    }
    function handleClickClosePlcConfigurationsModal() {
        setIsOpenPLCConfigurationsModal(false)
        setSelectedPlcConfigurations([])
    }

    const [isOpenPLCConfigurationFormModal, setIsOpenPLCConfigurationFormModal] = React.useState(false)
    const [plcIdForNewConfiguration, setPlcIdForNewConfiguration] = React.useState(null)
    function handleClickOpenPlcConfigurationFormModal(plc_id) {
        setIsOpenPLCConfigurationFormModal(true)
        setPlcIdForNewConfiguration(plc_id)
    }

    function handleClickClosePlcConfigurationFormModal() {
        setIsOpenPLCConfigurationFormModal(false)
        setPlcIdForNewConfiguration(null)
    }
    const [plcConfigurationValidationErrors, setPlcConfigurationValidationErrors] = React.useState()
    const plcNewConfigurationMutation = useMutation(
        (data) => proceduresQueries.newProcedurePLCConfiguration.fn(replaceEmptyStringsWithNullWithin(data)),
        {
            onSuccess: () => {
                setPlcConfigurationValidationErrors([])
                handleClickClosePlcConfigurationFormModal()
            },
            onError: (error) => {
                setPlcConfigurationValidationErrors(error.response.data)
            },
        }
    )

    function submitNewPlcConfiguration(data) {
        /*
        console.log("submitNewPlcConfiguration: ", data)
        {
            "plc_configuration": {
                "operation_mode": "continues_sampling",
                "sampling_frequency": {
                    "value": 1
                },
                "connected_sensor": 12,
                "procedure_id": "66a396b7b89de761ab4be0f1",
                "plc_id": "669e6ed64d4c572093201426"
            }
        }
        */
        let plc_configuration = {
            "operation_mode": data["plc_configuration"]["operation_mode"],
            "sampling_frequency": data["plc_configuration"]["sampling_frequency"]["value"],
            "connected_sensor": data["plc_configuration"]["connected_sensor"],
            "procedure_id": data["plc_configuration"]["procedure_id"],
            "plc_id": data["plc_configuration"]["plc_id"]
        }

        // console.log("sending configuration: ", plc_configuration)

        if (isDirty && isValid) {
            runPendingOps().then(() => {
                plcNewConfigurationMutation.mutate(plc_configuration)
            })
        }
    }

    const [selectedPlcConfigurations, setSelectedPlcConfigurations] = React.useState([])


    return (
        <>
            <Grid item container direction="column" justify-conte="space-around" spacing={3}>
                <Grid item>
                    <Typography variant="h4" component="h1" align="center">
                        {t('admin.plc')}
                    </Typography>
                </Grid>
                <Grid item align="right">
                    <Button variant="contained" onClick={handleClickOpenNewPlcAssociationForm}>
                        {t('admin.assign_plc')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    {t('admin.plc.assign')}
                    <div className={classes.analysis_catalog_table}>
                        <DataGrid
                            rows={plcAssignedToProcedure}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    </div>
                </Grid>
            </Grid>
            {/*Dialog di associazione PLC a procedure*/}
            <Dialog
                open={isOpenNewPLCAssociationForm}
                onClose={handleCloseNewPlcAssociationForm}
                aria-labelledby="form-plc"
                fullWidth={true}
                maxWidth="md"
            >
                <AppForm onSubmit={submitNewPlcAssociation} methods={methods}>
                    <DialogTitle id="form-analysis-catalog">PLC</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('admin.assign_plc_description')}</DialogContentText>
                        <Grid item>
                            <DisplayValidationErrors validationErrors={plcAssociationValidationErrors} />
                            <DynamicFormFields
                                disableAll={false}
                                fieldsKey={'plc_association'}
                                formFields={{
                                    procedure_id: {
                                        _type: 'hidden',
                                        defaultValue: procedureId
                                    },
                                    plc_id: {
                                        ...createSelect(
                                            allPlcData.map((plc) => {
                                                return { label: plc.name, value: plc.id }
                                            })
                                        ),
                                        __meta: { required: true },
                                    },
                                }}
                                formId={`form_plc_association`}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty || !isValid}
                        >
                            {procedureMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <>
                                    <SaveIcon />
                                    {t('salva')}
                                </>
                            )}
                        </Fab>
                    </DialogActions>
                </AppForm>
            </Dialog>

            {/*Dialog di tutte le configurazioni fatte sul PLC*/}
            <Dialog
                open={isOpenPLCConfigurationsModal}
                onClose={handleClickClosePlcConfigurationsModal}
                aria-labelledby="plc-configurations"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="plc-active-configuration">{t('plc_configuration.configurations')}</DialogTitle>
                <DialogContent>
                    {
                        selectedPlcConfigurations.map((plc_configuration, index) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={"plc-config-panel-header"+index}
                                >
                                    <Typography className={plc_configuration.is_active ? classes.configuration_active : classes.configuration_not_active}>{t('plc_configuration.configuration')} {index+1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid>
                                        <Grid>{t('plc_configuration.is_active')}: {plc_configuration.is_active ? t('SI') : t('NO')}</Grid>
                                        <Grid>{t('plc_configuration.start_date')}: {plc_configuration.start_date}</Grid>
                                        <Grid>{t('plc_configuration.end_date')}: {plc_configuration.end_date}</Grid>
                                        <Grid>{t('plc_configuration.configuration')}:
                                            <Grid>
                                                <Grid>{t('plc_configuration.operation_mode')}: {plc_configuration.configuration.operation_mode}</Grid>
                                                <Grid>{t('plc_configuration.sampling_frequency')}: {plc_configuration.configuration.sampling_frequency} Hz</Grid>
                                                <Grid>{t('plc_configuration.connected_sensor')}: {plc_configuration.configuration.connected_sensor}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </DialogContent>
            </Dialog>

            {/*Dialog di creazione di una nuova configurazione per il PLC*/}
            <Dialog
                open={isOpenPLCConfigurationFormModal}
                onClose={handleClickClosePlcConfigurationFormModal}
                aria-labelledby="plc-configuration-form"
                fullWidth={true}
                maxWidth="md"
            >
                <AppForm onSubmit={submitNewPlcConfiguration} methods={methods}>
                    <DialogTitle id="plc-active-configuration">{t('plc_configuration.configuration')}</DialogTitle>
                    <DialogContent>
                        <Grid item>
                            <DisplayValidationErrors validationErrors={plcConfigurationValidationErrors} />
                            <DynamicFormFields
                                disableAll={false}
                                fieldsKey={'plc_configuration'}
                                formFields={{
                                    procedure_id: {
                                        _type: 'hidden',
                                        defaultValue: procedureId
                                    },
                                    plc_id: {
                                        _type: 'hidden',
                                        defaultValue: plcIdForNewConfiguration
                                    },
                                    operation_mode: {
                                        ...createSelect([
                                            { label: "Continua", value: "continues_sampling" },
                                            { label: "Ad Intervalli", value: "interval_sampling" },
                                        ]),
                                        __meta: { required: true },
                                    },
                                    sampling_frequency: {
                                        _type: 'numberUnit',
                                        unit: 'Hz',
                                        step: '1',
                                        min: '1',
                                    },
                                    connected_sensor: {
                                        _type: 'number',
                                        step: '1',
                                        min: '1',
                                    },
                                }}
                                formId={`form_plc_configuration`}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty || !isValid}
                        >
                            {plcNewConfigurationMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <>
                                    <SaveIcon />
                                    {t('salva')}
                                </>
                            )}
                        </Fab>
                    </DialogActions>
                </AppForm>
            </Dialog>
        </>
    )
}
