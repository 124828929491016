import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { Fab, Paper, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useDisableForm from '../../customHooks/useDisableForm'
import { queryClient, levelsQueries, proceduresQueries } from '../../networking'
import { usePendingOperationsStore } from '../../stateManagement'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'
import AppForm from '../../components/AppForm'
import SaveIcon from '@material-ui/icons/Save'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import { mit_la_4_model } from '../../FormConfigs/mit_la_4_config'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

export default function LGP_LA4() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])

    const {
        isFetched: isProcedureFetched,
        isLoading,
        data: livello4,
    } = useQuery([levelsQueries.getMitLevel4.name, procedureId], () =>
        levelsQueries.getMitLevel4.fn(procedureId)
    )

    const { data: procedure = {} } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId),
        {
            enabled: isProcedureFetched,
            onSuccess: (data) => methods.reset({ ...livello4, hidden_company_id: data.owner.id }),
        }
    )
    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    const updateLevelMutation = useMutation(
        (data) => levelsQueries.updateLevel.fn(procedureId, 'mit_guidelines', 'level4', data),
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getMitLevel4.name),
        }
    )

    React.useEffect(() => {
        clearPendingOps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    function sendData(data) {
        if (isDirty && isValid) {
            delete data.hidden_company_id
            runPendingOps().then(() => updateLevelMutation.mutate(data))
        }
    }

    return (
        <MethodologyPage methodology="lgp" alert={alert}>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('allegati'))}
                </Typography>
                <AppForm onSubmit={sendData} methods={methods}>
                    <DynamicFormFields
                        fieldsKey={''}
                        formFields={mit_la_4_model}
                        disableAll={isNotAllowedToWrite}
                    />
                    {!isNotAllowedToWrite && (
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={
                                !isDirty || !isValid || isLoading || updateLevelMutation.isLoading
                            }
                            className={classes.fab}
                        >
                            {updateLevelMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    )}
                </AppForm>
            </Paper>
        </MethodologyPage>
    )
}
