import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button, Grid, Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease'
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease'
import Delete from '@material-ui/icons/Delete'
import Check from '@material-ui/icons/Check'
import AppForm from './AppForm'
import DynamicFormFields from './FormItems/DynamicFormFields'
import { booleanType, createSelect, numberType, textType } from '../FormConfigs/utils'
import {
    convertBackendSchemaToFrontendSchema,
    convertFrontendSchemaToBackendSchema,
    recursiveSearch,
} from '../utils'
import AlertDialog from './Dialogs/AlertDialog'
import { useUserProfileStore } from '../stateManagement'

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        background: theme.palette.background.default,
        padding: theme.spacing(2),
    },
}))

function FieldPicker({
    onSubmit,
    onDownClick,
    onUpClick,
    onDeleteClick,
    currentPointer,
    isReader,
}) {
    const methods = useForm()
    const { t } = useTranslation()

    const fieldSchema = {
        name: textType,
        type: createSelect(['str', 'float', 'bool', 'dict']),
    }

    return (
        <Grid item style={{ marginBottom: 50 }}>
            <AppForm methods={methods} onSubmit={onSubmit}>
                <DynamicFormFields fieldsKey={''} formFields={fieldSchema} disableAll={isReader} />
                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    style={{ marginRight: 5 }}
                    disabled={isReader}
                >
                    <AddIcon />
                </Button>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={onDownClick}
                    edge="start"
                    style={{ marginRight: 5 }}
                    disabled={isReader}
                >
                    <FormatIndentIncreaseIcon />
                </Button>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={onUpClick}
                    edge="start"
                    style={{ marginRight: 5 }}
                    disabled={isReader}
                >
                    <FormatIndentDecreaseIcon />
                </Button>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={onDeleteClick}
                    edge="start"
                    style={{ marginRight: 5 }}
                    disabled={isReader}
                >
                    <Delete />
                </Button>
            </AppForm>
            <Grid container direction="column" style={{ marginTop: 16 }}>
                <Typography variant="caption" component="p" color="textSecondary">
                    {t('posizione_inserimento_attuale') + ':'}
                </Typography>
                {currentPointer.replaceAll('.', ' → ')}
            </Grid>
        </Grid>
    )
}

export default function CustomCompanyProcedureFields({ company, mutation }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [localSchema, setLocalSchema] = React.useState(
        company.settings?.procedure_extra_fields_schema
            ? convertBackendSchemaToFrontendSchema(
                  company.settings?.procedure_extra_fields_schema,
                  'procedure_extra_fields_schema'
              )
            : {}
    )
    const profile = useUserProfileStore((state) => state.profile)
    const [schemaPointer, setSchemaPointer] = React.useState('')
    const [availablePointers, setAvailablePointers] = React.useState(
        _.map(localSchema, (k, v) => v)
    )

    const [pointerIndex, setPointerIndex] = React.useState(0)
    const methods = useForm()

    const decodeMap = {
        str: textType,
        bool: booleanType,
        dict: { _type: 'group' },
        float: numberType,
    }
    const isReader = company.readers.map((reader) => reader.id).includes(profile.id)

    function handleFieldPick(value) {
        if (value?.name && value?.type) {
            const clonedState = _.cloneDeep(localSchema)
            _.set(
                clonedState,
                schemaPointer ? schemaPointer + '.' + value.name : value.name,
                decodeMap[value.type]
            )
            setLocalSchema(clonedState)
        }
    }

    function nextGroupDownward() {
        if (pointerIndex < availablePointers.length - 1) {
            setSchemaPointer(availablePointers[pointerIndex + 1])
            setPointerIndex(pointerIndex + 1)
        } else {
            const newPointers = recursiveSearch(
                localSchema,
                availablePointers[0],
                (x) => x?._type === 'group'
            )
            setAvailablePointers(newPointers || [])
            if (newPointers?.length > 0) {
                setSchemaPointer(newPointers[0])
            } else {
                setSchemaPointer('')
            }
            setPointerIndex(0)
        }
    }

    function nextGroupUpward() {
        if (pointerIndex > 0) {
            setPointerIndex(pointerIndex - 1)
            setSchemaPointer(availablePointers[pointerIndex - 1])
        } else if (schemaPointer) {
            //REDO search and setPointerIndex to result length
            let upByOne = schemaPointer.substring(0, schemaPointer.lastIndexOf('.')) || ''
            setSchemaPointer(upByOne)
        } else {
            setAvailablePointers(_.map(localSchema, (k, v) => v))
        }
    }

    function deleteNode() {
        if (!schemaPointer) {
            setLocalSchema({})
            setSchemaPointer('')
        } else {
            const clonedSchema = _.cloneDeep(localSchema)
            _.unset(clonedSchema, schemaPointer)
            setSchemaPointer('')
            setLocalSchema(clonedSchema)
        }
    }

    const confirmSendMutation = () => {
        mutation.mutate(convertFrontendSchemaToBackendSchema(localSchema))
    }

    return (
        <Grid item container xs={12} direction="column">
            <FieldPicker
                onSubmit={handleFieldPick}
                onDownClick={nextGroupDownward}
                onUpClick={nextGroupUpward}
                onDeleteClick={deleteNode}
                currentPointer={schemaPointer}
                isReader={isReader}
            />
            <Grid item>
                <Paper className={classes.previewContainer}>
                    <Typography variant="h6" gutterBottom align="center">
                        {t('anteprima')}
                    </Typography>
                    {_.isEmpty(localSchema) && (
                        <Typography
                            variant="caption"
                            component="p"
                            color="textSecondary"
                            align="center"
                            gutterBottom
                            style={{ marginBottom: 8 }}
                        >
                            {t('nessuna_anteprima_disponibile')}
                        </Typography>
                    )}
                    <AppForm methods={methods}>
                        <DynamicFormFields
                            fieldsKey=""
                            formFields={localSchema}
                            disableAll={isReader}
                        />
                    </AppForm>
                </Paper>
                {!isReader && (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Check />}
                        style={{ marginTop: 16 }}
                        onClick={() => setIsDialogOpen(true)}
                    >
                        {t('conferma')}
                    </Button>
                )}
            </Grid>
            <AlertDialog
                open={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                title="procedure_extra_fields_setting_confirm_title"
                text="procedure_extra_fields_setting_confirm_text"
                primaryButton="conferma"
                onSubmitCb={() => {
                    setIsDialogOpen(false)
                    confirmSendMutation()
                }}
            />
        </Grid>
    )
}
