import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import ProcedureStepper from '../ProcedureStepper'
import { capitalizeFirstLetter } from '../../../utils'
const useStyles = makeStyles(() => ({
    details: {
        fontSize: '0.65rem',
        fontWeight: 100,
    },
}))

export default function MethodologiesProgress({ methodologies = {} }) {
    const classes = useStyles()

    const getPhaseName = (phaseName) => {
        switch (phaseName) {
            case 'started':
                return 'In lavorazione'
            default:
                return phaseName
        }
    }
    return (
        <Grid>
            {Object.keys(methodologies).map((methodologyName, index) => {
                const methodology = methodologies[methodologyName]
                return (
                    <Grid
                        key={methodologyName + index}
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Typography
                                className={classes.details}
                                variant="caption"
                                color="textSecondary"
                            >
                                {`${
                                    methodology.name === 'Linee guida MIT - LA2'
                                        ? 'LGP'
                                        : methodology.name.toUpperCase()
                                }:  ${capitalizeFirstLetter(
                                    getPhaseName(methodology.progress.phase_name.toLowerCase())
                                )}`}
                            </Typography>
                        </Grid>
                        <Grid item>{/* <ProcedureStepper steps={methodology.progress} /> */}</Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}
