import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    section: {
        padding: theme.spacing(3),
        height: '100%',
    },
}))

export default function Section({
    children,
    direction,
    innerSpacing,
    wrap,
    alignItems,
    paperStyle,
    ...props
}) {
    const classes = useStyles()

    return (
        <Grid {...props} item>
            <Paper className={classes.section} style={paperStyle ? paperStyle : undefined}>
                <Grid
                    container
                    spacing={innerSpacing}
                    direction={direction}
                    wrap={wrap}
                    alignItems={alignItems}
                    style={{ position: 'relative' }}
                >
                    {children}
                </Grid>
            </Paper>
        </Grid>
    )
}
