import React, { useContext } from 'react'
import { methodologies, structureTypesByMethodology } from '../constants'
import _ from 'lodash'
import { retractBranch } from '../utils'
import create from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const StoredFiltersContext = React.createContext({})

export const getMethodologyStoredFiltersProvider = (() => {
    const storesByMethodology = {}
    return function getMethodologyStoredFiltersProvider(methodology) {
        if (methodology !== undefined && !methodologies.includes(methodology)) {
            throw new Error(`invalid methodology ${methodology}`)
        }
        methodology = methodology || ''
        if (!(methodology in storesByMethodology)) {
            storesByMethodology[methodology] = create(
                persist(
                    (set, get) => ({
                        filters: { rules: {} },
                        setFilters: (filters) => {
                            set({ filters: { rules: filters?.rules || {} } })
                        },
                        activeStructureTypes: [...structureTypesByMethodology[methodology]].sort(),
                        setActiveStructureTypes: (activeStructureTypes) =>
                            set({
                                activeStructureTypes: activeStructureTypes
                                    .filter((type) =>
                                        structureTypesByMethodology[methodology].includes(type)
                                    )
                                    .sort(),
                            }),
                        companyId: undefined,
                        setCompanyId: (companyId) => set({ companyId }),
                        // filteredProcedures: [],
                        // setFilteredProcedures: (filteredProcedures) => set({ filteredProcedures }),
                        resetAllFilters: () => {
                            set({
                                filters: { rules: {} },
                                // activeStructureTypes: Object.fromEntries(structureTypes.map(type => [type, true])),
                            })
                        },
                        addRule: (path, operatore, valore, options) => {
                            const rulesAreEqual =
                                options?.rulesAreEqual ||
                                ((rule) => rule.operatore === operatore && rule.valore === valore)
                            const filters = get().filters
                            const rulesOnPath = _.get(filters.rules, path, [])
                            if (_.findIndex(rulesOnPath, rulesAreEqual) !== -1) {
                                // rule is already present
                                return false
                            }
                            rulesOnPath.push({
                                chiave: undefined,
                                operatore: operatore,
                                valore: valore,
                            })
                            _.set(filters.rules, path, rulesOnPath)
                            get().setFilters(filters)
                            return true
                        },
                        removeRule: (path, operatore, valore, options) => {
                            const rulesAreEqual =
                                options?.rulesAreEqual ||
                                ((rule) => rule.operatore === operatore && rule.valore === valore)
                            const filters = get().filters
                            const rulesOnPath = _.get(filters.rules, path, [])
                            if (_.remove(rulesOnPath, rulesAreEqual).length) {
                                _.set(filters.rules, path, rulesOnPath)
                                retractBranch(filters.rules, path)
                                get().setFilters(filters)
                                return true
                            }
                            return false
                        },
                        toggleRule: (path, operatore, valore, options) => {
                            // restituisce lo stato finale della regola
                            return (
                                get().addRule(path, operatore, valore, options) ||
                                !get().removeRule(path, operatore, valore, options)
                            )
                        },
                        isRuleSet: (path, operatore, valore, options) => {
                            const rulesAreEqual =
                                options?.rulesAreEqual ||
                                ((rule) => rule.operatore === operatore && rule.valore === valore)
                            const filters = get().filters
                            const rulesOnPath = _.get(filters.rules, path, [])
                            return _.findIndex(rulesOnPath, rulesAreEqual) !== -1
                        },
                    }),
                    {
                        storage: createJSONStorage(() => localStorage),
                        name: `${methodology}-filters-store`,
                    }
                )
            )
        }

        return ({ children }) => (
            <StoredFiltersContext.Provider value={{ store: storesByMethodology[methodology] }}>
                {children}
            </StoredFiltersContext.Provider>
        )
    }
})()
export const useStoredFilters = (extractState) =>
    useContext(StoredFiltersContext).store(extractState)
