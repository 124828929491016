import useProcedureId from "../useProcedureId";
import { useMutation } from "react-query";
import { queryClient } from "../../networking";
import axios from "axios";

export function useCreateAnalysisMutation() {
    const {procedureId} = useProcedureId()
    return useMutation(
        async (data) => {
            const resp = await axios.post('/analyses', { ...data, procedure: procedureId })
            return resp.data
        },
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['getProcedureAnalyses', data.procedure])
                return data
            },
        }
    )
}

export function usePatchAnalysisVersionMutation() {
    return useMutation(
        async ({ new_version, is_active, active_version_major_minor_patch, analysis_id }) => {
            const resp = await axios.patch('/analyses', {
                analysis_id,
                new_version,
                is_active,
                active_version_major_minor_patch,
            })
            return resp.data
        },
        {
            onSuccess: async (data) => {
                // console.log(data)
                await queryClient.invalidateQueries(['getProcedureAnalyses', data.procedure])
                return data
            },
        }
    )
}