import React from 'react'
import _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { levelsQueries, queryClient, proceduresQueries } from '../../../networking'
import SchedeIspezione from '../../../components/LGP/LA1/SchedeIspezione'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import NetworkErrorOverlay from '../../../components/NetworkErrorOverlay'
import MethodologyPage from '../../MethodologyPage'
import { useUserProfileStore } from '../../../stateManagement'
import useDisableForm from '../../../customHooks/useDisableForm'

export default function LGP_LA1_difetto_overview() {
    const profile = useUserProfileStore((state) => state.profile)
    const { t } = useTranslation()
    const history = useHistory()
    const { elementoStrutturale, procedureId } = useParams()
    const [currentElementTotCount, setCurrentElementTotCount] = React.useState(t('N/D'))

    // taken from url in kebab-case, need it in snake_case for be purposes
    const currentElementoStrutturale = elementoStrutturale.replaceAll('-', '_')

    const {
        data: livello0 = {},
        isLoading: isLivello0Loading,
        refetch: refetch0,
        error: error0,
    } = useQuery([levelsQueries.getMitLevel0.name, procedureId], () =>
        levelsQueries.getMitLevel0.fn(procedureId)
    )

    const { data: procedure } = useQuery([proceduresQueries.getProcedure.name, procedureId], () =>
        proceduresQueries.getProcedure.fn(procedureId)
    )

    const {
        data: livello1 = {},
        isLoading: isLivello1Loading,
        refetch: refetch1,
        error: error1,
    } = useQuery([levelsQueries.getMitLevel1.name, procedureId], () =>
        levelsQueries.getMitLevel1.fn(procedureId)
    )

    const addSchedaMutation = useMutation(
        ({ chosenMaterial, setChosenMaterial }) => {
            const data = _.cloneDeep(livello1)
            // prepopulate fields
            const progressivaKmIniziale = livello0?.identificazione.progressiva_km_iniziale
            const profileName = `${profile.first_name || ''} ${profile.last_name || ''}`.trim()
            const newScheda = {
                progressiva_km: progressivaKmIniziale,
                tecnico_rilevatore: profileName,
                materiale: chosenMaterial,
            }
            data.schede_ispezione[currentElementoStrutturale] = [
                ...data.schede_ispezione[currentElementoStrutturale],
                newScheda,
            ]
            return levelsQueries.updateLevel.fn(procedureId, 'mit_guidelines', 'level1', data)
        },
        {
            onSuccess: ({ data }, { setChosenMaterial }) => {
                setChosenMaterial('')
                queryClient.setQueryData(
                    [levelsQueries.getMitLevel1.name, procedureId],
                    data.methodologies.mit_guidelines.workspace.level1
                )
                history.push(
                    `./${currentElementoStrutturale.replaceAll('_', '-')}/${
                        schedeIspezione.length + 1
                    }`
                )
            },
        }
    )

    const copySchedaMutation = useMutation(
        ({ index, setIsDuplicateDialogOpen, cardsToDuplicate }) => {
            // adding manually data before sending
            const data = _.cloneDeep(livello1)
            const scheda = livello1.schede_ispezione[currentElementoStrutturale][index]
            const clonedScheda = _.cloneDeep(scheda)
            Object.entries(clonedScheda.difetti).forEach(([id, difetto]) => {
                delete difetto.foto
            })
            data.schede_ispezione[currentElementoStrutturale] = [
                ...data.schede_ispezione[currentElementoStrutturale],
            ]
            for (let i = 0; i < cardsToDuplicate; i++)
                data.schede_ispezione[currentElementoStrutturale].push(clonedScheda)
            return levelsQueries.updateLevel
                .fn(
                    procedureId,
                    'mit_guidelines',
                    'level1',
                    replaceEmptyStringsWithNullWithin(data)
                )
                .then(() => {
                    setIsDuplicateDialogOpen(false)
                    return queryClient.invalidateQueries([
                        levelsQueries.getMitLevel1.name,
                        procedureId,
                    ])
                })
        }
    )
    const deleteSchedaMutation = useMutation((index) => {
        const data = _.cloneDeep(livello1)
        data.schede_ispezione[currentElementoStrutturale].splice(index, 1)

        levelsQueries.updateLevel
            .fn(procedureId, 'mit_guidelines', 'level1', replaceEmptyStringsWithNullWithin(data))
            .then(() => {
                queryClient.invalidateQueries([levelsQueries.getMitLevel1.name, procedureId])
            })
    })

    const addSchedaToGroupMutation = useMutation(
        ({ groupingIndex, groupName, setGroupingIndex }) => {
            if (groupingIndex !== null && groupName) {
                const data = _.cloneDeep(livello1)
                data.schede_ispezione[currentElementoStrutturale][groupingIndex].group = groupName
                return levelsQueries.updateLevel.fn(procedureId, 'mit_guidelines', 'level1', data)
            }
        },
        {
            onSuccess: ({ data }, { setGroupingIndex }) => {
                // use be response to set the data, no need to refetch
                queryClient.setQueryData(
                    [levelsQueries.getMitLevel1.name, procedureId],
                    data.methodologies.mit_guidelines.workspace.level1
                )
                setGroupingIndex(null)
            },
        }
    )

    const deleteGroupMutation = useMutation(
        (groupName) => {
            const data = _.cloneDeep(livello1)
            data.schede_ispezione[currentElementoStrutturale].forEach((scheda) => {
                if (scheda.group === groupName) {
                    scheda.group = null
                }
            })
            return levelsQueries.updateLevel.fn(procedureId, 'mit_guidelines', 'level1', data)
        },
        {
            onSuccess: ({ data }) => {
                queryClient.setQueryData(
                    [levelsQueries.getMitLevel1.name, procedureId],
                    data.methodologies.mit_guidelines.workspace.level1
                )
            },
        }
    )

    const schedeIspezione = livello1?.schede_ispezione?.[currentElementoStrutturale]

    function calcTotalCount(element, source) {
        // n_elementi_totali is not used and will be removed from be
        const partialCounts = _.omit(source[element], 'n_elementi_totali')
        let sum = 0
        Object.entries(partialCounts).forEach(([material, number]) => {
            sum += number
        })
        return sum
    }

    React.useEffect(() => {
        if (Object.keys(livello0).length > 0 && Object.keys(livello1).length > 0) {
            const elementsTotalCountsMapping = {
                spalle: calcTotalCount('spalle', livello1.tipologia_elementi_strutturali),
                pile: calcTotalCount('pile', livello1.tipologia_elementi_strutturali),
                apparecchi_appoggio: livello1.apparecchi_appoggio.n_apparecchi_tot || 0,
                giunti: livello0.giunti.numero_totale_giunti || 0,
                archi: calcTotalCount('arco', livello1.tipologia_elementi_strutturali_impalcato),
                travi:
                    calcTotalCount('travi', livello1.tipologia_elementi_strutturali_impalcato) +
                    calcTotalCount('traversi', livello1.tipologia_elementi_strutturali_impalcato),
                soletta: calcTotalCount(
                    'soletta',
                    livello1.tipologia_elementi_strutturali_impalcato
                ),
            }
            setCurrentElementTotCount(elementsTotalCountsMapping[currentElementoStrutturale])
        }
    }, [livello0, livello1, currentElementoStrutturale])

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    return (
        <MethodologyPage methodology="lgp" alert={alert}>
            {error0 || error1 ? (
                <NetworkErrorOverlay
                    actions={[
                        {
                            onClick: () => {
                                refetch0()
                                refetch1()
                            },
                            label: t('retry'),
                        },
                    ]}
                    areActionsPending={isLivello0Loading || isLivello1Loading}
                />
            ) : (
                <SchedeIspezione
                    currentElementoStrutturale={currentElementoStrutturale}
                    currentElementTotCount={currentElementTotCount}
                    isNotAllowedToWrite={isNotAllowedToWrite}
                    isLivello1Loading={isLivello1Loading}
                    livello1={livello1}
                    handleCardClick={(fakeId) =>
                        history.push(
                            `./${currentElementoStrutturale.replaceAll('_', '-')}/${fakeId + 1}`
                        )
                    }
                    deleteGroupMutation={deleteGroupMutation}
                    addSchedaToGroupMutation={addSchedaToGroupMutation}
                    copySchedaMutation={copySchedaMutation}
                    addSchedaMutation={addSchedaMutation}
                    deleteSchedaMutation={deleteSchedaMutation}
                    isInBMSComparison={false}
                />
            )}
        </MethodologyPage>
    )
}
