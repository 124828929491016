import React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 24,
        height: 24,
        fontSize: '0.85rem',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginLeft: 10,
    },
    summary: {
        justifyContent: 'space-around',
    },
    customChip: {
        backgroundColor: theme.palette.secondary.main,
        color: 'black',
        marginRight: '10px',
        borderRadius: '10px',
        padding: '0 8px',
        display: 'flex',
        justifyContent: 'center',
    },
    customChipMobile: {
        fontSize: '11px',
        height: '16px',
        borderRadius: '8px',
        padding: '0 4px',
    },
}))

export default function AppAccordion({
    children,
    accordionLabel,
    avatarContent,
    renderHeaderContent,
    avatarStyle = {},
    accordionDetailsStyle,
    ...props
}) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: t(accordionLabel),
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {renderHeaderContent && renderHeaderContent()}
                    {avatarContent && (
                        <Avatar className={classes.avatar} style={avatarStyle}>
                            {avatarContent}
                        </Avatar>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails style={accordionDetailsStyle}>{children}</AccordionDetails>
        </Accordion>
    )
}
