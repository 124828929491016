import { createFieldArray, createSelect, numberType, textareaType, textType } from "./utils";
import { analysisExecutionTypes, analysisTypes, structureTypes } from "../constants";

export const inputFile = {
    _type: 'group',
    name: textType,
    content: textareaType
}

export const analysisTemplateConfigurationFields = {
    _type: 'group',
    docker_image_url: textType,
    scheduled_execution_type: createSelect(analysisExecutionTypes),
    input_files: createFieldArray(inputFile)
}
export const analysisConfigurationFields = {
    ...analysisTemplateConfigurationFields,
    schedule_every_seconds: {...numberType, step: 1, min: 1},
    schedule_at_date: {_type: 'dateTime'},
    seconds_data_to_analyze: {...numberType, step: 1, min: 1},
}

export const analysisTemplateVersionFields = {
    _type: 'group',
    version_major_minor_patch: textType,
    configuration: analysisTemplateConfigurationFields
}

export const analysisVersionFields = {
    ...analysisTemplateVersionFields,
    configuration: analysisConfigurationFields
}

export const analysisTemplateFields = {
    _type: 'group',
    name: textType,
    type: createSelect(analysisTypes),
    available_for_infrastructure_type: createSelect(structureTypes, true),
    available_for_execution_type: createSelect(analysisExecutionTypes, true),
    active_version: createSelect([]),
    versions: createFieldArray(analysisTemplateVersionFields),
}

export const analysisFields = {
    _type: 'group',
    template_name: createSelect([]),
    template_type: textType,
    active_version: createSelect([]),
    versions: createFieldArray(analysisVersionFields),
    procedure: {_type: 'hidden'},
}

