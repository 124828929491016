import { useQueryCompany } from "./queryHooks/companyQueries";
import { useUserProfileStore } from "../stateManagement";

export default function useIsCompanyAdmin() {
    const {data: company} = useQueryCompany()
    const [userProfile, isSuperAdmin] = useUserProfileStore(state => [state.profile, state.isSuperAdmin])
    const isCompanyAdmin = isSuperAdmin || (company?.admins || []).map(company => company?.id).includes(userProfile?.id)
    
    return { isCompanyAdmin }
}

