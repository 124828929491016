import React from 'react'
import { Grid, Typography, Divider, IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    fieldsContainer: {
        paddingTop: theme.spacing(2),
    },
    groupLabel: {
        marginRight: theme.spacing(),
        color: theme.palette.text.secondary,
    },
    outerContainer: {
        paddingRight: 0,
        paddingLeft: 0,
    },
}))

export default function FieldsGroup({
    groupLabel,
    children,
    direction = 'row',
    hideUI,
    renderExtra = null,
    extra = null,
    info,
    onClickInfo,
}) {
    const classes = useStyles({ hideUI })
    const { t } = useTranslation()

    return hideUI ? (
        <Grid
            container
            direction={direction}
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
        >
            {children}
        </Grid>
    ) : (
        <>
            <Divider light style={{ marginBottom: 10 }} />
            <Grid container justifyContent="space-between">
                <Typography
                    variant="caption"
                    className={classes.groupLabel}
                    dangerouslySetInnerHTML={{
                        __html: t(groupLabel),
                    }}
                />
                {renderExtra && renderExtra(extra)}
                {info && (
                    <Tooltip placement="top-end" title={info}>
                        <IconButton size="small" onClick={onClickInfo}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>
            <Grid
                container
                direction={direction}
                alignItems="center"
                spacing={3}
                className={classes.fieldsContainer}
            >
                {children}
            </Grid>
            <Divider light style={{ marginTop: 10 }} />
        </>
    )
}
