import dayjs from 'dayjs'
import React from 'react'
import {
    AppBar,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useQueries } from 'react-query'

import DettaglioSchedaIspezione from '../../../components/LGP/LA1/DettaglioSchedaIspezione'
import LGPLA1DifettositaCards from '../../../components/LGP/LA1/difettosita'
import SchedaElementiAccessori from '../../../components/LGP/LA1/SchedaElementiAccessori'
import SchedeIspezione from '../../../components/LGP/LA1/SchedeIspezione'
import LGPToolbar from '../../../components/LGPToolbar'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { scheda_info } from '../../../FormConfigs/mit_la_1_difetto_config'
import { useLocation } from 'react-router-dom'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import AppAccordion from '../../../components/AppAccordion'
import { mit_la_4_model } from '../../../FormConfigs/mit_la_4_config'
import { snapshotsQueries } from '../../../networking'
import { isSafeToUnmount } from '../../../utils'
import { useForm } from 'react-hook-form'
import AppForm from '../../../components/AppForm'
import LA3Results from '../../../components/LA3Results'
import {
    sections_to_render_censimento,
    sections_to_render_documentazione,
    mit_la_0_model,
} from '../../../FormConfigs/mit_la_0_config'
import {
    mit_la_1_model_scheda_descrittiva,
    sections_to_render_scheda_descrittiva,
} from '../../../FormConfigs/mit_la_1_config'
import LA2Results from './LA2Results'
import MenuFenomeni from './MenuFenomeni'
import { mit_la_3_model_form, mit_la_3_model_documents } from '../../../FormConfigs/mit_la_3_config'
import AlertDialog from '../../../components/Dialogs/AlertDialog'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
    },
    outerContainer: {
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
    },
    rightContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    gridContainer: {
        marginTop: 15,
    },
}))

export default function InspectionsComparison({ open, onClose, inspections }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [isCloseDialogOpen, setIsCloseDialogOpen] = React.useState(false)
    const [currentLgpSection, setCurrentLgpSection] = React.useState({
        currentSection: 'level2',
    })

    const onBackButtonEvent = (e) => {
        e.preventDefault()
        setIsCloseDialogOpen(true)
    }

    React.useEffect(() => {
        if (open) {
            window.history.pushState(null, null, window.location.pathname)
            window.addEventListener('popstate', onBackButtonEvent)
        } else window.removeEventListener('popstate', onBackButtonEvent)
    }, [open])

    React.useEffect(() => {
        if (open) {
            setCurrentLgpSection({
                currentSection: 'level2',
            })
        }
    }, [open])

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const results = useQueries(
        inspections.map((inspectionId) => ({
            queryKey: [snapshotsQueries.getInspection.name, inspectionId],
            queryFn: () => snapshotsQueries.getInspection.fn(inspectionId),
            enabled: open && !!inspectionId,
        }))
    )

    // TODO: sistemare le date nel titolo
    const { pathname } = useLocation()

    const lgpLevelsUrl = pathname.split('lgp')[0] + 'lgp'

    const comparisonLgpMenu = [
        {
            text: 'LA0',
            items: [
                {
                    text: 'censimento',
                    selected: currentLgpSection.currentSection === 'level0.censimento',
                    onClick: () =>
                        setCurrentLgpSection({
                            currentSection: 'level0.censimento',
                            levelName: 'level0',
                            sections_to_render: sections_to_render_censimento,
                            model: mit_la_0_model,
                        }),
                },
                {
                    text: 'documentazione',
                    selected: currentLgpSection.currentSection === 'level0.documentazione',
                    onClick: () =>
                        setCurrentLgpSection({
                            currentSection: 'level0.documentazione',
                            levelName: 'level0',
                            sections_to_render: sections_to_render_documentazione,
                            model: mit_la_0_model,
                        }),
                },
            ],
        },
        {
            text: 'LA1',
            items: [
                {
                    text: 'scheda_descrittiva',
                    selected: currentLgpSection.currentSection === 'level1.scheda-descrittiva',
                    onClick: () =>
                        setCurrentLgpSection({
                            currentSection: 'level1.scheda-descrittiva',
                            levelName: 'level1',
                            sections_to_render: sections_to_render_scheda_descrittiva,
                            model: mit_la_1_model_scheda_descrittiva,
                        }),
                },
                {
                    text: 'frane_e_fenomeni_idraulici',
                    selected: currentLgpSection.currentSection?.startsWith(
                        'level1.frane_e_fenomeni_idraulici'
                    ),
                    onClick: () =>
                        setCurrentLgpSection({
                            currentSection: 'level1.frane_e_fenomeni_idraulici',
                            levelName: 'level1.',
                            sections_to_render: null,
                            model: null,
                        }),
                },
                {
                    text: 'difettosita',
                    selected: currentLgpSection.currentSection?.startsWith('level1.difettosita'),
                    onClick: () =>
                        setCurrentLgpSection({
                            currentSection: 'level1.difettosita',
                            levelName: 'level1.difettosita',
                            sections_to_render: null,
                            model: null,
                        }),
                },
            ],
        },
        {
            text: 'LA2',
            selected: currentLgpSection.currentSection === 'level2',
            onClick: () =>
                setCurrentLgpSection({
                    currentSection: 'level2',
                }),
        },
        {
            text: 'LA3',
            selected: currentLgpSection.currentSection === 'level3.results',
            onClick: () =>
                setCurrentLgpSection({
                    currentSection: 'level3.results',
                    levelName: 'level3',
                    sections_to_render: null,
                    model: mit_la_3_model_documents,
                }),
            items: [
                {
                    text: 'dati',
                    selected: currentLgpSection.currentSection === 'level3.data',
                    onClick: () => {
                        setCurrentLgpSection({
                            currentSection: 'level3.data',
                            sections_to_render: mit_la_3_model_form,
                            model: mit_la_3_model_form,
                        })
                    },
                },
            ],
        },
        {
            text: 'LA4',
            selected: currentLgpSection.currentSection === 'livello-4',
            onClick: () =>
                setCurrentLgpSection({
                    currentSection: 'livello-4',
                    levelName: 'level4',
                    model: mit_la_4_model,
                }),
        },
    ]

    const [sectionToRender, setSectionToRender] = React.useState(null)
    React.useEffect(() => {
        if (
            results[0].status !== 'success' ||
            _.isEmpty(results[0]?.data) ||
            _.isEmpty(results[1]?.data)
        )
            return
        methods.reset({
            [inspections[0]]: results[0].data.data,
            [inspections[1]]: results[1].data.data,
        })
        function calcTotalCount(element, source) {
            // n_elementi_totali is not used and will be removed from be
            const partialCounts = _.omit(source[element], 'n_elementi_totali')
            let sum = 0
            Object.entries(partialCounts).forEach(([material, number]) => {
                sum += number
            })
            return sum
        }

        switch (currentLgpSection.currentSection) {
            case 'level1.frane_e_fenomeni_idraulici':
                setSectionToRender(<MenuFenomeni onSectionSelection={setCurrentLgpSection} />)
                break
            case 'level1.difettosita': {
                // onCardClick,
                // elementsTotalCountsMapping, // un oggetto con le chiavi spalle, pile, ecc e i valori sono il numero totale di elementi di quella tipologia presenti sul ponte
                // schedeConDifettiCount, // un oggetto con le chiavi spalle, pile, ecc e i valori sono il numero di elementi ispezionati che hanno almeno un difetto di quella tipologia
                // schedeIspezione, // tutte le schede d'ispezione: un oggetto con le chiavi spalle, pile, ecc e i valori sono il numero totale di elementi ispezionati di quella tipologia

                const elementsTotalCountsMapping = [
                    {
                        spalle: calcTotalCount(
                            'spalle',
                            results[0].data.data.level1.tipologia_elementi_strutturali
                        ),
                        pile: calcTotalCount(
                            'pile',
                            results[0].data.data.level1.tipologia_elementi_strutturali
                        ),
                        'apparecchi-appoggio':
                            results[0].data.data.level1.apparecchi_appoggio.n_apparecchi_tot,
                        giunti: results[0].data.data.level0.giunti.numero_totale_giunti,
                        archi: calcTotalCount(
                            'arco',
                            results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                        travi:
                            calcTotalCount(
                                'travi',
                                results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ) +
                            calcTotalCount(
                                'traversi',
                                results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ),
                        soletta: calcTotalCount(
                            'soletta',
                            results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                    },
                    {
                        spalle: calcTotalCount(
                            'spalle',
                            results[1].data.data.level1.tipologia_elementi_strutturali
                        ),
                        pile: calcTotalCount(
                            'pile',
                            results[1].data.data.level1.tipologia_elementi_strutturali
                        ),
                        'apparecchi-appoggio':
                            results[1].data.data.level1.apparecchi_appoggio.n_apparecchi_tot,
                        giunti: results[1].data.data.level0.giunti.numero_totale_giunti,
                        archi: calcTotalCount(
                            'arco',
                            results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                        travi:
                            calcTotalCount(
                                'travi',
                                results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ) +
                            calcTotalCount(
                                'traversi',
                                results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ),
                        soletta: calcTotalCount(
                            'soletta',
                            results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                    },
                ]

                function countSchedeConDifetti(schede) {
                    let count = 0
                    schede.forEach((scheda) => {
                        if (calcDefectsNumber(scheda.difetti) >= 1) {
                            count++
                        }
                    })
                    return count
                }

                function calcDefectsNumber(collection = {}) {
                    let sum = 0
                    Object.entries(collection).forEach(([key, value]) => {
                        if ((value.estensione || value.intensita) && !value.motivo_assenza) {
                            sum += 1
                        }
                    })
                    return sum
                }

                const schedeConDifettiCount = [
                    {
                        spalle: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.spalle
                        ),
                        pile: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.pile
                        ),
                        'apparecchi-appoggio': countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.apparecchi_appoggio
                        ),
                        giunti: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.giunti
                        ),
                        piedritti: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.piedritti
                        ),
                        archi: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.archi
                        ),
                        travi: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.travi
                        ),
                        soletta: countSchedeConDifetti(
                            results[0].data.data.level1.schede_ispezione.soletta
                        ),
                    },
                    {
                        spalle: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.spalle
                        ),
                        pile: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.pile
                        ),
                        'apparecchi-appoggio': countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.apparecchi_appoggio
                        ),
                        giunti: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.giunti
                        ),
                        piedritti: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.piedritti
                        ),
                        archi: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.archi
                        ),
                        travi: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.travi
                        ),
                        soletta: countSchedeConDifetti(
                            results[1].data.data.level1.schede_ispezione.soletta
                        ),
                    },
                ]
                setSectionToRender(
                    <Grid
                        item
                        container
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={5}
                        style={{ paddingTop: '50px', paddingLeft: '50px', paddingRight: '50px' }}
                    >
                        <LGPLA1DifettositaCards
                            inspectionDates={[
                                results[0].data.inspection_date,
                                results[1].data.inspection_date,
                            ]}
                            schedeIspezione={[
                                results[0].data.data.level1.schede_ispezione,
                                results[1].data.data.level1.schede_ispezione,
                            ]}
                            elementsTotalCountsMapping={elementsTotalCountsMapping}
                            schedeConDifettiCount={schedeConDifettiCount}
                            isInBMSComparison
                            onCardClick={(elementoStrutturaleName) =>
                                setCurrentLgpSection({
                                    currentSection: `level1.difettosita.${elementoStrutturaleName}`,
                                    currentElementoStrutturale: elementoStrutturaleName,
                                    levelName: 'level1.difettosita',
                                    sections_to_render: null,
                                    model: null,
                                })
                            }
                        />
                    </Grid>
                )
                break
            }
            case 'level1.difettosita.spalle.scheda':
            case 'level1.difettosita.pile.scheda':
            case 'level1.difettosita.apparecchi-appoggio.scheda':
            case 'level1.difettosita.giunti.scheda':
            case 'level1.difettosita.piedritti.scheda':
            case 'level1.difettosita.archi.scheda':
            case 'level1.difettosita.travi.scheda':
            case 'level1.difettosita.soletta.scheda': {
                setSectionToRender(
                    <>
                        <Grid item xs={6}>
                            <DettaglioSchedaIspezione
                                currentElementoStrutturale={
                                    currentLgpSection.currentElementoStrutturale
                                }
                                livello1={results[0].data.data.level1}
                                formPath={`schede_ispezione.${currentLgpSection.currentElementoStrutturale}.[${currentLgpSection.schedaIndex}]`}
                                formPathForDynamicFormFields={`${inspections[0]}.level1.schede_ispezione.${currentLgpSection.currentElementoStrutturale}.[${currentLgpSection.schedaIndex}]`}
                                methods={methods}
                                isNotAllowedToWrite
                                isInBMSComparison
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DettaglioSchedaIspezione
                                currentElementoStrutturale={
                                    currentLgpSection.currentElementoStrutturale
                                }
                                livello1={results[1].data.data.level1}
                                formPath={`schede_ispezione.${currentLgpSection.currentElementoStrutturale}.[${currentLgpSection.schedaIndex}]`}
                                formPathForDynamicFormFields={`${inspections[1]}.level1.schede_ispezione.${currentLgpSection.currentElementoStrutturale}.[${currentLgpSection.schedaIndex}]`}
                                methods={methods}
                                isNotAllowedToWrite
                                isInBMSComparison
                            />
                        </Grid>
                    </>
                )
                break
            }
            case 'level1.difettosita.elementi-accessori': {
                setSectionToRender(
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={6}>
                            <SchedaElementiAccessori
                                methods={methods}
                                formPath={`schede_ispezione.elementi_accessori`}
                                formPathForDynamicFormFields={`${inspections[0]}.level1.schede_ispezione.elementi_accessori`}
                                isInBMSComparison
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SchedaElementiAccessori
                                methods={methods}
                                formPath={`schede_ispezione.elementi_accessori`}
                                formPathForDynamicFormFields={`${inspections[1]}.level1.schede_ispezione.elementi_accessori`}
                                isInBMSComparison
                            />
                        </Grid>
                    </Grid>
                )
                break
            }
            case 'level1.difettosita.spalle':
            case 'level1.difettosita.pile':
            case 'level1.difettosita.apparecchi-appoggio':
            case 'level1.difettosita.giunti':
            case 'level1.difettosita.piedritti':
            case 'level1.difettosita.archi':
            case 'level1.difettosita.travi':
            case 'level1.difettosita.soletta': {
                const currentElementTotCount = [
                    {
                        spalle: calcTotalCount(
                            'spalle',
                            results[0].data.data.level1.tipologia_elementi_strutturali
                        ),
                        pile: calcTotalCount(
                            'pile',
                            results[0].data.data.level1.tipologia_elementi_strutturali
                        ),
                        'apparecchi-appoggio':
                            results[0].data.data.level1.apparecchi_appoggio.n_apparecchi_tot,
                        giunti: results[0].data.data.level0.giunti.numero_totale_giunti,
                        archi: calcTotalCount(
                            'arco',
                            results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                        travi:
                            calcTotalCount(
                                'travi',
                                results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ) +
                            calcTotalCount(
                                'traversi',
                                results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ),
                        soletta: calcTotalCount(
                            'soletta',
                            results[0].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                    },
                    {
                        spalle: calcTotalCount(
                            'spalle',
                            results[1].data.data.level1.tipologia_elementi_strutturali
                        ),
                        pile: calcTotalCount(
                            'pile',
                            results[1].data.data.level1.tipologia_elementi_strutturali
                        ),
                        'apparecchi-appoggio':
                            results[1].data.data.level1.apparecchi_appoggio.n_apparecchi_tot,
                        giunti: results[1].data.data.level0.giunti.numero_totale_giunti,
                        archi: calcTotalCount(
                            'arco',
                            results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                        travi:
                            calcTotalCount(
                                'travi',
                                results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ) +
                            calcTotalCount(
                                'traversi',
                                results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                            ),
                        soletta: calcTotalCount(
                            'soletta',
                            results[1].data.data.level1.tipologia_elementi_strutturali_impalcato
                        ),
                    },
                ]
                setSectionToRender(
                    <>
                        <Grid item xs={6}>
                            <SchedeIspezione
                                currentElementoStrutturale={currentLgpSection.currentElementoStrutturale.replace(
                                    '-',
                                    '_'
                                )}
                                currentElementTotCount={
                                    currentElementTotCount[0][
                                    currentLgpSection.currentElementoStrutturale
                                    ]
                                }
                                isNotAllowedToWrite={true}
                                isLivello1Loading={results[0].isLoading}
                                livello1={results[0].data.data.level1}
                                handleCardClick={(fakeId) => {
                                    setCurrentLgpSection({
                                        currentSection: `level1.difettosita.${currentLgpSection.currentElementoStrutturale}.scheda`,
                                        currentElementoStrutturale:
                                            currentLgpSection.currentElementoStrutturale.replace(
                                                '-',
                                                '_'
                                            ),
                                        schedaIndex: fakeId,
                                        levelName: 'level1.difettosita',
                                        model: scheda_info,
                                    })
                                }}
                                isInBMSComparison={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SchedeIspezione
                                currentElementoStrutturale={currentLgpSection.currentElementoStrutturale.replace(
                                    '-',
                                    '_'
                                )}
                                currentElementTotCount={
                                    currentElementTotCount[1][
                                    currentLgpSection.currentElementoStrutturale
                                    ]
                                }
                                isNotAllowedToWrite={true}
                                isLivello1Loading={results[1].isLoading}
                                livello1={results[1].data.data.level1}
                                handleCardClick={(fakeId) => {
                                    setCurrentLgpSection({
                                        currentSection: `level1.difettosita.${currentLgpSection.currentElementoStrutturale}.scheda`,
                                        currentElementoStrutturale:
                                            currentLgpSection.currentElementoStrutturale.replace(
                                                '-',
                                                '_'
                                            ),
                                        schedaIndex: fakeId,
                                        levelName: 'level1.difettosita',
                                        model: scheda_info,
                                    })
                                }}
                                isInBMSComparison={true}
                            />
                        </Grid>
                    </>
                )
                break
            }
            case 'level2': {
                setSectionToRender(
                    <Grid container className={classes.gridContainer} justifyContent="space-evenly">
                        <Grid item xs={5}>
                            <LA2Results results={results[0]} />
                        </Grid>
                        <Grid item>
                            <Divider orientation="vertical" style={{ padding: 0 }} />
                        </Grid>
                        <Grid item xs={5}>
                            <LA2Results results={results[1]} />
                        </Grid>
                    </Grid>
                )
                break
            }
            case 'level3.results': {
                setSectionToRender(
                    <Grid
                        container
                        spacing={3}
                        className={classes.gridContainer}
                        justifyContent="space-evenly"
                    >
                        <Grid item xs={5} className={classes.gridContainer}>
                            <LA3Results
                                isNotAllowedToWrite
                                currentDataPath={results[0].data.data.level3}
                                isInBMSComparison
                                inspection={results[0].data}
                            />
                            <AppForm methods={methods}>
                                <DynamicFormFields
                                    disableAll
                                    fieldsKey={`${inspections[0]}.${currentLgpSection.levelName}`}
                                    formFields={currentLgpSection.model}
                                />
                            </AppForm>
                        </Grid>
                        <Grid item xs={1}>
                            <Divider
                                orientation="vertical"
                                style={{ padding: 0, margin: '0 auto' }}
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.gridContainer}>
                            <LA3Results
                                isNotAllowedToWrite
                                currentDataPath={results[1].data.data.level3}
                                isInBMSComparison
                                inspection={results[1].data}
                            />
                            <AppForm methods={methods}>
                                <DynamicFormFields
                                    disableAll
                                    fieldsKey={`${inspections[1]}.${currentLgpSection.levelName}`}
                                    formFields={currentLgpSection.model}
                                />
                            </AppForm>
                        </Grid>
                    </Grid>
                )
                break
            }
            case 'level3.data':
                setSectionToRender(
                    <Grid
                        container
                        spacing={3}
                        className={classes.gridContainer}
                        justifyContent="space-evenly"
                    >
                        <Grid item xs={5}>
                            <AppForm methods={methods}>
                                <DynamicFormFields
                                    fieldsKey={`${inspections[0]}.${currentLgpSection.currentSection}`}
                                    formFields={currentLgpSection.model}
                                    disableAll={true}
                                />
                            </AppForm>
                        </Grid>
                        <Grid item>
                            <Divider orientation="vertical" style={{ padding: 0 }} />
                        </Grid>
                        <Grid item xs={5}>
                            <AppForm methods={methods}>
                                <DynamicFormFields
                                    fieldsKey={`${inspections[1]}.${currentLgpSection.currentSection}`}
                                    formFields={currentLgpSection.model}
                                    disableAll={true}
                                />
                            </AppForm>
                        </Grid>
                    </Grid>
                )
                break
            default:
                setSectionToRender(
                    <Grid item xs={12}>
                        <AppForm methods={methods}>
                            {!currentLgpSection.sections_to_render ? (
                                <Grid container xs={12} justifyContent="space-evenly">
                                    <Grid item xs={5}>
                                        <DynamicFormFields
                                            disableAll={true}
                                            fieldsKey={`${inspections[0]}.${currentLgpSection.levelName}`}
                                            formFields={currentLgpSection.model}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Divider
                                            orientation="vertical"
                                            style={{ padding: 0, margin: '0 auto' }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <DynamicFormFields
                                            disableAll={true}
                                            fieldsKey={`${inspections[1]}.${currentLgpSection.levelName}`}
                                            formFields={currentLgpSection.model}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                currentLgpSection.sections_to_render.map((section, index) => {
                                    return (
                                        <AppAccordion
                                            TransitionProps={{
                                                unmountOnExit: isSafeToUnmount(
                                                    currentLgpSection.model[section]
                                                ),
                                            }}
                                            key={`${section}${index}`}
                                            accordionLabel={section}
                                        >
                                            <Grid container xs={12} justifyContent="space-evenly">
                                                <Grid xs={5}>
                                                    <DynamicFormFields
                                                        disableAll={true}
                                                        fieldsKey={`${inspections[0]}.${currentLgpSection.levelName}.${section}`}
                                                        formFields={
                                                            currentLgpSection.model[section]
                                                        }
                                                        isInBmsComparison={true}
                                                    />
                                                </Grid>
                                                <Divider
                                                    orientation="vertical"
                                                    style={{ padding: 0 }}
                                                />
                                                <Grid xs={5}>
                                                    <DynamicFormFields
                                                        disableAll={true}
                                                        fieldsKey={`${inspections[1]}.${currentLgpSection.levelName}.${section}`}
                                                        formFields={
                                                            currentLgpSection.model[section]
                                                        }
                                                        isInBmsComparison={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AppAccordion>
                                    )
                                })
                            )}
                        </AppForm>
                    </Grid>
                )
        }
    }, [
        currentLgpSection.currentSection,
        JSON.stringify(_.pick(results, ['0.status', '0.data', '1.status', '1.data'])),
    ])
    return (
        <Dialog fullScreen open={open} onClose={() => setIsCloseDialogOpen(true)} scroll="paper">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {t('comparing_inspections', {
                            inspection_1: dayjs(
                                _.get(results, [0, 'data', 'inspection_date'])
                            ).format('DD/MM/YYYY'),
                            inspection_2: dayjs(
                                _.get(results, [1, 'data', 'inspection_date'])
                            ).format('DD/MM/YYYY'),
                        })}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsCloseDialogOpen(true)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ padding: 0 }}>
                {results[0].isLoading || results[1].isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Grid container wrap="nowrap" className={classes.outerContainer}>
                        <Grid item style={{ overflow: 'auto' }}>
                            <LGPToolbar data={comparisonLgpMenu} />
                        </Grid>
                        <Grid item className={classes.rightContainer}>
                            <Grid container>{sectionToRender}</Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <AlertDialog
                open={isCloseDialogOpen}
                setIsOpen={setIsCloseDialogOpen}
                title='end_comparison'
                primaryButton='close'
                onSubmitCb={() => {
                    setIsCloseDialogOpen(false)
                    onClose()
                }}
                secondaryButtonAction={() => {
                    setIsCloseDialogOpen(false)
                    window.history.pushState(null, null, window.location.pathname)
                }}
            />
        </Dialog>
    )
}
