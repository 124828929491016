import _ from 'lodash'
import procedureModel from './procedureModel'
import { createSelect, textType } from './utils'
import { flattenObject } from '../utils'

export function getFiltersModelValueFromPathRule(
    path,
    dataModel,
    t = (x) => x,
    autoCompleteValues = {}
) {
    const fieldModel = _.get(dataModel, path, textType)
    const fieldType = fieldModel._type
    return {
        chiave: {
            _type: 'text',
            label: `.${path}`
                .split('.')
                .slice(-2)
                .map((s) => t(s))
                .join(' &rarr; '),
            disabled: true,
        },
        ...(() => {
            let operatore = null
            switch (fieldType) {
                // some types are blacklisted, see RecursiveNode
                case 'text':
                    operatore = createSelect([
                        { label: 'contiene', value: 'search' },
                        { label: 'esattamente uguale a', value: 'equals' },
                        { label: 'diverso da', value: 'ne' },
                    ])
                    break
                case 'number':
                case 'numberUnit':
                    operatore = createSelect([
                        { label: '>', value: 'gt' },
                        { label: '<', value: 'lt' },
                        { label: '=', value: 'eq' },
                        { label: '≠', value: 'ne' },
                    ])
                    break
                case 'boolean':
                    operatore = createSelect([
                        { label: '=', value: 'eq' },
                        { label: '≠', value: 'ne' },
                    ])
                    break
                case 'select':
                case 'buttonGroup':
                    operatore = createSelect([
                        { label: '=', value: 'in' },
                        { label: '≠', value: 'nin' },
                    ])
                    break
                case 'date':
                case 'year':
                    operatore = createSelect([
                        { label: 'precedente a', value: 'lt' },
                        { label: 'successivo a', value: 'gt' },
                        { label: 'esattamente uguale a', value: 'eq' },
                        { label: 'diverso da', value: 'ne' },
                    ])
                    break
                case 'coordinates':
                    operatore = { _type: 'hidden', defaultValue: 'geoWithin' }
                    break
                default:
                    operatore = createSelect([])
            }
            return operatore === undefined ? {} : { operatore }
        })(),
        valore: {
            ...fieldModel,
            ...(path in autoCompleteValues && {
                __meta: { autoCompleteValues: autoCompleteValues[path] },
            }),
            ...(fieldType === 'coordinates' && { __meta: { advancedSearch: true } }),
        },
    }
}

export function getRuleCountByPath(filters) {
    let currentPath = []
    let result = {}

    function _count(currentNode) {
        if (Array.isArray(currentNode) && currentNode.length) {
            result[currentPath.join('.')] = currentNode.length
        } else if (typeof currentNode === 'object') {
            Object.entries(currentNode).forEach(([key, value]) => {
                currentPath.push(key)
                _count(value)
                currentPath.pop()
            })
        }
    }

    _count(filters.rules)
    return result
}

export function setModelAutocompleteValues(model, autoCompleteValues) {
    const flattenedModel = flattenDataModel(model)
    Object.entries(flattenedModel)
        .filter(([path]) => path in autoCompleteValues)
        .forEach(([path, fieldModel]) => {
            _.set(fieldModel, '__meta.autoCompleteValues', autoCompleteValues[path])
        })
}

export function createFiltersModel(filters, options) {
    const filtersModel = {}
    const ruleCounts = getRuleCountByPath(filters)
    Object.entries(ruleCounts).forEach(([path, count]) => {
        for (let i = 0; i < count; i++) {
            addModelRule(filtersModel, path, { ...options, key: `${path}[${i}]` })
        }
    })
    return filtersModel
}

export function addModelRule(filtersModel, path, options) {
    const translationFunction = options?.translationFunction || ((x) => x)
    const _procedureModel = options?.procedureModel || procedureModel
    const autoCompleteValues = options?.autoCompleteValues || {}
    let key = options?.key
    filtersModel.rules = filtersModel.rules || {}
    if (key === undefined) {
        let i = 0
        do {
            key = `${path}[${i++}]`
        } while (key in filtersModel.rules)
    }
    filtersModel.rules[key] = getFiltersModelValueFromPathRule(
        path,
        _procedureModel,
        translationFunction,
        autoCompleteValues
    )
}

export const supportedTypes = Object.freeze(
    new Set([
        'text',
        'number',
        'numberUnit',
        'boolean',
        'select',
        'buttonGroup',
        'date',
        'year',
        'coordinates',
    ])
)

export function flattenDataModel(dataModel) {
    return Object.fromEntries(
        Object.entries(flattenObject(dataModel))
            .filter(([path, value]) => !!path.match(/\._type$/) && supportedTypes.has(value))
            .map(([path]) => path.replace(/\._type$/, ''))
            .map((path) => [path, _.get(dataModel, path)])
    )
}
