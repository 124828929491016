import React from 'react'
import { useLocation } from 'react-router-dom'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CoordinatesPicker from './CoordinatesPicker'
import { arrayToCoords, coordsToArray } from '../../utils'
import { usePlaceStore } from '../../stateManagement'
import useProcedureId from '../../customHooks/useProcedureId'

export default function CoordinatesPickerDialog({
    isOpen,
    setIsOpen,
    onChange,
    coordinates,
    initialZoom,
    drawCoordinatesArea = false,
    rectangleCoords,
}) {
    // React.useEffect(() => console.log("new coordinates dialog rendered, props: ", {
    //     isOpen,
    //     setIsOpen,
    //     onChange,
    //     coordinates,
    //     initialZoom,
    //     drawCoordinatesArea,
    //     rectangleCoords,
    // }), [])

    const location = useLocation()
    const { procedureId: paramsProcedureId } = useProcedureId()
    const [coords, setCoords] = React.useState(coordinates)
    const setPlace = usePlaceStore((state) => state.setPlace)

    function handleChange(coords) {
        if (!drawCoordinatesArea) {
            setCoords(coordsToArray(coords))
        } else {
            setCoords(coords)
        }
    }

    function confirmCoords(place) {
        onChange(arrayToCoords(coords))
        // place is needed for autocomplete purposes only in the procedure creation and edit form
        if (
            (location.pathname === '/procedures/init' ||
                location.pathname === `/procedures/${paramsProcedureId}`) &&
            place
        ) {
            setPlace(place)
        }
        onChange(drawCoordinatesArea ? coords : arrayToCoords(coords))
        // console.log('closing from onConfirm')
        setIsOpen(false)
    }

    const { t } = useTranslation()
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{t('coordinates_picker_dialog_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('coordinates_picker_dialog_description')}</DialogContentText>
                <CoordinatesPicker
                    initialZoom={initialZoom}
                    coordinates={coords}
                    onChange={handleChange}
                    onConfirm={confirmCoords}
                    drawCoordinatesArea={drawCoordinatesArea}
                    rectangleCoords={rectangleCoords}
                />
            </DialogContent>
        </Dialog>
    )
}
