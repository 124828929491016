import { saveAs } from 'file-saver'
import _ from 'lodash'
import React from 'react'
import RESISTO_Classe01 from './assets/resisto/classes/RESISTO_Classe01.png'
import RESISTO_Classe02 from './assets/resisto/classes/RESISTO_Classe02.png'
import RESISTO_Classe03 from './assets/resisto/classes/RESISTO_Classe03.png'
import RESISTO_Classe04 from './assets/resisto/classes/RESISTO_Classe04.png'
import RESISTO_Classe05 from './assets/resisto/classes/RESISTO_Classe05.png'
import RESISTO_Classi_colored from './assets/resisto/classes/RESISTO_Classi_colored.png'
import { getRuleCountByPath, setModelAutocompleteValues } from './FormConfigs/filter_model_config'
import procedureModel from './FormConfigs/procedureModel'
import { structureTypes } from './constants'

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
}

export const removeSnakeCaseAndCapitalize = (stringa) => {
    const splittedString = stringa.split('_')
    const result = splittedString
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    return result
}

export function getInitials(name, surname) {
    return name?.charAt(0).toUpperCase() + surname?.charAt(0).toUpperCase() || '--'
}

export function arrayToCoords(someObj) {
    if (Array.isArray(someObj) && someObj.length === 2) {
        return { lat: someObj[0], lng: someObj[1] }
    } else {
        return null
    }
}

export function coordsToArray(coordsObj) {
    return [coordsObj.lat, coordsObj.lng]
}

// export function generate_sio_jwt(password = 'supersecret', token = 'pippo123') {
//     const oHeader = { alg: 'HS256', typ: 'JWT' }
//     const oPayload = {
//         iss: 'FIBA_backend',
//         sub: 'generate_docx_report',
//         exp: jws.IntDate.get('now + 1hour'),
//         token: token,
//     }
//     return jws.JWS.sign('HS256', JSON.stringify(oHeader), JSON.stringify(oPayload), password)
// }

export function recursiveSearch(someObject, startingPath, decidingFunction) {
    function _search(someObj, path) {
        if (decidingFunction(someObj)) {
            if (!startingPath || (startingPath && path !== startingPath)) {
                return [path] // found it
            }
        }
        let result = []

        if (!(Array.isArray(someObj) || typeof someObj === 'object')) return

        _.each(someObj, (value, key) => {
            let found = _search(value, path ? path + '.' + key : key)

            if (found) {
                result = [...result, ...found]
            }
        })
        return result
    }

    const currentValue = startingPath ? _.get(someObject, startingPath, {}) : someObject

    return _search(currentValue, startingPath)
}

export function convertFrontendSchemaToBackendSchema(feSchema) {
    const decodeMap = {
        text: 'str',
        boolean: 'bool',
        group: 'dict',
        number: 'float',
    }
    //This still needs to be tested with arrays
    function convert(obj, output) {
        if (Array.isArray(obj) || typeof obj === 'object') {
            if (!Array.isArray(obj) && (!obj._type || obj._type === 'group')) {
                // is object but doesn't contain _type
                output.schema_type = 'dict'
                output.schema_value = {}
                const { _type, ...objWithoutType } = obj
                _.each(objWithoutType, (value, key) => {
                    output.schema_value[key] = convert(value, {})
                })
            } else {
                output.schema_type = decodeMap[obj._type]
            }
        }
        return output
    }

    const result = { schema_type: 'dict', schema_value: {} }
    convert(feSchema, result)
    return result
}

export function convertBackendSchemaToFrontendSchema(beSchema) {
    if (!beSchema) {
        return {}
    }
    const decodeMap = {
        str: 'text',
        bool: 'boolean',
        dict: 'group',
        float: 'number',
    }

    function convert(obj, output) {
        if (obj.schema_type) {
            output._type = decodeMap[obj.schema_type]
            if (output._type === 'group') {
                _.each(obj.schema_value, (value, key) => {
                    output[key] = convert(value, {})
                })
            }
        }
        return output
    }

    const result = {}
    convert(beSchema, result)
    const { _type, ...resultWithoutType } = result
    return resultWithoutType
}

export function useDebouncedValue(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = React.useState(value)
    React.useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
}

export function flattenObj(obj, parent = undefined, res = {}) {
    // this doesn't really flatten the input, as it handles arrays as atomic values
    if (Array.isArray(obj)) {
        res[parent] = obj
    } else {
        for (let key in obj) {
            let propName = parent ? parent + '.' + key : key
            if (typeof obj[key] == 'object' && !Array.isArray(obj)) {
                flattenObj(obj[key], propName, res)
            } else {
                res[propName] = obj[key]
            }
        }
    }
    return res
}

export const flattenObject = (object) =>
    object instanceof Object
        ? Object.fromEntries(
              Object.entries(object)
                  .map(([key, object]) => [key, flattenObject(object)])
                  .flatMap(([key, object]) =>
                      object instanceof Object
                          ? Object.entries(object).map(([path, object]) => [
                                `${key}.${path}`,
                                object,
                            ])
                          : [[key, object]]
                  )
          )
        : object

export function isSafeToUnmount(formConfigObject) {
    /***
     * Unmounting fieldsArrays loses statefulcomponents state, so they cant
     * really be unmounted, regardless of react-hook-form 'shoundUnregister'
     */
    return !JSON.stringify(formConfigObject).includes('fieldsArray')
}

export const materialsMapping = {
    spalle: [
        { value: 'CA', label: 'calcestruzzo_armato' },
        { value: 'MURATURA', label: 'muratura' },
    ],
    pile: [
        { value: 'CA', label: 'calcestruzzo_armato' },
        { value: 'MURATURA', label: 'muratura' },
        { value: 'ACCIAIO', label: 'acciaio' },
        { value: 'METALLO', label: 'metallo' },
    ],
    piedritti: [
        { value: 'CA', label: 'calcestruzzo_armato' },
        { value: 'ACCIAIO', label: 'acciaio' },
        { value: 'METALLO', label: 'metallo' },
    ],
    archi: [
        { value: 'CA', label: 'calcestruzzo_armato' },
        { value: 'MURATURA', label: 'muratura' },
        { value: 'ACCIAIO', label: 'acciaio' },
        { value: 'LEGNO', label: 'legno' },
    ],
    travi: [
        { value: 'CA', label: 'calcestruzzo_armato' },
        { value: 'CAP', label: 'calcestruzzo_armato_precompresso' },
        { value: 'ACCIAIO', label: 'acciaio' },
        { value: 'METALLO', label: 'metallo' },
        { value: 'LEGNO', label: 'legno' },
    ],
    soletta: [
        { value: 'CA', label: 'calcestruzzo_armato' },
        { value: 'LEGNO', label: 'legno' },
    ],
}

export const isL3Enabled = (procedure) => {
    return procedure?.owner?.enabled_modules?.includes('L3')
}

export const isBmsEnabled = (entity) => {
    if (Object.keys(entity).includes('owner'))
        return entity?.owner?.enabled_modules?.includes('BMS')
    else return entity?.enabled_modules?.includes('BMS')
}

export const conversions = { ALTA: 5, MEDIOALTA: 4, MEDIA: 3, MEDIOBASSA: 2, BASSA: 1 }

export function autofillAddressRelatedFields(procedureType, place, setValue) {
    if (place) {
        if (procedureType === 'bridge') {
            setValue('address', place.formatted_address)
        } else if (procedureType === 'building') {
            // transform array into useful object
            const addressComponentsMap = _.keyBy(place.address_components, (el) => el.types[0])
            setValue('place.street', addressComponentsMap?.route?.long_name || '')
            setValue('place.street_number', addressComponentsMap?.street_number?.long_name || '')
            setValue('place.city', addressComponentsMap?.locality?.long_name || '')
            setValue('place.asset_code', addressComponentsMap?.postal_code?.short_name || '')
            setValue(
                'place.province',
                addressComponentsMap?.administrative_area_level_2?.short_name || ''
            )
            setValue('place.country', addressComponentsMap?.country?.long_name || '')
        }
    }
}

export const getResistoClassImage = (resisto_class) => {
    switch (resisto_class) {
        case '1':
            return RESISTO_Classe01
        case '2':
            return RESISTO_Classe02
        case '3':
            return RESISTO_Classe03
        case '4':
            return RESISTO_Classe04
        case '5':
            return RESISTO_Classe05
        case 'logo':
            return RESISTO_Classi_colored
        default:
            return 'image not found'
    }
}

export const removeDashFromString = (inputString) => {
    const cleaned_string = _.replace(inputString, /-/g, ' ')
    return cleaned_string
}

export const formatFiltersForBe = (rules) => {
    const flattenedRules = flattenObj(rules)
    const encodedFilters = Object.entries(flattenedRules)
        .flatMap(([path, rules]) => rules.map((rule) => [path, rule]))
        .map(([path, { operatore, valore }]) => JSON.stringify([path, operatore, valore]))
    return encodedFilters

    const filtersAsArray = []
    for (let [path, rules] of Object.entries(flattenedRules)) {
        for (let rule of rules) {
            if (rule.operatore) {
                let value
                switch (typeof rule.valore) {
                    case 'string':
                        value = "'" + rule.valore + "'"
                        break
                    case 'object':
                        if (rule.valore?.value) {
                            // input type "numberUnit"
                            path += '.value'
                            value = rule.valore.value
                        } else {
                            value = JSON.stringify(rule.valore)
                        }
                        break
                    default:
                        value = rule.valore
                }
                filtersAsArray.push(`${path},${rule.operatore},${value}`)
            } else {
                //quando in coordinate
                if (Array.isArray(rule.valore) && rule.valore.length === 4) {
                    const value = rule.valore
                        .map((point) => {
                            return point.coordinates.join(',')
                        })
                        .join(';')
                    filtersAsArray.push(`${path},geoWithin,${value}`)
                }
            }
        }
    }
    return filtersAsArray
}

export const handleExcelResponseFile = (response, filename, type) => {
    const blob = new Blob([response], {
        type: type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, filename)
}

export function retractBranch(someObject, path) {
    const objects = [someObject]
    const keys = []
    for (const key of path.split('.')) {
        const tail = objects.at(-1)
        if (tail instanceof Object && key in tail) {
            keys.push(key)
            objects.push(tail[key])
        } else break
    }
    if (!(objects.at(-1) instanceof Object)) return
    for (const key of keys.reverse()) {
        const tail = objects.pop()
        if (Object.keys(tail).length === 0) {
            delete objects.at(-1)[key]
        } else break
    }
}

export function getSearchableModel(procedure_extra_fields_schema, extraFieldValues) {
    const searchableModel = _.cloneDeep(procedureModel)
    if (!!procedure_extra_fields_schema) {
        searchableModel.extra_fields = convertBackendSchemaToFrontendSchema(
            procedure_extra_fields_schema
        )
        setModelAutocompleteValues(
            { extra_fields: searchableModel.extra_fields },
            _.cloneDeep(extraFieldValues)
        )
    }
    return searchableModel
}

export function getFiltersParam(filters, activeStructureTypes = []) {
    const rules = filters?.rules || {}
    const typeRules = rules?.type || []
    const finalTypeRules = []
    const finalActiveStructureTypes = [...activeStructureTypes]
    typeRules.forEach(({ operatore, valore }) => {
        if (!structureTypes.includes(valore)) {
            return
        }
        if (['eq', 'equals', 'in'].includes(operatore)) {
            finalActiveStructureTypes.push(valore)
        } else if (['neq', 'nin'].includes(operatore)) {
            finalActiveStructureTypes.push(...structureTypes.filter((type) => type !== valore))
        } else {
            finalTypeRules.push({ operatore, valore })
        }
    })
    if (
        finalActiveStructureTypes.length &&
        !structureTypes.reduce((acc, type) => acc && finalActiveStructureTypes.includes(type), true)
    ) {
        ;[...new Set(finalActiveStructureTypes)].forEach((type) =>
            finalTypeRules.push({ operatore: 'eq', valore: type })
        )
    }
    return (
        'filters=' +
        JSON.stringify([...new Set(formatFiltersForBe({ ...rules, type: finalTypeRules }))].sort())
    )
}

// export function getNormalizedFilters(filters) {
//     const rules = {}
//     Object.keys(getRuleCountByPath(filters) || {})
//         .sort()
//         .forEach((path) => {
//             _.set(rules, path, [])
//             _.get(filters?.rules, path, [])
//             .filter(rule => (typeof rule?.operatore === 'string') && (rule?.valore !== undefined))
//             .sort((r, s) => (
//                 r.operatore.localeCompare(s.operatore)
//                 || JSON.stringify(r.valore).localeCompare(JSON.stringify(s.valore))
//             ))
//             .forEach((rule) => {
//                 const { operatore, valore } = rule
//                 _.get(rules, path).push({ chiave: undefined, operatore, valore })
//             })
//         })
//     return { rules }
// }

export const deepFreeze = (obj) =>
    Object.freeze(
        Array.isArray(obj)
            ? obj.map(deepFreeze)
            : obj instanceof Object
            ? Object.fromEntries(
                  Object.entries(obj).map(([key, value]) => [key, deepFreeze(value)])
              )
            : obj
    )
