import useProcedureId from '../useProcedureId'
import { useQuery } from 'react-query'
import axios from 'axios'
import { proceduresQueries } from "../../networking";

export function useQueryProcedureAnalyses() {
    const { procedureId } = useProcedureId()
    const params = `procedure_id=${procedureId}`
    return useQuery(
        ['getProcedureAnalyses', procedureId],
        async () => (await axios.get(`/analyses?${params}`)).data,
        {
            enabled: !!procedureId,
        }
    )
}

export function useQueryProcedureAnalysisTemplates() {
    const {procedureId} = useProcedureId()
    return useQuery(
        [proceduresQueries.getProceduresAnalysisCatalog.name, procedureId],
        () => proceduresQueries.getProceduresAnalysisCatalog.fn(procedureId),
        {enabled: !!procedureId}
    )
}