import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Card, CardHeader, Grid, IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    FileCopy as FileCopyIcon,
    MailOutline as MailOutlineIcon,
    StarOutline as StarOutlineIcon,
    VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons'

import AlertDialog from '../Dialogs/AlertDialog'
import { backend_url } from '../../constants'
import { useSnackBarStore } from '../../stateManagement'
import { getInitials } from '../../utils'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import { companiesQueries } from '../../networking'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import useCompanyId from '../../customHooks/useCompanyId'

const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    avatar: {
        background: theme.palette.background.paperLight,
        color: theme.palette.text.secondary,
    },
    role: {
        fontSize: '0.7rem',
        color: theme.palette.text.secondary,
    },
    action: {
        marginLeft: 8,
    },
    hide: {
        display: 'none',
    },
    cardContainer: {
        position: 'relative',
    },
    secondaryIcon: {
        position: 'absolute',
        top: 5,
        left: 0,
        height: '15px',
        color: theme.palette.secondary.main,
    },
    copyIcon: {
        height: '10px',
        verticalAlign: 'baseline',
        marginLeft: '-5px',
    },
    mail: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
}))

export const UserCard = ({
    user,
    isAdmin = false,
    isMember,
    isReader = false,
    invitationId = null,
    uniqueAdmin = false,
    isSuperAdmin = false,
    isCompanyAdmin = false,
    setSelectedUser,
    selectedUser,
    handleDeleteInvitation,
    refetchCompany,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [isDeleteMemberDialogOpen, setIsDeleteMemberDialogOpen] = React.useState(false)
    const showSnackBar = useSnackBarStore((state) => state.show)
    const { companyId } = useCompanyId()

    const copyToClipBoard = (dataToCopy) => {
        navigator.clipboard.writeText(dataToCopy).then(
            () => {
                showSnackBar({
                    message: t('copied_email'),
                    severity: 'success',
                })
            },
            () => {
                showSnackBar({
                    message: t('error_clipboard'),
                    severity: 'error',
                })
            }
        )
    }
    const [anchorElement, setAnchorElement] = React.useState(null)
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)

    function openMenu(e, user) {
        e.stopPropagation()
        setAnchorElement(e.target)
        setIsMenuOpen(true)
        setSelectedUser(user)
    }

    function closeMenu(e) {
        e?.stopPropagation()
        setAnchorElement(null)
        setIsMenuOpen(false)
    }

    const handleRoles = useMutation(companiesQueries.handleRoles.fn, {
        onSuccess: () => {
            refetchCompany()
            console.log('success')
        },
    })

    const handleSetNewRole = (userId, action) => {
        handleRoles.mutate({ companyId, userId, action })
    }

    return (
        <Grid item className={classes.cardContainer}>
            <Card>
                <CardHeader
                    classes={{
                        root: classes.card,
                        action: classes.action,
                    }}
                    avatar={
                        <>
                            {isAdmin && <StarOutlineIcon className={classes.secondaryIcon} />}
                            {isReader && (
                                <VisibilityOutlinedIcon className={classes.secondaryIcon} />
                            )}
                            {invitationId && <MailOutlineIcon className={classes.secondaryIcon} />}
                            <Avatar
                                src={
                                    user?.image?.depot_url
                                        ? String(new URL(user?.image?.depot_url, backend_url))
                                        : null
                                }
                                alt={`${user.first_name} ${user.last_name}`}
                                className={classes.avatar}
                            >
                                {getInitials(user.first_name, user.last_name)}
                            </Avatar>
                        </>
                    }
                    title={`${user.first_name} ${user.last_name}`}
                    subheader={
                        <>
                            <div
                                className={classes.mail}
                                onClick={() => {
                                    copyToClipBoard(user.email)
                                }}
                            >
                                {user.email} <FileCopyIcon className={classes.copyIcon} />
                            </div>
                        </>
                    }
                    subheaderTypographyProps={{
                        variant: 'caption',
                        className: classes.role,
                    }}
                    action={
                        (isSuperAdmin || isCompanyAdmin) &&
                        !uniqueAdmin && (
                            <>
                                {!invitationId ? (
                                    <>
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            onClick={(e) => {
                                                openMenu(e, user)
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            open={isMenuOpen}
                                            onClose={closeMenu}
                                            anchorEl={anchorElement}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            {!isAdmin && (
                                                <MenuItem
                                                    className={classes.option}
                                                    onClick={() =>
                                                        handleSetNewRole(user.id, 'mark_admin')
                                                    }
                                                >
                                                    {t('Rendi Amministratore')}
                                                </MenuItem>
                                            )}
                                            {!isMember && (
                                                <MenuItem
                                                    className={classes.option}
                                                    onClick={() =>
                                                        handleSetNewRole(user.id, 'mark_member')
                                                    }
                                                >
                                                    {t('Rendi Membro')}
                                                </MenuItem>
                                            )}
                                            {(isAdmin || isMember) && (
                                                <MenuItem
                                                    className={classes.option}
                                                    onClick={() =>
                                                        handleSetNewRole(user.id, 'mark_reader')
                                                    }
                                                >
                                                    {t('Rendi utente in sola lettura')}
                                                </MenuItem>
                                            )}
                                            {!invitationId && (
                                                <MenuItem
                                                    className={classes.option}
                                                    onClick={() =>
                                                        setIsDeleteMemberDialogOpen(true)
                                                    }
                                                >
                                                    {t('Elimina')}
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    </>
                                ) : (
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        onClick={() => {
                                            setIsDeleteMemberDialogOpen(true)
                                        }}
                                        aria-label="delete"
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )}
                                <AlertDialog
                                    open={isDeleteMemberDialogOpen}
                                    setIsOpen={setIsDeleteMemberDialogOpen}
                                    title={t('user_delete_conferma_titolo')}
                                    primaryButton={'Elimina'}
                                    onSubmitCb={() =>
                                        invitationId
                                            ? handleDeleteInvitation(selectedUser, invitationId)
                                            : handleSetNewRole(user.id, 'delete')
                                    }
                                />
                            </>
                        )
                    }
                />
            </Card>
        </Grid>
    )
}
