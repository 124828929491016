import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Checkbox,
    Tooltip,
    IconButton,
    InputAdornment,
} from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    option: {
        minHeight: 36,
        color: theme.palette.text.secondary,
        display: 'inherit',
        flex: 1,
        whiteSpace: 'unset',
        wordBreak: 'break-word',
    },
}))
const emptyArray = Object.freeze([])
export default function AppSelect({
    multiple,
    value: propValue,
    defaultValue,
    fieldLabel,
    optionsArray = emptyArray,
    onChange,
    watchConfig,
    translate = true,
    style,
    required,
    disableAll,
    info,
    onClickInfo,
    ...props
}) {
    const classes = useStyles()
    const valueToUse = propValue || defaultValue
    const { t } = useTranslation()

    // --- watchConfig setup -----------------------------------
    const [additionalProps, setAdditionalProps] = React.useState({})
    const [options, setOptions] = React.useState(optionsArray)

    const methods = useFormContext()
    let watchedValue
    if (methods && methods.watch) {
        watchedValue = methods.watch(watchConfig?.name || null)
    }
    const casesToWatch = watchConfig?.cases || undefined
    React.useEffect(() => {
        if (!casesToWatch) {
            setOptions(optionsArray)
        } else {
            for (const [triggerValue, caseSettings] of Object.entries(casesToWatch)) {
                if (watchedValue === triggerValue) {
                    if (caseSettings.options) {
                        setOptions(caseSettings.options)
                        break
                    } else {
                        setAdditionalProps(caseSettings)
                        break
                    }
                }
            }
        }
    }, [casesToWatch, watchedValue, optionsArray])
    // ------------------------------------------------
    return (
        <>
            <FormControl
                variant="outlined"
                {...props}
                style={{ width: '100%', ...style }}
                disabled={disableAll}
            >
                <InputLabel
                    component={({ children, ...props }) => (
                        <label
                            {...props}
                            dangerouslySetInnerHTML={{
                                __html: t(fieldLabel) + (required ? ' *' : ''),
                            }}
                        />
                    )}
                />
                <Select
                    {...props}
                    {...additionalProps}
                    onChange={onChange}
                    label={fieldLabel || undefined}
                    defaultValue={multiple ? [] : defaultValue ? defaultValue : ''}
                    multiple={multiple}
                    value={valueToUse}
                    style={{ overflow: 'hidden' }}
                    required={required}
                    startAdornment={
                        info && (
                            <InputAdornment position="start">
                                <Tooltip placement="top-start" title={info}>
                                    <IconButton size="small" onClick={onClickInfo}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={option.value + index}
                            className={classes.option}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            <label style={{ display: 'unset' }}>
                                {multiple && (
                                    <Checkbox checked={valueToUse?.includes(option.value)} />
                                )}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: translate
                                            ? t(option.label || option.value)
                                            : option.label || option.value,
                                    }}
                                />
                            </label>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}
