import React from 'react'
import _ from 'lodash'
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Input,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import {
    MoreVert as MoreVertIcon,
    NavigateNext as NavigateNextIcon,
    Save as SaveIcon,
} from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { Link, useHistory, useParams } from 'react-router-dom'
import AppAccordion from '../AppAccordion'
import AppForm from '../AppForm'
import { CompanyListDialog } from '../Companies/CompanyListDialog'
import AlertDialog from '../Dialogs/AlertDialog'
import DynamicFormFields from '../FormItems/DynamicFormFields'
import {
    informazioni_organizzative_field,
     procedure_update_fields_by_type
} from "../../FormConfigs/procedure_config";
import { autofillAddressRelatedFields } from '../../utils'
import FormFillProgressIndicator from '../FormItems/FormFillProgressIndicator'
import Bridge from '../../assets/bridge.png'
import Building from '../../assets/building.png'
import {
    useCompanyStore,
    usePlaceStore,
    useUserProfileStore,
} from '../../stateManagement'

import LoadingSpinner from '../LoadingSpinner'
import Section from '../Section'
import {
    companiesQueries,
    proceduresQueries,
    queryClient,
} from '../../networking'
import useDisableForm from '../../customHooks/useDisableForm'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import { useQueryProcedure } from "../../customHooks/queryHooks/procedureQueries";
import { useDuplicateProcedureMutation, useProcedureMutation, useProcedureNameMutation } from "../../customHooks/mutationHooks/procedureMutations";
import useProcedureId from "../../customHooks/useProcedureId";
import useSearchableModel from "../../customHooks/useSearchableModel";
import windmillIcon from '../../assets/customIcons/windmillIcon.svg'

const useStyles = makeStyles((theme) => ({
    submit: {
        marginTop: theme.spacing(2),
    },
    typeIcon: {
        height: 40,
        width: 40,
        position: 'absolute',
        top: 15,
        right: -11,
        filter: 'brightness(0.7)',
        transform: 'scale(1.5)',
    },
    currentBreadcrumb: {
        color: theme.palette.text.primary,
    },
    secondaryText: {
        color: theme.palette.text.secondary,
    },
    disabledBreadcrumb: {
        cursor: 'not-allowed',
    },
    editIconButton: {
        marginLeft: theme.spacing(1.5),
        marginBottom: '15px',
    },
    checkIconButton: {
        marginLeft: theme.spacing(1.5),
    },
    form: {
        marginTop: '16px',
    },
}))


const procedureTypeImg = {
    bridge: Bridge,
    building: Building,
    windmill: windmillIcon
}
const emptyObject = Object.freeze({})

export default function OverviewSection() {
    const classes = useStyles()
    const { t } = useTranslation()
    const [isUserAllowed, setIsUserAllowed] = React.useState(false)
    const [isEditable, setIsEditable] = React.useState(false)
    
    const { procedureId } = useProcedureId()
    const {data: procedure} = useQueryProcedure()
    const {mutate: updateProcedure, isLoading} = useProcedureMutation()
    const procedureEditFields = procedure?.type ? procedure_update_fields_by_type[procedure.type] : emptyObject
    
    
    const place = usePlaceStore((state) => state.place)
    const [anchorElement, setAnchorElement] = React.useState(null)
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: { extra_fields: procedure.extra_fields },
    })
    const { isDirty, isValid } = methods.formState

    const history = useHistory()
    const [isSelectCompanyDialogOpen, setIsSelectCompanyDialogOpen] = React.useState(false)
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false)
    const [isLoadingDialogOpen, setIsLoadingDialogOpen] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const isAdmin = useCompanyStore((state) => state.writeCompanies).find(
        (company) => company?.id === selectedCompany?.id
    )
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const [procedureName, setProcedureName] = React.useState(procedure.name)

    const formatAttachmentsForFE = (files) => {
        return files && files.map((file) => ({ allegato: file })) || undefined
    }

    const companies = useCompanyStore((state) => state.companies)
    
    const duplicateProcedure = useDuplicateProcedureMutation()
    

    const {searchableModel} = useSearchableModel({companyId: procedure?.owner?.id})

    React.useEffect(() => {
        methods.reset({
            ...procedure,
            cadastral_maps: formatAttachmentsForFE(procedure?.cadastral_maps),
            files: formatAttachmentsForFE(procedure?.files),
            ...(procedure?.type === 'windmill' && {
                type_specific_fields: {
                    ...procedure?.type_specific_fields,
                    documentazione: formatAttachmentsForFE(procedure?.type_specific_fields?.documentazione)
                }
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedure])

    React.useEffect(() => {
        autofillAddressRelatedFields(procedure?.type, place, methods.setValue)
    }, [place, procedure?.type, methods.setValue])

    function sendData(data) {
        
        if (data?.cadastral_maps?.length && data.cadastral_maps.at(0)?.allegato) {
            data.cadastral_maps = data.cadastral_maps.map((attachment) =>
                _.omit(attachment.allegato, 'company_id')
            )
        }
        
        if (data?.files?.length && data.files.at(0)?.allegato) {
            data.files = data.files.map((file) => _.omit(file.allegato, 'company_id'))
        }
        
        if (data?.type_specific_fields?.documentazione?.length) {
            data.type_specific_fields.documentazione = data.type_specific_fields.documentazione.map((file) => _.omit(file.allegato, 'company_id'))
        }
        
        delete data.hidden_company_id
        updateProcedure(data)
    }

    function openMenu(e) {
        e.stopPropagation()
        setAnchorElement(e.target)
    }

    function closeMenu(e) {
        e.stopPropagation()
        setAnchorElement(null)
    }

    async function handleDuplicateProcedure(companyId) {
        setIsConfirmationDialogOpen(false)
        setIsSelectCompanyDialogOpen(false)
        setIsLoadingDialogOpen(true)
        await duplicateProcedure.mutateAsync(companyId)
        setIsLoadingDialogOpen(false)
    }

    const onCompanyClick = (company) => {
        setIsConfirmationDialogOpen(true)
        setSelectedCompany(company)
    }

    //try to make the call, if it is successful it means the user can access the company page (render link), if not render span in breadcrumb
    useQuery(
        [companiesQueries.headCompany.name, procedure?.owner?.id],
        () => companiesQueries.headCompany.fn(procedure?.owner?.id),
        {
            enabled: !!procedure,
            retry: 0,
            onSuccess: () => {
                setIsUserAllowed(true)
            },
            onError: () => {
                setIsUserAllowed(false)
            },
        }
    )

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    //edit procedureName
    const enableProcedureNameEdit = () => {
        setIsEditable(true)
        setProcedureName(procedure.name)
    }
    const handleProcedureNameChange = (e) => {
        setProcedureName(e.target.value)
    }
    const {mutateAsync: mutateProcedureName} = useProcedureNameMutation()
    
    const handleProcedureNameSubmit = async () => {
        if (procedure?.name === procedureName) {
            setIsEditable(false)
            return
        }
        await mutateProcedureName({ procedureName: procedureName }).then(() => setIsEditable(false))
    }

    return (
        <>
            <div
                style={{
                    background: `url('${procedureTypeImg[procedure?.type]}') no-repeat`,
                }}
                className={classes.typeIcon}
            />
            <Grid item xs={12}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {isUserAllowed ? (
                        <Link to={`/companies/${procedure?.owner?.id}`}>{procedure?.owner?.name}</Link>
                    ) : (
                        <Typography className={classes.disabledBreadcrumb}>
                            {procedure?.owner?.name}
                        </Typography>
                    )}

                    <Typography className={classes.currentBreadcrumb}>{procedure.name}</Typography>
                </Breadcrumbs>
            </Grid>
            {alert && (
                <Grid item>
                    <Alert severity="info">{t('missing_write_permission')}</Alert>
                </Grid>
            )}
            <Section xs={12} direction="column" innerSpacing={3}>
                <div>
                    <IconButton onClick={openMenu}>
                        <MoreVertIcon color="secondary" />
                    </IconButton>
                    <Menu
                        open={Boolean(anchorElement)}
                        onClose={closeMenu}
                        anchorEl={anchorElement}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <MenuItem
                            className={classes.option}
                            onClick={() => history.push(`/procedures/${procedureId}/access`)}
                        >
                            {t('permessi_accesso')}
                        </MenuItem>
                        {isAdmin && (
                            <MenuItem
                                className={classes.option}
                                onClick={(e) => {
                                    closeMenu(e)
                                    setIsSelectCompanyDialogOpen(true)
                                }}
                            >
                                {t('duplicate')}
                            </MenuItem>
                        )}
                    </Menu>
                </div>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        component="h6"
                        align="center"
                        className={classes.secondaryText}
                    >
                        {procedure?.owner?.name}
                    </Typography>
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item>
                            {isEditable ? (
                                <Typography>
                                    <Input
                                        id="procedure-name"
                                        onChange={handleProcedureNameChange}
                                        inputProps={{
                                            style: {
                                                fontSize: '2.0243rem',
                                                textAlign: 'center',
                                                padding: '0',
                                            },
                                        }}
                                        defaultValue={procedureName}
                                        autoFocus
                                    />
                                </Typography>
                            ) : (
                                <Typography variant="h4" component="h1" align="center">
                                    {procedureName}
                                </Typography>
                            )}
                        </Grid>
                        {isSuperAdmin && (
                            <Grid item>
                                {isEditable ? (
                                    <IconButton
                                        className={classes.checkIconButton}
                                        color="secondary"
                                        size="small"
                                        onClick={handleProcedureNameSubmit}
                                    >
                                        <CheckIcon fontSize="small" />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        className={classes.editIconButton}
                                        color="secondary"
                                        size="small"
                                        onClick={enableProcedureNameEdit}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    {procedure.internal_protocol_identifier && (
                        <Typography align="center" className={classes.secondaryText}>
                            {procedure.internal_protocol_identifier}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.form}>
                    <AppForm onSubmit={sendData} methods={methods}>
                        <DynamicFormFields
                            fieldsKey={``}
                            formFields={procedureEditFields}
                            disableAll={isNotAllowedToWrite}
                        />

                        {!_.isEmpty(searchableModel?.extra_fields) && (
                            <AppAccordion
                                style={{
                                    marginTop: 20,
                                    // marginBottom: 30
                                }}
                                accordionLabel={'company_requested_procedure_fields'}
                                renderHeaderContent={() => (
                                    <FormFillProgressIndicator
                                        currentPath={'extra_fields'}
                                        control={methods.control}
                                        formConfig={searchableModel?.extra_fields}
                                    />
                                )}
                            >
                                <DynamicFormFields
                                    fieldsKey={'extra_fields'}
                                    formFields={searchableModel?.extra_fields}
                                />
                            </AppAccordion>
                        )}
                        <AppAccordion
                            style={{
                                // marginTop: 20,
                                marginBottom: 30,
                            }}
                            accordionLabel={'informazioni_organizzative'}
                        >
                            <DynamicFormFields
                                fieldsKey={'informazioni_organizzative'}
                                formFields={informazioni_organizzative_field}
                            />
                        </AppAccordion>
                        {isNotAllowedToWrite === false && (
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                    disabled={!isDirty || !isValid || isLoading}
                                    startIcon={
                                        isLoading ? (
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        ) : (
                                            <SaveIcon />
                                        )
                                    }
                                >
                                    {t('salva')}
                                </Button>
                            </Grid>
                        )}
                    </AppForm>
                </Grid>
                <CompanyListDialog
                    companies={companies}
                    isOpen={isSelectCompanyDialogOpen}
                    setIsOpen={setIsSelectCompanyDialogOpen}
                    onCompanyClick={onCompanyClick}
                    confirmButtonText={t('duplicate_procedure_company_selection_source_company')}
                />
                <AlertDialog
                    open={isConfirmationDialogOpen}
                    setIsOpen={setIsConfirmationDialogOpen}
                    title={t('duplicate_procedure_alert_title', {
                        companyName: selectedCompany.name,
                    })}
                    primaryButton="duplicate"
                    onSubmitCb={async () => {
                        await handleDuplicateProcedure(selectedCompany.id)
                    }}
                />
                <Dialog
                    open={isLoadingDialogOpen}
                    aria-labelledby="loading-dialog-title"
                    scroll="paper"
                    onClose={() => {
                        setIsLoadingDialogOpen(false)
                    }}
                >
                    <DialogContent className={classes.loadingDialog}>
                        <DialogTitle id="loading-dialog-title">
                            {t('operation_in_progress')}
                        </DialogTitle>
                        <LoadingSpinner />
                    </DialogContent>
                </Dialog>
            </Section>
        </>
    )
}
