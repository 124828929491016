import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colorsMapping } from '../customTheme'
import AppTooltip from './AppTooltip'

const useStyles = makeStyles((theme) => ({
    trafficLight: {
        display: 'inline-block',
        height: 26,
        width: 26,
        lineHeight: '26px',
        fontWeight: 600,
        borderRadius: '50%',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    trafficLightLabel: {
        fontSize: '0.70rem',
        fontWeight: 600,
        textShadow: '1px 1px 3px rgb(0 0 0 / 30%), -1px -1px 3px rgb(0 0 0 / 30%)',
        color: theme.palette.common.white,
    },
}))

export default function TrafficLight({ value = null, label = '', tooltipTitle = '' }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const classeToNumber = {
        null: 0,
        BASSA: 1,
        MEDIOBASSA: 2,
        MEDIA: 3,
        MEDIOALTA: 4,
        ALTA: 5,
        'N/A': 0,
    }

    const isNA = value === 'N/A'

    return (
        <AppTooltip
            variant="contrast"
            title={
                <Typography variant="body2">
                    {isNA ? t('non_applicabile') : t(tooltipTitle)}
                </Typography>
            }
        >
            <span
                className={classes.trafficLight}
                style={{
                    background: colorsMapping[classeToNumber[value]],
                }}
            >
                <Typography variant="caption" className={classes.trafficLightLabel}>
                    {isNA ? 'N/A' : t(label)}
                </Typography>
            </span>
        </AppTooltip>
    )
}
