import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import _ from 'lodash' // added only to try and find why fieldsarray wont set isDirty
import { queryClient, levelsQueries } from '../../../networking'
import { Paper, Fab, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import { useTranslation } from 'react-i18next'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import { mit_la_0_model, sections_to_render_censimento } from '../../../FormConfigs/mit_la_0_config'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import AppAccordion from '../../../components/AppAccordion'
import FormFillProgressIndicator from '../../../components/FormItems/FormFillProgressIndicator'
import AppForm from '../../../components/AppForm'
import { usePendingOperationsStore, useUserProfileStore } from '../../../stateManagement'
import { capitalizeFirstLetter, isSafeToUnmount } from '../../../utils'
import MethodologyPage from '../../MethodologyPage'
import { RequiredLA2Info } from '../../../components/FormItems/RequiredLA2'
import { proceduresQueries } from '../../../networking'
import useDisableForm from '../../../customHooks/useDisableForm'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    hide: {
        display: 'none',
    },
}))

export default function LGP_LA0_censimento() {
    const classes = useStyles()
    const { t } = useTranslation()
    const isTranslationManager = useUserProfileStore((state) => Boolean(state.isTranslationManager))

    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])

    const { data: procedure, isFetched: isProcedureFetched } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )

    useQuery(
        [levelsQueries.getMitLevel0.name, procedureId],
        () => levelsQueries.getMitLevel0.fn(procedureId),
        {
            enabled: isProcedureFetched,
            onSuccess: (data) =>
                methods.reset({ ..._.cloneDeep(data), hidden_company_id: procedure.owner.id }),
        }
    )

    const updateLevelMutation = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'mit_guidelines',
                'level0',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getMitLevel0.name),
        }
    )

    React.useEffect(() => {
        clearPendingOps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    function sendData(data) {
        if (isDirty && isValid) {
            runPendingOps().then(() => {
                delete data.hidden_company_id
                updateLevelMutation.mutate(data)
            })
        }
    }

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    return (
        <MethodologyPage methodology="lgp" alert={alert}>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {capitalizeFirstLetter(t('censimento'))}
                </Typography>
                <RequiredLA2Info />
                <AppForm onSubmit={sendData} methods={methods}>
                    {sections_to_render_censimento.map((section, index) => {
                        return (
                            <AppAccordion
                                TransitionProps={{
                                    unmountOnExit: isSafeToUnmount(mit_la_0_model[section]),
                                }}
                                key={`${section}${index}`}
                                accordionLabel={section}
                                renderHeaderContent={() => {
                                    return (
                                        <FormFillProgressIndicator
                                            currentPath={section}
                                            control={methods.control}
                                            formConfig={mit_la_0_model[section]}
                                        />
                                    )
                                }}
                            >
                                <DynamicFormFields
                                    disableAll={isNotAllowedToWrite}
                                    fieldsKey={section}
                                    formFields={mit_la_0_model[section]}
                                    editableInfos={isTranslationManager}
                                    formId={`workspace_lgp_${section}`}
                                />
                            </AppAccordion>
                        )
                    })}
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={
                            !isDirty || !isValid
                            // isLoading ||
                            // updateLevelMutation.isLoading
                        }
                        className={`${classes.fab} ${isNotAllowedToWrite && classes.hide}`}
                    >
                        {updateLevelMutation.isLoading ? (
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                        ) : (
                            <SaveIcon />
                        )}
                        {t('salva')}
                    </Fab>
                </AppForm>
            </Paper>
        </MethodologyPage>
    )
}
