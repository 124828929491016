import React from 'react'
import { Card, CardHeader, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import AlertDialog from './Dialogs/AlertDialog'

const useStyles = makeStyles((theme) => ({
    group: {
        background: theme.palette.background.default,
        margin: theme.spacing(),
        marginBottom: theme.spacing(2),
    },
    header: {
        overflow: 'hidden',
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

export default function SplittableGroup({
    children,
    groupName,
    deleteGroup,
    groupId,
    canUserDeleteGroup,
    hideDeleteGroupButton = false,
    alertBody = '',
    style,
}) {
    const classes = useStyles()
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    const isUserAllowedToDeleteGroup = canUserDeleteGroup()

    return (
        <div style={style}>
            <Card variant="outlined" className={classes.group}>
                <CardHeader
                    title={groupName}
                    classes={{ content: classes.header, title: classes.title }}
                    action={
                        !hideDeleteGroupButton && (
                            <IconButton
                                aria-label="split group"
                                color="secondary"
                                onClick={() => setIsDialogOpen(true)}
                                disabled={!isUserAllowedToDeleteGroup}
                            >
                                <CallSplitIcon />
                            </IconButton>
                        )
                    }
                />
                {children}
            </Card>
            <AlertDialog
                open={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                title="gruppo_scioglimento_conferma_titolo"
                text={alertBody}
                primaryButton="conferma"
                onSubmitCb={() => {
                    setIsDialogOpen(false)
                    deleteGroup(groupId)
                }}
            />
        </div>
    )
}
