import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import SchedaElementiAccessori from '../../../components/LGP/LA1/SchedaElementiAccessori'
import NetworkErrorOverlay from '../../../components/NetworkErrorOverlay'
import useDisableForm from '../../../customHooks/useDisableForm'
import { levelsQueries, proceduresQueries, queryClient } from '../../../networking'
import MethodologyPage from '../../MethodologyPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
}))

export default function LGP_LA1_scheda_elemento_accessorio() {
    const classes = useStyles()
    const { procedureId } = useParams()
    const { t } = useTranslation()
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })
    const { isDirty, isValid } = methods.formState
    const formPath = `schede_ispezione.elementi_accessori`

    const { data: procedure, isFetched: isProcedureFetched } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId),
        {
            onSuccess: (data) => {
                methods.reset({ ...livello1, hidden_company_id: data.owner.id })
            },
        }
    )

    const {
        data: livello1,
        isLoading,
        error,
        refetch,
    } = useQuery(
        [levelsQueries.getMitLevel1.name, procedureId],
        () => levelsQueries.getMitLevel1.fn(procedureId),
        {
            enabled: isProcedureFetched,
            onSuccess: (data) => methods.reset({ ...data, hidden_company_id: procedure.owner.id }),
        }
    )

    const updateElementiAccessoriMutation = useMutation((data) => {
        return levelsQueries.updateLevel
            .fn(procedureId, 'mit_guidelines', 'level1', replaceEmptyStringsWithNullWithin(data))
            .then(() => {
                queryClient.invalidateQueries(levelsQueries.getMitLevel1.name)
            })
    })

    function sendData(data) {
        if (isDirty && isValid) {
            delete data.hidden_company_id
            updateElementiAccessoriMutation.mutate(data)
        }
    }

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    return (
        <MethodologyPage methodology="lgp" alert={alert}>
            {error ? (
                <NetworkErrorOverlay
                    actions={[{ onClick: refetch, label: t('retry') }]}
                    areActionsPending={isLoading}
                />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <SchedaElementiAccessori
                        methods={methods}
                        formPath={formPath}
                        formPathForDynamicFormFields={formPath}
                        isLevel1DataLoading={isLoading}
                        isMutationLoading={updateElementiAccessoriMutation.isLoading}
                        sendData={sendData}
                        isNotAllowedToWrite={isNotAllowedToWrite}
                    />
                </Paper>
            )}
        </MethodologyPage>
    )
}
