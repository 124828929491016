import React from 'react'
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon, Done as DoneIcon, GetApp as GetAppIcon } from '@material-ui/icons'
import axios from 'axios'
import FileSaver from 'file-saver'
import { useDropzone } from 'react-dropzone'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isThisAFieldsArrayContext } from './FieldsArray'
import AlertDialog from '../Dialogs/AlertDialog'
import PaperDropzone from '../Files/PaperDropzone'
import { backend_url } from '../../constants'
import { useWatchForAdditionalProps } from '../../FormConfigs/utils'
import { usePendingOperationsStore, useSnackBarStore } from '../../stateManagement'

const useStyles = makeStyles(() => ({
    hide: {
        display: 'none',
    },
    paperDropzone: {
        height: 160,
        width: 213,
        position: 'relative',
    },
}))

export default function AttachmentDropzone({
    handleChange,
    control,
    defaultValue,
    name,
    useDialog = true,
    filesToAccept,
    watchConfig,
    value,
    disableAll,
    ...props
}) {
    const { t } = useTranslation()
    const classes = useStyles()
    const setOperationsBeforeSubmit = usePendingOperationsStore((state) => state.setPendingOp)
    const showSnackBar = useSnackBarStore((state) => state.show)
    const additionalProps = useWatchForAdditionalProps(watchConfig)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false)
    const [localFile, setLocalFile] = React.useState(null)
    const isThisAFieldsArray = React.useContext(isThisAFieldsArrayContext)
    const hidden_company_id = useWatch({ control, name: 'hidden_company_id', defaultValue: false })
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#202d40',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
    }
    const focusedStyle = {
        // borderColor: '#ffbe45',
    }

    const acceptStyle = {
        // borderColor: '#ffbe45',
    }

    const rejectStyle = {
        // borderColor: 'red',
    }
    const onDrop = React.useCallback((files) => setLocalFile(files[0]), [setLocalFile])

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
        useDropzone({
            accept: filesToAccept,
            onDrop,
        })

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )
    const { clearErrors } = useFormContext()

    React.useEffect(() => {
        if ((value?.name || value?.path) && Object.keys(value).length) {
            let file
            if (value?.path) {
                file = new File([], value.path, { type: value.type })
            } else {
                file = new File([], value.name, { type: value.content_type })
            }
            if (value?.data || localFile?.name !== file.name) {
                setLocalFile({ file, data: value.data })
            }
        } else if (localFile != null) {
            setLocalFile(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    //for dialog dropzone
    function handleDropzoneDialogFilesChange() {
        if (localFile) {
            const fileObj = localFile

            let reader = new FileReader()
            reader.onload = function () {
                fileObj.data = reader.result
                const toSend = {
                    name: fileObj.name,
                    data: fileObj.data,
                    path: fileObj?.path,
                    type: fileObj?.type,
                    company_id: control.getValues('hidden_company_id') || hidden_company_id,
                }
                handleChange(toSend, true)
                clearErrors(name)
            }
            reader.readAsDataURL(fileObj)
        } else {
            setLocalFile(null)
        }
    }

    //for basic dropzone
    function handleFilesChange(files) {
        if (files.length > 0) {
            const fileObj = files[0]

            let reader = new FileReader()
            reader.onload = function () {
                fileObj.data = reader.result
                const toSend = {
                    data: fileObj.data,
                    path: fileObj?.path,
                    type: fileObj?.type,
                    company_id: control.getValues('hidden_company_id') || hidden_company_id,
                }
                handleChange(toSend, true)
                clearErrors(name)
            }
            reader.readAsDataURL(fileObj)
        } else {
            setLocalFile(null)
        }
    }

    function handleDeleteRequest() {
        const value = control.getValues(name)
        if (value?.depot_id) {
            const deleteInfo = { depot_id: value.depot_id }
            if (value?.thumb_depot_id) {
                deleteInfo.thumb_depot_id = value.thumb_depot_id
            }
            setOperationsBeforeSubmit(`delete-file-${value.depot_id}`, () =>
                axios.delete('/files', { data: deleteInfo }).then(() => {})
            )
        }
        setLocalFile(null)
        handleChange(null, true)
    }

    const previewUri =
        localFile?.data ||
        (value?.thumb_depot_url ? String(new URL(value?.thumb_depot_url, backend_url)) : null) ||
        null

    const fullUri =
        localFile?.data ||
        (value?.depot_url ? String(new URL(value?.depot_url, backend_url)) : null) ||
        null

    function downloadFile() {
        try {
            FileSaver.saveAs(fullUri, localFile?.file.name)
        } catch (e) {
            showSnackBar({ message: t('download_failed'), severity: 'error' })
            console.error(e)
        }
    }

    function closeDialog() {
        setLocalFile(null)
        setIsDialogOpen(false)
    }

    return useDialog ? (
        <>
            {value?.name ? (
                <>
                    <Grid item>
                        <Chip
                            icon={<GetAppIcon />}
                            label={value.name}
                            clickable
                            color="secondary"
                            onClick={downloadFile}
                            onDelete={
                                isThisAFieldsArray || disableAll
                                    ? undefined
                                    : () => setIsConfirmationDialogOpen(true)
                            }
                        />
                    </Grid>
                    <AlertDialog
                        open={isConfirmationDialogOpen}
                        setIsOpen={setIsConfirmationDialogOpen}
                        title="remove_attachment"
                        primaryButton="remove"
                        onSubmitCb={handleDeleteRequest}
                    />
                </>
            ) : (
                <>
                    <Grid item>
                        <Button
                            {...additionalProps}
                            variant="contained"
                            color="secondary"
                            startIcon={localFile ? <DoneIcon /> : <AddIcon />}
                            onClick={() => setIsDialogOpen(true)}
                            disabled={!localFile && disableAll}
                        >
                            {t('allegato')}
                        </Button>
                    </Grid>
                    <Dialog
                        open={isDialogOpen}
                        onClose={closeDialog}
                        maxWidth={'md'}
                        aria-labelledby="max-width-dialog-title"
                        fullWidth
                    >
                        <DialogTitle id="max-width-dialog-title">
                            {t('attachment_dialog_title')}
                        </DialogTitle>
                        <DialogContent>
                            <Grid item>
                                <div>
                                    <div {...getRootProps({ style })}>
                                        <input {...getInputProps()} />
                                        <p>{t('empty_dropzone_text')}</p>
                                    </div>
                                </div>
                            </Grid>
                            {localFile && (
                                <Grid item style={{ marginTop: '20px' }}>
                                    <Chip
                                        icon={<DoneIcon />}
                                        label={acceptedFiles[0]?.name}
                                        clickable
                                        color="secondary"
                                        onClick={() => {
                                            console.log('download')
                                        }}
                                        onDelete={() => {
                                            setLocalFile(null)
                                        }}
                                    />
                                </Grid>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={
                                    localFile
                                        ? handleDropzoneDialogFilesChange
                                        : () => {
                                              setIsDialogOpen(false)
                                          }
                                }
                                color="secondary"
                                variant="contained"
                            >
                                {localFile ? t('accept_and_close') : t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    ) : (
        <div className={disableAll && previewUri === null ? classes.hide : classes.paperDropzone}>
            <PaperDropzone
                width={213}
                height={160}
                onDrop={handleFilesChange}
                acceptedFiles={acceptedFiles}
                onDelete={handleDeleteRequest}
                previewUri={previewUri}
                fullSizeUri={fullUri}
                disable={disableAll}
            />
        </div>
    )
}
