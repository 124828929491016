import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Environment, OrbitControls, PerspectiveCamera, useGLTF } from "@react-three/drei";
import { usePlinto } from "../../3d-models/plinto";
import React from 'react'
import { useTheme } from "@material-ui/core/styles";

export default function PlintoCanvas() {
    const theme = useTheme()
    return (
        <Canvas
            style={{
                height: 400,
                borderRadius: theme.shape.borderRadius
            }}
        >
            <Suspense fallback={null}>
                <PerspectiveCamera makeDefault position={[0, 8, 20]} />
                <Environment preset={'studio'} />
                <ambientLight intensity={7} />
                <Model />
                <OrbitControls makeDefault target={[0, -3, 0]} />
            </Suspense>
        </Canvas>
    )
}

function Model() {
    const { models: plintoModels } = usePlinto()
    return (
        <>
            <mesh
                geometry={plintoModels.Plinto_Wire.mesh.geometry}
                material={plintoModels.Plinto.materiale}
                // material-color={'green'}
            />
            {new Array(12).fill(null).map((_, i, arr) => (
                <group rotation={[0, (i * 2 * Math.PI) / arr.length, 0]} key={'index' + i}>
                    <mesh
                        geometry={plintoModels.Fibra.mesh.geometry}
                        material={plintoModels.Fibra.materiale}
                        // material-color={'blue'}
                    />
                    <mesh
                        geometry={plintoModels.Pilone.mesh.geometry}
                        position={[7.5, 0, 0]}
                        material={plintoModels.Pilone.materiale}
                        // material-color={'orange'}
                    />
                </group>
            ))}
        </>
    
    )
}

useGLTF.preload(process.env.PUBLIC_URL + '/assets/Pilone.gltf')
