import React from 'react'
import Alert from '@material-ui/lab/Alert'

export default function DisplayValidationErrors({ validationErrors }) {
    return (
        <>
            {validationErrors && validationErrors.length > 0 && (
                <div>
                    {/*{JSON.stringify(analysisCatalogErrors)}*/}
                    {validationErrors.map((error) => {
                        for (var errKey in error) {
                            return (
                                <Alert severity="error">
                                    {errKey} : {error[errKey]}
                                </Alert>
                            )
                        }
                    })}
                </div>
            )}
        </>
    )
}
