import React, {useRef} from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart } from 'recharts';
import LoadingSpinner from '../../../components/LoadingSpinner'
import {Grid, IconButton, List, ListItem, ListItemText} from '@material-ui/core';
import {ArrowBackIos, ArrowForwardIos, FiberManualRecord as FiberManualRecordIcon} from '@material-ui/icons'
import ScrollableCarousel from "../../../components/ScrollableCarousel";

const cdaValueKeys = [
    "bassa",
    "medio_bassa",
    "media",
    "medio_alta",
    "alta",
]

const cdaValueColors = {
    bassa: '#078242',
    medio_bassa: '#98ca3b',
    media: '#e2cd20',
    medio_alta: '#ec8a23',
    alta: '#c91f2e',
    n_a: 'grey'
}

const cdaValueNames = {
    bassa: 'BASSA',
    medio_bassa: 'MEDIOBASSA',
    media: 'MEDIA',
    medio_alta: 'MEDIOALTA',
    alta: 'ALTA',
    n_a: "N/A"
}

const cdaFields = [
    {
        fieldName: 'CdA_valutazione',
        label: "CdA complessiva",
    },
    {
        fieldName: 'CdA_valutazione_strutturale_fondazionale',
        label: "CdA strutturale fondazionale",
    },
    {
        fieldName: 'CdA_valutazione_sismica',
        label: "CdA sismica",
    },
    {
        fieldName: 'CdA_valutazione_rischio_idraulico',
        label: "Rischio idraulico",
    },
    {
        fieldName: 'CdA_valutazione_rischio_frane',
        label: "Rischio frane",
    },
    {
        fieldName: 'CdA_prevalutazione',
        label: "CdA complessiva (prevalutazione)",
    },
    {
        fieldName: 'CdA_prevalutazione_strutturale_fondazionale',
        label: "CdA strutturale fondazionale (prevalutazione)",
    },
    {
        fieldName: 'CdA_prevalutazione_sismica',
        label: "CdA sismica (prevalutazione)",
    },
]

export default function LGPPieCharts({ methodologyAnalytics, isLoading }) {
    const { t } = useTranslation()
    const pieChartProps = React.useMemo(() => getPieChartProps(methodologyAnalytics), [methodologyAnalytics])
    const [currentPieIndex, _setCurrentPieIndex] = React.useState(0)
    const setCurrentPieIndex = React.useCallback((arg) => {
        _setCurrentPieIndex(current => {
            const newIndex = (typeof arg === 'function') ? arg(current) : arg
            return ((newIndex % pieChartProps.length) + pieChartProps.length) % pieChartProps.length
        })
    }, [pieChartProps.length])

    const renderCdaCarouselItem = React.useCallback(({key, ...pieChartProps}, index) => (
        <CdaCarouselItem
            key={key}
            {...pieChartProps}
            index={index}
            isCurrent={index === currentPieIndex}
            setCurrentPieIndex={setCurrentPieIndex}
            isLoading={isLoading}
        />
    ), [
        currentPieIndex,
        setCurrentPieIndex,
        isLoading
    ])

    // const isTablet = useMediaQuery('(max-width:1024px)')
    // const xsValue = isTablet ? 12 : 4

    return (
        <>
            <Grid item style={{width: '100%'}}>
                <div style={{
                    maxWidth: "100%",
                    height: 'max-content',
                    marginBottom: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                }}>
                    {/*<div style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    top: 0,*/}
                    {/*    left: -1,*/}
                    {/*    bottom: 0,*/}
                    {/*    right: 'calc(100% - 20px)',*/}
                    {/*    background: `linear-gradient(to right, rgba(33, 46, 64, 1), rgba(33, 46, 64, 0))`,*/}
                    {/*    // border: '1px dashed red',*/}
                    {/*    zIndex: 10*/}
                    {/*}}/>*/}
                    <ScrollableCarousel
                        currentIndex={currentPieIndex}
                        renderItem={renderCdaCarouselItem}
                        itemProps={pieChartProps}
                        scrollTarget={'visible'}
                        scrollMargin={{left: 40, right: 40}}
                    />
                    {/*<div style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    top: 0,*/}
                    {/*    right: -1,*/}
                    {/*    bottom: 0,*/}
                    {/*    left: 'calc(100% - 20px)',*/}
                    {/*    background: `linear-gradient(to left, rgba(33, 46, 64, 1), rgba(33, 46, 64, 0))`,*/}
                    {/*    // border: '1px dashed red',*/}
                    {/*    zIndex: 10*/}
                    {/*}}/>*/}
                </div>
            </Grid>
            <Grid item>
                <Grid container spacing={5}>
                    <Grid item style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <IconButton
                            onClick={() => setCurrentPieIndex(i => i - 1)}
                            disabled={currentPieIndex === 0}
                        >
                            <ArrowBackIos/>
                        </IconButton>
                    </Grid>
                    <Grid item style={{display: "flex", alignItems: "center", justifyContent: "center", width: 245, height: 245}}>
                        <CdaPieChart
                            pieData={pieChartProps[currentPieIndex]?.pieData}
                            pieRadius={{inner: 80, outer: 120}}
                            width={245}
                            height={245}
                        />
                    </Grid>
                    <Grid item style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <IconButton
                            onClick={() => setCurrentPieIndex(i => i + 1)}
                            disabled={currentPieIndex === pieChartProps.length - 1}
                        >
                            <ArrowForwardIos/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <CdaPieChartTitle title={t(pieChartProps[currentPieIndex]?.title)} color={"textPrimary"} variant={"h4"} width={"100%"}/>
            </Grid>
            <Grid item>
                <List>
                    <Grid
                        container
                        style={{ gap: 20 }}
                        justifyContent='center'
                    >
                        {((pieChartProps[currentPieIndex]?.pieData) || []).map(({name, value, color}) => (
                            <Grid item key={name}>
                                <ListItem>
                                    <FiberManualRecordIcon
                                        style={{
                                            color: color,
                                            marginRight: 10,
                                        }}
                                    />
                                    <ListItemText
                                        primary={t(name)}
                                        secondary={value || 0}
                                    />
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                </List>
            </Grid>
        </>
    );
}

function getPieData(data) {
    return cdaValueKeys.map(key => ({
        name: cdaValueNames[key],
        value: (data || {})[key],
        color: cdaValueColors[key]
    }));
}

function getPieChartProps(methodologyAnalytics) {
    return cdaFields.map(({fieldName, label}) => ({
        key: fieldName,
        title: label,
        pieData: getPieData(methodologyAnalytics[fieldName]).filter(({value}) => !!value)
    }));
}

function CdaPieChart({
     width = 200,
     height = 200,
     pieRadius = {inner: 40, outer: 60},
     pieData
 }) {
    const {t} = useTranslation()
    return (
        !pieData?.length ? <Typography>{t('no_data')}</Typography> : (
            <PieChart width={width} height={height}>
                <Pie
                    data={pieData}
                    dataKey="value"
                    // nameKey="name"
                    // cx="50%"
                    // cy="50%"
                    innerRadius={pieRadius.inner}
                    outerRadius={pieRadius.outer}
                    label={false}
                >
                    {pieData.map(entry => (
                        <Cell key={entry.name} fill={entry.color}/>
                    ))}
                </Pie>
            </PieChart>
        )
    );
}

function CdaPieChartTitle({title, color, variant = 'h6', width = 200, scale = 1}) {
    const { t } = useTranslation()
    return (
        <Typography
            align="center"
            variant={variant}
            style={{
                width: width,
                fontWeight: 'bold',
                transform: `scale(${scale})`,
            }}
            color={color}
        >
            {t(title)}
        </Typography>
    );
}

const CdaCarouselItem = React.memo(({
    title,
    pieData,
    index,
    isCurrent,
    setCurrentPieIndex,
    isLoading,
}) => {
    const {t} = useTranslation()
    return (
        <Box onClick={() => setCurrentPieIndex(index)}>
            <Grid container
                  direction='column'
                  style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: 'center'
                  }}
            >
                <Grid item
                      style={{
                          width: 150,
                          height: 150,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: isCurrent ? 'scale(1)' : 'scale(0.8)',
                          transition: 'transform .2s ease',
                      }}
                >
                    {isLoading ? <LoadingSpinner/> : (
                        <CdaPieChart
                            pieData={pieData}
                            width={150}
                            height={150}
                        />
                    )}
                </Grid>
                <Grid item>
                    <CdaPieChartTitle color={isCurrent ? "textPrimary" : "textSecondary"} scale={0.8} title={t(title)} width={180}/>
                </Grid>
            </Grid>
        </Box>
    )
})
