import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useCompanyId from "../customHooks/useCompanyId";
import { useCompanyStore } from "../stateManagement";
import React from "react";
import { methodologiesByStructureType } from "../constants";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export function CompanySelect() {
    const { t } = useTranslation();
    
    const { pathname } = useLocation();
    
    const { companyId, setCompanyId, methodology } = useCompanyId();
    
    const [companies, dashboardCompanies] = useCompanyStore((state) => [
        state.companies,
        state.dashboardCompanies
    ]);
    
    const getOptionDisabled = React.useMemo(() => {
        if (!methodology) return () => false;
        const structureTypes = Object.entries(methodologiesByStructureType)
        .filter(([,methodologies]) => methodologies.includes(methodology))
        .map(([structureType,]) => structureType )
        const enabledValues = new Set(
            dashboardCompanies
            .filter(company => structureTypes.some(structureType => structureType in (company.procedures || {})))
            .map((company) => company.id)
        );
        return (option) => !enabledValues.has(option.value);
    }, [methodology, dashboardCompanies]);
    
    const [options, getOptionByValue] = React.useMemo(() => {
        const options = [{ value: null, label: t("tutti_gli_studi") }].concat(
            (companies || [])
            // .sort((c, d) => c.name.localeCompare(d.name))
            // .sort((c, d) => d.owned_procedures.length - c.owned_procedures.length)
            .map((company) => ({ value: company.id, label: company.name }))
            .sort((a, b) => (
                getOptionDisabled(a) - getOptionDisabled(b) || a.label.localeCompare(b.label)
            ))
        );
        const optionsByValue = new Map(options.map((option) => [option.value, option]));
        return [options, (value) => optionsByValue.get(value)];
    }, [companies, t, getOptionDisabled]);

    const isHome = /^\/home/.test(pathname);
    const option = getOptionByValue(companyId) || getOptionByValue(null)
    return (
        <Autocomplete
            renderInput={(params) => <TextField {...params} variant="outlined" size={"small"} />}
            style={{
                width: (methodology || isHome) ? 250 : 0,
                transition: "width 0.5s ease",
                overflow: "hidden"
            }}
            disableClearable
            getOptionDisabled={getOptionDisabled}
            value={option}
            onChange={(event, option, eventType) => {
                eventType === "select-option" && setCompanyId(option.value || null);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
        />
    );
}