import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Grid, List, ListItem, Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import DynamicFormFields from './DynamicFormFields'
import { useWatchForAdditionalProps } from '../../FormConfigs/utils'
import AlertDialog from '../Dialogs/AlertDialog'
import { useTranslation } from 'react-i18next'

const DeleteButton = withStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
        '&:hover': {
            background: theme.palette.background.default,
        },
    },
}))(Button)

const useStyles = makeStyles((theme) => ({
    listItem: {
        backgroundColor: theme.palette.background.defaultAlpha40,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    deleteButton: {
        minWidth: 'unset',
        width: 45,
        height: 45,
        borderRadius: '50%',
        marginLeft: theme.spacing(2),
    },
    addButton: {
        display: 'flex',
        margin: '0 auto',
    },
    hide: {
        display: 'none',
    },
}))

export const isThisAFieldsArrayContext = React.createContext(false)

export default function FieldsArray({
    control,
    formNamePrefix,
    path,
    register,
    formFields,
    addNewDisabled = false,
    watchConfig,
    disableAll,
    editableInfos,
    formId,
    ...props
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [isAddDisabled, setIsAddDisabled] = React.useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(null)
    const { append, remove, fields } = useFieldArray({
        control,
        shouldUnregister: false,
        name: formNamePrefix,
        keyName: formNamePrefix,
    })
    const additionalProps = useWatchForAdditionalProps(watchConfig)

    function handleRemove(index) {
        remove(index)
    }
    const { watch, getValues, setError } = useFormContext()

    const handleDeleteDialog = (index) => {
        const val = getValues(`${formNamePrefix}[${index}]`)
        if (fields[index]?.depot_url || val?.data || val?.depot_url) {
            setSelectedIndex(index)
            setIsDeleteDialogOpen(true)
        } else {
            handleRemove(index)
        }
    }

    const handleDeleteAttachment = (index) => {
        handleRemove(index)
        setIsDeleteDialogOpen(false)
    }

    React.useEffect(() => {
        if (formFields.inputs._type === 'attachment') {
            const dynamicFields = watch(formNamePrefix)

            if (dynamicFields?.length && dynamicFields[dynamicFields.length - 1].name === null) {
                setIsAddDisabled(true)
            } else {
                setIsAddDisabled(false)
            }
        }
    }, [JSON.stringify(watch(formNamePrefix))])

    return (
        <isThisAFieldsArrayContext.Provider value={true}>
            <Grid item xs={12}>
                <List>
                    {fields.map((field, index) => (
                        // usiamo key=field[formNamePrefix] perché field.id non esiste e noi abbiamo il valore dell'id in field[formNamePrefix]
                        <ListItem key={field[formNamePrefix]} className={classes.listItem}>
                            <DynamicFormFields
                                {...props}
                                fieldsKey={`${formNamePrefix}[${index}]`}
                                formFields={formFields.inputs}
                                path={path}
                                control={control}
                                register={register}
                                disableAll={disableAll}
                                defaultValue={field}
                                editableInfos={editableInfos}
                                formId={formId}
                            />
                            <DeleteButton
                                variant="contained"
                                // onClick={() => handleRemove(index)}
                                onClick={() => handleDeleteDialog(index)}
                                aria-label="delete"
                                className={`${classes.deleteButton} ${disableAll && classes.hide}`}
                                disabled={disableAll}
                            >
                                <DeleteIcon color="secondary" fontSize="small" />
                            </DeleteButton>
                        </ListItem>
                    ))}
                </List>
                <Button
                    variant="contained"
                    onClick={() => {
                        const _type = formFields.inputs._type
                        let valueToAppend
                        let errorToSet = false
                        switch (_type) {
                            case 'fieldsArray':
                                valueToAppend = []
                                break
                            case 'attachment':
                                valueToAppend = {
                                    data: null,
                                    name: null,
                                    path: null,
                                    type: null,
                                }
                                errorToSet = { type: 'custom', message: 'campo non compilato' }
                                break
                            case 'numberUnit':
                                valueToAppend = {
                                    value: '',
                                    unit: null,
                                }
                                errorToSet = { type: 'custom', message: 'campo non compilato' }
                                break
                            case 'text':
                                valueToAppend = ''
                                break
                            default:
                                valueToAppend = {}
                                break
                        }
                        append(valueToAppend)
                        if (errorToSet) {
                            setError(`${formNamePrefix}[${fields.length}]`, errorToSet)
                        }
                    }}
                    aria-label="add"
                    color="secondary"
                    className={classes.addButton}
                    disabled={
                        addNewDisabled || additionalProps.disabled || isAddDisabled || disableAll
                    }
                >
                    <AddIcon color="primary" />
                </Button>
                <AlertDialog
                    open={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    title={'remove_attachment'}
                    text={'cancellation_not_effective_until_save'}
                    primaryButton={'elimina'}
                    onSubmitCb={() => {
                        handleDeleteAttachment(selectedIndex)
                    }}
                />
            </Grid>
        </isThisAFieldsArrayContext.Provider>
    )
}
