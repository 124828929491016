import React from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStoredFilters } from "../customHooks/storedFiltersContext";

const useStyles = makeStyles((theme) => {
    return {
        chip: {
            marginLeft: 5,
            marginRight: 5,
        },
        activeChip: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:focus': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        inactiveChip: {
            backgroundColor: theme.palette.background.disabledModulePill,
        },
    }
})


export const QuickFilterChip = ({ label, path, operatore, valore, ruleEditingOptions, onDelete }) => {
    const [
        isRuleSet,
        toggleRule,
        removeRule
    ] = useStoredFilters(state => [state.isRuleSet, state.toggleRule, state.removeRule])

    const isActive = isRuleSet(path, operatore, valore, ruleEditingOptions)
    const toggleFilter = () => toggleRule(path, operatore, valore, ruleEditingOptions)
    if (onDelete && (typeof onDelete !== 'function')) onDelete = () => removeRule(path, operatore, valore, ruleEditingOptions)

    return(
        <AppChip
            onClick={toggleFilter}
            isActive={isActive}
            label={label}
            onDelete={onDelete}
            // deleteIcon={onDelete && <Backspace />}
        />
    )
}

export const AppChip = ({onClick, onDelete, isActive, label, deleteIcon, children}) => {
    const classes = useStyles()
    return (
        <Chip
            label={label}
            clickable={!!onClick || !!onDelete}
            className={`${classes.Chip} ${isActive ? classes.activeChip : classes.inactiveChip}`}
            color={'primary'}
            onClick={onClick}
            onDelete={onDelete}
            deleteIcon={deleteIcon}
        />
    )
}