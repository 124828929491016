import React from 'react'
import { useEffect } from 'react'
import _ from 'lodash'
import { useQuery } from 'react-query'
import { profileQueries } from '../networking'

//this hook checks if the user has permission to write on the procedure and sets an alert.
//  the returned {isNotAllowedToWrite} in most cases will be passed to DynammicFormFields as disableAll prop while the
// returned {alert} in most cases will be passed to MethodologyPage as alert prop.

const useDisableForm = (procedure) => {
    const [isNotAllowedToWrite, setIsNotAllowedToWrite] = React.useState(true)
    const [alert, setAlert] = React.useState(false)
    const [user, setUser] = React.useState()
    //enable alert only after permission check
    const [flag, setFlag] = React.useState(false)

    useQuery(profileQueries.getUser.name, profileQueries.getUser.fn, {
        onSuccess: setUser,
    })

    useEffect(() => {
        if (user && procedure) {
            user.needs.forEach((need) => {
                if (need[0] === 'write' && need[1] === 'procedure' && need[2] === procedure.id) {
                    setIsNotAllowedToWrite(false)
                }
            })

            if (_.find(user.roles, { name: 'admin' })) {
                setIsNotAllowedToWrite(false)
            } else if (
                (procedure && procedure.owner?.admins?.includes(user.id)) ||
                (procedure && procedure.owner?.members?.includes(user.id))
            ) {
                setIsNotAllowedToWrite(false)
            }
            setFlag(true)
        }
    }, [user, procedure])

    useEffect(() => {
        if (flag && isNotAllowedToWrite) {
            setAlert(true)
        } else if (flag && !isNotAllowedToWrite) {
            setAlert(false)
        }
    }, [flag, isNotAllowedToWrite])

    return { isNotAllowedToWrite, alert }
}

export default useDisableForm
