import { CircularProgress, Fab, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import AppForm from '../../components/AppForm'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import LoadingSpinner from '../../components/LoadingSpinner'
import {
    masonryGndtModel,
    prefabricatedGndtModel,
    reinforcedConcreteGndtModel,
} from '../../FormConfigs/scheda_gndt_II_livello_config'
import {levelsQueries, proceduresQueries, queryClient} from '../../networking'
import MethodologyPage from '../MethodologyPage'
import SaveIcon from '@material-ui/icons/Save'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../utils'
import { replaceEmptyStringsWithNullWithin } from '../../FormConfigs/utils'
import {useSnackBarStore} from "../../stateManagement";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
    },
    section: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: '20px',
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    tooltipIcon: {
        color: theme.palette.background.paperLight,
        fontSize: 22,
    },
    deleteButton: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
}))

const gndtModelsByMaterial = {
    REINFORCED_CONCRETE: reinforcedConcreteGndtModel,
    PREFABRICATED: prefabricatedGndtModel,
    MASONRY: masonryGndtModel,
}

export const Scheda_gndt = () => {
    const procedureId = useParams().procedureId
    const classes = useStyles()
    const { t } = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)



    const { data: procedure, isLoading: isProcedureLoading } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )
    const {
        data: gndt,
        isLoading: isResistoGndtLoading,
        error: gndtFetchError
    } = useQuery(
        [levelsQueries.getResistoGndt.name, procedureId],
        () => levelsQueries.getResistoGndt.fn(procedureId),
        {
            onSuccess: (data) => methods.reset(data),
        }
    )
    const {
        mutate: updteGndt,
        isLoading: isUpdateGndtLoading,
        error: gndtUpdateError
    } = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'resisto',
                'gndt_level_2',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: (resp) => {
                queryClient.invalidateQueries([levelsQueries.getResistoGndt.name, procedureId])
                queryClient.invalidateQueries([proceduresQueries.getProcedure.name, procedureId])
            },
            onError: () => showSnackBar({ message: t('invalid gndt data'), severity: 'error' })
        }
    )

    const methods = useForm({
        mode: 'onBlur',
        shouldUnregister: false,
    })
    const stringifiedData = JSON.stringify(gndt)

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState


    React.useEffect(() => {
        if (gndt) {
            methods.reset(gndt)
        }
    }, [stringifiedData])

    function sendData(data) {
        if (isDirty && isValid) {
            updteGndt(data)
        }
    }

    const formModel = gndtModelsByMaterial[procedure?.building_material]

    return (
        <MethodologyPage methodology="resisto">
            {isProcedureLoading || isResistoGndtLoading ? (
                <LoadingSpinner />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {capitalizeFirstLetter(t('Scheda GNDT II LIV.'))}
                    </Typography>
                    <AppForm onSubmit={sendData} methods={methods}>
                        <Paper className={classes.section}>
                        <Grid xs={12}>
                            <Grid item xs={12}>
                                <DynamicFormFields
                                    fieldsKey={""}
                                    formFields={formModel}
                                    gridSpacing={1}
                                    flexDirection={"column"}
                                    flexWrap={"noWrap"}
                                />
                            </Grid>
                        </Grid>
                        </Paper>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={
                                isResistoGndtLoading ||
                                isUpdateGndtLoading ||
                                !isDirty ||
                                !isValid
                            }
                            className={classes.fab}
                        >
                            {isUpdateGndtLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    </AppForm>
                </Paper>
            )}
        </MethodologyPage>
    )
}
