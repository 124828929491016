import { useQueryProcedureAnalyses } from './queryHooks/analysisQueries'
import React from 'react'
import { flattenObject } from '../utils'

export function useProcedureAnalysesTable() {
    const { data: analyses } = useQueryProcedureAnalyses()
    return React.useMemo(
        () => ({
            rows: (analyses || []).map((analysis) => flattenObject(analysis)),
            columns: [],
        }),
        [analyses]
    )
}