import React from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Container, Tab, Tabs, Typography, Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LoadingSpinner from '../components/LoadingSpinner'
import { changelogQueries } from '../networking'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '70vh',
        overflow: 'hidden',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    chip: {
        padding: '2px 8px',
        borderRadius: 20,
        border: `1px solid ${theme.palette.success.main}`,
        color: theme.palette.success.main,
        marginLeft: 'auto',
    },
}))

function TabPanel({ currentTab, value, data }) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        currentTab === value && (
            <Grid
                container
                direction="column"
                wrap="nowrap"
                style={{ flex: 1, overflowY: 'auto', padding: 16 }}
                spacing={2}
            >
                {data.releases.map((release, index) => (
                    <Grid item key={release.version}>
                        <Paper variant="outlined" style={{ padding: 16 }}>
                            <Grid container alignItems="center">
                                <Typography variant="h5">v{release.version}</Typography>
                                <Typography variant="h5" style={{ marginLeft: 10 }}>
                                    ({dayjs(release.date).format('DD-MM-YYYY')})
                                </Typography>
                                {index === 0 && <div className={classes.chip}>{t('last')}</div>}
                            </Grid>
                            <ul>
                                {release.changes.map((change) => (
                                    <li key={change}>
                                        <Typography>{change}</Typography>
                                    </li>
                                ))}
                            </ul>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        )
    )
}

export default function ChangelogPage() {
    const classes = useStyles()
    const { t } = useTranslation()
    const [currentTab, setCurrentTab] = React.useState(0)

    const { data: changelog = {}, isLoading } = useQuery(
        changelogQueries.getChangelog.name,
        changelogQueries.getChangelog.fn
    )

    function handleTabChange(event, value) {
        setCurrentTab(value)
    }

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <Container maxWidth="md" className={classes.container}>
            <Typography variant="h4" component="h1" align="center" gutterBottom paragraph>
                {t('release_notes')}
            </Typography>
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    value={currentTab}
                    onChange={handleTabChange}
                    className={classes.tabs}
                >
                    <Tab label="FIBA" />
                    <Tab label={t('lgp_math')} />
                    <Tab label="L3" />
                </Tabs>
                <TabPanel currentTab={currentTab} value={0} data={changelog.fiba} />
                <TabPanel currentTab={currentTab} value={1} data={changelog.lgp} />
                <TabPanel currentTab={currentTab} value={2} data={changelog.l3} />
            </div>
        </Container>
    )
}
