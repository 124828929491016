import { useMutation } from 'react-query'
import { loginQueries, queryClient } from '../../networking'
import { useUserProfileStore } from '../../stateManagement'
import { useHistory } from 'react-router-dom'

export function useLogoutMutation() {
    const [setProfile] = useUserProfileStore((state) => [state.setProfile])
    const history = useHistory()

    return useMutation({
        mutationKey: loginQueries.logout.name,
        mutationFn: loginQueries.logout.fn,
        onSuccess: async () => {
            console.log('logged out')
            setProfile({})
            history.push('/login')
            await queryClient.resetQueries()
        },
    })
}
