import React from 'react'
import { Switch, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useThemeStore } from '../stateManagement'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import Brightness5Icon from '@material-ui/icons/Brightness5'

export default function ThemeSwitch() {
    const { t } = useTranslation()
    const { themeMode, setThemeMode } = useThemeStore()

    function toggleTheme() {
        if (themeMode === 'dark') {
            setThemeMode('light')
            localStorage.setItem('themeMode', 'light')
        } else {
            setThemeMode('dark')
            localStorage.setItem('themeMode', 'dark')
        }
    }

    return (
        <Grid container alignItems="center">
            <Typography style={{ marginRight: 30 }}>{t('theme')}</Typography>
            <Brightness5Icon />
            <Switch checked={themeMode === 'dark'} onChange={toggleTheme} />
            <Brightness2Icon />
        </Grid>
    )
}
