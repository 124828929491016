import React from 'react'
import _ from 'lodash'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckIcon from '@material-ui/icons/Check'
import CreateIcon from '@material-ui/icons/Create'

const useStyles = makeStyles((theme) => ({
    container: {
        height: 25,
        width: 25,
        position: 'relative',
    },
    innerIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}))

const isObject = (x) => x !== null && typeof x === 'object'

function getProgress(element, total, progress, formConfig) {
    const blacklist = ['unit']

    let _total = total
    let _progress = progress
    if (!formConfig?.optional && (Array.isArray(element) || isObject(element))) {
        _.each(element, (elem, key) => {
            if (!blacklist.includes(key)) {
                ;[_progress, _total] = getProgress(elem, _total, _progress, _.get(formConfig, key))
            }
        })
    } else if (!formConfig?.optional) {
        _total += 1
        if (element !== null && typeof element !== 'undefined' && element !== '') {
            _progress += 1
        }
    }
    return [_progress, _total]
}

export default function FormFillProgressIndicator({ control, currentPath, formConfig }) {
    const classes = useStyles()
    const [max, setMax] = React.useState(null)
    const [current, setCurrent] = React.useState(null)
    const currentFormObject = _.get(control.getValues(), currentPath)

    React.useEffect(() => {
        const [newProgress, newMax] = getProgress(currentFormObject, 0, 0, formConfig)
        if (newMax !== max) {
            setMax(newMax)
        }
        if (newProgress !== current) {
            setCurrent(newProgress)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFormObject, max, current])

    const ratio = current / max
    const displayValue = Math.trunc(100 * ratio)

    const colorsMapping = {
        4: '#058241',
        3: '#98ca3b',
        2: '#e2cd20',
        1: '#ec8a22',
        0: '#c9202e',
    }

    function chooseIconColor(value) {
        const colorClass = Math.floor(value / 25)
        return colorsMapping[colorClass]
    }

    return (
        !isNaN(displayValue) && (
            <div className={classes.container}>
                <CircularProgress
                    variant="determinate"
                    value={Math.trunc(100 * ratio)}
                    size={25}
                    style={{ color: chooseIconColor(displayValue) }}
                />
                {displayValue === 100 ? (
                    <CheckIcon
                        fontSize="small"
                        className={classes.innerIcon}
                        style={{ color: colorsMapping[4] }}
                    />
                ) : displayValue === 0 ? (
                    <CreateIcon fontSize="small" className={classes.innerIcon} color="disabled" />
                ) : (
                    <HourglassEmptyIcon
                        className={classes.innerIcon}
                        style={{
                            color: chooseIconColor(displayValue),
                            fontSize: '1rem',
                        }}
                    />
                )}
            </div>
        )
    )
}
