import React from 'react'
import {useForm} from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { levelsQueries, queryClient, proceduresQueries } from '../../networking'
import {CircularProgress, Collapse, Fab, Grid, IconButton, Paper, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MethodologyPage from '../MethodologyPage'
import {
    Save as SaveIcon,
    ArrowDropDown, ArrowDropUp,
} from '@material-ui/icons'
import {capitalizeFirstLetter} from "../../utils";
import AppForm from "../../components/AppForm";
import DynamicFormFields from "../../components/FormItems/DynamicFormFields";
import useDisableForm from "../../customHooks/useDisableForm";
import {reinforcedConcreteFloorModel, prefabricatedFloorModel, masonryFloorModel} from "../../FormConfigs/resisto_livello_config";
import _ from "lodash";
import {useSnackBarStore} from "../../stateManagement";
import {replaceEmptyStringsWithNullWithin} from "../../FormConfigs/utils";
import LoadingSpinner from "../../components/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
    },
    section: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: '20px',
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    tooltipIcon: {
        color: theme.palette.background.paperLight,
        fontSize: 22,
    },
    deleteButton: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
}))

const floorModelByMaterial = {
    "REINFORCED_CONCRETE": reinforcedConcreteFloorModel,
    "PREFABRICATED": prefabricatedFloorModel,
    "MASONRY": masonryFloorModel
}
const campiSottoSezioniByMaterial = {
    REINFORCED_CONCRETE: {
        misurazioni: ["h", "Ps", "Atot", "N"],
        n_colonne: ["n_columns"],
        colonne: ["ref_column", "columns", "column_images"],
        n_setti: ["n_piers"],
        setti: ["ref_pier", "piers", "pier_images"],
        materiali: ["material_data"],
    },
    PREFABRICATED: {
        misurazioni: ["h", "Ps", "Atot", "beams_on_x_weight", "beams_on_y_weight", "claddings_weight", "covers_weight_mq", "N"],
        n_colonne: ["n_columns"],
        colonne: ["ref_column", "columns", "column_images"],
        n_setti: ["n_piers"],
        setti: ["ref_pier", "piers", "pier_images"],
        materiali: ["material_data"],
    },
    MASONRY: {
        misurazioni: ["h", "Ax", "Ay", "Tr", "Ps", "Atot"],
        materiali: ["material_data"],
    }
}

export default function Resisto_livello_details() {
    const classes = useStyles()
    const {t} = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const params = useParams()
    const procedureId = params.procedureId
    const lvl = parseInt(params.levelId) - 1

    const {
        data: procedure,
        isFetched: isProcedureFetched,
        isLoading: isProcedureLoading,
        // error: procedureFetchError,
        // refetch: refetchProcedure
    } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId),
        {
            onError: () => showSnackBar({message: `${t('could_not_load')} ${t('procedure')}`, severity: 'error'})
        }
    )
    const {
        data: floors = [],
        // isFetched: isFloorsFetched,
        isLoading: isFloorsLoading,
        // error: floorsFetchError,
        // refetch: refetchFloors,
    } = useQuery(
        [levelsQueries.getResistoLivelli.name, procedureId],
        () => levelsQueries.getResistoLivelli.fn(procedureId),
        {
            enabled: isProcedureFetched,
            onSuccess: (data) => {
                methods.reset(getFrontendCompatibleFloorData(data, lvl))
            }
        }
    )
    const {
        mutate: updateFloors,
        isLoading: isUpdateFloorsLoading,
        // error: updateFloorsError,
    } = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'resisto',
                'floors',
                getBackendCompatibleFloors(data, lvl, floors)
            ),
        {
            // enabled: isProcedureFetched && isFloorsFetched,
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getResistoLivelli.name),
            onError: () => showSnackBar({ message: t('invalid data'), severity: 'error' })
        }
    )

    const buildingMaterial = procedure?.building_material
    const sottoSezioni = React.useMemo(() => {
        const floorModel = _.cloneDeep(floorModelByMaterial[buildingMaterial] || {})
        if (floorModel.columns) {
            const how_many = {
                _type: 'number',
                min: 1,
                step: 1,
                defaultValue: 1
            }
            floorModel.columns.inputs.how_many = how_many
            floorModel.piers.inputs.how_many = how_many
        }
        return Object.entries(campiSottoSezioniByMaterial[buildingMaterial] || {})
            .map(([nome, campi]) => ({[nome]: Object.assign({}, ...campi.map(nomeCampo => ({[nomeCampo]: floorModel[nomeCampo]})))}))
            .reduce((acc, cur) => Object.assign(acc, cur), {})
    }, [buildingMaterial])

    const methods = useForm({
        mode: 'onBlur',
        shouldUnregister: false,
    })
    const {isDirty, isValid} = methods.formState
    const data = methods.getValues()
    function sendData(data) {
        if (isDirty && isValid) {
            updateFloors(data)
        }
    }

    const {isNotAllowedToWrite, alert} = useDisableForm(procedure)
    const [openColumns, setOpenColumns] = React.useState(true)
    const [openPiers, setOpenPiers] = React.useState(true)

    React.useEffect(() => setOpenColumns(!!data.n_columns), [data.n_columns])
    React.useEffect(() => setOpenPiers(!!data.n_piers), [data.n_piers])

    return (
        <MethodologyPage methodology="resisto">
            {isFloorsLoading || isProcedureLoading ? (
                <LoadingSpinner />
            ) : (
                <AppForm onSubmit={sendData} methods={methods}>
                        <Paper elevation={0} className={classes.paper}>
                            {buildingMaterial !== "PREFABRICATED" && <Typography variant="h4" component="h1" align="center" gutterBottom>
                                {`${capitalizeFirstLetter(t('livello'))} ${lvl + 1}`}
                            </Typography>}

                            {/* MATERIALI */}
                            {sottoSezioni.materiali && <Paper className={classes.section}>
                                <Grid xs={12}>
                                    <Grid item>
                                        <Typography variant="h6" component="h2" align="center" gutterBottom>
                                            {t("building_materials")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <DynamicFormFields
                                            fieldsKey={""}
                                            formFields={sottoSezioni.materiali}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>}


                            {/* MISURAZIONI */}
                            {sottoSezioni.misurazioni && <Paper className={classes.section}>
                                <Grid xs={12}>
                                    <Grid item>
                                        <Typography variant="h6" component="h2" align="center" gutterBottom>
                                            {t("misurazioni_livello")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <DynamicFormFields
                                            fieldsKey={""}
                                            formFields={sottoSezioni.misurazioni}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>}

                            {/* PILASTRI */}
                            {sottoSezioni.n_colonne && (
                                <Paper className={classes.section}>
                                    <Grid xs={12}>
                                        <Grid item>
                                            <Typography variant="h6" component="h2" align="center" gutterBottom>
                                                {t("columns")}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid item xs>
                                                <DynamicFormFields
                                                    fieldsKey={""}
                                                    formFields={sottoSezioni.n_colonne}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton disabled={!data.n_columns} onClick={() => setOpenColumns(value => !value)}>
                                                    {openColumns ? <ArrowDropUp/> : <ArrowDropDown/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {sottoSezioni.colonne && (
                                            <Collapse in={openColumns}>
                                                <Grid item>
                                                    <DynamicFormFields
                                                        fieldsKey={""}
                                                        formFields={sottoSezioni.colonne}
                                                    />
                                                </Grid>
                                            </Collapse>
                                        )}
                                    </Grid>
                                </Paper>
                            )}

                            {/* SETTI */}
                            {sottoSezioni.n_setti && (
                                <Paper className={classes.section}>
                                    <Grid xs={12}>
                                        <Grid item>
                                            <Typography variant="h6" component="h2" align="center" gutterBottom>
                                                {t("piers")}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid item xs>
                                                <DynamicFormFields
                                                    fieldsKey={""}
                                                    formFields={sottoSezioni.n_setti}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton disabled={!data.n_piers} onClick={() => setOpenPiers(value => !value)}>
                                                    {openPiers ? <ArrowDropUp/> : <ArrowDropDown/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {sottoSezioni.setti && (
                                            <Collapse in={openPiers}>
                                                <Grid item>
                                                    <DynamicFormFields
                                                        fieldsKey={""}
                                                        formFields={sottoSezioni.setti}
                                                    />
                                                </Grid>
                                            </Collapse>
                                        )}
                                    </Grid>
                                </Paper>
                            )}



                        </Paper>
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={!isDirty || isUpdateFloorsLoading}
                        className={`${classes.fab} ${isNotAllowedToWrite && classes.hide}`}
                    >
                        {isUpdateFloorsLoading ? (
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                        ) : (
                            <SaveIcon />
                        )}
                        {t('salva')}
                    </Fab>
                </AppForm>
            )}
        </MethodologyPage>
    )
}

function groupEqualEntries(someObjectsArray) {
    return Object.entries(someObjectsArray.map(obj => JSON.stringify(obj)).reduce((acc, cur) => {
        acc[cur] = (acc[cur] || 0) + 1
        return acc
    }, {})).map(([objStr, how_many]) => ({...JSON.parse(objStr), how_many}))
}
function expandGroupedEntries(someObject) {
    const {how_many, ...otherFields} = someObject
    return new Array(how_many).fill(JSON.stringify(otherFields)).map(objStr => JSON.parse(objStr))
}

function getBackendCompatibleFloors(data, lvl, floors) {
    // console.log({floors_from_form: data})
    const backendAdaptedData = _.cloneDeep(data)
    backendAdaptedData.lvl = lvl
    if (backendAdaptedData.ref_column && !backendAdaptedData.n_columns) {
        backendAdaptedData.ref_column = null
        backendAdaptedData.columns = []
        backendAdaptedData.column_images = []
    }
    if (backendAdaptedData.ref_pier && !backendAdaptedData.n_piers) {
        backendAdaptedData.ref_pier = null
        backendAdaptedData.piers = []
        backendAdaptedData.pier_images = []
    }
    if (backendAdaptedData.columns) {
        backendAdaptedData.columns = backendAdaptedData.columns.flatMap(col => expandGroupedEntries(col))
    }
    if (backendAdaptedData.piers) {
        backendAdaptedData.piers = backendAdaptedData.piers.flatMap(pier => expandGroupedEntries(pier))
    }
    // console.log({backendAdaptedData})

    const updatedFloors = [...floors]
    updatedFloors[lvl] = replaceEmptyStringsWithNullWithin(backendAdaptedData)
    return updatedFloors
}
function getFrontendCompatibleFloorData(floors, lvl) {
    const frontendAdaptedFloor = _.cloneDeep(floors[lvl])
    if (frontendAdaptedFloor?.columns) {
        frontendAdaptedFloor.columns = groupEqualEntries(frontendAdaptedFloor.columns)
    }
    if (frontendAdaptedFloor?.piers) {
        frontendAdaptedFloor.piers = groupEqualEntries(frontendAdaptedFloor.piers)
    }
    // console.log({frontendAdaptedFloor})
    return frontendAdaptedFloor
}