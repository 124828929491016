import React from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Paper, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { levelsQueries, queryClient, proceduresQueries } from '../../../networking'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import difettiMap from '../../../assets/lgp/difetti_map.json'
import difettiInfo from '../../../assets/lgp/difetti_info.json'
import LoadingSpinner from '../../../components/LoadingSpinner'
import NetworkErrorOverlay from '../../../components/NetworkErrorOverlay'
import { RequiredLA2Info } from '../../../components/FormItems/RequiredLA2'
import MethodologyPage from '../../MethodologyPage'
import AppTooltip from '../../../components/AppTooltip'
import AlertDialog from '../../../components/Dialogs/AlertDialog'
import GlobalSnackBar from '../../../components/GlobalSnackBar'
import { useSnackBarStore } from '../../../stateManagement'
import useDisableForm from '../../../customHooks/useDisableForm'
import DettaglioSchedaIspezione from '../../../components/LGP/LA1/DettaglioSchedaIspezione'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
        position: 'relative',
    },
}))

export default function LGP_LA1_scheda_difetto() {
    const classes = useStyles()
    const { t } = useTranslation()
    const { procedureId, elementoStrutturale } = useParams()
    const indexParams = parseInt(useParams().index)
    const showSnackBar = useSnackBarStore((state) => state.show)

    const [invalidDifetti, setInvalidDifetti] = React.useState([])
    const [isErrorsDialogOpen, setIsErrorsDialogOpen] = React.useState(false)

    // taken from url in kebab-case, need it in snake_case for be purposes
    const currentElementoStrutturale = elementoStrutturale.replaceAll('-', '_')
    const formPath = `schede_ispezione.${currentElementoStrutturale}[${indexParams - 1}]`

    const { data: procedure, isFetched: isProcedureFetched } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )

    const {
        data: livello1,
        isFetching,
        isLoading,
        error,
        refetch,
    } = useQuery(
        [levelsQueries.getMitLevel1.name, procedureId],
        async () => {
            return levelsQueries.getMitLevel1.fn(procedureId)
        },
        {
            enabled: isProcedureFetched,
            onSuccess: (data) => {
                methods.reset({ ...data, hidden_company_id: procedure.owner.id })
                return data
            },
        }
    )

    const methods = useForm({
        mode: 'onBlur',
        shouldUnregister: false,
        defaultValues: livello1,
    })

    const handleEditMaterial = (newMaterial, setIsEditMaterialOpen) => {
        const newDifetti = difettiMap[currentElementoStrutturale][newMaterial]
        const oldDifetti = _.get(methods.getValues(), `${formPath}.difetti`, {})
        const commonDifetti = {}

        _.forEach(newDifetti, (newDifetto) => {
            if (oldDifetti[newDifetto.id]) {
                commonDifetti[newDifetto.id] = oldDifetti[newDifetto.id]
            }
        })

        livello1.schede_ispezione[currentElementoStrutturale][parseInt(indexParams) - 1].materiale =
            newMaterial

        livello1.schede_ispezione[currentElementoStrutturale][parseInt(indexParams) - 1].difetti =
            commonDifetti

        levelsQueries.updateLevel
            .fn(
                procedureId,
                'mit_guidelines',
                'level1',
                replaceEmptyStringsWithNullWithin(livello1)
            )
            .then(async (data) => {
                await queryClient.invalidateQueries([levelsQueries.getMitLevel1.name, procedureId])
                setIsEditMaterialOpen(false)
                return data
            })
    }

    const updateSchedaMutation = useMutation((data) => {
        const difetti =
            data.schede_ispezione[currentElementoStrutturale][parseInt(indexParams) - 1].difetti

        // adding manually missing data
        Object.entries(difetti).forEach(([id, difetto]) => {
            difetto.codice = id
            difetto.g = difettiInfo[id].weight
        })

        // validate difetti
        const invalidDifetti = []
        _.forEach(difetti, (difetto) => {
            if (difetto.estensione && difetto.intensita) {
                if (!difetto.foto || difetto.foto?.length === 0) {
                    // 1. mandatory photo
                    invalidDifetti.push({ id: difetto.codice, error: 'missing_photo' })
                }
                if ([4, 5].includes(difetto.g) && difetto.ps == null) {
                    // 2. if G4 or G5 -> mandatory PS
                    invalidDifetti.push({ id: difetto.codice, error: 'missing_ps' })
                }
            } else if (['NA', 'NR'].includes(difetto.motivo_assenza) && !difetto.note) {
                // 3. if motivo_assenza NA or NR -> mandatory notes
                invalidDifetti.push({ id: difetto.codice, error: 'missing_notes' })
            }
        })
        if (invalidDifetti.length) {
            showSnackBar({
                message: t('saving_not_allowed'),
                severity: 'error',
            })
            setInvalidDifetti(invalidDifetti)
            setIsErrorsDialogOpen(true)
            return
        }

        delete data.hidden_company_id
        return levelsQueries.updateLevel
            .fn(procedureId, 'mit_guidelines', 'level1', replaceEmptyStringsWithNullWithin(data))
            .then(() => queryClient.invalidateQueries([levelsQueries.getMitLevel1.name, procedureId]))
    })

    function sendData(data) {
        updateSchedaMutation.mutate(data)
    }

    function configureEstensioneIntensitaFields(model, data) {
        const {
            estensione: estensioneModel,
            intensita: intensitaModel,
            ...otherModelFields
        } = model
        const { estensione: estensioneData, intensita: intensitaData } = data
        let configuredEstensioneButtons, configuredIntensitaButtons
        if (estensioneData[0] === 'tutti') {
            configuredEstensioneButtons = [{ value: 0.2 }, { value: 0.5 }, { value: 1 }]
        } else {
            configuredEstensioneButtons = [{ value: estensioneData[0] }]
        }
        if (intensitaData[0] === 'tutti') {
            configuredIntensitaButtons = [{ value: 0.2 }, { value: 0.5 }, { value: 1 }]
        } else {
            configuredIntensitaButtons = [{ value: intensitaData[0] }]
        }
        return {
            estensione: {
                ...estensioneModel,
                buttons: configuredEstensioneButtons,
            },
            intensita: {
                ...intensitaModel,
                buttons: configuredIntensitaButtons,
            },
            ...otherModelFields,
        }
    }

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)
    
    return isFetching ? (
        <LoadingSpinner />
    ) : (
        <MethodologyPage methodology="lgp" alert={alert}>
            {error ? (
                <NetworkErrorOverlay
                    actions={[{ onClick: refetch, label: t('retry') }]}
                    areActionsPending={isFetching}
                />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <DettaglioSchedaIspezione
                        currentElementoStrutturale={currentElementoStrutturale}
                        livello1={livello1}
                        formPath={formPath}
                        formPathForDynamicFormFields={formPath}
                        isNotAllowedToWrite={isNotAllowedToWrite}
                        handleEditMaterial={handleEditMaterial}
                        sendData={sendData}
                        methods={methods}
                        isLoading={isFetching}
                        isUpdateLoading={updateSchedaMutation.isLoading}
                    />
                </Paper>
            )}
            <AlertDialog
                open={isErrorsDialogOpen}
                setIsOpen={setIsErrorsDialogOpen}
                title="invalid_difetti"
                secondaryButton="close"
                maxWidth="lg"
            >
                {invalidDifetti.map((difetto, index) => (
                    <Grid container key={difetto.id + index} alignItems="center">
                        <Typography style={{ marginRight: 10 }}>
                            {difettiInfo[difetto.id]?.name} -
                        </Typography>
                        <Typography color="error" variant="body2">
                            {t(difetto.error)}
                        </Typography>
                    </Grid>
                ))}
            </AlertDialog>
            <GlobalSnackBar />
        </MethodologyPage>
    )
}
