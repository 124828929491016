import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCompanyStore } from "../stateManagement";
import { methodologies } from "../constants";
import React from "react";
import { usePersistentStore } from "./createPersistentStore";

const homePattern = new RegExp('^/home(?=[/?]|$)')
const dashboardMethodologyPattern = new RegExp(`(?<=^/dashboard/)(${methodologies.join('|')})(?=[/?]|$)`)

export default function useCompanyId() {
    const history = useHistory()
    const {pathname} = useLocation()
    const isHome = homePattern.test(pathname)
    const isDashboard = dashboardMethodologyPattern.test(pathname)
    const methodology = isDashboard ? pathname.match(dashboardMethodologyPattern)[0] : undefined
    const beMethodology = {"lgp": "mit_guidelines"}[methodology] || methodology
    const {companyId: urlCompanyId} = useParams()
    const companyId = (() => {
        if (urlCompanyId === 'tutti_gli_studi' && isHome) return urlCompanyId
        if (urlCompanyId && urlCompanyId !== 'tutti_gli_studi') return urlCompanyId
        if (new RegExp('(/dashboard/lgp/|home/)[0-9a-f]{24}').test(pathname)) {
            return pathname.match(new RegExp('(?<=/dashboard/lgp/|home/)[0-9a-f]{24}'))[0]
        }
        return undefined
    })()
    const [storedCompanyId, setStoredCompanyId] = usePersistentStore('storedFilters', state => [state.storedCompanyId, state.setStoredCompanyId])
    const [
        companies,
        dashboardCompanies
    ] = useCompanyStore(state => [
        state.companies,
        state.dashboardCompanies
    ])
    
    const reachableCompanyIds = React.useMemo(() => {
        return new Map((isDashboard ? dashboardCompanies : companies).map((c) => [c.id, c]))
    }, [isDashboard, companies, dashboardCompanies])

    const targetCompanyId = React.useMemo(() => {
        if (isHome) {
            return [companyId, storedCompanyId].find(id => reachableCompanyIds.has(id) || id === 'tutti_gli_studi') || null
        }
        if (isDashboard) {
            return [companyId, storedCompanyId].find(id => reachableCompanyIds.has(id)) || dashboardCompanies.at(0)?.id
        }
    }, [isHome, isDashboard, companyId, storedCompanyId, reachableCompanyIds, dashboardCompanies])
    
    const setCompanyId = React.useCallback(function(id) {
        id = (reachableCompanyIds.has(id) || (isHome && id === null)) ? id : targetCompanyId
        if (id && (id !== storedCompanyId)) setStoredCompanyId(id)
        
        if (isHome) {
            if (id !== companyId) history.push(id ? `/home/${id}`: '/home', {companyId})
        }
        else if (isDashboard) {
            id = id || targetCompanyId
            if (!id) {
                history.push(`/home`)
            } else if (id !== companyId) {
                history.push(`/dashboard/${methodology}/${id}`, {companyId})
            }
        }
    }, [isHome, isDashboard, history, methodology, companyId, storedCompanyId, targetCompanyId, reachableCompanyIds, setStoredCompanyId])
    
    React.useEffect(() => {
        if (isDashboard && !reachableCompanyIds.has(companyId) && targetCompanyId) {
            setCompanyId(targetCompanyId)
        }
    }, [isDashboard, companyId, setCompanyId, reachableCompanyIds, targetCompanyId])
    return {companyId, setCompanyId, methodology, beMethodology}
}