import { Link } from 'react-router-dom'
import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function AdminIndex({ children, maxWidth = 'md' }) {
    const { t } = useTranslation()

    return (
        <>
            <Grid item container direction="column" justifyContent="space-around" spacing={3}>
                <Grid item>
                    <Typography variant="h4" component="h1" align="center">
                        {t('admin.section_title')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained">
                        <Link to={`/admin/field_pc`}>{t('admin.field_pc')}</Link>
                    </Button>
                    <Button variant="contained">
                        <Link to={`/admin/plc`}>{t('admin.plc')}</Link>
                    </Button>
                    <Button variant="contained">
                        <Link to={`/admin/analysis`}>{t('admin.analysis')}</Link>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
