import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Paper, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import CallIcon from '@material-ui/icons/Call'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    paper: {
        padding: theme.spacing(5),
        paddingBottom: 100,
        position: 'relative',
    },
    leftIcon: {
        marginRight: 15,
    },
    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
    decoration: {
        fontSize: 160,
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: 0.2,
    },
    text: {
        fontSize: '1.25rem',
    },
}))

export default function HelpPage() {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom paragraph>
                    {t('help_page')}
                </Typography>
                <Typography
                    align="center"
                    gutterBottom
                    style={{ whiteSpace: 'pre-line', fontSize: '1.25rem' }}
                >
                    {t('help_page.body')}
                </Typography>

                <Grid container direction="column" spacing={5} style={{ marginTop: 30 }}>
                    <Grid item>
                        <Typography gutterBottom className={classes.text}>
                            {t('help_page.email')}
                        </Typography>
                        <Grid container alignItems="center">
                            <EmailIcon className={classes.leftIcon} />
                            <Typography
                                classes={{
                                    root: classes.link,
                                }}
                                className={classes.text}
                            >
                                <a href="mailto:HelpmeFIBA@builti.it">HelpmeFIBA@builti.it</a>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom className={classes.text}>
                            {t('help_page.telephone')}
                        </Typography>
                        <Grid container alignItems="center">
                            <CallIcon className={classes.leftIcon} />
                            <Typography className={classes.text}>011387655 int. 110</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <ContactSupportIcon color="disabled" className={classes.decoration} />
            </Paper>
        </Container>
    )
}
