import React from 'react'
import { TextField, InputAdornment, Typography, Tooltip, IconButton } from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShouldLabelCut, useWatchForAdditionalProps } from '../../FormConfigs/utils'
import AppTooltip from '../AppTooltip'

function Text({
    label,
    unit,
    step,
    min,
    watchConfig,
    onChange,
    value,
    valueToUse,
    info,
    onClickInfo,
    ...props
}) {
    const additionalProps = useWatchForAdditionalProps(watchConfig)
    const { clearErrors, setError } = useFormContext()
    const { t } = useTranslation()
    // This component receives value=null from the Controller (defaultValue prop)
    // so it never falls back to defaultValue.
    // Forcing this behavior:

    // ------------------------------------------------

    const onInputChange = (e) => {
        if (e.target.value === '') {
            setError(props.name.replace('.value', ''), {
                type: 'custom',
                message: 'campo non compilato',
            })
            onChange(null)
        } else {
            clearErrors(props.name.replace('.value', ''))
            onChange(parseFloat(e.target.value))
        }
    }
    const labelToShow = useShouldLabelCut(t(label))
    return (
        <>
            {labelToShow === t(label) ? (
                <TextField
                    type="number"
                    variant="outlined"
                    {...props}
                    {...additionalProps}
                    label={labelToShow}
                    inputProps={{ step, min }}
                    onChange={onInputChange}
                    onBlur={onInputChange}
                    value={isNaN(value) || value === null ? '' : value}
                    InputLabelProps={{
                        component: ({ children, ...props }) => (
                            <label
                                {...props}
                                dangerouslySetInnerHTML={{
                                    __html: label || undefined,
                                }}
                            />
                        ),
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                <InputAdornment
                                    position="end"
                                    dangerouslySetInnerHTML={{
                                        __html: `${unit}`,
                                    }}
                                    style={{ fontSize: 14 }}
                                />
                                {info && (
                                    <Tooltip placement="top-end" title={info}>
                                        <IconButton size="small" onClick={onClickInfo}>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        ),
                    }}
                />
            ) : (
                <AppTooltip
                    title={<Typography variant="body2">{t(label)}</Typography>}
                    placement="top"
                >
                    <TextField
                        type="number"
                        variant="outlined"
                        {...props}
                        {...additionalProps}
                        label={labelToShow}
                        inputProps={{ step, min }}
                        onChange={onInputChange}
                        onBlur={onInputChange}
                        value={isNaN(value) || value === null ? '' : value}
                        InputLabelProps={{
                            component: ({ children, ...props }) => (
                                <label
                                    {...props}
                                    dangerouslySetInnerHTML={{
                                        __html: labelToShow || undefined,
                                    }}
                                />
                            ),
                            shrink: true,
                        }}
                        InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment
                                            position="end"
                                            dangerouslySetInnerHTML={{ __html: `${unit}` }}
                                            style={{ fontSize: 14 }}
                                        />
                                        {info && <Tooltip placement="top-end" title={info}>
                                            <IconButton size="small" onClick={onClickInfo}>
                                                <HelpOutlineIcon/>
                                            </IconButton>
                                        </Tooltip>}
                                    </>
                                )
                        }}
                    />
                </AppTooltip>
            )}
        </>
    )
}

export default function UnitMeasureField(props) {
    const { t } = useTranslation()
    const t_label = t(props.label)

    return <Text {...props} label={t_label} />
}
