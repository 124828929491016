import create from 'zustand'
import { createPersistentStore } from './customHooks/createPersistentStore'

export const useUserProfileStore = create((set) => ({
    profile: {},
    isSuperAdmin: null,
    isUsersManager: null,
    isModulesManager: null,
    isCreditManager: null,
    isTranslationManager: null,
    setProfile: (profile) => {
        const isSuperAdmin = !!(profile?.roles || []).find((role) => role?.name === 'admin')
        set({
            profile: { ...profile },
            isSuperAdmin,
            isUsersManager:
                isSuperAdmin ||
                !!(profile?.roles || []).find((role) => role?.name === 'users_manager'),
            isModulesManager:
                isSuperAdmin ||
                !!(profile?.roles || []).find((role) => role?.name === 'modules_manager'),
            isCreditManager:
                isSuperAdmin ||
                !!(profile?.roles || []).find((role) => role?.name === 'credit_manager'),
            isTranslationManager:
                isSuperAdmin ||
                !!(profile?.roles || []).find((role) => role?.name === 'translation_manager'),
        })
    },
}))

export const usePlaceStore = create((set) => ({
    place: null,
    setPlace: (place) => set({ place }),
}))

export const useThemeStore = create((set) => ({
    themeMode: 'dark',
    setThemeMode: (themeMode) => set({ themeMode }),
}))

export const useWindowWidthStore = create((set) => ({
    width: window.outerWidth,
    setWidth: (width) => set({ width }),
}))

export const usePendingOperationsStore = create((set, get) => ({
    pendingOps: {},
    setPendingOp: (id, func) =>
        set((state) => ({
            pendingOps: {
                ...state.pendingOps,
                [id]: func,
            },
        })),
    clearPendingOps: () => set({ pendingOps: {} }),
    runPendingOps: () => {
        const promises = Object.entries(get().pendingOps).map(([id, func]) => func())
        promises.push(get().clearPendingOps())
        return Promise.all(promises)
    },
}))

export const useSnackBarStore = create((set, get) => ({
    display: false,
    autoHideDelay: 3500,
    onClose: () => get().hide(),
    message: null,
    severity: 'info',
    show: ({ message, severity }) => {
        setTimeout(get().hide, get().autoHideDelay)
        return set({ display: true, message, severity })
    },
    hide: () => set({ display: false, message: null }),
    reset: () =>
        set({
            display: false,
            autoHideDelay: 6000,
            onClose: () => {},
            message: null,
            severity: 'info',
        }),
}))

export const useCompanyStore = create((set) => ({
    companies: [],
    setCompanies: (companies) => set({ companies: [...companies] }),
    writeCompanies: [],
    setWriteCompanies: (writeCompanies) => set({ writeCompanies: [...writeCompanies] }),
    dashboardCompanies: [],
    setDashboardCompanies: (dashboardCompanies) =>
        set({ dashboardCompanies: [...dashboardCompanies] }),
}))

// export const useProceduresFiltersStore = create((set, get) => ({
//     filters: { rules: {} },
//     setFilters: (filters) => {
//         set({ filters: { rules: filters?.rules || {} } })
//     },
//     activeStructureTypes: [...structureTypes].sort(),
//     setActiveStructureTypes: (activeStructureTypes) =>
//         set({activeStructureTypes: [...activeStructureTypes].sort()}),
//     companyId: undefined,
//     setCompanyId: (companyId) => set({ companyId }),
//     filteredProcedures: [],
//     setFilteredProcedures: (filteredProcedures) => set({ filteredProcedures }),
//     resetAllFilters: () => {
//         set({
//             filters: { rules: {} },
//             // activeStructureTypes: Object.fromEntries(structureTypes.map(type => [type, true])),
//         })
//     },
//     addRule: (path, operatore, valore, options) => {
//         const rulesAreEqual =
//             options?.rulesAreEqual ||
//             ((rule) => rule.operatore === operatore && rule.valore === valore)
//         const filters = get().filters
//         const rulesOnPath = _.get(filters.rules, path, [])
//         if (_.findIndex(rulesOnPath, rulesAreEqual) !== -1) {
//             // rule is already present
//             return false
//         }
//         rulesOnPath.push({
//             chiave: undefined,
//             operatore: operatore,
//             valore: valore,
//         })
//         _.set(filters.rules, path, rulesOnPath)
//         get().setFilters(filters)
//         return true
//     },
//     removeRule: (path, operatore, valore, options) => {
//         const rulesAreEqual =
//             options?.rulesAreEqual ||
//             ((rule) => rule.operatore === operatore && rule.valore === valore)
//         const filters = get().filters
//         const rulesOnPath = _.get(filters.rules, path, [])
//         if (_.remove(rulesOnPath, rulesAreEqual).length) {
//             _.set(filters.rules, path, rulesOnPath)
//             retractBranch(filters.rules, path)
//             get().setFilters(filters)
//             return true
//         }
//         return false
//     },
//     toggleRule: (path, operatore, valore, options) => {
//         // restituisce lo stato finale della regola
//         return (
//             get().addRule(path, operatore, valore, options) ||
//             !get().removeRule(path, operatore, valore, options)
//         )
//     },
//     isRuleSet: (path, operatore, valore, options) => {
//         const rulesAreEqual =
//             options?.rulesAreEqual ||
//             ((rule) => rule.operatore === operatore && rule.valore === valore)
//         const filters = get().filters
//         const rulesOnPath = _.get(filters.rules, path, [])
//         return _.findIndex(rulesOnPath, rulesAreEqual) !== -1
//     },
// }))

createPersistentStore('storedFilters', (set) => ({
    dashboardFilters: { rules: {} },
    homeFilters: { rules: {} },
    storedCompanyId: undefined,
    setDashboardFilters: (dashboardFilters) => set({ dashboardFilters: { ...dashboardFilters } }),
    setHomeFilters: (homeFilters) => set({ homeFilters: { ...homeFilters } }),
    setStoredCompanyId: (storedCompanyId) => set({ storedCompanyId }),
}))

export const useProceduresPanelStore = create((set, get) => ({
    totCount: 0,
    setTotCount: (totCount) => set({ totCount }),
    listedProcedures: [],
    setListedProcedures: (listedProcedures) => set({ listedProcedures }),
}))

export const useMapStore = create((set, get) => ({
    mapRef: null,
    setMapRef: (mapRef) => set({ mapRef }),
}))

export const useProcedureSelectionOnMapStore = create((set) => ({
    selectedProcedureId: null,
    setSelectedProcedureId: (id) =>
        set((state) => (id !== state.selectedProcedureId ? { selectedProcedureId: id } : state)),
    proceduresListRef: null,
    setProceduresListRef: (proceduresListRef) => set({ proceduresListRef }),
}))
