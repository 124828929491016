import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        height: '100%',
    },
    paper: {
        padding: theme.spacing(6),
    },
    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
}))

export default function GdprPage() {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom paragraph>
                    {t('gdpr_page.title')}
                </Typography>
                <Typography variant="body2">
                    Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.
                </Typography>
                <Typography paragraph variant="body2">
                    Questo documento può essere stampato utilizzando il comando di stampa presente
                    nelle impostazioni di qualsiasi browser.
                </Typography>
                <Typography variant="h6" component="h2">
                    Titolare del Trattamento dei Dati
                </Typography>
                <Typography variant="body2">BUILTI S.R.L.</Typography>
                <Typography variant="body2">Via Guelfa 5</Typography>
                <Typography variant="body2">40138 Bologna (BO)</Typography>
                <Typography paragraph variant="body2">
                    Indirizzo email del Titolare: support@builti.it
                </Typography>
                <Typography variant="h6" component="h2">
                    Tipologie di Dati raccolti
                </Typography>
                <Typography paragraph variant="body2">
                    Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite
                    terze parti, ci sono: nome; cognome; numero di telefono; email; Dati di
                    utilizzo; Strumento di Tracciamento; risposte alle domande; clic; eventi
                    keypress; eventi relativi ai sensori di movimento; movimenti del mouse;
                    posizione relativa allo scorrimento; eventi touch.
                </Typography>
                <Typography paragraph variant="body2">
                    Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle
                    sezioni dedicate di questa privacy policy o mediante specifici testi informativi
                    visualizzati prima della raccolta dei dati stessi.
                </Typography>
                <Typography paragraph variant="body2">
                    I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di
                    Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.
                </Typography>
                <Typography paragraph variant="body2">
                    Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione
                    sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere
                    impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa
                    Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di
                    astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla
                    disponibilità del Servizio o sulla sua operatività.
                </Typography>
                <Typography paragraph variant="body2">
                    Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono
                    incoraggiati a contattare il Titolare.
                </Typography>
                <Typography paragraph variant="body2">
                    L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte
                    di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa
                    Applicazione, ove non diversamente precisato, ha la finalità di fornire il
                    Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel
                    presente documento e nella Cookie Policy, se disponibile.
                </Typography>
                <Typography paragraph variant="body2">
                    L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti,
                    pubblicati o condivisi mediante questa Applicazione e garantisce di avere il
                    diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi
                    responsabilità verso terzi.
                </Typography>
                <Typography variant="h6" component="h2">
                    Modalità e luogo del trattamento dei Dati raccolti
                </Typography>
                <Typography variant="body1">Modalità di trattamento</Typography>
                <Typography paragraph variant="body2">
                    Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso,
                    la divulgazione, la modifica o la distruzione non autorizzate dei Dati
                    Personali.
                </Typography>
                <Typography paragraph variant="body2">
                    Il trattamento viene effettuato mediante strumenti informatici e/o telematici,
                    con modalità organizzative e con logiche strettamente correlate alle finalità
                    indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati
                    altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale
                    amministrativo, commerciale, marketing, legali, amministratori di sistema)
                    ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
                    postali, hosting provider, società informatiche, agenzie di comunicazione)
                    nominati anche, se necessario, Responsabili del Trattamento da parte del
                    Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al
                    Titolare del Trattamento.
                </Typography>
                <Typography variant="body1">Base giuridica del trattamento</Typography>
                <Typography paragraph variant="body2">
                    Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle
                    seguenti condizioni:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body2">
                            l’Utente ha prestato il consenso per una o più finalità specifiche;
                            Nota: in alcuni ordinamenti il Titolare può essere autorizzato a
                            trattare Dati Personali senza che debba sussistere il consenso
                            dell’Utente o un’altra delle basi giuridiche specificate di seguito,
                            fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento.
                            Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali
                            sia regolato dalla legislazione europea in materia di protezione dei
                            Dati Personali;
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            il trattamento è necessario all'esecuzione di un contratto con l’Utente
                            e/o all'esecuzione di misure precontrattuali;
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            il trattamento è necessario per adempiere un obbligo legale al quale è
                            soggetto il Titolare;
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            il trattamento è necessario per l'esecuzione di un compito di interesse
                            pubblico o per l'esercizio di pubblici poteri di cui è investito il
                            Titolare;
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            il trattamento è necessario per il perseguimento del legittimo interesse
                            del Titolare o di terzi.
                        </Typography>
                    </li>
                </ul>
                <Typography paragraph variant="body2">
                    È comunque sempre possibile richiedere al Titolare di chiarire la concreta base
                    giuridica di ciascun trattamento ed in particolare di specificare se il
                    trattamento sia basato sulla legge, previsto da un contratto o necessario per
                    concludere un contratto.
                </Typography>
                <Typography variant="body1">Luogo</Typography>
                <Typography paragraph variant="body2">
                    I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro
                    luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori
                    informazioni, contatta il Titolare.
                </Typography>
                <Typography paragraph variant="body2">
                    I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da
                    quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo
                    del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli
                    sul trattamento dei Dati Personali.
                </Typography>
                <Typography paragraph variant="body2">
                    L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del
                    trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione
                    internazionale di diritto internazionale pubblico o costituita da due o più
                    paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate
                    dal Titolare per proteggere i Dati.
                </Typography>
                <Typography paragraph variant="body2">
                    L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti
                    esaminando la sezione di questo documento relativa ai dettagli sul trattamento
                    di Dati Personali o chiedere informazioni al Titolare contattandolo agli estremi
                    riportati in apertura.
                </Typography>
                <Typography variant="body1">Periodo di conservazione</Typography>
                <Typography variant="body2">
                    I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le
                    quali sono stati raccolti.
                </Typography>
                <Typography paragraph variant="body2">
                    Pertanto:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body2">
                            I Dati Personali raccolti per scopi collegati all’esecuzione di un
                            contratto tra il Titolare e l’Utente saranno trattenuti sino a quando
                            sia completata l’esecuzione di tale contratto.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            I Dati Personali raccolti per finalità riconducibili all’interesse
                            legittimo del Titolare saranno trattenuti sino al soddisfacimento di
                            tale interesse. L’Utente può ottenere ulteriori informazioni in merito
                            all’interesse legittimo perseguito dal Titolare nelle relative sezioni
                            di questo documento o contattando il Titolare.
                        </Typography>
                    </li>
                </ul>
                <Typography paragraph variant="body2">
                    Quando il trattamento è basato sul consenso dell’Utente, il Titolare può
                    conservare i Dati Personali più a lungo sino a quando detto consenso non venga
                    revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati
                    Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per
                    ordine di un’autorità.
                </Typography>
                <Typography paragraph variant="body2">
                    Al termine del periodo di conservazione i Dati Personali saranno cancellati.
                    Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione,
                    rettificazione ed il diritto alla portabilità dei Dati non potranno più essere
                    esercitati.
                </Typography>
                <Typography variant="h6" component="h2">
                    Finalità del Trattamento dei Dati raccolti
                </Typography>
                <Typography paragraph variant="body2">
                    I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il
                    Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni
                    esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze
                    parti), individuare eventuali attività dolose o fraudolente, nonché per le
                    seguenti finalità: Contattare l'Utente, Gestione dei tag, Visualizzazione di
                    contenuti da piattaforme esterne, Statistica e Protezione dallo SPAM.
                </Typography>
                <Typography paragraph variant="body2">
                    Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati
                    Personali trattati per ciascuna finalità, l’Utente può fare riferimento alla
                    sezione “Dettagli sul trattamento dei Dati Personali”.
                </Typography>
                <Typography variant="h6" component="h2">
                    Dettagli sul trattamento dei Dati Personali
                </Typography>
                <Typography paragraph variant="body2">
                    I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i
                    seguenti servizi:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body2">Contattare l'Utente</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Gestione dei tag</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Protezione dallo SPAM</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">Statistica</Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            Visualizzazione di contenuti da piattaforme esterne
                        </Typography>
                    </li>
                </ul>
                <Typography variant="h6" component="h2">
                    Diritti dell’Utente
                </Typography>
                <Typography variant="body2">
                    Gli Utenti possono esercitare determinati diritti con riferimento ai Dati
                    trattati dal Titolare.
                </Typography>
                <Typography paragraph variant="body2">
                    In particolare, l’Utente ha il diritto di:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body2">
                            <b>revocare il consenso in ogni momento.</b> L’Utente può revocare il
                            consenso al trattamento dei propri Dati Personali precedentemente
                            espresso.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>opporsi al trattamento dei propri Dati.</b> L’Utente può opporsi al
                            trattamento dei propri Dati quando esso avviene su una base giuridica
                            diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono
                            indicati nella sezione sottostante.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>accedere ai propri Dati.</b> L’Utente ha diritto ad ottenere
                            informazioni sui Dati trattati dal Titolare, su determinati aspetti del
                            trattamento ed a ricevere una copia dei Dati trattati.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>verificare e chiedere la rettificazione.</b> L’Utente può verificare
                            la correttezza dei propri Dati e richiederne l’aggiornamento o la
                            correzione.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>ottenere la limitazione del trattamento.</b> Quando ricorrono
                            determinate condizioni, l’Utente può richiedere la limitazione del
                            trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati
                            per alcun altro scopo se non la loro conservazione.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>ottenere la cancellazione o rimozione dei propri Dati Personali.</b>{' '}
                            Quando ricorrono determinate condizioni, l’Utente può richiedere la
                            cancellazione dei propri Dati da parte del Titolare.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>ricevere i propri Dati o farli trasferire ad altro titolare.</b>{' '}
                            L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di
                            uso comune e leggibile da dispositivo automatico e, ove tecnicamente
                            fattibile, di ottenerne il trasferimento senza ostacoli ad un altro
                            titolare. Questa disposizione è applicabile quando i Dati sono trattati
                            con strumenti automatizzati ed il trattamento è basato sul consenso
                            dell’Utente, su un contratto di cui l’Utente è parte o su misure
                            contrattuali ad esso connesse.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            <b>proporre reclamo.</b> L’Utente può proporre un reclamo all’autorità
                            di controllo della protezione dei dati personali competente o agire in
                            sede giudiziale.
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1">Dettagli sul diritto di opposizione</Typography>
                <Typography paragraph variant="body2">
                    Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di
                    pubblici poteri di cui è investito il Titolare oppure per perseguire un
                    interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al
                    trattamento per motivi connessi alla loro situazione particolare.
                </Typography>
                <Typography paragraph variant="body2">
                    Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di
                    marketing diretto, possono opporsi al trattamento senza fornire alcuna
                    motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing
                    diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo
                    documento.
                </Typography>
                <Typography variant="body1">Come esercitare i diritti</Typography>
                <Typography paragraph variant="body2">
                    Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una
                    richiesta agli estremi di contatto del Titolare indicati in questo documento. Le
                    richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve
                    tempo possibile, in ogni caso entro un mese.
                </Typography>
                <Typography variant="h6" component="h2">
                    Cookie Policy
                </Typography>
                <Typography paragraph variant="body2">
                    Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di
                    più, l’Utente può consultare la{' '}
                    <Typography
                        variant="body2"
                        display="inline"
                        classes={{
                            root: classes.link,
                        }}
                    >
                        <a href="/cookie-policy" target="_blank" rel="noreferrer">
                            Cookie Policy
                        </a>
                    </Typography>
                    .
                </Typography>
                <Typography variant="h6" component="h2">
                    Ulteriori informazioni sul trattamento
                </Typography>
                <Typography variant="body1">Difesa in giudizio</Typography>
                <Typography paragraph variant="body2">
                    I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in
                    giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la
                    difesa da abusi nell'utilizzo di questa Applicazione o dei Servizi connessi da
                    parte dell’Utente.
                </Typography>
                <Typography paragraph variant="body2">
                    L’Utente dichiara di essere consapevole che il Titolare potrebbe essere
                    obbligato a rivelare i Dati per ordine delle autorità pubbliche.
                </Typography>
                <Typography variant="body1">Informative specifiche</Typography>
                <Typography paragraph variant="body2">
                    Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa
                    privacy policy, questa Applicazione potrebbe fornire all'Utente delle
                    informative aggiuntive e contestuali riguardanti Servizi specifici, o la
                    raccolta ed il trattamento di Dati Personali.
                </Typography>
                <Typography variant="body1">Log di sistema e manutenzione</Typography>
                <Typography paragraph variant="body2">
                    Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione
                    e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di
                    sistema, ossia file che registrano le interazioni e che possono contenere anche
                    Dati Personali, quali l’indirizzo IP Utente.
                </Typography>
                <Typography variant="body1">Informazioni non contenute in questa policy</Typography>
                <Typography paragraph variant="body2">
                    Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno
                    essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando
                    gli estremi di contatto.
                </Typography>
                <Typography variant="body1">Risposta alle richieste “Do Not Track”</Typography>
                <Typography paragraph variant="body2">
                    Questa Applicazione non supporta le richieste “Do Not Track”. Per scoprire se
                    gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è
                    invitato a consultare le rispettive privacy policy.
                </Typography>
                <Typography variant="body1">Modifiche a questa privacy policy</Typography>
                <Typography paragraph variant="body2">
                    Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla
                    presente privacy policy in qualunque momento notificandolo agli Utenti su questa
                    pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e
                    legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli
                    estremi di contatto di cui è in possesso. Si prega dunque di consultare con
                    frequenza questa pagina, facendo riferimento alla data di ultima modifica
                    indicata in fondo.
                </Typography>
                <Typography paragraph variant="body2">
                    Qualora le modifiche interessino trattamenti la cui base giuridica è il
                    consenso, il Titolare provvederà a raccogliere nuovamente il consenso
                    dell’Utente, se necessario.
                </Typography>
                <hr />
                <Typography variant="h6" component="h2">
                    Definizioni e riferimenti legali
                </Typography>
                <Typography variant="body1">Dati Personali (o Dati)</Typography>
                <Typography paragraph variant="body2">
                    Costituisce dato personale qualunque informazione che, direttamente o
                    indirettamente, anche in collegamento con qualsiasi altra informazione, ivi
                    compreso un numero di identificazione personale, renda identificata o
                    identificabile una persona fisica.
                </Typography>
                <Typography variant="body1">Dati di Utilizzo</Typography>
                <Typography paragraph variant="body2">
                    Sono le informazioni raccolte automaticamente attraverso questa Applicazione
                    (anche da applicazioni di parti terze integrate in questa Applicazione), tra
                    cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che
                    si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform
                    Resource Identifier), l’orario della richiesta, il metodo utilizzato
                    nell’inoltrare la richiesta al server, la dimensione del file ottenuto in
                    risposta, il codice numerico indicante lo stato della risposta dal server (buon
                    fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e
                    del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali
                    della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli
                    relativi all’itinerario seguito all’interno dell’Applicazione, con particolare
                    riferimento alla sequenza delle pagine consultate, ai parametri relativi al
                    sistema operativo e all’ambiente informatico dell’Utente.
                </Typography>
                <Typography variant="body1">Utente</Typography>
                <Typography paragraph variant="body2">
                    L'individuo che utilizza questa Applicazione che, salvo ove diversamente
                    specificato, coincide con l'Interessato.
                </Typography>
                <Typography variant="body1">Interessato</Typography>
                <Typography paragraph variant="body2">
                    La persona fisica cui si riferiscono i Dati Personali.
                </Typography>
                <Typography variant="body1">
                    Responsabile del Trattamento (o Responsabile)
                </Typography>
                <Typography paragraph variant="body2">
                    La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente
                    che tratta dati personali per conto del Titolare, secondo quanto esposto nella
                    presente privacy policy.
                </Typography>
                <Typography variant="body1">Titolare del Trattamento (o Titolare)</Typography>
                <Typography paragraph variant="body2">
                    La persona fisica o giuridica, l'autorità pubblica, il servizio o altro
                    organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi
                    del trattamento di dati personali e gli strumenti adottati, ivi comprese le
                    misure di sicurezza relative al funzionamento ed alla fruizione di questa
                    Applicazione. Il Titolare del Trattamento, salvo quanto diversamente
                    specificato, è il titolare di questa Applicazione.
                </Typography>
                <Typography variant="body1">Questa Applicazione</Typography>
                <Typography paragraph variant="body2">
                    Lo strumento hardware o software mediante il quale sono raccolti e trattati i
                    Dati Personali degli Utenti.
                </Typography>
                <Typography variant="body1">Servizio</Typography>
                <Typography paragraph variant="body2">
                    Il Servizio fornito da questa Applicazione così come definito nei relativi
                    termini (se presenti) su questo sito/applicazione.
                </Typography>
                <Typography variant="body1">Unione Europea (o UE)</Typography>
                <Typography paragraph variant="body2">
                    Salvo ove diversamente specificato, ogni riferimento all’Unione Europea
                    contenuto in questo documento si intende esteso a tutti gli attuali stati membri
                    dell’Unione Europea e dello Spazio Economico Europeo.
                </Typography>
                <Typography variant="body1">Cookie</Typography>
                <Typography paragraph variant="body2">
                    I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di
                    dati conservate all'interno del browser dell'Utente.
                </Typography>
                <Typography variant="body1">Strumento di Tracciamento</Typography>
                <Typography paragraph variant="body2">
                    Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie,
                    identificativi univoci, web beacons, script integrati, e-tag e fingerprinting -
                    che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando
                    informazioni sul dispositivo dell’Utente.
                </Typography>
                <Typography variant="body1">Riferimenti legali</Typography>
                <Typography paragraph variant="body2">
                    La presente informativa privacy è redatta sulla base di molteplici ordinamenti
                    legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
                </Typography>
                <Typography paragraph variant="body2">
                    Ove non diversamente specificato, questa informativa privacy riguarda
                    esclusivamente questa Applicazione.
                </Typography>
            </Paper>
        </Container>
    )
}
