import React from 'react'
import {
    OutlinedInput,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
    field: {
        marginBottom: theme.spacing(),
    },
}))

export default function PasswordField({
    label,
    name,
    inputRef,
    notchedAreaWidth,
    error = false,
    disabled = false,
    onChange,
}) {
    const classes = useStyles()
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)

    function toggleShowPassword() {
        setIsPasswordVisible(!isPasswordVisible)
    }

    return (
        <FormControl variant="outlined" error={error}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                name={name}
                inputRef={inputRef}
                type={isPasswordVisible ? 'text' : 'password'}
                labelWidth={notchedAreaWidth}
                className={classes.field}
                disabled={disabled}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={toggleShowPassword}
                        >
                            {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}
