import {
    textType,
    textareaType,
    attachmentType,
    createFieldArray,
    coordinateType,
    createSelect,
    booleanType,
} from './utils'
import { structureTypes } from "../constants";

export const link_field = {
    _type: 'linkField',
    __meta: { hideGroupUI: false },
    description: {
        ...textType,
        __meta: { required: true },
    },
    link: {
        ...textType,
        __meta: { required: true },
    },
}
export const informazioni_organizzative_field = {
    _type: 'group',
    necessita_drone: booleanType,
    fornitore_drone: textType,
    area_open: booleanType,
    necessita_sfalcio: booleanType,
    intradosso_non_visibile_presenza_pelo_libero_acqua: {
        ...booleanType,
        label: 'intradosso_non_visibile\npresenza_pelo_libero_acqua',
    },
    possibilita_neve: booleanType,
    non_valutabilita: {
        _type: 'group',
        non_valutabile: booleanType,
        note: textareaType,
    },
}

export const procedure_creation_config = {
    type: {
        _type: 'buttonGroup',
        buttons: structureTypes.map((structureType) => ({ value: structureType })),
        label: 'tipo',
        __meta: { required: true },
    },
    name: { ...textType, __meta: { required: true } },
    internal_protocol_identifier: textType,
    description: textareaType,
}

export const procedure_edit_config = {
    image: {
        ...attachmentType,
        useDialog: false,
        acceptedFiles: ['image/png', 'image/jpeg', 'image/jpg'],
    },
    description: textareaType,
    // informazioni_organizzative: informazioni_organizzative_field,
    // external_links: createFieldArray(link_field)
}


export const building_place_fields = {
    gps_position: {
        ...coordinateType,
        __meta: { required: true },
    },
    place: {
        _type: 'group',
        __meta: { hideGroupUI: true },
        street: { ...textType, __meta: { required: true } },
        street_number: { ...textType, __meta: { required: true } },
        city: { ...textType, __meta: { required: true } },
        suburb: textType,
        asset_code: { ...textType, __meta: { required: true } },
        province: { ...textType, __meta: { required: true } },
        country: { ...textType, __meta: { required: true } },
        build_position: {
            ...createSelect(['ISOLATO', 'INTERNO', 'ESTREMITA', 'ANGOLO']),
            __meta: { required: true },
        },
        cadastral_data: {
            _type: 'onlyUIGroup',
            paper: { ...textType, __meta: { required: true } },
            attachment: { ...textType, __meta: { required: true } },
            particle: { ...textType, __meta: { required: true } },
        },
    },
}

export const building_material_field = {
    building_material: {
        ...createSelect(['REINFORCED_CONCRETE', 'PREFABRICATED', 'MASONRY']),
        __meta: { required: true },
    },
    customer_name: textType,
}

export const building_edit_fields = {
    cadastral_maps: createFieldArray({
        allegato: attachmentType,
    }),

    files: createFieldArray({
        allegato: attachmentType,
    }),
}

export const building_creation_fields = {
    ...building_place_fields,
    ...building_material_field
}

export const bridge_creation_fields = {
    gps_position: {
        ...coordinateType,
        __meta: { required: true },
    },
    address: textType,
}


export const windmill_creation_fields = {
    ...bridge_creation_fields,
    type_specific_fields: {
        _type: 'group',
        __meta: {hideGroupUI: true},
        impianto: { ...textType, __meta: { required: true } },
        id_turbina: { ...textType, __meta: { required: true } },
        modello_turbina: textType,
        documentazione: createFieldArray({
            allegato: attachmentType,
        }),

    },
}


export const bridge_update_fields = {
    ...procedure_edit_config,
    ...bridge_creation_fields
}

export const building_update_fields = {
    ...procedure_edit_config,
    ...building_edit_fields,
    ...building_material_field,
}

export const windmill_update_fields = {
    ...procedure_edit_config,
    ...windmill_creation_fields
}

export const procedure_update_fields_by_type = {
    bridge: bridge_update_fields,
    building: building_update_fields,
    windmill: windmill_update_fields
}