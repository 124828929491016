import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CookieConsent from 'react-cookie-consent'
import { useMutation } from 'react-query'
import {
    Container,
    TextField,
    Grid,
    Button,
    Typography,
    Box,
    CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PasswordField from '../components/FormItems/PasswordField'
import Section from '../components/Section'
import { queryClient, loginQueries } from '../networking'
import FibaLogoPayoff from '../assets/Fiba_logo_payoff.png'
import FibaLogoPayoffColored from '../assets/Fiba_logo_payoff_colored.png'
import FibaBackground from '../assets/Fiba_background_colors.png'
import { backend_url, environment } from '../constants'
import { useThemeStore } from '../stateManagement'
import TerminiUso from '../assets/DISCLAIMER_USO_PIATTAFORMA_SW_BUILTI.pdf'

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    usernameField: {
        marginBottom: theme.spacing(),
    },
    img: {
        width: '45%',
        marginTop: 20,
        marginBottom: 40,
    },
    coloredBorder: {
        position: 'relative',
        padding: 20,
        borderRadius: theme.shape.borderRadius,
        background: `url(${FibaBackground}) center/cover no-repeat`,
    },
    forgetPasswordLink: {
        alignSelf: 'flex-end',
        marginTop: 10,
        color: theme.palette.text.secondary,
    },
    consentText: {
        fontSize: 12,
    },
    consentLink: {
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
    cookieLink: {
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
    cookieBtnContainer: {
        alignSelf: 'center',
        margin: 5,
    },
    bannerContainer: {
        padding: theme.spacing(2),
    },
    cookieText: {
        margin: '5px !important',
    },
}))

export default function LoginPage() {
    const classes = useStyles()
    const { t } = useTranslation('login')
    const themeMode = useThemeStore((state) => state.themeMode)
    const history = useHistory()
    const { register, handleSubmit } = useForm()
    const [loginError, setLoginError] = React.useState(false)

    const loginMutation = useMutation(loginQueries.login.fn, {
        onSuccess: enterFiba,
        onError: showLoginError,
    })

    const loginAsAdminMutation = useMutation(loginQueries.loginAsAdmin.fn, {
        onSuccess: enterFiba,
        onError: showLoginError,
    })
    const loginAsMemberMutation = useMutation(loginQueries.loginAsMember.fn, {
        onSuccess: enterFiba,
        onError: showLoginError,
    })
    const loginAsUserMutation = useMutation(loginQueries.loginAsUser.fn, {
        onSuccess: enterFiba,
        onError: showLoginError,
    })

    function enterFiba() {
        const params = new URLSearchParams(window.location.search)
        const path = params.get('redirect') || '/home'
        queryClient.invalidateQueries()
        history.push(path)
    }

    function showLoginError(err) {
        if (err.response.status === 401) setLoginError(err)
        else alert(err)
    }
    function sendData(data) {
        loginMutation.mutate(data)
    }
    const isLoginPending =
        loginMutation.isLoading ||
        loginAsAdminMutation.isLoading ||
        loginAsMemberMutation.isLoading ||
        loginAsUserMutation.isLoading

    return (
        <>
            <Container maxWidth="xs" className={classes.container}>
                <Section
                    direction="column"
                    wrap="nowrap"
                    alignItems="center"
                    className={classes.coloredBorder}
                >
                    <img
                        src={themeMode === 'dark' ? FibaLogoPayoff : FibaLogoPayoffColored}
                        alt="Fiba logo"
                        className={classes.img}
                    />

                    <form className={classes.form} onSubmit={handleSubmit(sendData)}>
                        <Grid container direction="column" wrap="nowrap">
                            <TextField
                                variant="outlined"
                                label={t('email')}
                                name="email"
                                inputRef={register}
                                className={classes.usernameField}
                                error={loginError}
                                disabled={isLoginPending}
                                onChange={() => setLoginError(false)}
                            />
                            <PasswordField
                                label={t('password')}
                                name="password"
                                inputRef={register}
                                notchedAreaWidth={72}
                                error={loginError}
                                disabled={isLoginPending}
                                onChange={() => setLoginError(false)}
                            />
                        </Grid>
                        {loginError && (
                            <Typography color="error" gutterBottom>
                                {t('login_error_message')}
                            </Typography>
                        )}

                        {/* Privacy policy and Terms of use */}
                        <Box marginY={0.7}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                display="inline"
                                className={classes.consentText}
                            >
                                {t('login_consent_text_1')}
                            </Typography>
                            <Typography
                                variant="body2"
                                display="inline"
                                className={classes.consentText}
                                classes={{
                                    root: classes.consentLink,
                                }}
                            >
                                <a href="/privacy-policy" target="_blank" rel="noreferrer">
                                    {t('informativa_privacy')}
                                </a>
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                display="inline"
                                className={classes.consentText}
                            >
                                {t('login_consent_text_2')}
                            </Typography>
                            <Typography
                                variant="body2"
                                display="inline"
                                className={classes.consentText}
                                classes={{
                                    root: classes.consentLink,
                                }}
                            >
                                <a href={TerminiUso} target="_blank" rel="noreferrer">
                                    {t('termini_condizioni_uso')}
                                </a>
                            </Typography>
                            .
                        </Box>

                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{ marginTop: 8 }}
                            disabled={isLoginPending}
                        >
                            {isLoginPending && (
                                <CircularProgress
                                    size={18}
                                    style={{ marginRight: 8 }}
                                    color="inherit"
                                />
                            )}
                            {t('login')}
                        </Button>
                    </form>

                    <Button
                        className={classes.forgetPasswordLink}
                        size="small"
                        href={new URL('/reset', backend_url)}
                        target="_blank"
                    >
                        {t('forgot_password')}
                    </Button>
                </Section>
            </Container>
            <CookieConsent
                buttonText={t('cookies_understood')}
                // debug
                overlay
                disableButtonStyles
                containerClasses={classes.bannerContainer}
                contentClasses={classes.cookieText}
                buttonWrapperClasses={classes.cookieBtnContainer}
                ButtonComponent={({ children, ...props }) => (
                    <Button {...props} variant="contained" color="secondary">
                        {children}
                    </Button>
                )}
            >
                <Typography variant="body1">{t('cookies_consent')}</Typography>
                <Typography
                    variant="body1"
                    classes={{
                        root: classes.cookieLink,
                    }}
                >
                    <a href={'/cookie-policy'} target="_blank" rel="noreferrer">
                        {t('cookie_policy_link')}
                    </a>
                </Typography>
            </CookieConsent>
            {/*{!['prod', 'staging'].includes(environment) && (*/}
            {/*    <Grid container justifyContent="center" style={{ marginTop: 30 }}>*/}
            {/*        <Button*/}
            {/*            variant="outlined"*/}
            {/*            onClick={loginAsAdminMutation.mutate}*/}
            {/*            size="small"*/}
            {/*            style={{ marginRight: 8 }}*/}
            {/*        >*/}
            {/*            login super admin*/}
            {/*        </Button>*/}
            {/*        <Button*/}
            {/*            variant="outlined"*/}
            {/*            onClick={loginAsMemberMutation.mutate}*/}
            {/*            size="small"*/}
            {/*            style={{ marginRight: 8 }}*/}
            {/*        >*/}
            {/*            login member*/}
            {/*        </Button>*/}
            {/*        <Button variant="outlined" onClick={loginAsUserMutation.mutate} size="small">*/}
            {/*            login user*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*)}*/}
        </>
    )
}
