import { attachmentType, createFieldArray, textareaType } from './utils'

export const resisto_vulnerabilità_locali_fields = {
    existence: {
        _type: 'boolean',
        variant: 'columnInlineWide',
        label: 'vulnerabilità_locali',
    },
    description: textareaType,
    images: createFieldArray({
        ...attachmentType,
        __meta: { hideGroupUI: true },
    }),
}
