import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'

export default function CustomTable({ headers, data, renderRow, ...props }) {
    const { t } = useTranslation()
    return (
        <TableContainer component={Paper} {...props}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers.map((h) => (
                            <TableCell key={h}>{t(h)}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{data.map((row) => renderRow(row))}</TableBody>
            </Table>
        </TableContainer>
    )
}
