import React from 'react'
import { TextField, Grid, InputAdornment, Tooltip, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AppSelect from './AppSelect'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'

export default function YearField({
    selectLabel,
    selectOptions,
    onChange,
    // defaultValue,
    value: propValue,
    className,
    disableAll = false,
}) {
    const valueToUse = propValue
    const { t } = useTranslation()

    const [localValue, setLocalValue] = React.useState(valueToUse)

    function handleBlur() {
        onChange(localValue)
    }

    function changeYear({ target: { value } }) {
        setLocalValue({
            anno: value,
            anno_tipo: localValue.anno_tipo,
        })
    }

    function changeYearType({ target: { value } }) {
        setLocalValue({
            anno: localValue.anno,
            anno_tipo: value,
        })
        onChange({
            anno: localValue.anno,
            anno_tipo: value,
        })
    }

    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    className={className}
                    type="number"
                    variant="outlined"
                    label={t('anno')}
                    onChange={changeYear}
                    onBlur={handleBlur}
                    value={localValue?.anno || ''}
                    disabled={disableAll}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <AppSelect
                    className={className}
                    optionsArray={selectOptions}
                    fieldLabel={t(selectLabel)}
                    onChange={changeYearType}
                    value={localValue?.anno_tipo ?? ''}
                    disableAll={disableAll}
                />
            </Grid>
        </>
    )
}
