import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import _ from 'lodash'
import AppForm from '../AppForm'
import Section from '../Section'
import DynamicFormFields from '../FormItems/DynamicFormFields'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { numberType } from '../../FormConfigs/utils'
import Check from '@material-ui/icons/Check'
import { useUserProfileStore } from '../../stateManagement'

const methodologiesValuesConfig = {
    mit_guidelines: {
        soglia_esiguo_elevato_percentuale: { ...numberType, min: 1 },
    },
}

export default function CompanyMethodologiesSettingsSection({ company, mutation }) {
    const { t } = useTranslation()
    const methods = useForm({ defaultValues: company.settings.methodologies })
    const profile = useUserProfileStore((state) => state.profile)
    const isReader = company.readers.map((reader) => reader.id).includes(profile.id)

    function onSubmit(data) {
        mutation.mutate(data)
    }

    return (
        <Section xs={12} direction="column" innerSpacing={4}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="center">
                    {t('company_configuratore_valori_speciali_metodologie')}
                </Typography>
                <Typography variant="caption" component="h2" align="center">
                    {t('company_configuratore_valori_speciali_metodologie_detail')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <AppForm methods={methods} onSubmit={onSubmit}>
                    {_.map(methodologiesValuesConfig, (methConfig, methodologyName) => (
                        <React.Fragment key={methodologyName}>
                            <Typography variant="caption" component="h2" align="center">
                                {t(methodologyName)}
                            </Typography>
                            <DynamicFormFields
                                fieldsKey={methodologyName}
                                formFields={methConfig}
                                disableAll={isReader}
                            />
                        </React.Fragment>
                    ))}
                    {!isReader && (
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            startIcon={<Check />}
                        >
                            {t('conferma')}
                        </Button>
                    )}
                </AppForm>
            </Grid>
        </Section>
    )
}
