import React from 'react'
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
} from '@material-ui/core'
import {initReactI18next, useTranslation} from 'react-i18next'
import {useMutation} from "react-query";
import {dynamicTranslationQueries} from "../../networking";
import i18next from "i18next";
import i18n from "i18next";

const FieldInfoDialog = ({ translationKey, initialText, onClose  }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = React.useState(false)
    const updateTranslation = useMutation(dynamicTranslationQueries.updateTranslation.name, dynamicTranslationQueries.updateTranslation.fn, {
        onSubmit: () => {
            setIsLoading(true)
        },
        onSuccess: () => {
            i18next.reloadResources("it", "form_info").then(() => {
                setIsLoading(false)
                handleClose()
            })
            // i18n.use(initReactI18next)
        },
    })
    const [text, setText] = React.useState("")
    const handleInfoChange = React.useCallback((event) => setText(event.target.value), [])
    const handleClose = onClose
    const handleSubmit = React.useCallback(() => {
        updateTranslation.mutate({language: "it", namespace: "form_info", key: translationKey, value: text})
    }, [translationKey, text, updateTranslation])
    const isOpen = Boolean(translationKey)

    React.useEffect(() => {setText(initialText)}, [translationKey, initialText])

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault()
                    handleClose()
                },
            }}
        >
            <DialogTitle>{t('description')}</DialogTitle>
            <DialogContent>
                <TextField
                    id="name"
                    type="text"
                    multiline
                    value={text}
                    onChange={handleInfoChange}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} /> : null}
                    onClick={handleClose}
                >
                    {t('annulla')}
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="secondary">
                    {t('conferma')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FieldInfoDialog
