import { useProceduresPanelStore } from '../../stateManagement'
import { getFiltersParam } from '../../utils'
import { useQuery } from 'react-query'
import axios from 'axios'
import useCompanyId from '../useCompanyId'
import useProcedureId from '../useProcedureId'
import { useStoredFilters } from '../storedFiltersContext'
import { proceduresQueries } from "../../networking";

export function useQueryFilteredProcedures() {
    const [
        filters,
        activeStructureTypes,
        // companyId,
        // setFilteredProcedures
    ] = useStoredFilters((state) => [
        state.filters,
        state.activeStructureTypes,
        // state.companyId,
        // state.setFilteredProcedures,
    ])
    const { companyId } = useCompanyId()
    const params =
        'company_id=' + (companyId || '') + '&' + getFiltersParam(filters, activeStructureTypes)
    return useQuery(
        ['getProcedures', params],
        async () => (await axios.get('/procedures?' + params)).data,
        {
            // enabled: !!companyId, why?
            // onSuccess: (data) => {
            //     setFilteredProcedures(data)
            //     return data
            // },
        }
    )
}

export function useQueryAllProcedures() {
    const [
        activeStructureTypes,
        // companyId
    ] = useStoredFilters((state) => [
        state.activeStructureTypes,
        // state.companyId,
    ])
    const { companyId } = useCompanyId()
    const setTotCount = useProceduresPanelStore((state) => state.setTotCount)
    const params =
        'company_id=' +
        (companyId || '') +
        '&' +
        getFiltersParam({ rules: {} }, activeStructureTypes)
    return useQuery(
        ['getProcedures', params],
        async () => (await axios.get('/procedures?' + params)).data,
        {
            onSuccess: (data) => {
                setTotCount(data.length)
                return data
            },
        }
    )
}

export function useQueryProcedure() {
    const { procedureId } = useProcedureId()
    return useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        async () => (await axios.get(`/procedures/${procedureId}`)).data,
        {
            enabled: !!procedureId,
        }
    )
}

export function useQueryProcedureInspections() {
    const params =
        'company_id=&' +
        getFiltersParam({ rules: { next_inspection_date: [{ operatore: 'ne', valore: null }] } })
    return useQuery(
        [proceduresQueries.getProcedures.name, params],
        async () => (await axios.get('/procedures?' + params)).data
    )
}
