import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { Container, Paper, Button, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import DynamicFormFields from '../components/FormItems/DynamicFormFields'
import AppForm from '../components/AppForm'
import { proceduresQueries, queryClient } from '../networking'
import { useSnackBarStore, usePlaceStore, useUserProfileStore } from '../stateManagement'
import {
    procedure_creation_config,
    bridge_creation_fields,
    building_creation_fields,
    windmill_creation_fields
} from "../FormConfigs/procedure_config";
import { autofillAddressRelatedFields } from '../utils'
import { structureTypes } from "../constants";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(3),
    },
    submit: {
        marginTop: theme.spacing(2),
    },
}))

const AssetTypeEnum = Object.fromEntries(structureTypes.map(structureType => [structureType.toUpperCase(), structureType]))

const assetTypeValues = Object.values(AssetTypeEnum)

export default function CreateProcedurePage() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const companyId = location.state.companyId
    const showSnackBar = useSnackBarStore((state) => state.show)
    const place = usePlaceStore((state) => state.place)
    const setPlace = usePlaceStore((state) => state.setPlace)
    const isTranslationManager = useUserProfileStore((state) => Boolean(state.isTranslationManager))

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: { type: 'bridge' },
    })
    const watchType = useWatch({
        control: methods.control,
        name: 'type',
    })

    React.useEffect(() => {
        autofillAddressRelatedFields(watchType, place, methods.setValue)
    }, [place])

    // leave them like this
    //https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    const createProcedureMutation = useMutation(proceduresQueries.createProcedure.fn, {
        onSuccess: () => {
            queryClient.invalidateQueries(proceduresQueries.getProcedures.name)
            history.push('/home')
            setPlace(null)
        },
    })

    function sendData(data) {
        // Material-UI doesnt support required prop on ToggleButtonGroup, so validation is added manually
        if (isDirty && isValid && data.type) {
            let adjustedData = { ...data, place: null, owner: companyId }
            if (data.place) {
                adjustedData.place = data.place
            }
            createProcedureMutation.mutate(adjustedData)
        } else {
            showSnackBar({ message: t('missing_required_fields'), severity: 'error' })
        }
    }

    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {t('create_procedure_page.title')}
                </Typography>
                <AppForm onSubmit={sendData} methods={methods}>
                    <Box marginBottom={1.5}>
                        <DynamicFormFields
                            fieldsKey=""
                            formFields={procedure_creation_config}
                            editableInfos={isTranslationManager}
                            formId={`create_procedure_generic`}
                        />
                    </Box>
                    {assetTypeValues.includes(watchType) && (
                        <DynamicFormFields
                            fieldsKey=""
                            formFields={
                                watchType === AssetTypeEnum.BRIDGE
                                    ? bridge_creation_fields
                                : watchType === AssetTypeEnum.BUILDING
                                    ? building_creation_fields
                                    : windmill_creation_fields
                            }
                            editableInfos={isTranslationManager}
                            formId={`create_procedure_${watchType}`}
                        />
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={!isDirty || !isValid}
                        startIcon={<SaveIcon />}
                        className={classes.submit}
                    >
                        {t('salva')}
                    </Button>
                </AppForm>
            </Paper>
        </Container>
    )
}
