import _ from 'lodash'
import {
    attachmentType,
    booleanType,
    coordinateSlmType,
    createFieldArray,
    createSelect,
    dateType,
    documentType,
    textareaType,
    positiveNumberType,
    textType,
    yearType,
    numberType,
    createOptions,
} from './utils'

export const sections_to_render_censimento = [
    'identificazione',
    'localizzazione',
    'fenomeni_erosivi_e_di_alluvionamento',
    'fenomeni_franosi',
    'informazioni_generali',
    'dati_progetto',
    'tutela_ddl_22_01_2004_42',
    'stato_opera',
    'classe_uso',
    'tipologia_ente_scavalcato',
    'strategicita_opera',
    'classificazione_collegamento_uso_stradale',
    'dati_geomorfologici',
    'caratteristiche_geometriche',
    'tipologia_strutturale',
    'schema_strutturale',
    'spalle',
    'pile',
    'impalcato',
    'sistemi_protezione_apparecchi_appoggio',
    'giunti',
    'interventi_strutturali',
    'interventi_manutenzione',
    'ispezioni_pregresse',
    'attivita_monitoraggio',
    'rete_stradale',
    'classi_di_conseguenza',
    'informazioni_per_censimento_ainop',
]

export const sections_to_render_documentazione = [
    'documenti_progettuali',
    'documenti_condizioni_rischio',
]

export const mit_la_0_model = {
    identificazione: {
        codice_iop: { ...textType, info: 'info_iop' },
        strada_di_appartenenza: textType,
        progressiva_km_iniziale: numberType,
        progressiva_km_finale: numberType,
    },
    localizzazione: {
        regione: textType,
        provincia: textType,
        comune: textType,
        localita: textType,
        sismicita_dell_area: {
            _type: 'numberUnit',
            unit: 'a<sub>g</sub>/g',
            step: '0.001',
            min: '0',
            la2_required: true,
        },
        categoria_topografica: {
            ...createSelect(['T1', 'T2', 'T3', 'T4']),
            la2_required: true,
        },
        categoria_sottosuolo: {
            ...createSelect(['A', 'B', 'C', 'D', 'E']),
            la2_required: true,
        },
        gps_centro: coordinateSlmType,
        gps_iniziale: coordinateSlmType,
        gps_finale: coordinateSlmType,
    },
    fenomeni_erosivi_e_di_alluvionamento: createSelect([
        'ASSENTI',
        'DA_VERIFICARE',
        'GIA_VALUTATI',
    ]),
    fenomeni_franosi: createSelect(['ASSENTI', 'DA_VERIFICARE', 'GIA_VALUTATI']),
    informazioni_generali: {
        proprietario: textType,
        concessionario: textType,
        ente_vigilante: textType,
        anno_di_collaudo: yearType,
        anno_ultima_manutenzione_significativa: yearType,
    },
    dati_progetto: {
        progettista: textType,
        norma_di_progetto: textType,
        ente_approvatore: textType,
        anno_inizio_progettazione: { ...yearType, la2_required: true },
        anno_fine_progettazione: yearType,
        anno_approvazione: yearType,
        criteri_progettazione: {
            ...createSelect(['SISMICI', 'NON_SISMICI']),
            la2_required: true,
        },
        categoria_ponte: createSelect([
            { label: 'ponte_categoria_1', value: 'I' },
            { label: 'ponte_categoria_2', value: 'II' },
        ]),
    },
    tutela_ddl_22_01_2004_42: {
        provvedimenti_di_tutela: textType,
        autore_della_progettazione: textType,
        inserimento_ponte_ambito_Piani_Paesaggistici: textType,
    },
    stato_opera: createSelect([
        { label: 'stato_opera_A', value: 'A' },
        { label: 'stato_opera_B', value: 'B' },
        { label: 'stato_opera_C', value: 'C' },
        { label: 'stato_opera_D', value: 'D' },
        { label: 'stato_opera_E', value: 'E' },
    ]),
    classe_uso: {
        ...createSelect(['I', 'II', 'III', 'IV']),
    },
    tipologia_ente_scavalcato: {
        ...createSelect(['ALTA', 'MEDIA', 'BASSA']),
        la2_required: true,
    },
    strategicita_opera: {
        ...createSelect([
            { label: 'strategicita_opera_STRATEGICA', value: 'STRATEGICA' },
            { label: 'strategicita_opera_NOTSTRATEGICA', value: 'NOTSTRATEGICA' },
        ]),
        la2_required: true,
    },
    classificazione_collegamento_uso_stradale: {
        tipo_collegamento: createSelect([
            'PONTE_CORSO_ACQUA_RETICOLO_PRINCIPALE',
            'PONTE_CORSO_ACQUA_RETICOLO_SECONDARIO',
            'PONTE_SPECCHI_ACQUA_MARINI',
            'VIADOTTO_ZONA_EDIFICATA',
            'VIADOTTO_ZONA_URBANIZZATA',
            'VIADOTTO_ALTRA_VIA_COMUNICAZIONE',
            'PONTE_VIADOTTO_DISCONTINUITA_OROGRAFICA',
            'PONTE_FERROVIA',
        ]),
        classificazione_uso_stradale: createSelect([
            'AUTOSTRADA_FERROVIA',
            'STRADA_EXTRAURBANA_PRINCIPALE',
            'STRADA_EXTRAURBANA_SECONDARIA',
            'STRADA_URBANA_SCORRIMENTO',
            'STRADA_URBANA_QUARTIERE',
            'STRADA_LOCALE',
        ]),
    },
    dati_geomorfologici: {
        morfologia_del_sito: createSelect([
            'CRESTA',
            'PENDIO_DOLCE',
            'PENDIO_MODERATO',
            'PENDIO_RIPIDO',
            'PIANURA',
            'PIANURA_BASE_VERSANTI',
        ]),
    },
    caratteristiche_geometriche: {
        luce_complessiva: {
            _type: 'numberUnit',
            unit: 'm',
        },
        n_campate: { ...positiveNumberType, la2_required: true },
        larghezza_impalcato: {
            _type: 'numberUnit',
            unit: 'm',
        },
        luce_campate: {
            ...createFieldArray({
                _type: 'numberUnit',
                unit: 'm',
                defaultValue: { value: null, unit: 'm' }, // I think it's only needed in fieldArray
                label: 'luce_campata', // needed in fieldArrays, can't infer it.
            }),
            la2_required: true,
        },
        use_custom_luce_media: booleanType,
        custom_luce_media: {
            ...numberType,
            optional: true,
            watchConfig: {
                name: 'caratteristiche_geometriche.use_custom_luce_media',
                cases: {
                    true: { disabled: false },
                    false: { disabled: true },
                    null: { disabled: true },
                },
            },
        },
        tracciato: createSelect(['RETTILINEO', 'IN_CURVA']),
    },
    tipologia_strutturale: {
        _type: 'group',
        __meta: {
            hideGroupUI: true,
        },
        tipologia: createSelect([
            'ARCO_MURATURA',
            'TRAVATE_APPOGGIATE',
            'TRAVATE_CONTINUE',
            'SOLETTA_CA',
            'SEZIONE_TUBOLARE_CA',
            'ARCO_CA',
            'TRAVATE_GERBER',
            'CASSONE_PRECOMPRESSO',
            'SEZIONE_TUBOLARE_ACCIAIO',
            'ARCO_ACCIAIO',
            'STRALLATO_SOSPESO',
            'ALTRO',
            'TRAVATE_CAP_CAVI_POST_TESI',
        ]),
        altro: {
            ...textType,
            optional: true,
            watchConfig: {
                name: 'tipologia_strutturale.tipologia',
                cases: {
                    ARCO_MURATURA: { disabled: true },
                    TRAVATE_APPOGGIATE: { disabled: true },
                    TRAVATE_CONTINUE: { disabled: true },
                    SOLETTA_CA: { disabled: true },
                    SEZIONE_TUBOLARE_CA: { disabled: true },
                    ARCO_CA: { disabled: true },
                    TRAVATE_GERBER: { disabled: true },
                    CASSONE_PRECOMPRESSO: { disabled: true },
                    SEZIONE_TUBOLARE_ACCIAIO: { disabled: true },
                    ARCO_ACCIAIO: { disabled: true },
                    STRALLATO_SOSPESO: { disabled: true },
                    ALTRO: { disabled: false },
                    TRAVATE_CAP_CAVI_POST_TESI: { disabled: true },
                },
            },
        },
    },
    schema_strutturale: {
        ...createSelect(['SCHEMA_ISOSTATICO', 'SCHEMA_IPERSTATICO']),
        la2_required: true,
    },
    spalle: {
        tipologia_spalla_iniziale: textType,
        tipologia_spalla_finale: textType,
        fondazioni_spalla_iniziale: textType,
        fondazioni_spalla_finale: textType,
    },
    pile: {
        materiale_costruttivo: {
            _type: 'group',
            materiale: createSelect([
                'MURATURA',
                'CA',
                'CAP',
                'ACCIAIO',
                'MISTO',
                'LEGNO',
                'ALTRO',
            ]),
            altro: {
                ...textType,
                optional: true,
                watchConfig: {
                    name: 'pile.materiale_costruttivo.materiale',
                    cases: {
                        MURATURA: { disabled: true },
                        CA: { disabled: true },
                        CAP: { disabled: true },
                        ACCIAIO: { disabled: true },
                        MISTO: { disabled: true },
                        LEGNO: { disabled: true },
                        ALTRO: { disabled: false },
                    },
                },
            },
        },
        tipologie_sezione: textType,
        geometria_sezione: textType,
        tipologia_fondazioni: textType,
        n_fondazioni: positiveNumberType,
        altezza_pile: {
            _type: 'numberUnit',
            unit: 'm',
        },
        evoluzione_rispetto_al_fondo_alveo: textType,
    },
    impalcato: {
        materiale_costruttivo: {
            _type: 'group',
            materiale: {
                ...createSelect([
                    'MURATURA',
                    'CA',
                    'CAP',
                    'ACCIAIO',
                    { value: 'MISTO', label: 'MISTO_CA_ACCIAIO' },
                    'LEGNO',
                    'ALTRO',
                ]),
                la2_required: true,
            },
            altro: {
                ...textType,
                optional: true,
                watchConfig: {
                    name: 'impalcato.materiale_costruttivo.materiale',
                    cases: {
                        MURATURA: { disabled: true },
                        CA: { disabled: true },
                        CAP: { disabled: true },
                        ACCIAIO: { disabled: true },
                        MISTO: { disabled: true },
                        LEGNO: { disabled: true },
                        ALTRO: { disabled: false },
                    },
                },
            },
        },
        tipologia_soletta: {
            _type: 'group',
            tipologia: createSelect([
                'CA',
                'CAP',
                'ACCIAIO',
                { value: 'MISTO', label: 'MISTO_CA_ACCIAIO' },
                'LEGNO',
                'ALTRO',
            ]),
            altro: {
                ...textType,
                optional: true,
                watchConfig: {
                    name: 'impalcato.tipologia_soletta.tipologia',
                    cases: {
                        CA: { disabled: true },
                        CAP: { disabled: true },
                        ACCIAIO: { disabled: true },
                        MISTO: { disabled: true },
                        LEGNO: { disabled: true },
                        ALTRO: { disabled: false },
                    },
                },
            },
        },
    },
    sistemi_protezione_apparecchi_appoggio: {
        tipo_sistemi_protezione: textType,
        larghezza_carreggiata: {
            _type: 'numberUnit',
            unit: 'm',
        },
        tipologia_apparecchi_appoggio: textType,
        tipologia_dispositivi_antisismici: textType,
    },
    giunti: {
        tipologia_giunti: textType,
        numero_totale_giunti: positiveNumberType,
        lunghezza_giunto_spalla: {
            _type: 'numberUnit',
            unit: 'm',
        },
        lunghezza_giunto_pila: {
            _type: 'numberUnit',
            unit: 'm',
        },
    },
    interventi_strutturali: {
        ...createFieldArray({
            tipo: createSelect([
                'VOLTA_MURATURA',
                'RIPARAZIONE_SOSTITUZIONE_ELEMENTI_STRUTTURALI',
                'AMPLIAMENTO_CARREGGIATA_E_STRUTTURE',
                'ELEMENTI_STRUTTURALI_AGGIUNTIVI_CONSOLIDAMENTO',
                'INTERVENTI_CARATTERE_GEOTECNICO_FONDAZIONE_SPALLE',
                'INTERVENTI_MITIGAZIONE_PROTEZIONE_EROSIONE_SPALLE_PILE',
                'ALTRO',
            ]),
            descrizione: textareaType,
        }),
        forceKeepMounted: true,
        __meta: { hideGroupUI: true },
    },
    interventi_manutenzione: {
        stato: createSelect(['PRESENTI', 'ASSENTI', 'NON_NOTI']),
        piano_manutenzione: textType,
        numero_interventi_manutentivi_effettuati: positiveNumberType,
        data_ultimo_intervento: dateType,
        periodicita: textType,
        lista_interventi: createFieldArray({
            data: dateType,
            tipo: textType,
            allegato: attachmentType,
        }),
    },
    ispezioni_pregresse: {
        stato: createSelect(['PRESENTI', 'ASSENTI', 'NON_NOTI']),
        programma_ispezioni: textType,
        numero_ispezioni_effettuate: positiveNumberType,
        data_ultima_ispezione: dateType,
        periodicita: textType,
        lista_ispezioni: createFieldArray({
            data: dateType,
            metodologia: textType,
            allegato: attachmentType,
            ente_ispettivo: textType,
        }),
        risultati_significativi: textareaType,
    },
    attivita_monitoraggio: {
        stato: createSelect(['PRESENTI', 'ASSENTI', 'NON_NOTI']),
        tipo_rilevamento: textType,
        metodologia_monitoraggio: textareaType,
        data_inizio: dateType,
        data_ultimo_aggiornamento: dateType,
        data_fine: dateType,
        tipologia_strumentazione: textType,
        grandezze_misurate: textType,
        risultati_significativi: textareaType,
        livello_allerta: textType,
        documentazione_relativa: attachmentType,
    },
    rete_stradale: {
        itinerario_internazionale: booleanType,
        rete_TEN: booleanType,
        rete_emergenza: booleanType,
        numero_carreggiate: positiveNumberType,
        numero_corsie_per_carreggiate: positiveNumberType,
        presenza_curve: booleanType,
        traffico_medio_giornaliero_intera_carreggiata: {
            _type: 'numberUnit',
            unit: 'veicoli/giorno',
            la2_required: true,
        },
        traffico_medio_giornaliero_veicoli_commerciali_intera_carreggiata: {
            _type: 'numberUnit',
            unit: 'veicoli/giorno',
        },
        traffico_medio_giornaliero_veicoli_commerciali_singola_corsia: {
            _type: 'numberUnit',
            unit: 'veicoli/giorno',
            la2_required: true,
        },
        limitazione_carico: {
            _type: 'numberUnit',
            unit: 'ton',
        },
        trasporto_merci_pericolose: booleanType,
        presenza_alternative_stradali: {
            ...booleanType,
            la2_required: true,
        },
        durata_deviazione_distanza: {
            _type: 'numberUnit',
            unit: 'km',
        },
        durata_deviazione_tempo: {
            _type: 'numberUnit',
            unit: 'min',
        },
        categoria_percorso_alternativo_individuato: textType,
        disponibilita_studi_trasporti_specifici: booleanType,
        disponibilita_studi_trasporti_specifici_allegato: {
            ...attachmentType,
            watchConfig: {
                name: 'rete_stradale.disponibilita_studi_trasporti_specifici',
                cases: {
                    true: { disabled: false },
                    false: { disabled: true },
                    null: { disabled: true },
                },
            },
        },
    },
    classi_di_conseguenza: {
        _type: 'group',
        __meta: {
            hideGroupUI: true,
        },
        classe: createSelect(['CLASSE_1', 'CLASSE_2', 'CLASSE_3']),
        giustificazione: {
            ...textareaType,
            optional: true,
            watchConfig: {
                name: 'classi_di_conseguenza.classe',
                cases: {
                    CLASSE_1: { disabled: false },
                    CLASSE_2: { disabled: false },
                    CLASSE_3: { disabled: true },
                },
            },
        },
    },
    documenti_progettuali: {
        documentazione_iniziale: {
            _type: 'group',
            strumenti_finanziamento: documentType,
            piani_strumenti_programmazione: documentType,
        },
        progetto_preliminare_massima: {
            _type: 'group',
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
            documenti_iter_approvazione: documentType,
        },
        progetto_definitivo_esecutivo: {
            _type: 'group',
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
            documenti_iter_approvazione: documentType,
        },
        progetto_esecutivo_cantierabile: {
            _type: 'group',
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
            documenti_iter_approvazione: documentType,
        },
        documenti_inerenti_direzione_lavori: {
            _type: 'group',
            documenti_contabili: documentType,
            grafici_allegati_contabilita_appalto: documentType,
        },
        documenti_inerenti_realizzazione: {
            _type: 'group',
            documenti_contrattuali: documentType,
            documenti_contabili: documentType,
        },
        varianti_corso_opera: createFieldArray({
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
            documenti_contrattuali_e_contabili: documentType,
        }),
        documenti_inerenti_collaudo: {
            _type: 'group',
            relazione_collaudo: documentType,
            allegati_relazione_collaudo: createFieldArray({
                ..._.omit(documentType, '_type'),
            }),
        },
        interventi_manutenzione: createFieldArray({
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
            documenti_contrattuali_e_contabili: documentType,
        }),
        segnalazioni: createFieldArray({
            documenti_descrittivi_con_date: documentType,
            documenti_grafici_con_date: documentType,
        }),
        progetti_incremento_sicurezza: createFieldArray({
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
        }),
        interventi_incremento_sicurezza: createFieldArray({
            documenti_descrittivi: documentType,
            documenti_grafici: documentType,
        }),
    },
    documenti_condizioni_rischio: {
        rischio_idraulico: createFieldArray(attachmentType),
        rischio_frane: createFieldArray(attachmentType),
        altre_documentazioni: createFieldArray(attachmentType),
    },
    informazioni_per_censimento_ainop: {
        tipologiaOpera: {
            _type: 'select',
            options: createOptions([
                '',
                'PONTE',
                'VIADOTTO',
                'SOTTOVIA',
                'CAVALCAVIA',
                'SOVRAPPASSO',
                'SOTTOPASSO',
                'SOPRAELEVATA',
                'GALLERIA',
                'TOMBINO',
                'CORPO_STRADALE',
                'CORPO_DI_FABBRICA',
                'STAZIONE',
                'FERROVIA',
                'ALTRA_TIPOLOGIA_OPERA',
            ]),
        },
        codiceInfrastruttura: textType,
        nomeOpera: textType,
    },
}
