import React from 'react'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    default: {
        background: theme.palette.background.paperLight,
        color: theme.palette.text.secondary,
    },
    contrast: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
}))

export default function AppTooltip({ children, title, placement = 'bottom', variant = 'default' }) {
    const classes = useStyles()

    return (
        <Tooltip classes={{ tooltip: classes[variant] }} placement={placement} title={title}>
            {children}
        </Tooltip>
    )
}
