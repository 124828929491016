import React from 'react'
import { Paper, Typography, Fab, CircularProgress, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import MethodologyPage from '../MethodologyPage'
import AppForm from '../../components/AppForm'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import {
    resisto_pgad_model,
    resisto_pgad_spettri_model,
} from '../../FormConfigs/resisto_pgad_config'
import { levelsQueries, queryClient } from '../../networking'
import { replaceEmptyStringsWithNullWithin } from '../../FormConfigs/utils'
import SaveIcon from '@material-ui/icons/Save'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
    },
    section: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: '20px',
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

export default function Resisto_PGAD() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
    })

    const { isLoading } = useQuery(
        [levelsQueries.getResistoPGAd.name, procedureId],
        () => levelsQueries.getResistoPGAd.fn(procedureId),
        {
            onSuccess: (data) => methods.reset(data),
        }
    )

    const updateLevelMutation = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'resisto',
                'PGAd',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getResistoPGAd.name),
        }
    )

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    function sendData(data) {
        if (isDirty && isValid) {
            updateLevelMutation.mutate(data)
        }
    }

    return (
        <MethodologyPage methodology="resisto">
            <Paper elevation={0} className={classes.paper}>
                <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    gutterBottom
                    dangerouslySetInnerHTML={{
                        __html: t('pgad'),
                    }}
                />
                <AppForm onSubmit={sendData} methods={methods}>
                    <div className={classes.section} style={{ marginBottom: 16 }}>
                        <DynamicFormFields fieldsKey={''} formFields={resisto_pgad_model} />
                    </div>
                    <div className={classes.section}>
                        <Typography
                            variant="h5"
                            component="h2"
                            align="center"
                            gutterBottom
                            dangerouslySetInnerHTML={{
                                __html: t('calcolo_pgad'),
                            }}
                        />
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{ marginBottom: 25 }}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    gutterBottom
                                    dangerouslySetInnerHTML={{
                                        __html: t('calcolo_pgad_body'),
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    href="http://sicurnet2.cslp.it/Sicurnet2/Normativa/GetAttachment?docnumber=15567163"
                                    startIcon={<GetAppIcon />}
                                >
                                    {t('spettri_file_name')}
                                </Button>
                            </Grid>
                        </Grid>

                        <DynamicFormFields fieldsKey={''} formFields={resisto_pgad_spettri_model} />
                    </div>
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={
                            !isDirty || !isValid || isLoading || updateLevelMutation.isLoading
                        }
                        className={classes.fab}
                    >
                        {updateLevelMutation.isLoading ? (
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                        ) : (
                            <SaveIcon />
                        )}
                        {t('salva')}
                    </Fab>
                </AppForm>
            </Paper>
        </MethodologyPage>
    )
}
