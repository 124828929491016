import React from 'react'
import { useWindowWidthStore } from '../stateManagement'
import { useTheme } from '@material-ui/core/styles'
import { useFormContext } from 'react-hook-form'

export function useShouldLabelCut(translatedLabel) {
    const windowWidth = useWindowWidthStore((state) => state.width)
    const { sm, md } = useTheme().breakpoints.values

    // from 0 to 599 —> max 70 characters
    // from 600 to 798 —> max 36
    // from 799 to 959 —> max 70
    // from 960 —> max 51
    let finalLabel = translatedLabel
    if (windowWidth >= md && translatedLabel.length > 33) {
        finalLabel = translatedLabel.substring(0, 30) + '...'
    } else if (
        ((windowWidth < md && windowWidth >= 799) || windowWidth < sm) &&
        translatedLabel.length > 52
    ) {
        finalLabel = translatedLabel.substring(0, 49) + '...'
    } else if (windowWidth >= sm && windowWidth <= 798 && translatedLabel.length > 18) {
        finalLabel = translatedLabel.substring(0, 15) + '...'
    }
    return finalLabel
}

export function useWatchForAdditionalProps(watchConfig) {
    const [additionalProps, setAdditionalProps] = React.useState({})
    const { watch } = useFormContext()
    const watchedValue = watch(watchConfig?.name || null)
    const casesToWatch = watchConfig?.cases || undefined

    React.useEffect(() => {
        if (!casesToWatch) return
        for (const [triggerValue, caseSettings] of Object.entries(casesToWatch)) {
            if (String(watchedValue) === String(triggerValue)) {
                setAdditionalProps(caseSettings)
                break
            }
        }
    }, [casesToWatch, watchedValue, additionalProps])

    return additionalProps
}

export function replaceEmptyStringsWithNullWithin(obj) {
    const intermediateString = JSON.stringify(obj).replaceAll(':""', ':null')
    return JSON.parse(intermediateString)
}

export function createOptions(options) {
    return options.map((option) => {
        if (typeof option === 'string') {
            return {
                value: option,
            }
        } else {
            const finalOption = {
                value: option.value,
            }
            if (option.label) {
                finalOption.label = option.label
            }
            return finalOption
        }
    })
}

export function createSelect(optionsValues, multiple = false, watchConfig, label) {
    let optionsArray
    if (multiple) {
        optionsArray = createOptions(optionsValues)
    } else {
        optionsArray = [{ value: null, label: '' }, ...createOptions(optionsValues)]
    }

    return {
        _type: 'select',
        options: optionsArray,
        watchConfig,
        multiple,
        label,
    }
}
export function createFieldArray(fields) {
    return {
        _type: 'fieldsArray',
        inputs: fields,
    }
}
export const textType = {
    _type: 'text',
}
export const textareaType = {
    _type: 'text',
    multiline: true,
}
export const emailType = {
    _type: 'email',
}
export const numberType = {
    _type: 'number',
}
export const positiveNumberType = {
    _type: 'number',
    min: 0,
}
export const booleanType = {
    _type: 'boolean',
}
export const dateType = {
    _type: 'date',
}
export const attachmentType = {
    __meta: { hideGroupUI: true },
    _type: 'attachment',
}
export const documentType = {
    _type: 'group',
    presenza: booleanType,
    fonte: textType,
    allegato: attachmentType,
}
export const coordinateType = {
    _type: 'coordinates',
}
export const coordinateSlmType = {
    _type: 'group',
    coords: coordinateType,
    quota_slm: {
        _type: 'numberUnit',
        unit: 'm',
    },
}
export const yearType = {
    _type: 'year',
    anno: numberType,
    anno_tipo: createSelect(['EFFETTIVO', 'PRESUNTO']),
}
export const numberUnitType = (unit) => {
    return {
        _type: 'numberUnit',
        unit: unit,
    }
}

export function noOp() {
    // does nothing intentionally
}
