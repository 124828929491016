import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import PermissionPicker from '../components/Permissions/PermissionPicker'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
}))

export default function ProcedureAccessPage() {
    const classes = useStyles()
    const { procedureId } = useParams()

    return (
        <Container maxWidth="md" className={classes.container}>
            <PermissionPicker entity_type={'procedure'} entity_id={procedureId} />
        </Container>
    )
}
