import React from 'react'
import { Paper, List, ListItem, ListItemText, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.primary.main,
        width: 150
    },
    list: {
        background: theme.palette.primary.main
    },
    root: {
        color: theme.palette.primary.contrastText,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {},
    },
    selected: {},
}))

export default function ResistoToolbar() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const paramsProcedureId = useParams().procedureId
    const currentUrl = useLocation().pathname

    return (
        <Paper className={classes.toolbar} square elevation={3}>
            <List className={classes.list}>

                {/* PANORAMICA */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "panoramica")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/panoramica`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={t("panoramica")}
                        primaryTypographyProps={{
                            className: classes.levelBtn
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* PGAD */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "pgad")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/pgad`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={<div dangerouslySetInnerHTML={{ __html: t("pgad") }} />}
                        primaryTypographyProps={{
                            className: classes.levelBtn

                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* SOPRALLUOGO */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "sopralluogo")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/sopralluogo`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={t("sopralluogo")}
                        primaryTypographyProps={{
                            className: classes.levelBtn
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* LIVELLI */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "livelli/1")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/livelli`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={t("livelli")}
                        primaryTypographyProps={{
                            className: classes.levelBtn
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* SCHEDA GNDT */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "scheda-gndt-II-liv")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/scheda-gndt-II-liv`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={t("scheda_gndt_II_liv")}
                        primaryTypographyProps={{
                            className: classes.levelBtn
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* STATO EDIFICIO */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "stato-edificio")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/stato-edificio`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={t("stato_edificio")}
                        primaryTypographyProps={{
                            className: classes.levelBtn
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* VULNERABILITA' LOCALI */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, "vulnerabilita-locali")}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/resisto/vulnerabilita-locali`)
                    }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary={t("vulnerabilita_locali")}
                        primaryTypographyProps={{
                            className: classes.levelBtn
                        }}
                    />
                </ListItem>
            </List>
        </Paper>
    )
}
