import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import {
    Fab,
    Paper,
    Typography,
    CircularProgress,
    Divider,
    Grid,
    ButtonGroup,
    Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LA3Results from '../../../components/LA3Results'
import AppForm from '../../../components/AppForm'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import useDisableForm from '../../../customHooks/useDisableForm'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import { mit_la_3_model_documents } from '../../../FormConfigs/mit_la_3_config'
import { queryClient, levelsQueries, proceduresQueries } from '../../../networking'
import { snapshotsQueries } from '../../../networking'
import { useSnackBarStore } from '../../../stateManagement'
import _ from 'lodash'
import { usePendingOperationsStore } from '../../../stateManagement'
import { capitalizeFirstLetter, isL3Enabled } from '../../../utils'
import Avvertenze from './Avvertenze'
import FileSaver from 'file-saver'
import { backend_url } from '../../../constants'
import dayjs from 'dayjs'
import {
    Save as SaveIcon,
    Edit as EditIcon,
    Description as DescriptionIcon,
    Done as DoneIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import SocketIOClient from '../../../components/SocketIOClient'
import MethodologyPage from '../../MethodologyPage'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    smallButton: {
        maxWidth: 180,
        height: 40,
        lineHeight: 1.2,
    },
}))

const dataPaths = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    CONFIRMED: 'CONFIRMED',
})

export default function LGP_LA3() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const showSnackBar = useSnackBarStore((state) => state.show)
    const [generationProgress, setGenerationProgress] = React.useState(null)
    const [reportUrl, setReportUrl] = React.useState()
    const [hasUserRequestedDownload, setHasUserRequestedDownload] = React.useState(false)
    const [loadSocketIO, setLoadSocketIO] = React.useState(false)
    const [currentDataPath, setCurrentDataPath] = React.useState({
        inUse: dataPaths.IN_PROGRESS,
        results: null,
        errors: null,
    })

    const { data: lastSnapshot, refetch: refetchLastSnapshot } = useQuery(
        [snapshotsQueries.getLastSnapshot.name, procedureId, 'L3'],
        () => snapshotsQueries.getLastSnapshot.fn(procedureId, 'mit_guidelines', 'L3'),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data?.document) {
                    if (hasUserRequestedDownload) {
                        downloadReport(data.document.depot_url, data.document.name)
                    }
                }
                setReportUrl(data.document?.depot_url)
            },
            onError: (data) => {
                console.log('onError', data)
            },
            onSettled: () => setHasUserRequestedDownload(false),
        }
    )
    function downloadReport(url, fileName) {
        if (url) {
            FileSaver.saveAs(String(new URL(url, backend_url)), fileName)
        } else {
            setHasUserRequestedDownload(true)
            setLoadSocketIO(true)
        }
    }

    const switchToWorkInProgressData = () =>
        setCurrentDataPath({
            inUse: dataPaths.IN_PROGRESS,
            results: la3.results,
            errors: la3.errors,
        })

    const switchToConfirmedData = () =>
        setCurrentDataPath({
            inUse: dataPaths.CONFIRMED,
            results: lastSnapshot.data.level3.results,
            errors: lastSnapshot.data.level3.errors,
        })

    const createSnapshotMutation = useMutation(
        () => {
            return snapshotsQueries.createSnapshot.fn({
                procedureId: procedureId,
                procedure_owner: procedure.owner.id,
                methodology: 'mit_guidelines',
                type: 'L3',
            })
        },
        {
            onError: () =>
                showSnackBar({
                    message: t('generic_error'),
                    severity: 'error',
                }),
            onSuccess: () => {
                refetchProcedure()
                refetchLastSnapshot()
                setReportUrl(null)
            },
        }
    )
    function handleConfirmData() {
        createSnapshotMutation.mutate()
    }

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const {
        data: procedure = {},
        refetch: refetchProcedure,
        isFetched: isProcedureFetched,
    } = useQuery([proceduresQueries.getProcedure.name, procedureId], () =>
        proceduresQueries.getProcedure.fn(procedureId)
    )

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    const [runPendingOps, clearPendingOps] = usePendingOperationsStore((state) => [
        state.runPendingOps,
        state.clearPendingOps,
    ])

    const { isLoading, data: la3 = { results: null, errors: null } } = useQuery(
        [levelsQueries.getMitLevel3.name, procedureId],
        () => levelsQueries.getMitLevel3.fn(procedureId),
        {
            enabled: isProcedureFetched,
            onSuccess: (data) => {
                methods.reset({ ...data, hidden_company_id: procedure.owner.id })
                setCurrentDataPath({
                    inUse: dataPaths.IN_PROGRESS,
                    results: data.results,
                    errors: data.errors,
                })
            },
        }
    )

    const last_modified_at = _.get(la3, 'results.date', null)
    const last_snapshot_date = _.get(lastSnapshot, 'created_at', null)
    const isChangedSinceLastConfirm =
        !last_snapshot_date || dayjs(last_modified_at).isAfter(dayjs(last_snapshot_date || ''))

    const updateLevelMutation = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'mit_guidelines',
                'level3',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getMitLevel3.name),
        }
    )

    React.useEffect(() => {
        clearPendingOps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    function sendData(data) {
        if (isDirty && isValid) {
            runPendingOps().then(() => {
                delete data.hidden_company_id
                updateLevelMutation.mutate(data)
            })
        }
    }

    function handleDocumentGenerationStatusChange(status) {
        if (!!status.error_message) {
            setLoadSocketIO(false)
            setGenerationProgress(null)
        } else if (status.current_step === status.total_steps) {
            setLoadSocketIO(false)
            setGenerationProgress(null)
            refetchLastSnapshot()
        } else {
            setGenerationProgress((status?.current_step / status?.total_steps) * 100)
        }
    }
    return (
        <>
            {loadSocketIO && (
                <SocketIOClient
                    procedureId={procedureId}
                    methodology="mit_guidelines"
                    updateStatus={handleDocumentGenerationStatusChange}
                    type="L3"
                />
            )}
            <MethodologyPage methodology="lgp" alert={alert}>
                <Paper elevation={0} className={classes.paper}>
                    {(isL3Enabled(procedure) || la3?.results) && (
                        <>
                            <Typography variant="h4" align="center" gutterBottom>
                                {capitalizeFirstLetter(t('risultati'))}
                            </Typography>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <ButtonGroup
                                        style={{ marginBottom: '20px' }}
                                        disableElevation
                                        variant="contained"
                                    >
                                        <Button
                                            startIcon={<EditIcon />}
                                            className={classes.smallButton}
                                            color={
                                                currentDataPath.inUse === dataPaths.IN_PROGRESS &&
                                                'secondary'
                                            }
                                            // disabled={!isMethodologyUnlocked}
                                            onClick={switchToWorkInProgressData}
                                        >
                                            {t('use_work_in_progress_methodology_data')}
                                        </Button>
                                        <Button
                                            startIcon={<SaveIcon />}
                                            className={classes.smallButton}
                                            color={
                                                currentDataPath.inUse === dataPaths.CONFIRMED &&
                                                'secondary'
                                            }
                                            // disabled={!isMethodologyUnlocked}
                                            disabled={!lastSnapshot}
                                            onClick={switchToConfirmedData}
                                        >
                                            {t('use_latest_confirmed_methodology_data')}
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                {currentDataPath.inUse === dataPaths.IN_PROGRESS && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={classes.smallButton}
                                            startIcon={
                                                createSnapshotMutation.isLoading ? (
                                                    <CircularProgress size={20} />
                                                ) : isChangedSinceLastConfirm ? (
                                                    <SaveIcon />
                                                ) : (
                                                    <DoneIcon />
                                                )
                                            }
                                            disabled={
                                                isNotAllowedToWrite ||
                                                // !isMethodologyUnlocked ||
                                                !isChangedSinceLastConfirm ||
                                                !currentDataPath.results
                                            }
                                            onClick={handleConfirmData}
                                        >
                                            {t('conferma_dati')}
                                        </Button>
                                    </Grid>
                                )}
                                {currentDataPath.inUse === dataPaths.CONFIRMED &&
                                    !currentDataPath.errors && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                className={classes.smallButton}
                                                startIcon={
                                                    generationProgress ? (
                                                        <CircularProgress
                                                            size={25}
                                                            variant="determinate"
                                                            value={generationProgress}
                                                        />
                                                    ) : (
                                                        <DescriptionIcon />
                                                    )
                                                }
                                                disabled={
                                                    isNotAllowedToWrite && !lastSnapshot?.document
                                                    //     //     !last_snapshot_date ||
                                                    //     //     !isMethodologyUnlocked
                                                }
                                                onClick={() =>
                                                    downloadReport(
                                                        reportUrl,
                                                        lastSnapshot?.document?.name
                                                    )
                                                }
                                            >
                                                {t('ottieni_relazione')}
                                            </Button>
                                        </Grid>
                                    )}
                            </Grid>
                            <Avvertenze />
                            <Grid item style={{ width: '100%' }}>
                                <LA3Results
                                    isNotAllowedToWrite={isNotAllowedToWrite}
                                    currentDataPath={currentDataPath}
                                    lastSnapshot={lastSnapshot}
                                    showLastSnapshotAuthorDate={
                                        currentDataPath.inUse === dataPaths.CONFIRMED
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        {(la3?.results || la3?.errors) !== undefined ? (
                            <>
                                <Typography variant="h4" component="h1" align="center" gutterBottom>
                                    {capitalizeFirstLetter(t('allegati'))}
                                </Typography>
                                <AppForm onSubmit={sendData} methods={methods}>
                                    <DynamicFormFields
                                        fieldsKey={''}
                                        formFields={mit_la_3_model_documents}
                                        disableAll={isNotAllowedToWrite}
                                    />
                                    {!isNotAllowedToWrite && (
                                        <Fab
                                            variant="extended"
                                            color="secondary"
                                            type="submit"
                                            disabled={
                                                !isDirty ||
                                                !isValid ||
                                                isLoading ||
                                                updateLevelMutation.isLoading
                                            }
                                            className={classes.fab}
                                        >
                                            {updateLevelMutation.isLoading ? (
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            ) : (
                                                <SaveIcon />
                                            )}
                                            {t('salva')}
                                        </Fab>
                                    )}
                                </AppForm>
                            </>
                        ) : (
                            <>
                                <Typography variant="h4" component="h1" align="center" gutterBottom>
                                    {capitalizeFirstLetter(t('allegati'))}
                                </Typography>
                                <AppForm onSubmit={sendData} methods={methods}>
                                    <DynamicFormFields
                                        fieldsKey={''}
                                        formFields={mit_la_3_model_documents}
                                        disableAll={isNotAllowedToWrite}
                                    />
                                    {!isNotAllowedToWrite && (
                                        <Fab
                                            variant="extended"
                                            color="secondary"
                                            type="submit"
                                            disabled={
                                                !isDirty ||
                                                !isValid ||
                                                isLoading ||
                                                updateLevelMutation.isLoading
                                            }
                                            className={classes.fab}
                                        >
                                            {updateLevelMutation.isLoading ? (
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            ) : (
                                                <SaveIcon />
                                            )}
                                            {t('salva')}
                                        </Fab>
                                    )}
                                </AppForm>
                            </>
                        )}
                    </Grid>
                </Paper>
            </MethodologyPage>
        </>
    )
}
