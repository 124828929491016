import React from 'react'
import { Paper, List, ListItem, ListItemText, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.primary.main,
        width: 70,
        [theme.breakpoints.up('xs')]: {
            width: 80,
        },
        [theme.breakpoints.up('sm')]: {
            width: 140,
        },
    },
    list: {
        background: theme.palette.primary.main,
    },
    levelBtn: {
        textAlign: 'center',
        fontSize: '0.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem',
        },
    },
    notClickableLA: {
        color: theme.palette.text.notClickable,
    },
    sublevelBtn: {
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: 5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.65rem',
        },
    },
    // material-ui override
    root: {
        color: theme.palette.primary.contrastText,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {},
    },
    selected: {},
}))

export default function LGPToolbar({ data }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const currentUrl = useLocation().pathname

    const TBDivider = () => <Divider light variant="middle" />
    const TBListItem = (props) => (
        <ListItem
            {...props}
            dense
            classes={{
                root: classes.root,
                selected: classes.selected,
            }}
        />
    )

    return (
        <Paper className={classes.toolbar} square elevation={3}>
            <List className={classes.list}>
                {_.compact(data).map((item, index) => {
                    if (item.externalLink === 1)
                        return (
                            <a href={item.href} target="_blank" rel="noreferrer">
                                <TBListItem disableGutters button>
                                    <ListItemText
                                        primary={t(item.text)}
                                        primaryTypographyProps={{
                                            className: classes.levelBtn,
                                        }}
                                    />
                                </TBListItem>
                            </a>
                        )
                    return (
                        <>
                            {item.onClick ? (
                                <TBListItem
                                    disableGutters
                                    button
                                    // selected={((url) => item.selected(url))(currentUrl)}
                                    selected={item.selected}
                                    onClick={item.onClick}
                                >
                                    <ListItemText
                                        primary={t(item.text)}
                                        primaryTypographyProps={{
                                            className: classes.levelBtn,
                                        }}
                                    />
                                </TBListItem>
                            ) : (
                                <ListItem disableGutters dense>
                                    <ListItemText
                                        primary={t(item.text)}
                                        primaryTypographyProps={{
                                            className: [
                                                classes.levelBtn,
                                                classes.notClickableLA,
                                            ].join(' '),
                                        }}
                                    />
                                </ListItem>
                            )}
                            {_.size(_.compact(item.items)) ? (
                                <List>
                                    {_.compact(item.items).map((subItem) => (
                                        <TBListItem
                                            disableGutters
                                            button
                                            selected={subItem.selected}
                                            onClick={subItem.onClick}
                                        >
                                            <ListItemText
                                                primary={t(subItem.text)}
                                                primaryTypographyProps={{
                                                    className: classes.sublevelBtn,
                                                }}
                                            />
                                        </TBListItem>
                                    ))}
                                </List>
                            ) : undefined}
                            {index !== data.length - 1 && <TBDivider />}
                        </>
                    )
                })}
            </List>
        </Paper>
    )
}
