import React from 'react'
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TrafficLight from './TrafficLight'

const useStyles = makeStyles((theme) => ({
    table: {
        tableLayout: 'fixed',
        background: theme.palette.background.paperLight,
    },
    tableValue: {
        padding: '6px 8px 6px 8px',
    },
    tableHeadings: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '0.7rem',
        padding: '6px 8px 6px 8px',
    },
}))

export default function MitOverviewTable({ statusTrace = {}, isPrevalutazione }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const tableStructure = {
        strutturale_fondazionale: {
            value: null,
            causes: {
                pericolosita: null,
                vulnerabilita: null,
                esposizione: null,
            },
        },
        sismica: {
            value: null,
            causes: {
                pericolosita: null,
                vulnerabilita: null,
                esposizione: null,
            },
        },
        rischio_frane: {
            value: null,
            causes: {
                pericolosita: null,
                vulnerabilita: null,
                esposizione: null,
            },
        },
    }

    const prevalutazioneTableStructure = {
        strutturale_fondazionale: {
            value: null,
            causes: {
                pericolosita: null,
                vulnerabilita: null,
                esposizione: null,
            },
        },
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell component="th"></TableCell>
                        <TableCell component="th" align="center" className={classes.tableHeadings}>
                            {t('tot')}
                        </TableCell>
                        <TableCell component="th" align="center" className={classes.tableHeadings}>
                            {t('table_heading_pericolosita')}
                        </TableCell>
                        <TableCell component="th" align="center" className={classes.tableHeadings}>
                            {t('table_heading_vulnerabilita')}
                        </TableCell>
                        <TableCell component="th" align="center" className={classes.tableHeadings}>
                            {t('table_heading_esposizione')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(
                        isPrevalutazione ? prevalutazioneTableStructure : tableStructure
                    ).map(([CdA, CdAObj]) => {
                        return (
                            <TableRow key={CdA}>
                                <TableCell component="th" className={classes.tableHeadings}>
                                    {t(CdA).toUpperCase()}
                                </TableCell>
                                <TableCell align="center" className={classes.tableValue}>
                                    <TrafficLight
                                        value={statusTrace?.[CdA]?.value}
                                        tooltipTitle={statusTrace?.[CdA]?.value}
                                    />
                                </TableCell>
                                {Object.keys(CdAObj.causes).map((parameter) => (
                                    <TableCell
                                        key={CdA + parameter}
                                        align="center"
                                        className={classes.tableValue}
                                    >
                                        <TrafficLight
                                            value={statusTrace?.[CdA]?.causes?.[parameter]}
                                            tooltipTitle={statusTrace?.[CdA]?.causes?.[parameter]}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}

                    {!isPrevalutazione && (
                        <TableRow>
                            <TableCell component="th" className={classes.tableHeadings}>
                                {t('rischio_idraulico').toUpperCase()}
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight
                                    value={statusTrace.rischio_idraulico?.value}
                                    tooltipTitle={statusTrace.rischio_idraulico?.value}
                                />
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight label="N/A" tooltipTitle="non_applicabile" />
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight label="N/A" tooltipTitle="non_applicabile" />
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight
                                    value={statusTrace.rischio_idraulico?.esposizione}
                                    tooltipTitle={statusTrace.rischio_idraulico?.esposizione}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
