import React from "react";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";

import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import MethodologyPage from '../MethodologyPage'
import LoadingSpinner from '../../components/LoadingSpinner'
import PropTypes from 'prop-types'
import { backend_url } from '../../constants'
import { useQueryProcedure } from '../../customHooks/queryHooks/procedureQueries'
import { usePlinto } from "../../3d-models/plinto";
import PlintoCanvas from "../../components/3d-models/Plinto";
import { useGLTF } from "@react-three/drei";

const useStyles = makeStyles((theme) => ({}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}
export default function DynamicsDashboard({children, maxWidth = 'md'}) {

    const classes = useStyles()

    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    // const { data: procedure, isFetched: isProcedureFetched } = useQuery(
    // const { data: procedure, isLoading: isProcedureLoading } = useQuery(
    //     [proceduresQueries.getProcedure.name, procedureId],
    //     () => proceduresQueries.getProcedure.fn(procedureId)
    // )
    const {data: procedure, isLoading: isProcedureLoading} = useQueryProcedure()
    // const {
    //     data: procedure = {},
    //     isLoading: isProcedureLoading,
    //     refetch: refetchProcedure,
    // } = useQuery(
    //     [proceduresQueries.getProcedure.name, procedureId],
    //     () => proceduresQueries.getProcedure.fn(procedureId),
    //     {
    //         onSuccess: (data) => {
    //             console.log("dataaa: ", data)
    //         },
    //     }
    // )

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return isProcedureLoading ? (
        <LoadingSpinner />
    ) : (
        <MethodologyPage methodology="dynamics" alert={alert}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.overlay}
            >
                <Grid item xs={12} md={12} >
                    {/* Image Or 3D Model */}
                    Visualizzazione
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Standard" {...tabProps(0)} />
                            <Tab label="3D" {...tabProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <img
                            src={String(new URL(procedure?.image?.depot_url, backend_url))}
                            alt=''
                            width={'100%'}
                            className={classes.img}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Paper className={classes.paper}>
                            <PlintoCanvas />
                            
                        </Paper>
                    </TabPanel>
                </Grid>
                {/*<Grid item xs={6} md={6}>*/}
                {/*    ALTRO*/}
                {/*</Grid>*/}
            </Grid>
            {/*<Paper elevation={0} className={classes.paper}>*/}
            {/*    <Typography variant="h4" component="h1" align="center" gutterBottom>*/}
            {/*        /!*{capitalizeFirstLetter(t('censimento'))}*!/*/}
            {/*        <p>Overview</p>*/}
            {/*    </Typography>*/}
            {/*</Paper>*/}
        </MethodologyPage>
    )
}

