import create from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const stores = new Map()

export function createPersistentStore(name, initStore, options = {}) {
    const { _storage, ..._options } = options
    if (!name || typeof name !== 'string' || stores.has(name))
        throw new Error(
            `error while creating persistent store named ${name}: invalid name or name already used`
        )
    const storage = _storage || createJSONStorage(() => localStorage)
    stores.set(name, create(persist(initStore, { storage, ..._options, name })))
}

export function usePersistentStore(name, extractState) {
    if (!stores.has(name)) throw new Error(`invalid store name ${name}: no store found`)
    return stores.get(name)(extractState)
}
