import DeleteIcon from '@material-ui/icons/Delete'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import React from 'react'
import {
    Button,
    CardActionArea,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Add as AddIcon } from '@material-ui/icons'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useThemeStore } from '../../../stateManagement'
import { materialsMapping } from '../../../utils'
import AppTooltip from '../../AppTooltip'
import AlertDialog from '../../Dialogs/AlertDialog'
import InputDialog from '../../Dialogs/InputDialog'
import SearchDialog from '../../Dialogs/SearchDialog'
import AppSelect from '../../FormItems/AppSelect'
import LoadingSpinner from '../../LoadingSpinner'
import SplittableGroup from '../../SplittableGroup'
import VerticalCard from '../../VerticalCard'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        minHeight: '40vh',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    totInput: {
        marginLeft: theme.spacing(),
        maxWidth: 100,
    },
}))
export default function SchedeIspezione({
    currentElementoStrutturale,
    currentElementTotCount,
    isNotAllowedToWrite,
    isLivello1Loading,
    livello1,
    handleCardClick,
    // da qui in poi sono opzionali
    deleteGroupMutation,
    addSchedaToGroupMutation,
    copySchedaMutation,
    addSchedaMutation,
    deleteSchedaMutation,
    isInBMSComparison = false,
}) {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const themeMode = useThemeStore((state) => state.themeMode)

    const [elementsToRender, setElementsToRender] = React.useState([])
    // states for searchGroupDialog
    const [isSearchGroupDialogOpen, setIsSearchGroupDialogOpen] = React.useState(false)
    const [groupsNamesList, setGroupsNamesList] = React.useState([])
    const [groupingIndex, setGroupingIndex] = React.useState(null)
    // states for InputDialog
    const [groupName, setGroupName] = React.useState(null)
    const [isInputGroupDialogOpen, setIsInputGroupDialogOpen] = React.useState(false)
    // states for MaterialDialog
    const [isMaterialDialogOpen, setIsMaterialDialogOpen] = React.useState(false)
    const [chosenMaterial, setChosenMaterial] = React.useState('')
    // states for DuplicateDialog
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = React.useState(false)
    const [cardsToDuplicate, setCardsToDuplicate] = React.useState(1)
    const [cardIndex, setCardIndex] = React.useState(null)
    // states for DeleteDialog
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
    const [deletingIndex, setDeletingIndex] = React.useState(null)

    const schedeIspezione = livello1?.schede_ispezione?.[currentElementoStrutturale]

    React.useEffect(() => {
        const groupsMapping = {}
        const ungroupedSchede = []
        schedeIspezione?.forEach((scheda, index) => {
            if (!scheda.group) {
                ungroupedSchede.push({ fakeId: index, ...scheda })
            } else {
                if (!groupsMapping[scheda.group]) {
                    groupsMapping[scheda.group] = {
                        name: scheda.group,
                        schede: [],
                    }
                }
                groupsMapping[scheda.group].schede.push({ fakeId: index, ...scheda })
            }
        })

        const sortedGroups = _.sortBy(groupsMapping, ['name'])
        setElementsToRender([...sortedGroups, ...ungroupedSchede])
        setGroupsNamesList(Object.keys(groupsMapping).map((group) => ({ name: group })))
    }, [livello1, currentElementoStrutturale])

    function closeMaterialDialog() {
        setIsMaterialDialogOpen(false)
        setChosenMaterial('')
    }

    function handleMaterialChange({ target: { value } }) {
        setChosenMaterial(value)
    }

    function handleCreateScheda() {
        setIsMaterialDialogOpen(false)
        addSchedaMutation.mutate({ chosenMaterial, setChosenMaterial })
    }

    function handleAddNewClick() {
        // some elementi strutturali don't support a material choice
        const elementiStrutturaliWithoutMaterials = [
            'apparecchi_appoggio',
            'giunti',
            'elementi_accessori',
        ]
        if (elementiStrutturaliWithoutMaterials.includes(currentElementoStrutturale)) {
            addSchedaMutation.mutate({ chosenMaterial, setChosenMaterial })
        } else {
            setIsMaterialDialogOpen(true)
        }
    }

    function openGroupsDialog(index) {
        setGroupingIndex(index)
        setIsSearchGroupDialogOpen(true)
    }

    function openDuplicateDialog(index) {
        setCardIndex(index)
        setIsDuplicateDialogOpen(true)
    }

    function promptDeleteConfirm(index) {
        setDeletingIndex(index)
        setIsDeleteDialogOpen(true)
    }

    function deleteSelectedScheda() {
        setIsDeleteDialogOpen(false)
        deleteSchedaMutation.mutate(deletingIndex)
        setDeletingIndex(null)
    }

    function calcDefectsNumber(collection = {}) {
        let sum = 0
        Object.entries(collection).forEach(([key, value]) => {
            if ((value.estensione || value.intensita) && !value.motivo_assenza) {
                sum += 1
            }
        })
        return sum
    }

    function renderElement(el, index) {
        const Scheda = ({ scheda, grouped }) => (
            <>
                <CardActionArea
                    style={{
                        padding: grouped ? 0 : 4,
                        marginBottom: !isInBMSComparison ? 'unset' : 16,
                    }}
                >
                    <VerticalCard
                        height={190}
                        width={150}
                        backgroundColor={
                            themeMode === 'dark'
                                ? theme.palette.background.paperLight
                                : theme.palette.background.paper
                        }
                        title={`${t('scheda')} ${scheda.fakeId + 1}`}
                        handleClick={() => handleCardClick(scheda.fakeId)}
                    >
                        {scheda.materiale && (
                            <Typography variant="body2" color="textSecondary">
                                {t('materiale')}:
                                <Typography align="center" color="textPrimary" gutterBottom>
                                    {t(scheda.materiale)}
                                </Typography>
                            </Typography>
                        )}
                        <Typography variant="body2" color="textSecondary">
                            {t('difetti')}:
                            <Typography align="center" color="textPrimary">
                                {calcDefectsNumber(scheda.difetti)}
                            </Typography>
                        </Typography>
                    </VerticalCard>
                </CardActionArea>
                {!isInBMSComparison && (
                    <>
                        {grouped ? (
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {t('ispezione.change_group')}
                                    </Typography>
                                }
                            >
                                <IconButton
                                    clickable
                                    color={'secondary'}
                                    onClick={() => openGroupsDialog(scheda.fakeId)}
                                    disabled={isNotAllowedToWrite}
                                >
                                    <LowPriorityIcon fontSize="small" />
                                </IconButton>
                            </AppTooltip>
                        ) : (
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {t('ispezione.add_to_group')}
                                    </Typography>
                                }
                            >
                                <IconButton
                                    clickable
                                    color={'secondary'}
                                    onClick={() => openGroupsDialog(el.fakeId)}
                                    disabled={isNotAllowedToWrite}
                                >
                                    <PlaylistAddIcon fontSize="small" />
                                </IconButton>
                            </AppTooltip>
                        )}
                        <AppTooltip
                            title={<Typography variant="body2">{t('duplicate')}</Typography>}
                        >
                            <IconButton
                                clickable
                                color={'secondary'}
                                onClick={() => openDuplicateDialog(scheda.fakeId)}
                                disabled={isNotAllowedToWrite}
                            >
                                <LibraryBooksIcon fontSize="small" />
                            </IconButton>
                        </AppTooltip>
                        <AppTooltip title={<Typography variant="body2">{t('delete')}</Typography>}>
                            <IconButton
                                clickable
                                color={'secondary'}
                                onClick={() => promptDeleteConfirm(scheda.fakeId)}
                                disabled={isNotAllowedToWrite}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </AppTooltip>
                    </>
                )}
            </>
        )
        return el.schede ? (
            <SplittableGroup
                key={el.name + index}
                groupName={el.name}
                deleteGroup={() => deleteGroupMutation.mutate(el.name)}
                canUserDeleteGroup={() => !isNotAllowedToWrite}
                hideDeleteGroupButton={isInBMSComparison}
                alertBody="ispezione.gruppo_scioglimento_conferma_testo"
            >
                <Grid container spacing={3} style={{ padding: '0 20px' }}>
                    {el.schede.map((scheda) => (
                        <Grid item key={scheda.fakeId}>
                            <Scheda scheda={scheda} grouped />
                        </Grid>
                    ))}
                </Grid>
            </SplittableGroup>
        ) : (
            <Grid item key={el.fakeId}>
                <Scheda scheda={el} />
            </Grid>
        )
    }

    return (
        <Paper elevation={0} className={classes.paper}>
            <>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {t(currentElementoStrutturale)}
                </Typography>
                <Grid container alignItems="center" spacing={2} justifyContent="space-between">
                    <Grid item>
                        {currentElementoStrutturale === 'piedritti' ? (
                            <Typography gutterBottom>
                                {`${t('elementi_ispezionati')}: ${_.size(schedeIspezione)}`}
                            </Typography>
                        ) : (
                            <>
                                <Typography gutterBottom>
                                    {t('elementi_ispezionati') + ': '}
                                </Typography>
                                <Grid container alignItems="center" wrap="nowrap">
                                    <Typography>{schedeIspezione?.length} /</Typography>
                                    <TextField
                                        disabled
                                        className={classes.totInput}
                                        variant="outlined"
                                        size="small"
                                        label={t('n_totale')}
                                        InputLabelProps={{ shrink: true }}
                                        value={currentElementTotCount}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {!isInBMSComparison && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<AddIcon />}
                                onClick={handleAddNewClick}
                                disabled={isNotAllowedToWrite}
                            >
                                {t('scheda_compila_nuova_pulsante')}
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Dialog open={isMaterialDialogOpen} onClose={closeMaterialDialog}>
                    <DialogTitle>{t('scheda_crea_nuova_titolo')}</DialogTitle>
                    <DialogContent>
                        <AppSelect
                            fullWidth
                            required
                            fieldLabel="materiale"
                            optionsArray={materialsMapping[currentElementoStrutturale]}
                            value={chosenMaterial}
                            onChange={handleMaterialChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeMaterialDialog}>{t('annulla')}</Button>

                        <Button
                            onClick={handleCreateScheda}
                            color="secondary"
                            variant="contained"
                            disabled={!chosenMaterial}
                        >
                            {t('crea')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Divider className={classes.divider} />

                {isLivello1Loading ? (
                    <LoadingSpinner />
                ) : (
                    <Grid container spacing={3} alignItems="flex-end">
                        {elementsToRender?.length === 0 ? (
                            <Grid item xs={12}>
                                <Typography align="center">
                                    {t('scheda_non_disponibili_testo')}
                                </Typography>
                            </Grid>
                        ) : (
                            elementsToRender.map(renderElement)
                        )}
                    </Grid>
                )}

                <SearchDialog
                    open={isSearchGroupDialogOpen}
                    setIsOpen={setIsSearchGroupDialogOpen}
                    title="ispezione.gruppo_selezione_titolo"
                    button="ispezione.gruppo_selezione_pulsante"
                    handleButtonClick={() => setIsInputGroupDialogOpen(true)}
                    data={groupsNamesList}
                    handleResultClick={(groupName) => {
                        addSchedaToGroupMutation.mutate({
                            groupingIndex,
                            groupName,
                            setGroupingIndex,
                        })
                        setIsSearchGroupDialogOpen(false)
                    }}
                    icon={<ViewModuleIcon />}
                />
                <InputDialog
                    open={isInputGroupDialogOpen}
                    setIsOpen={setIsInputGroupDialogOpen}
                    title="ispezione.gruppo_creazione_titolo"
                    button="crea"
                    inputType="text"
                    inputLabel="nome"
                    value={groupName}
                    setValue={setGroupName}
                    onSubmitCb={() => {
                        addSchedaToGroupMutation.mutate({
                            groupingIndex,
                            groupName,
                            setGroupingIndex,
                        })
                        setIsSearchGroupDialogOpen(false)
                        setIsInputGroupDialogOpen(false)
                    }}
                />

                <InputDialog
                    open={isDuplicateDialogOpen}
                    setIsOpen={setIsDuplicateDialogOpen}
                    title="Numero di copie da creare"
                    button="conferma"
                    inputType="number"
                    value={cardsToDuplicate}
                    setValue={setCardsToDuplicate}
                    onSubmitCb={() => {
                        copySchedaMutation.mutate({
                            index: cardIndex,
                            setIsDuplicateDialogOpen,
                            cardsToDuplicate,
                        })
                    }}
                    isLoading={copySchedaMutation?.isLoading}
                />

                <AlertDialog
                    open={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    title="difetti_delete_scheda_confirm"
                    text="difetti_delete_scheda_confirm_text"
                    primaryButton="conferma"
                    onSubmitCb={deleteSelectedScheda}
                />
            </>
        </Paper>
    )
}
