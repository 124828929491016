import React from 'react'
import CustomTable from './CustomTable'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TableCell,
    TableRow,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function CalculationErrorsDialog({ errors, open, setIsOpen }) {
    const { t } = useTranslation()
    return !errors?.length ? null : (
        <Dialog open={open} onClose={() => setIsOpen(false)} fullWidth maxWidth={'lg'}>
            <DialogContent>
                <CustomTable
                    style={{ boxShadow: 'none' }}
                    headers={['Sezione', 'Errore', 'Parametri']}
                    data={errors.map((x) => x.split('|')) || []}
                    renderRow={([item0, item1, item2]) => (
                        <TableRow>
                            <TableCell component="td" scope="row">
                                {item0 && item0 !== 'None'
                                    ? item0.split('.').map(t).join(' -> ')
                                    : null}
                            </TableCell>
                            <TableCell component="td" scope="row">
                                {t(item1)}
                            </TableCell>
                            <TableCell
                                component="td"
                                scope="row"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        item2 && item2 !== 'None'
                                            ? item2
                                                  .split('$') // split, translate, join and add spaces
                                                  .map(t)
                                                  .join(' -> ')
                                            : null,
                                }}
                            />
                        </TableRow>
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'secondary'} onClick={() => setIsOpen(false)}>
                    {t('chiudi')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
