import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, List, ListItem } from '@material-ui/core'
import Section from '../../Section'
import MethodologyCard from './MethodologyCard'
import { useQueryProcedure } from "../../../customHooks/queryHooks/procedureQueries";

export default function MethodologiesSection() {
    const { t } = useTranslation()
    const { data: procedure } = useQueryProcedure()

    const methodologies = procedure?.methodologies || {}
    const buildingMaterial = procedure?.type === 'building' && procedure?.building_material

    return (
        <Section xs={12} innerSpacing={3} direction="column">
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="center" gutterBottom>
                    {t('metodologie')}
                </Typography>
                {buildingMaterial && (
                    <Typography variant="body2" align="center">
                        {t('available_methodologies_subtitle')}{' '}
                        {/* FIXME c.a. should remain uppercase */}
                        <b>{t(buildingMaterial).toLowerCase()}</b>
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                        <List>
                            {/* al momento statico e sisma bonus sono nascoste perchè non implementate */}
                            {Object.keys(methodologies).map((methodology) => {
                                return (
                                    <ListItem key={methodology}>
                                        <MethodologyCard methodology={methodology} />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Section>
    )
}
