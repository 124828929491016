import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Map from '../components/Map/Map'
import ProceduresPanel from '../components/Procedures/ProceduresPanel'

const useStyles = makeStyles((theme) => ({
    homepage: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.background.default,
    },
    mapContainer: {
        minHeight: 300,
    },
}))

export default function MapScreen() {
    const classes = useStyles()

    return (
        <Grid container wrap="wrap-reverse" className={classes.homepage}>
            <Grid container item xs={12} md={6} lg={8} className={classes.mapContainer}>
                <Map />
            </Grid>
            <Grid item container direction="column" xs={12} md={6} lg={4} id="proceduresPanel">
                <ProceduresPanel />
            </Grid>
        </Grid>
    )
}
