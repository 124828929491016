import React from 'react'
import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'
import { isThisAFieldsArrayContext } from '../FormItems/FieldsArray'

const useStyles = makeStyles((theme) => ({
    dropZone: {
        overflow: 'hidden',
        position: 'relative',
        flex: 1,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
    },
    preview: { objectFit: 'contain', cursor: 'pointer' },
    deleteBtn: { position: 'absolute', top: 0, right: 0 },
    dialogContent: { display: 'flex', justifyContent: 'center' },
    emptyDropzone: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    fullsizePreview: { width: '100%', objectFit: 'contain' },
}))

export default function PaperDropzone({
    onDrop,
    acceptedFiles = [],
    file,
    previewUri,
    fullSizeUri,
    onDelete,
    height = 160,
    width = 'auto',
    textWhenEmpty = 'empty_dropzone_text',
    disable = false,
}) {
    const styles = useStyles()
    const { t } = useTranslation()
    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false)
    const isThisAFieldsArray = React.useContext(isThisAFieldsArrayContext)
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptedFiles.join(', '),
        disabled: disable,
    })
    const { ref, ...rootProps } = getRootProps()

    function handleDelete(e) {
        e.stopPropagation()
        onDelete(file)
    }

    function previewImage(e) {
        e.stopPropagation()
        setIsPreviewOpen(true)
    }

    return (
        <RootRef rootRef={ref}>
            <>
                <Paper
                    {...rootProps}
                    variant="outlined"
                    className={styles.dropZone}
                    style={{ width, height }}
                >
                    <input {...getInputProps()} />
                    {previewUri ? (
                        <div style={{ width, height }}>
                            <img
                                src={previewUri}
                                height={height}
                                width={width}
                                className={styles.preview}
                                alt=""
                                onClick={previewImage}
                            />

                            {!isThisAFieldsArray && (
                                <IconButton
                                    aria-label="delete"
                                    onClick={handleDelete}
                                    className={styles.deleteBtn}
                                    disabled={disable}
                                >
                                    {!disable && <DeleteIcon color={'secondary'} />}
                                </IconButton>
                            )}
                        </div>
                    ) : (
                        <div className={styles.emptyDropzone} style={{ height, width }}>
                            <span>{t(textWhenEmpty)}</span>
                        </div>
                    )}
                </Paper>
                <Dialog fullScreen open={isPreviewOpen} onClose={() => setIsPreviewOpen(false)}>
                    <DialogContent className={styles.dialogContent}>
                        <img
                            src={fullSizeUri || previewUri}
                            className={styles.fullsizePreview}
                            alt=""
                            onClick={() => setIsPreviewOpen(false)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsPreviewOpen(false)}>{t('chiudi')}</Button>
                    </DialogActions>
                </Dialog>
            </>
        </RootRef>
    )
}
