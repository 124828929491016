import { numberType, booleanType, createSelect } from './utils'

export const resisto_pgad_model = {
    nominal_life: {
        _type: 'numberUnit',
        unit: 'anni',
    },
    vertical_component_q: numberType,
    horizontal_component_q0: numberType,
    height_ratios: numberType,
    purpose_coefficient: createSelect([
        { value: 1, label: 'I' },
        { value: 2, label: 'II' },
        { value: 3, label: 'III' },
        { value: 4, label: 'IV' },
    ]),
    underground_category: createSelect(['A', 'B', 'C', 'D', 'E']),
    topographic_category: createSelect(['T1', 'T2', 'T3', 'T4']),
    horizontal_component_height_regulation: booleanType,
}

export const resisto_pgad_spettri_model = {
    Ag: numberType,
    F0: numberType,
    Tc_star: numberType,
    result: { ...numberType, label: 'pgad_result' },
}
