import React from "react";
import { useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import AppForm from "../AppForm";
import DynamicFormFields from "../FormItems/DynamicFormFields";
import { analysisVersionFields } from "../../FormConfigs/analysis_config";
import { useTranslation } from "react-i18next";
import { usePatchAnalysisVersionMutation } from "../../customHooks/mutationHooks/analysisMutations";

export const AnalysisVersionDisplayDialog = React.forwardRef((props, ref) => {
    const {t} = useTranslation()
    const [analysis, setAnalysis] = React.useState(null);
    const [majorMinorPatch, setMajorMinorPatch] = React.useState(null);
    React.useImperativeHandle(ref, () => ({
        close: () => {
            setAnalysis(null);
            setMajorMinorPatch(null);
        },
        open: (analysis, majorMinorPatch) => {
            setAnalysis(analysis);
            setMajorMinorPatch(majorMinorPatch);
        }
    }), [setAnalysis, setMajorMinorPatch]);
    const methods = useForm();
    React.useEffect(() => {
        if (
            analysis?.versions?.length &&
            analysis.versions
            .map((version) => version.version_major_minor_patch)
            .find((el) => el === majorMinorPatch)
        ) {
            methods.reset(
                analysis.versions.find(
                    (version) => version.version_major_minor_patch === majorMinorPatch
                )
            );
        }
    }, [analysis, majorMinorPatch]);
    
    const {mutateAsync: mutateAnalysis} = usePatchAnalysisVersionMutation()
    const activateVersion = async () => {
        const data = await mutateAnalysis({
            analysis_id: analysis?.id,
            active_version_major_minor_patch: majorMinorPatch,
        })
        setAnalysis(data)
    }

    return (
        <Dialog open={!!analysis} onClose={() => setAnalysis(null)} fullWidth={true} maxWidth="md">
            <DialogContent>
                <AppForm methods={methods}>
                    <DynamicFormFields
                        disableAll={true}
                        fieldsKey={''}
                        formFields={analysisVersionFields}
                    />
                </AppForm>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={activateVersion}
                    disabled={
                        analysis?.active_version?.version_major_minor_patch === majorMinorPatch
                    }
                >
                    {t('activate_version')}
                </Button>
                <Button variant={'contained'} color={'secondary'} onClick={() => setAnalysis(null)}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
});