import {
    booleanType,
    attachmentType,
    textareaType,
    textType,
    numberType,
    createFieldArray,
} from './utils'

export const tecnico_rilevatore = {
    tecnico_rilevatore: textType,
}

export const scheda_info = {
    progressiva_km: numberType,
    ...tecnico_rilevatore,
    data_ispezione: {
        _type: 'date',
        label: 'data_ispezione',
    },
}

export const note = {
    note: {
        ...textareaType,
        label: 'eventuali_note',
    },
}

export const difetto_generico = {
    estensione: {
        _type: 'buttonGroup',
        label: 'estensioneK1',
        variant: 'estensioneIntensitaLA1',
        la2_required: true,
    },
    intensita: {
        _type: 'buttonGroup',
        label: 'intensitaK2',
        variant: 'estensioneIntensitaLA1',
        la2_required: true,
    },
    motivo_assenza: {
        _type: 'buttonGroup',
        buttons: [{ value: 'NA' }, { value: 'NR' }, { value: 'NP' }],
        label: 'motivo_assenza',
        variant: 'columnInline',
    },
    ps: {
        ...booleanType,
        label: 'PS',
    },
    foto: createFieldArray({
        ...attachmentType,
        useDialog: false,
        acceptedFiles: ['image/*'],
    }),
    note: {
        ...textareaType,
        label: 'difetto_note',
    },
}
