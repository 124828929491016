import React from 'react'
import _ from 'lodash'
import {
    Menu,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Typography,
    Button,
    Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { VariableSizeList } from 'react-window'
import dayjs from 'dayjs'
import axios from 'axios'
import { notificationQueries, queryClient } from '../networking'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: '0.8rem',
        lineHeight: 1.2,
    },
}))

export default function NotificationsMenu({ open, closeMenu, anchorElement, notifications }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const handleNotificationOptionClick = async (index, key, { label, href }) => {
        try {
            const response = await axios.patch(href.replace('/api/v1', ''))
            if (response.status === 200) {
                await queryClient.invalidateQueries(notificationQueries.getNotifications.name)
            }
        } catch (error) {
            console.error('Error submitting notification action', error)
        }
    }

    function renderNotifications({ index, style }) {
        // TODO: implement options and image

        return (
            <ListItem key={index} style={style} divider={index !== notifications.length - 1}>
                <ListItemAvatar>
                    <Avatar />
                </ListItemAvatar>
                <ListItemText disableTypography>
                    <Typography className={classes.text} color="textSecondary">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t(notifications[index].payload.text, {
                                    ...notifications[index].payload.data,
                                    interpolation: { escapeValue: false },
                                }),
                            }}
                        />{' '}
                        - {dayjs().to(notifications[index].creation_date)}
                    </Typography>
                    {!_.isEmpty(notifications[index].payload.options) && (
                        <Grid container spacing={1} justifyContent="flex-end">
                            {Object.entries(notifications[index].payload.options)
                                .reverse()
                                .map(([key, action]) => (
                                    <Grid item key={key}>
                                        <Button
                                            size="small"
                                            variant={
                                                action.label === 'Accept' ? 'contained' : 'text'
                                            }
                                            color={
                                                action.label === 'Accept' ? 'secondary' : 'default'
                                            }
                                            onClick={() =>
                                                handleNotificationOptionClick(index, key, action)
                                            }
                                        >
                                            {t(action.label)}
                                        </Button>
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </ListItemText>
            </ListItem>
        )
    }

    function calcItemSize(index) {
        let height = 70
        const notificationPayload = notifications[index].payload

        const textLines = Math.ceil(notificationPayload.text.length / 40)
        const hasOptions =
            notificationPayload.options && Object.keys(notificationPayload.options).length > 0

        height += 10 * (textLines - 1)

        if (hasOptions || textLines <= 1) {
            height += 10
        }

        return height
    }

    function itemKey(index) {
        return notifications[index].id
    }

    return (
        <Menu
            open={open}
            onClose={closeMenu}
            anchorEl={anchorElement}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <VariableSizeList
                width={330}
                height={Math.min(
                    500,
                    notifications.reduce((acc, cur, idx) => acc + calcItemSize(idx), 0)
                )}
                itemSize={calcItemSize}
                itemCount={notifications?.length}
                itemKey={itemKey}
            >
                {renderNotifications}
            </VariableSizeList>
        </Menu>
    )
}
