import React from 'react'
import AppSnackbar from './AppSnackbar'
import { useSnackBarStore } from '../stateManagement'
import axios from 'axios'
import i18n from '../i18n'
import { unstable_batchedUpdates } from 'react-dom'

axios.interceptors.response.use(
    (response) => {
        if (!window.location.pathname.includes(['/access'])) {
            if (response.config.method === 'put' && response.status === 200) {
                useSnackBarStore.getState().show({
                    message: i18n.t('http_put_successful'),
                    severity: 'success',
                })
            }
        }
        return response
    },
    (error) => {
        if (error.response?.status === 401) {
            if (
                !['/login', '/privacy-policy', '/cookie-policy', '/landing'].includes(
                    window.location.pathname
                )
            ) {
                window.location = '/login?redirect=' + window.location.pathname
            }
        } else if (error.response?.status === 403) {
            unstable_batchedUpdates(() => {
                // to force react state changes outside of React components
                useSnackBarStore.getState().show({
                    message: `${i18n.t('forbidden')}: ${error.toJSON().config.url}`,
                    severity: 'error',
                })
            })
            window.history.back()
        }
        return Promise.reject(error)
    }
)

export default function GlobalSnackBar() {
    const [display, message, severity, onClose] = useSnackBarStore((state) => [
        state.display,
        state.message,
        state.severity,
        state.onClose,
    ])
    return <AppSnackbar open={display} message={message} severity={severity} onClose={onClose} />
}
