import React from "react";
import { getSearchableModel } from "../utils";
import { useQueryExtraFieldValues } from "./queryHooks/companyQueries";
import useCompanyId from "./useCompanyId";

export default function useSearchableModel({companyId} = {}) {
    const { companyId: _companyId } = useCompanyId()
    companyId = companyId || _companyId
    const { data } = useQueryExtraFieldValues({companyId})
    const values = data?.values
    const schema = data?.schema
    
    const searchableModel = React.useMemo(() => {
        return getSearchableModel(schema, values)
    }, [schema, values])
    
    return {searchableModel, extraFieldValues: values}
}
