import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import it from 'dayjs/locale/it'

LicenseInfo.setLicenseKey('a14af87e9b59581923203f71aba29ccdTz05MzU3MyxFPTE3NTE1NTU4MDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.locale(it)
dayjs.tz.setDefault(dayjs.tz.guess())

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
