import { emailType, textType, attachmentType, createFieldArray } from './utils'

export const user_config = {
    image: { ...attachmentType, useDialog: false, acceptedFiles: ['image/*'] },
    first_name: { ...textType, __meta: { required: true } },
    last_name: { ...textType, __meta: { required: true } },
    email: { ...emailType, disabled: true },
    pec: emailType,
    telephone: textType,
    registration_order: textType,
    registration_number: textType,
}

export const userBuiltiDocuments = {
    _type: 'group',
    __meta: { hideGroupUI: true },
    contratto_incarico_professionale: createFieldArray(attachmentType),
    duvri_a1: createFieldArray(attachmentType),
    duvri_a2: createFieldArray(attachmentType),
    duvri_a3: createFieldArray(attachmentType),
    duvri_a4: createFieldArray(attachmentType),
    duvri_a5: createFieldArray(attachmentType),
    duvri_a6: createFieldArray(attachmentType),
    other_documents: createFieldArray(attachmentType),
}

export const userPersonalDocuments = {
    iscrizione_ordine_ingegneri: { ...attachmentType, __meta: { hideGroupUI: false } },
    assicurazione_professionale: { ...attachmentType, __meta: { hideGroupUI: false } },
    documento_identita: { ...attachmentType, __meta: { hideGroupUI: false } },
    regolarita_contribuzione_inarcassa: { ...attachmentType, __meta: { hideGroupUI: false } },
    pre_job_checks: createFieldArray(attachmentType),
    // conferme_presa_visione: needs dynamic disabled prop passed in UserProfilePage
}

export const userFormModel = {
    first_name: { ...textType, __meta: { required: true } },
    last_name: { ...textType, __meta: { required: true } },
    telephone: textType,
    image: { ...attachmentType, useDialog: false, acceptedFiles: ['image/*'] },
}

export const newUserFormModel = {
    first_name: { ...textType, __meta: { required: true, autofocus: true } },
    last_name: { ...textType, __meta: { required: true } },
    email: { ...emailType, __meta: { required: true } },
    password: { ...textType, __meta: { required: true } },
    pec: { ...textType, __meta: { required: false } },
    registration_order: { ...textType, __meta: { required: false } },
    registration_number: { ...textType, __meta: { required: false } },
    // roles: dynamic value
}

export const editUserFormModel = {
    first_name: { ...textType, __meta: { required: true } },
    last_name: { ...textType, __meta: { required: true } },
    // roles: dynamic value
}
