import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { backend_url } from '../../constants'
import { useMutation } from 'react-query'
import { duplicateProcedureQueries } from '../../networking'

export const CompanyListDialog = ({
    companies,
    isOpen,
    setIsOpen,
    onDuplicationSuccess,
    onConfirm,
    confirmButtonText,
    selectedProcedureId,
    onCompanyClick,
}) => {
    const { t } = useTranslation()
    // const duplicateProcedure = useMutation(
    //     (destination_company_id = null) =>
    //         duplicateProcedureQueries.createDuplicateProcedure.fn(
    //             selectedProcedureId,
    //             destination_company_id
    //         ),
    //     {
    //         onSuccess: () => {
    //             onDuplicationSuccess()
    //         },
    //     }
    // )
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="select-company-dialog-title"
            scroll="paper"
        >
            <DialogTitle id="select-company-dialog-title">
                {t('duplicate_procedure_company_selection_title')}
            </DialogTitle>
            <DialogContent>
                <List>
                    {companies.map((company) => {
                        const company_img_url = company.image?.depot_url
                            ? String(new URL(company.image.depot_url, backend_url))
                            : null
                        return (
                            <ListItem
                                key={company.id}
                                onClick={() => onCompanyClick(company)}
                                button
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={company_img_url}
                                        alt={company.name}
                                        variant="rounded"
                                        imgProps={{
                                            style: {
                                                objectFit: 'contain',
                                            },
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText>{company.name}</ListItemText>
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)}>{t('annulla')}</Button>
                <Button onClick={() => onConfirm(null)}>{confirmButtonText}</Button>
            </DialogActions>
        </Dialog>
    )
}
