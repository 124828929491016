import {
    attachmentType,
    createFieldArray,
    positiveNumberType,
    booleanType,
    numberUnitType,
    createSelect,
    numberType,
    dateType,
    textType,
} from './utils'

export const mit_la_3_model_form = {
    anno_ponte: {
        ...positiveNumberType,
        info: 'info_anno',
    },
    cassone: { ...booleanType, info: 'info_cassone' },
    n_anime: {
        ...positiveNumberType,
        watchConfig: {
            name: 'data.cassone',
            cases: {
                false: { disabled: true },
                true: { disabled: false },
            },
        },
        info: 'info_n_anime',
    },
    b: {
        ...numberUnitType('m'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                false: { disabled: true },
                true: { disabled: false },
            },
        },
        info: 'info_b',
    },
    J_l: {
        ...numberUnitType('m<sup>4</sup>'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_J_l',
    },
    J_t: {
        ...numberUnitType('m<sup>4</sup>'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_J_t',
    },
    K_l: {
        ...numberUnitType('m<sup>4</sup>'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_K_l',
    },
    K_t: {
        ...numberUnitType('m<sup>4</sup>'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_K_t',
    },
    E: {
        ...numberUnitType('MPa'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_E',
    },
    b1: {
        ...numberUnitType('m'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_b1',
    },
    l1: {
        ...numberUnitType('m'),
        watchConfig: {
            name: 'data.cassone',
            cases: {
                true: { disabled: true },
                false: { disabled: false },
            },
        },
        info: 'info_l1',
    },
    schema_strutturale: createSelect(['SCHEMA_ISOSTATICO', 'SCHEMA_IPERSTATICO']),
    luce_struttura: {
        ...numberUnitType('m'),
        info: 'info_luce_struttura',
    },
    tipologia_appoggio: createSelect(['SEMPLICE_APPOGGIO', 'TRAVI_CONTINUE', 'TRAVATA_GERBER']),
    luce_mensola: {
        ...numberUnitType('m'),
        watchConfig: {
            name: 'data.tipologia_appoggio',
            cases: {
                SEMPLICE_APPOGGIO: { disabled: true },
                TRAVI_CONTINUE: { disabled: true },
                TRAVATA_GERBER: { disabled: false },
                null: { disabled: false },
            },
        },
        info: 'info_luce_mensola',
    },
    luce_campate: {
        ...createFieldArray({
            ...numberUnitType('m'),
            label: 'luce_campata',
        }),
        info: 'info_luce_campate',
    },

    L: {
        ...numberUnitType('m'),
        info: 'info_L',
    },
    n: {
        ...positiveNumberType,
        info: 'info_n',
    },
    categoria_strada: {
        ...createSelect(['PRIMA_CATEGORIA', 'SECONDA_CATEGORIA', 'TERZA_CATEGORIA']),
        info: 'Categoria di strada che varia a seconda della normativa',
    },
    marciapiede: booleanType,
    larghezza_marciapiede: {
        ...numberUnitType('m'),
        watchConfig: {
            name: 'data.marciapiede',
            cases: {
                false: { disabled: true },
                true: { disabled: false },
            },
        },
    },
    y: {
        ...numberUnitType('m'),
        info: 'info_y',
    },
    tipo_calcolo: createSelect(['APPOGGIO_APPOGGIO', 'INCASTRO_INCASTRO']),
    n_corsie_limitate: {
        ...positiveNumberType,
        info: 'info_n_corsie_limitate',
    },
    offset_corsie: {
        ...numberUnitType('m'),
        info: 'info_offset_corsie',
    },
}

export const mit_la_3_model_documents = {
    documenti: { ...createFieldArray(attachmentType), __meta: { hideGroupUI: true } },
}

export const mit_la_3_model_results = {
    results: {
        m_max_ultima_normativa: numberType,
        m_max_pos_ultima_normativa: numberType,
        m_max_neg_ultima_normativa: numberType,
        v_max_ultima_normativa: numberType,
        m_max_normativa_storica: numberType,
        m_max_pos_normativa_storica: numberType,
        m_max_neg_normativa_storica: numberType,
        v_max_normativa_storica: numberType,
        rapporto_m_max: numberType,
        rapporto_v_max: numberType,
        rapporto_m_max_pos: numberType,
        rapporto_m_max_neg: numberType,
        risultati_cds: {
            carico: textType,
            m_max_cds: numberType,
            rapporto_m_max: numberType,
            m_max_pos_cds: numberType,
            m_max_neg_cds: numberType,
            rapporto_m_max_pos: numberType,
            rapporto_m_max_neg: numberType,
            v_max_cds: numberType,
            rapporto_v_max: numberType,
        },
        date: dateType,
        version: textType,
    },
}
