import {
    createSelect,
    createFieldArray,
    textType,
    booleanType,
    dateType,
    attachmentType,
    positiveNumberType,
    textareaType,
} from './utils'

const tipologiaElStruttMateriali = {
    _type: 'group',
    ca: positiveNumberType,
    cap: positiveNumberType,
    acciaio: positiveNumberType,
    acciaio_calcestruzzo: positiveNumberType,
    muratura: positiveNumberType,
    legno: positiveNumberType,
    altro: positiveNumberType,
}

const tipologiaElStruttMaterialiImpalcato = {
    _type: 'group',
    // n_elementi_totali: positiveNumberType,
    ca: positiveNumberType,
    cap: positiveNumberType,
    acciaio: positiveNumberType,
    acciaio_calcestruzzo: positiveNumberType,
    muratura: positiveNumberType,
    legno: positiveNumberType,
    altro: positiveNumberType,
}

export const sections_to_render_scheda_descrittiva = [
    'tipologia_fondazioni',
    'schema_statico',
    'classificazione_vie_attacco',
    'schemi_geometrici',
    'rilievo_caratteristiche_geometriche',
    'tipologia_elementi_strutturali',
    'tipologia_elementi_strutturali_impalcato',
    'apparecchi_appoggio',
    'elementi_critici',
    'informazioni_ispezione',
    'scheda_speciale_cap_post_tesi',
]

export const sections_to_render_fenomeni_scheda = [
    'localizzazione',
    'presenza_rischio',
    'informazioni_generali',
    'ispezioni_precedenti',
    'contesto_geomorfologico',
    'rischio_documentazione',
]

export const sections_to_render_fenomeni_frane = [
    'area_pericolosa',
    'contesto_geologico',
    'tipologia_fenomeno',
    'distribuzione_attivita',
    'uso_suolo',
    'morfometria_frana',
    'dati_generali',
    'stato_attivita_frane',
    'parametro_magnitudo',
    'parametro_velocita_max',
    'estensione_interferenza',
    'affidabilita_valutazione',
]

export const sections_to_render_fenomeni_idraulico = [
    'tipologia_fenomeno',
    'area_pericolosa',
    'individuazione_area',
    'individuazione_parti',
    'confinamento_alveo',
    'rischio_sormonto',
    'fenomeni_erosione',
]

export const mit_la_1_model_scheda_descrittiva = {
    tipologia_fondazioni: {
        dirette: booleanType,
        tipologia: createSelect(['MURATURE', 'PILE_ALVEO', 'NON_NOTA']),
    },
    schema_statico: {
        ...createSelect([
            'TRAVATE_APPOGGIATE',
            'TRAVATE_CONTINUE_TELAIO',
            'SOLETTA_APPOGGIATA',
            'SOLETTA_INCASTRATA',
            'ARCO_MASSICCIO',
            'ARCO_SOTTILE',
            'TRAVATE_GERBER_O_PONTI_STAMPELLA',
        ]),
        la2_required: true,
    },
    classificazione_vie_attacco: {
        classificazione: createSelect([
            'RILEVATO_TERRA',
            'RILEVATO_TERRA_RINFORZATA_ARMATA',
            'RILEVATO_IN_GOLENA',
            'ROCCIA',
            'VIADOTTO_IN_PENDENZA_CURVA',
            'ALTRO',
        ]),
        altro: {
            ...textType,
            optional: true,
            watchConfig: {
                name: 'classificazione_vie_attacco.classificazione',
                cases: {
                    RILEVATO_TERRA: { disabled: true },
                    RILEVATO_TERRA_RINFORZATA_ARMATA: { disabled: true },
                    RILEVATO_IN_GOLENA: { disabled: true },
                    ROCCIA: { disabled: true },
                    VIADOTTO_IN_PENDENZA_CURVA: { disabled: true },
                    ALTRO: { disabled: false },
                },
            },
        },
    },
    schemi_geometrici: {
        sezione_trasversale: { _type: 'attachment' },
        sezione_longitudinale: { _type: 'attachment' },
        pianta: { _type: 'attachment' },
    },
    rilievo_caratteristiche_geometriche: {
        lunghezza_sbalzo_soletta: {
            _type: 'numberUnit',
            unit: 'm',
        },
        altezza_max_pile: {
            _type: 'numberUnit',
            unit: 'm',
        },
    },
    tipologia_elementi_strutturali: {
        spalle: tipologiaElStruttMateriali,
        pile: tipologiaElStruttMateriali,
        impalcato: tipologiaElStruttMateriali,
    },
    tipologia_elementi_strutturali_impalcato: {
        soletta: tipologiaElStruttMaterialiImpalcato,
        travi: tipologiaElStruttMaterialiImpalcato,
        traversi: tipologiaElStruttMaterialiImpalcato,
        arco: tipologiaElStruttMaterialiImpalcato,
        altro: tipologiaElStruttMaterialiImpalcato,
    },
    apparecchi_appoggio: {
        presenti: booleanType,
        tipo_apparecchi: {
            ...textType,
            optional: true,
            watchConfig: {
                name: 'scheda_speciale_cap_post_tesi',
                cases: {
                    undefined: { disabled: false },
                    false: { disabled: true },
                },
            },
        },
        n_apparecchi_rilevabili: {
            ...positiveNumberType,
            optional: true,
            watchConfig: {
                name: 'apparecchi_appoggio.presenti',
                cases: {
                    true: { disabled: false },
                    false: { disabled: true },
                    null: { disabled: true },
                },
            },
        },
        n_apparecchi_tot: {
            ...positiveNumberType,
            optional: true,
            watchConfig: {
                name: 'apparecchi_appoggio.presenti',
                cases: {
                    true: { disabled: false },
                    false: { disabled: true },
                    null: { disabled: true },
                },
            },
        },
        nessun_apparecchio_visibile: {
            ...booleanType,
            optional: true,
            watchConfig: {
                name: 'apparecchi_appoggio.presenti',
                cases: {
                    true: { disabled: false },
                    false: { disabled: true },
                },
            },
        },
    },
    elementi_critici: {
        presenti: booleanType,
        elementi: {
            ...createFieldArray({
                tipologia_elemento: textType,
                stato_degrado: createSelect(['ASSENTE', 'PRESENTE', 'AVANZATO']),
                stato_degrado_descrizione: textareaType,
                allegato: {
                    ...attachmentType,
                    __meta: { hideGroupUI: false },
                },
            }),
            watchConfig: {
                name: 'elementi_critici.presenti',
                cases: {
                    true: { disabled: false },
                    false: { disabled: true },
                    null: { disabled: true },
                },
            },
        },
    },
    informazioni_ispezione: {
        possibilita_accedere_sotto_ponte: createSelect(['SI', 'NO', 'PARZIALE']),
        n_campate_completamente_ispezionate: {
            ...positiveNumberType,
            optional: true,
            watchConfig: {
                name: 'informazioni_ispezione.possibilita_accedere_sotto_ponte',
                cases: {
                    SI: { disabled: true },
                    NO: { disabled: true },
                    PARZIALE: { disabled: false },
                },
            },
        },
        ispezionati_entrambi_prospetti: booleanType,
        prospetto_ispezionato: textType,
    },
    scheda_speciale_cap_post_tesi: {
        tracciato_cavi: {
            _type: 'group',
            reperibile_da_progetto_originale: booleanType,
            allegati_riferimento: {
                ...createFieldArray(attachmentType),
                watchConfig: {
                    name: 'scheda_speciale_cap_post_tesi.tracciato_cavi.reperibile_da_progetto_originale',
                    cases: {
                        true: { disabled: false },
                        false: { disabled: true },
                        null: { disabled: true },
                    },
                },
            },
            schemi_da_progetto: createFieldArray({
                elemento_strutturale: textType,
                schema: { ...attachmentType, __meta: { hideGroupUI: false } },
            }),
            indagini: {
                __meta: { groupLabel: 'indagini_non_distruttive_semi_distruttive' },
                ...createFieldArray({
                    tipologia_indagine: textType,
                    tipologia_elemento: textType,
                    numero_elementi: positiveNumberType,
                    numero_totale_indagini: positiveNumberType,
                }),
            },
            risultati_indagini: createFieldArray({
                tipologia_elemento: textType,
                tipologia_indagine: textType,
                schema_tracciato_cavi: {
                    ...attachmentType,
                    __meta: { hideGroupUI: false },
                },
                corrispondenza_progetto: createSelect(['COMPLETA', 'PARZIALE', 'ASSENTE']),
                schema_localizzazione_difetti: {
                    ...attachmentType,
                    __meta: { hideGroupUI: false },
                },
            }),
        },
        campagna_indagini_valutazione_difetti: {
            _type: 'group',
            indagini: {
                __meta: { groupLabel: 'indagini_semi_distruttive_distruttive' },
                ...createFieldArray({
                    tipologia_indagine: textType,
                    tipologia_elemento: textType,
                    numero_elementi: positiveNumberType,
                    numero_totale_indagini: positiveNumberType,
                }),
            },
            risultati_indagini: createFieldArray({
                localizzazione_difetti: {
                    _type: 'group',
                    tipologia_elemento: textType,
                    tipologia_indagine: textType,
                    tipologia_difetti: textareaType,
                    schema_localizzazione_difetti: {
                        ...attachmentType,
                        __meta: { hideGroupUI: false },
                    },
                },
                valutazione_tensione_filo_calcestruzzo: {
                    _type: 'group',
                    descrizione: textareaType,
                    elementi_indagati: {
                        _type: 'group',
                        tipologia: textType,
                        numero: positiveNumberType,
                    },
                    numero_totale_misurazioni: positiveNumberType,
                    schema_localizzazione_indagini: {
                        ...attachmentType,
                        __meta: { hideGroupUI: false },
                    },
                    risultati: textareaType,
                    rispondenza_progetto_originale: booleanType,
                    informazioni_reperibili_progetto_originale: textareaType,
                },
                prelievo_materiale_iniezione: {
                    _type: 'group',
                    descrizione: textareaType,
                    elementi_indagati: {
                        _type: 'group',
                        tipologia: textType,
                        numero: positiveNumberType,
                    },
                    numero_totale_prelievi: positiveNumberType,
                    schema_localizzazione_indagini: {
                        ...attachmentType,
                        __meta: { hideGroupUI: false },
                    },
                },
                prove_chimiche: createFieldArray({
                    tipologia: textType,
                    numero_totale_prove: positiveNumberType,
                    risultati: textareaType,
                }),
            }),
        },
        analisi_difetti: createSelect([
            { label: 'cap_post_tesi_difetti_gravi', value: 'DANGER' },
            {
                label: 'cap_post_tesi_difetti_info_insufficienti',
                value: 'NOT_ENOUGH_INFO',
            },
            {
                label: 'cap_post_tesi_difetti_trascurabili',
                value: 'ACCEPTABLE',
            },
        ]),
    },
}

export const mit_la_1_model_fenomeni = {
    localizzazione: {
        coordinate_ctr: textType,
        scala: textType,
        numero_toponimo: textType,
    },
    informazioni_generali: {
        // proprietario: textType,
        // concessionario: textType,
        // ente_vigilante: textType,
        autorita_distrettuale: textType,
        bacino_idrografico: textType,
    },
    ispezioni_precedenti: {
        numero: positiveNumberType,
        data_ultima_ispezione: dateType,
        esito: textType,
    },
    presenza_rischio: {
        frana: { ...booleanType, la2_required: true },
        idraulico: { ...booleanType, la2_required: true },
    },
    contesto_geomorfologico: {
        unita_fisiografica: createSelect([
            'MONTUOSA',
            'COLLINARE',
            'PIANURA_INTERMONTANA',
            'PIANURA_BASSA',
        ]),
        confinamento_alveo: createSelect(['CONFINATO', 'SEMICONFINATO', 'NON_CONFINATO']),
        stralcio_cartografico: createFieldArray({
            presenza: { ...booleanType, label: 'reperibilita' },
            fonte: textType,
            allegato: attachmentType,
        }),
        riprese_fotografiche: createFieldArray(attachmentType),
    },
    rischio_documentazione: {
        condizione_pericolosita: textType,
        altri_documenti: createFieldArray(attachmentType),
        pericolosita_frane: textType,
        pericolosita_idraulico: textType,
    },
    rischio_frane: {
        area_pericolosa: {
            tipo_fenomeno: {
                ...createSelect([
                    'RICONOSCIUTO_NON_STUDIATO',
                    'MODELLATO_MONITORAGGIO',
                    'RICONOSCIUTO_STUDIATO',
                    'MITIGAZIONE',
                ]),
                la2_required: true,
            },
            riferimenti: createFieldArray(attachmentType),
        },
        contesto_geologico: {
            formazioni: textType,
            unita: createFieldArray({
                ...textType,
                label: 'unita',
            }),
        },
        tipologia_fenomeno: {
            accertato_potenziale: createSelect(['ACCERTATO', 'POTENZIALE']),
            tipologia: createSelect([
                'CROLLO_ROCCIA',
                'COLATE_DETITRICHE',
                'RIBALTAMENTO',
                'COLATE_VISCOSE',
                'SCORRIMENTO_ROTAZIONALE',
                'COMPLESSO_COMPOSITO',
                'SCORRIMENTO_TRASLATIVO',
                'FENOMENI_GRAVITATIVI',
            ]),
        },
        distribuzione_attivita: createSelect([
            'COSTANTE',
            'RETROGRESSIVO',
            'ALLARGAMENTO',
            'AVANZANTE',
            'DIMINUZIONE',
            'CONFINATO',
            'MULTIDIREZIONALE',
        ]),
        uso_suolo: {
            uso: createSelect([
                'AREA_URBANIZZATA',
                'AREA_ESTRATTIVA',
                'SEMINATIVO',
                'COLTURE',
                'VEGETAZIONE_RIPARIA',
                'RIMBOSCHIMENTO',
                'BOSCO_CEDUO',
                'BOSCO_ALTO_FUSTO',
                'INCOLTO_NUDO',
                'INCOLTO_MACCHIA_CESPUGLIATO',
                'INCOLTO_PRATO_PASCOLO',
                'ALTRO',
            ]),
            altro: {
                ...textType,
                optional: true,
                watchConfig: {
                    name: 'rischio_frane.uso_suolo.uso',
                    cases: {
                        AREA_URBANIZZATA: { disabled: true },
                        AREA_ESTRATTIVA: { disabled: true },
                        SEMINATIVO: { disabled: true },
                        COLTURE: { disabled: true },
                        VEGETAZIONE_RIPARIA: { disabled: true },
                        RIMBOSCHIMENTO: { disabled: true },
                        BOSCO_CEDUO: { disabled: true },
                        BOSCO_ALTO_FUSTO: { disabled: true },
                        INCOLTO_NUDO: { disabled: true },
                        INCOLTO_MACCHIA_CESPUGLIATO: { disabled: true },
                        INCOLTO_PRATO_PASCOLO: { disabled: true },
                        ALTRO: { disabled: false },
                    },
                },
            },
        },
        morfometria_frana: createSelect(['BASSA', 'MEDIA', 'FONDOVALLE', 'ALTA']),
        dati_generali: {
            quota_orlo: {
                _type: 'numberUnit',
                unit: 'm',
            },
            area_totale: {
                _type: 'numberUnit',
                unit: 'm<sup>2</sup>',
            },
            volume_massa: {
                _type: 'numberUnit',
                unit: 'm<sup>3</sup>',
            },
            quota_ponte_viadotto: {
                _type: 'numberUnit',
                unit: 'm',
            },
            profondita_superficie_distacco: {
                _type: 'numberUnit',
                unit: 'm',
            },
        },
        stato_attivita_frane: {
            tipologia_frana: {
                ...createSelect(['FRANE_RICONOSCIUTE', 'FRANE_POTENZIALI']),
                la2_required: true,
            },
            stato_attivita: {
                ...createSelect(['ATTIVO', 'SOSPESA', 'QUIESCENTE', 'INATTIVA', 'STABILIZZATA']),
                optional: true,
                la2_required: {
                    dependsOn: 'rischio_frane.stato_attivita_frane.tipologia_frana',
                    cases: {
                        FRANE_RICONOSCIUTE: true,
                        FRANE_POTENZIALI: false,
                    },
                },
                watchConfig: {
                    name: 'rischio_frane.stato_attivita_frane.tipologia_frana',
                    cases: {
                        FRANE_RICONOSCIUTE: { disabled: false },
                        FRANE_POTENZIALI: { disabled: true },
                    },
                },
            },
            grado_criticita: {
                ...createSelect(['ALTAMENTE_CRITICA', 'CRITICA', 'SCARSAMENTE_CRITICA']),
                optional: true,
                la2_required: {
                    dependsOn: 'rischio_frane.stato_attivita_frane.tipologia_frana',
                    cases: {
                        FRANE_RICONOSCIUTE: false,
                        FRANE_POTENZIALI: true,
                    },
                },
                watchConfig: {
                    name: 'rischio_frane.stato_attivita_frane.tipologia_frana',
                    cases: {
                        FRANE_RICONOSCIUTE: { disabled: true },
                        FRANE_POTENZIALI: { disabled: false },
                    },
                },
            },
        },
        parametro_magnitudo: {
            ...createSelect([
                {
                    value: 'MOLTO_GRANDE',
                    label: 'PARAMETRO_MAGNITUDO_MOLTO_GRANDE',
                },
                { value: 'GRANDE', label: 'PARAMETRO_MAGNITUDO_GRANDE' },
                { value: 'MEDIA', label: 'PARAMETRO_MAGNITUDO_MEDIA' },
                { value: 'PICCOLA', label: 'PARAMETRO_MAGNITUDO_PICCOLA' },
                {
                    value: 'MOLTO_PICCOLA',
                    label: 'PARAMETRO_MAGNITUDO_MOLTO_PICCOLA',
                },
            ]),
            la2_required: true,
        },
        parametro_velocita_max: {
            ...createSelect(['MOLTO_RAPIDA', 'RAPIDA', 'MODERATA', 'LENTA', 'MOLTO_LENTA']),
            la2_required: true,
        },
        estensione_interferenza: {
            tipo: {
                ...createSelect([
                    'TOTALE',
                    'ZONA_APPROCCIO',
                    { value: 'PARZIALE', label: 'PARZIALE_SPALLE_PILE' },
                    'ALTRO',
                ]),
                la2_required: true,
            },
            altro: {
                ...textType,
                optional: true,
                watchConfig: {
                    name: 'rischio_frane.estensione_interferenza.tipo',
                    cases: {
                        TOTALE: { disabled: true },
                        ZONA_APPROCCIO: { disabled: true },
                        PARZIALE: { disabled: true },
                        ALTRO: { disabled: false },
                    },
                },
            },
        },
        affidabilita_valutazione: {
            ...createSelect(['BUONA', 'LIMITATA']),
            la2_required: true,
        },
    },
    rischio_idraulico: {
        tipologia_fenomeno: {
            accertato_ipotizzato: createSelect(['ACCERTATO', 'IPOTIZZATO']),
            tipologia: {
                ...createSelect(['SORMONTO', 'EROSIONE', 'SORMONTO_EROSIONE']),
                la2_required: true,
            },
        },
        area_pericolosa: {
            tipo_fenomeno: createSelect([
                'RICONOSCIUTO_NON_STUDIATO',
                'MODELLATO_MONITORAGGIO',
                'RICONOSCIUTO_STUDIATO',
                'MITIGAZIONE',
            ]),
            riferimenti: createFieldArray(attachmentType),
        },
        individuazione_area: textareaType,
        individuazione_parti: textareaType,
        confinamento_alveo: {
            confinamento: createSelect(['CONFINATO', 'SEMICONFINATO', 'NON_CONFINATO']),
            tipologia: createSelect([
                'SINGOLO_RETTILINEO',
                'SINGOLO_SINUOSO',
                'SINGOLO_MEANDRIFORME',
                'INTRECCIATO',
            ]),
        },
        rischio_sormonto: {
            tipologia_alveo: createSelect([
                'EQUILIBRIO',
                'FONDO_FISSO',
                'FASE_EVOLUTIVA',
                'FONDO_MOBILE',
            ]),
            tipologia_reticolo: {
                _type: 'group',
                reticolo: {
                    ...createSelect(['PRINCIPALE', 'SECONDARIO', 'ARTIFICIALE']),
                    la2_required: true,
                },
                presenza_argini: { ...booleanType, la2_required: true },
                quota_sommita_arginale: {
                    _type: 'numberUnit',
                    unit: 'm',
                    la2_required: {
                        dependsOn:
                            'rischio_idraulico.rischio_sormonto.tipologia_reticolo.presenza_argini',
                        cases: {
                            true: true,
                        },
                    },
                    optional: true,
                    watchConfig: {
                        name: 'rischio_idraulico.rischio_sormonto.tipologia_reticolo.presenza_argini',
                        cases: {
                            null: { disabled: true },
                            true: { disabled: false },
                            false: { disabled: true },
                        },
                    },
                },
                area_mappata_direttiva: {
                    ...booleanType,
                    optional: true,
                    watchConfig: {
                        name: 'rischio_idraulico.rischio_sormonto.tipologia_reticolo.reticolo',
                        cases: {
                            PRINCIPALE: { disabled: true },
                            SECONDARIO: { disabled: false },
                            ARTIFICIALE: { disabled: false },
                        },
                    },
                },
                mappa_pericolosita: attachmentType,
                p2: {
                    _type: 'group',
                    quota_pelo_libero: {
                        _type: 'numberUnit',
                        unit: 'm',
                    },
                    quota_intradosso: {
                        _type: 'numberUnit',
                        unit: 'm',
                        optional: true,
                        watchConfig: {
                            name: 'rischio_idraulico.rischio_sormonto.tipologia_reticolo.reticolo',
                            cases: {
                                PRINCIPALE: { disabled: false },
                                SECONDARIO: { disabled: true },
                                ARTIFICIALE: { disabled: true },
                            },
                        },
                    },
                    franco_idraulico: {
                        _type: 'numberUnit',
                        unit: 'm',
                        la2_required: true,
                    },
                },
                p3: {
                    _type: 'group',
                    quota_pelo_libero: {
                        _type: 'numberUnit',
                        unit: 'm',
                        optional: true,
                        watchConfig: {
                            name: 'rischio_idraulico.rischio_sormonto.tipologia_reticolo.reticolo',
                            cases: {
                                PRINCIPALE: { disabled: false },
                                SECONDARIO: { disabled: true },
                                ARTIFICIALE: { disabled: true },
                            },
                        },
                    },
                    quota_intradosso: {
                        _type: 'numberUnit',
                        unit: 'm',
                        optional: true,
                        watchConfig: {
                            name: 'rischio_idraulico.rischio_sormonto.tipologia_reticolo.reticolo',
                            cases: {
                                PRINCIPALE: { disabled: false },
                                SECONDARIO: { disabled: true },
                                ARTIFICIALE: { disabled: true },
                            },
                        },
                    },
                    franco_idraulico: {
                        _type: 'numberUnit',
                        unit: 'm',
                        optional: true,
                        watchConfig: {
                            name: 'rischio_idraulico.rischio_sormonto.tipologia_reticolo.reticolo',
                            cases: {
                                PRINCIPALE: { disabled: false },
                                SECONDARIO: { disabled: true },
                                ARTIFICIALE: { disabled: true },
                            },
                        },
                        la2_required: {
                            dependsOn:
                                'rischio_idraulico.rischio_sormonto.tipologia_reticolo.reticolo',
                            cases: {
                                PRINCIPALE: true,
                                undefined: true,
                                null: true,
                            },
                        },
                    },
                },
            },
            condizioni: {
                ...createSelect(
                    [
                        'EVIDENZA_ACCENTUATI_FENOMENI_SEDIMENTI',
                        'EVIDENZA_SIGNIFICATIVI_FENOMENI_SEDIMENTI',
                        'EVIDENZA_TRASPORTO_MATERIALE',
                        'EVIDENZA_SIGNIFICATIVO_TRASPORTO_MATERIALE',
                        'DIMENSIONI_BACINO_RIDOTTE',
                        'DIMENSIONI_BACINO_MEDIE',
                        'DIMENSIONI_BACINO_ELEVATE',
                    ],
                    'multiple'
                ),
            },
        },
        fenomeni_erosione: {
            larghezza_alveo_pile_spalle: {
                _type: 'numberUnit',
                unit: 'm',
                la2_required: true,
            },
            larghezza_alveo_monte: {
                _type: 'numberUnit',
                unit: 'm',
                la2_required: true,
            },
            presenza_golene: {
                ...booleanType,
                la2_required: true,
            },
            larghezza_golene_pile_spalle: {
                _type: 'numberUnit',
                unit: 'm',
                la2_required: {
                    dependsOn: 'rischio_idraulico.fenomeni_erosione.presenza_golene',
                    cases: {
                        true: true,
                    },
                },
                watchConfig: {
                    name: 'rischio_idraulico.fenomeni_erosione.presenza_golene',
                    cases: {
                        null: { disabled: true },
                        true: { disabled: false },
                        false: { disabled: true },
                    },
                },
            },
            larghezza_golene_monte: {
                _type: 'numberUnit',
                unit: 'm',
                la2_required: {
                    dependsOn: 'rischio_idraulico.fenomeni_erosione.presenza_golene',
                    cases: {
                        true: true,
                    },
                },
                watchConfig: {
                    name: 'rischio_idraulico.fenomeni_erosione.presenza_golene',
                    cases: {
                        null: { disabled: true },
                        true: { disabled: false },
                        false: { disabled: true },
                    },
                },
            },
            caratteristiche_geometriche_pile: {
                _type: 'group',
                geometria_sez_trasversale: textType,
                dimensione_sez_trasversale: {
                    _type: 'numberUnit',
                    unit: 'm',
                    la2_required: {
                        dependsOn:
                            'rischio_idraulico.fenomeni_erosione.caratteristiche_alveo.max_profondita_scavo',
                        cases: {
                            null: true,
                            undefined: true,
                            NaN: true,
                        },
                    },
                },
                stato_conservazione: textareaType,
            },
            caratteristiche_geometriche_spalle: {
                _type: 'group',
                geometria_sez_trasversale: textType,
                dimensione_sez_trasversale: {
                    _type: 'numberUnit',
                    unit: 'm',
                    la2_required: {
                        dependsOn:
                            'rischio_idraulico.fenomeni_erosione.caratteristiche_alveo.max_profondita_scavo',
                        cases: {
                            null: true,
                            undefined: true,
                            NaN: true,
                        },
                    },
                },
                stato_conservazione: textareaType,
            },
            caratteristiche_alveo: {
                _type: 'group',
                natura_materiale: textType,
                accumulo_detriti: booleanType,
                max_profondita_scavo: {
                    _type: 'numberUnit',
                    unit: 'm',
                    la2_required: true,
                },
                profondita_posa_piano: {
                    _type: 'group',
                    profondita: {
                        _type: 'numberUnit',
                        unit: 'm',
                        la2_required: true,
                    },
                    allegato: attachmentType,
                },
                dimensioni_bacino: {
                    _type: 'numberUnit',
                    unit: 'kmq',
                },
                condizioni: {
                    ...createSelect(
                        [
                            'EVIDENZA_FONDAZIONI_SUPERFICIALI',
                            'EVIDENZA_FONDAZIONI_PROFONDE',
                            'EVIDENZA_ABBASSAMENTO_ALVEO',
                            'PONTE_POSIZIONE_CURVATURA',
                            'PRESENZA_ACCUMULI_DETRITI',
                            'DIVAGAZIONE_ALVEO',
                            'EVIDENZA_PROTEZIONE_PIEDE',
                            'PRESENZA_BRIGLIA_PROTEZIONE',
                        ],
                        'multiple'
                    ),
                    la2_required: true,
                },
                opere_arginatura: {
                    _type: 'group',
                    presenti: booleanType,
                    tipologia: textType,
                    stato_conservazione: textareaType,
                },
                opere_laminazione: {
                    _type: 'group',
                    presenti: booleanType,
                    tipologia: textType,
                    stato_conservazione: textareaType,
                },
                sistemi_monitoraggio: {
                    _type: 'group',
                    presenti: booleanType,
                    tipologia: textType,
                    stato_conservazione: textareaType,
                },
                tipologia_aree_allagamento: textareaType,
                affidabilita_valutazione: createSelect(['BUONA', 'LIMITATA']),
            },
        },
    },
}
