import React from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function NetworkErrorOverlay({ actions = [], areActionsPending }) {
    const { t } = useTranslation()
    return (
        <div
            style={{
                position: 'absolute',
                flex: 1,
                // height: '100%',
                // width: '100%',
                padding: 30,
                backgroundColor: 'rgba(40,40,40,0.4)',
            }}
        >
            <Typography variant={'h4'}>{t('network_request_failed_title')}</Typography>
            <Typography>{t('network_request_failed_details')}</Typography>
            {actions?.length > 0 && (
                <Typography>{t('network_request_actions_intro_text')}</Typography>
            )}
            {actions.map((action) => (
                <Button onClick={action.onClick} variant={'contained'} color={'secondary'}>
                    {areActionsPending && (
                        <CircularProgress style={{ marginRight: 10 }} size={20} />
                    )}
                    <Typography variant={'caption'}>{action.label}</Typography>
                </Button>
            ))}
        </div>
    )
}
