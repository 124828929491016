import React from 'react'
import { useQuery, useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
    Grid,
    Paper,
    Typography,
    Divider,
    Button,
    CardActionArea,
    IconButton, CardActions,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddToPhotosRounded from '@material-ui/icons/AddToPhotosRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'
import MethodologyPage from '../MethodologyPage'
import LoadingSpinner from '../../components/LoadingSpinner'
import VerticalCard from '../../components/VerticalCard'
import AppTooltip from '../../components/AppTooltip'
import AlertDialog from '../../components/Dialogs/AlertDialog'
import { levelsQueries, proceduresQueries, queryClient } from '../../networking'
import { useThemeStore } from '../../stateManagement'
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        minHeight: '40vh',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}))

export default function Resisto_livelli() {
    const classes = useStyles()
    const theme = useTheme()
    const themeMode = useThemeStore((state) => state.themeMode)
    const history = useHistory()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
    const [indexToBeDeleted, setIndexToBeDeleted] = React.useState(null)

    const {
        data: procedure,
        isFetched: isProcedureFetched
    } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )

    const {
        data: floors = [],
        isLoading: isFloorsLoading,
        // isFetched: isFloorsFetched
    } = useQuery(
        [levelsQueries.getResistoLivelli.name, procedureId],
        () => levelsQueries.getResistoLivelli.fn(procedureId),
        {
            enabled: isProcedureFetched,
        }
    )

    const cloneFloorMutation = useMutation((index) => {
        floors.push(_.cloneDeep(floors[index]))
        floors.forEach((floor, index) => floor.lvl = index)
        levelsQueries.updateLevel.fn(procedureId, 'resisto', 'floors', floors).then(() => {
            queryClient.invalidateQueries([levelsQueries.getResistoLivelli.name, procedureId])
        })
    })

    const addFloorMutation = useMutation(() => {
        floors.push({})
        floors.forEach((floor, index) => floor.lvl = index)
        levelsQueries.updateLevel.fn(procedureId, 'resisto', 'floors', floors).then(() => {
            queryClient.invalidateQueries([levelsQueries.getResistoLivelli.name, procedureId])
        })
    })

    const deleteFloorMutation = useMutation((index) => {
        floors.splice(index, 1)
        floors.forEach((floor, index) => floor.lvl = index)
        levelsQueries.updateLevel.fn(procedureId, 'resisto', 'floors', floors).then(() => {
            queryClient.invalidateQueries([levelsQueries.getResistoLivelli.name, procedureId])
        })
    })

    function openDeleteDialog(index) {
        setIndexToBeDeleted(index)
        setIsDeleteDialogOpen(true)
    }

    function deleteLivello() {
        deleteFloorMutation.mutate(indexToBeDeleted)
        setIndexToBeDeleted(null)
        setIsDeleteDialogOpen(false)
    }

    React.useEffect(() => {
        if (procedure?.building_material === 'PREFABRICATED') {
            // only 1 livello allowed: skip this page and go directly to livello_details
            history.replace('./livelli/1')
        }
    }, [procedure?.building_material, history])

    return (
        <MethodologyPage methodology="resisto">
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {t('livelli')}
                </Typography>
                <Divider className={classes.divider} />
                {isFloorsLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Grid container spacing={3}>
                        {floors?.map((floor) => (
                            <Grid item key={floor.lvl}>
                                <CardActionArea style={{textAlign: 'right'}}>
                                    <VerticalCard
                                        height={200}
                                        width={160}
                                        backgroundColor={
                                            themeMode === 'dark'
                                                ? theme.palette.background.paperLight
                                                : theme.palette.background.paper
                                        }
                                        title={`${t('floor')} ${floor.lvl + 1}`}
                                        handleClick={() =>
                                            history.push(`./livelli/${floor.lvl + 1}`)
                                        }
                                    >
                                        <Grid item container alignItems={"center"}>
                                            {floor?.n_columns !== undefined && (
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography>{t('columns').toLowerCase()}:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display: "flex", justifyContent: "center"}}>
                                                        <Typography color="secondary" variant="h6">
                                                            {floor?.n_columns || 0}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}

                                            {floor?.n_piers !== undefined && (
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography>{t('piers').toLowerCase()}:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display: "flex", justifyContent: "center"}}>
                                                        <Typography color="secondary" variant="h6">
                                                            {floor?.n_piers || 0}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </VerticalCard>
                                </CardActionArea>
                                        <Grid container style={{display: "flex", justifyContent: "center"}}>
                                            <Grid item>
                                                <AppTooltip
                                                    title={
                                                        <Typography variant="body2">
                                                            {t('duplicate_floor')}
                                                        </Typography>
                                                    }
                                                >
                                                    <IconButton
                                                        clickable
                                                        color={'secondary'}
                                                        onClick={() => cloneFloorMutation.mutate(floor.lvl)}
                                                    >
                                                        <AddToPhotosRounded/>
                                                    </IconButton>
                                                </AppTooltip>
                                            </Grid>
                                            <Grid item>
                                                <AppTooltip
                                                    title={
                                                        <Typography variant="body2">
                                                            {t('delete_floor')}
                                                        </Typography>
                                                    }
                                                >
                                                    <IconButton
                                                        clickable
                                                        color={'secondary'}
                                                        onClick={() => openDeleteDialog(floor.lvl)}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </AppTooltip>
                                            </Grid>
                                        </Grid>

                            </Grid>
                        ))}
                        <Grid item>
                                <VerticalCard
                                    height={200}
                                    width={160}
                                    backgroundColor={theme.palette.background.paper}
                                    // handleClick={addFloorMutation.mutate}
                                >
                                </VerticalCard>
                                <Grid container style={{display: "flex", justifyContent: "center"}}>
                                    <Grid item>
                                        <AppTooltip
                                            title={
                                                <Typography variant="body2">
                                                    {t('add_floor')}
                                                </Typography>
                                            }
                                        >
                                            <IconButton
                                                clickable
                                                color={'secondary'}
                                                onClick={addFloorMutation.mutate}
                                            >
                                                <AddIcon/>
                                            </IconButton>
                                        </AppTooltip>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                )}
                <AlertDialog
                    open={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    title="resisto_delete_livello_confirm"
                    text="resisto_delete_livello_confirm_text"
                    primaryButton="conferma"
                    onSubmitCb={deleteLivello}
                />
            </Paper>
        </MethodologyPage>
    )
}
