import { useTranslation } from "react-i18next";
import useProcedureId from "../../customHooks/useProcedureId";
import { useForm } from "react-hook-form";
import { useCreateAnalysisMutation } from "../../customHooks/mutationHooks/analysisMutations";
import { useQueryProcedureAnalysisTemplates } from "../../customHooks/queryHooks/analysisQueries";
import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid } from "@material-ui/core";
import AppForm from "../AppForm";
import DynamicFormFields from "../FormItems/DynamicFormFields";
import { createSelect, textType } from "../../FormConfigs/utils";
import { inputFile } from "../../FormConfigs/analysis_config";
import SaveIcon from "@material-ui/icons/Save";

export const AnalysisCreationDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const { procedureId } = useProcedureId();
    
    const methods = useForm({
        mode: "onBlur"
    });
    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState;
    const { mutateAsync: createAnalysis } = useCreateAnalysisMutation();
    const submitNewAnalysis = (data) => {
        createAnalysis(feToBe(data))
        .then(onClose)
        .catch(() => console.error("Failed to create analysis"));
    };
    const formValues = methods.getValues();
    
    const { data: templates } = useQueryProcedureAnalysisTemplates();
    const template = (templates || []).find(
        (template) => template.name === formValues?.template_name
    );
    const template_version = (template?.versions || []).find(
        (version) => version.version_major_minor_patch === formValues?.template_version
    );
    const scheduled_execution_type = template_version?.configuration?.scheduled_execution_type;
    
    React.useEffect(() => {
        methods.setValue(
            "template_version",
            template?.active_version?.version_major_minor_patch ?? ""
        );
    }, [template]);
    
    React.useEffect(() => {
        methods.setValue("active_version", template_version);
        // console.log(template_version);
    }, [template_version, template]);
    
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="create-analysis-form"
            fullWidth={true}
            maxWidth="md"
        >
            <AppForm onSubmit={submitNewAnalysis} methods={methods}>
                <DialogTitle id="plc-active-configuration">
                    {t("plc_configuration.configuration")}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <DynamicFormFields
                            fieldsKey={""}
                            formFields={{
                                template_name: createSelect(
                                    (templates || []).map((template) => template.name)
                                ),
                                template_version: createSelect(
                                    (template?.versions || []).map(
                                        (version) => version.version_major_minor_patch
                                    )
                                ),
                                procedure: { _type: "hidden", defaultValue: procedureId }
                            }}
                        />
                        <Grid item xs={12}>
                            {/*
                            Se
                                - time_interval: impostare ogni quanti Secondi avviare l'analisi
                                - fixed_time: impostare data + ora
                            */}
                            <DynamicFormFields
                                fieldsKey={"active_version"}
                                formFields={{
                                    ...(scheduled_execution_type && {
                                        configuration: {
                                            _type: "group",
                                            docker_image_url: textType,
                                            scheduled_execution_type: {
                                                ...textType,
                                                disabled: true
                                            },
                                            ...(scheduled_execution_type === "fixed_time" && {
                                                schedule_at_date: { _type: "dateTime" }
                                            }),
                                            ...(scheduled_execution_type === "time_interval" && {
                                                schedule_every_seconds: {
                                                    _type: "number",
                                                    step: 1,
                                                    min: 1
                                                }
                                            }),
                                            seconds_data_to_analyze: {
                                                _type: "number",
                                                step: 1,
                                                min: 1
                                            }
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid container spacing={1} direction={"column"}>
                            {(template_version?.configuration?.input_files || []).map(
                                (input_file, index) => {
                                    return (
                                        <>
                                            <Grid item>
                                                <DynamicFormFields
                                                    fieldsKey={`active_version.configuration.input_files[${index}]`}
                                                    formFields={{
                                                        ...inputFile,
                                                        name: { ...textType, disabled: true }
                                                    }}
                                                    // disableAll={true}
                                                />
                                            </Grid>
                                        </>
                                    );
                                }
                            )}
                        </Grid>
                        
                        {/*
                            In Base alla tipologia di versione analisi selezionata mostro il form di configurazione

                            Mostro menu con elenco versioni

                            "versions": [
                                {
                                    "version_major_minor_patch": "0.0.1",
                                    "scheduled_execution_type": "time_interval",
                                    "input_files": [
                                        {
                                            "name": "a.json",
                                            "content": {}
                                        }
                                    ]
                                },
                                {
                                    "version_major_minor_patch": "0.0.2",
                                    "scheduled_execution_type": "fixed_time",
                                    "input_files": [
                                        {
                                            "name": "input1.json",
                                            "content": {}
                                        }
                                    ]
                                }
                            ]

                            Selezionata la versione in base a: scheduled_execution_type
                            ->Se
                                - time_interval: impostare ogni quanti Secondi avviare l'analisi
                                - fixed_time: impostare data + ora

                            Se sono previsti input_files, mostrare preview/ possibilità di modificare

                        */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={!isDirty || !isValid}
                    >
                        {/*{plcNewConfigurationMutation.isLoading ? (*/}
                        {/*    <CircularProgress size={18} style={{ marginRight: 8 }} />*/}
                        {/*) : (*/}
                        <>
                            <SaveIcon />
                            {t("salva")}
                        </>
                        {/*)}*/}
                    </Fab>
                </DialogActions>
            </AppForm>
        </Dialog>
    );
}


function feToBe(data) {
    return {
        template_name: data?.template_name,
        template_version: data?.template_version,
        procedure: data?.procedure,
        is_active: true,
        active_version: {
            version_major_minor_patch: '0.0.1',
            configuration: { ...(data?.active_version?.configuration || {}), _cls: undefined }
        }
        
    }
}
