import MethodologyPage from '../MethodologyPage'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '@material-ui/data-grid'
import { useProcedureAnalysesTable } from "../../customHooks/useProce4dureAnalysesTable";

const useStyles = makeStyles((theme) => ({}))

export default function AnalysisDashboard({children, maxWidth = 'md'}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const {rows, columns} = useProcedureAnalysesTable()
    return (
        <MethodologyPage methodology="dynamics" >
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.overlay}
            >
                <Grid item xs={12} md={12} >
                    <Button variant="contained" color="secondary">
                        {t('Aggiungi Analisi')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                    Analisi Configurate
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    </div>
                </Grid>

            </Grid>
        </MethodologyPage>
    );
}

