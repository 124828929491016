import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'react-query'
import { Container, Paper, Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import LoadingSpinner from '../components/LoadingSpinner'
import AppAccordion from '../components/AppAccordion'
import DoneIcon from '@material-ui/icons/Done'
import { educationQueries, queryClient } from '../networking'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    seenVideoIndicator: {
        color: theme.palette.success.main,
        marginRight: 10,
    },
}))

export default function EducationAreaPage() {
    const { t } = useTranslation()
    const classes = useStyles()

    const { data: videos = [], isLoading } = useQuery(
        educationQueries.getVideos.name,
        educationQueries.getVideos.fn
    )

    const { data: profileSeenConfirmations = [] } = useQuery(
        educationQueries.getProfileSeenConfirmations.name,
        educationQueries.getProfileSeenConfirmations.fn
    )

    const createSeenConfirmationMutation = useMutation(educationQueries.createSeenConfirmation.fn, {
        onSuccess: () => {
            queryClient.invalidateQueries(educationQueries.getProfileSeenConfirmations.name)
        },
    })

    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper elevation={0} className={classes.paper}>
                <Grid item>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {t('education_page.title')}
                    </Typography>
                    <Grid container style={{ margin: '30px 0' }} alignItems="center" spacing={2}>
                        <Grid item md={8}>
                            <Typography style={{ fontStyle: 'italic' }}>
                                {t('mit_guidelines_link_text_1')}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                {t('mit_guidelines_link_text_2')}
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Button
                                href="https://www.mit.gov.it/nfsmitgov/files/media/notizia/2020-05/1_Testo_Linee_Guida_ponti.pdf"
                                target="_blank"
                                color="secondary"
                                variant="contained"
                                startIcon={<DescriptionIcon />}
                            >
                                {t('mit_guidelines_link_label')}
                            </Button>
                        </Grid>
                    </Grid>

                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        videos.map((video) => {
                            const hasVideoBeenSeen = profileSeenConfirmations.find(
                                (confirmation) => confirmation.video === video.id
                            )
                            return (
                                <AppAccordion
                                    key={video.id}
                                    accordionLabel={video.title}
                                    renderHeaderContent={() => (
                                        <>
                                            {hasVideoBeenSeen && (
                                                <DoneIcon className={classes.seenVideoIndicator} />
                                            )}
                                            <PlayCircleOutlineIcon />
                                        </>
                                    )}
                                    accordionDetailsStyle={{
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                    TransitionProps={{ unmountOnExit: true }}
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{ marginBottom: 30 }}
                                    >
                                        <Typography style={{ marginRight: 8 }}>
                                            {`${t('educational_video_seen_text')} "${video.title}"`}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>
                                                createSeenConfirmationMutation.mutate(video.id)
                                            }
                                            disabled={hasVideoBeenSeen}
                                            startIcon={hasVideoBeenSeen ? <DoneIcon /> : null}
                                        >
                                            {hasVideoBeenSeen ? t('confirmed') : t('conferma')}
                                        </Button>
                                    </Grid>
                                    <iframe
                                        width={'100%'}
                                        height={497.81}
                                        src={video.url}
                                        title={video.title}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    />
                                </AppAccordion>
                            )
                        })
                    )}
                </Grid>
            </Paper>
        </Container>
    )
}
