import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import { QuickFilterChip } from './QuickFilterChip'
import useSearchableModel from '../customHooks/useSearchableModel'
import { useStoredFilters } from '../customHooks/storedFiltersContext'
import { useQueryFilteredProcedures } from '../customHooks/queryHooks/procedureQueries'

const emptyArray = []
export const CustomQuickFiltersChip = ({ path, possibleValues, placeholder }) => {
    const { searchableModel } = useSearchableModel()
    const dataType = _.get(searchableModel, path)
    possibleValues = possibleValues || dataType?.__meta?.autoCompleteValues || emptyArray
    const [filters, addRule, removeRule] = useStoredFilters((state) => [
        state.filters,
        state.addRule,
        state.removeRule,
    ])
    const { data: filteredProcedures = emptyArray } = useQueryFilteredProcedures()

    const operatore =
        (dataType._type === 'text' && 'equals') ||
        (['select', 'buttonGroup'].includes(dataType._type) && 'in') ||
        (['number', 'numberUnit', 'boolean', 'date', 'year'].includes(dataType._type) && 'eq') ||
        undefined

    const actuallyPossibleValues = React.useMemo(() => {
        return new Set(filteredProcedures.map((procedure) => _.get(procedure, path)))
    }, [filteredProcedures, path])

    const options = React.useMemo(
        () =>
            [{ path, operatore, valore: null, label: 'null' }].concat(
                (possibleValues || [])
                    .filter(
                        (valore) => _.isString(valore) || _.isNumber(valore) || _.isBoolean(valore)
                    )
                    .sort(compareFn(dataType))
                    .sort(
                        (o1, o2) => actuallyPossibleValues.has(o2) - actuallyPossibleValues.has(o1)
                    )
                    .map((valore) => ({ path, operatore, valore, label: valore.toString() }))
            ),
        [possibleValues, dataType, operatore, path, actuallyPossibleValues]
    )

    const activeOptions = React.useMemo(() => {
        const rules = _.get(filters?.rules, path, []).filter((rule) => rule.operatore === operatore)
        return options.filter((option) => rules.find((rule) => rule.valore === option.valore))
    }, [filters, options, path, operatore])

    function handleChangeActiveOptions(event, newActiveOptions) {
        activeOptions
            .filter((option) => !newActiveOptions.find((newOption) => newOption === option))
            .forEach((option) => removeRule(path, operatore, option.valore))
        newActiveOptions
            .filter((option) => !activeOptions.find((oldOption) => oldOption === option))
            .forEach((option) => addRule(path, operatore, option.valore))
    }

    return (
        <Autocomplete
            options={options}
            multiple
            getOptionDisabled={(option) =>
                !activeOptions.length && !actuallyPossibleValues.has(option.valore)
            }
            getOptionLabel={(option) => option.label}
            value={activeOptions}
            onChange={handleChangeActiveOptions}
            renderTags={renderTags(removeRule, path, operatore)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    size={'small'}
                    variant="outlined"
                    fullWidth={true}
                />
            )}
            fullWidth={true}
            // style={{ width: 300 }}
        />
    )
}
const renderTags = (removeRule, path, operatore) => {
    return function renderTags(options) {
        return (
            <Grid container spacing={1}>
                {options.map((option) => (
                    <Grid item key={option.valore}>
                        <QuickFilterChip {...option} onDelete={true} />
                    </Grid>
                ))}
            </Grid>
        )
    }
}

function compareFn(dataType) {
    switch (dataType?._type) {
        case 'boolean':
        case 'number':
            return (a, b) => (a || 0) - (b || 0)
        default:
            return (a, b) => (a || '').toLowerCase().localeCompare((b || '').toLowerCase())
    }
}
