import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import loginItalianTranslation from './assets/locales/it/login.json'
import loginEnglishTranslation from './assets/locales/en/login.json'
import { backend_url } from './constants'

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        resources: {
            it: {
                login: loginItalianTranslation,
            },
            en: {
                login: loginEnglishTranslation,
            },
        },
        lng: 'it',
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
        },
        partialBundledLanguages: true, // !important: allow to load namespaces from backend event if some namespaces are already initialized
        debug: false,
        ns: [
            'login',
            'form_info',
            'translation',
        ],
        defaultNS: 'translation',
        // fallbackNS: 'translation',
        react: {
            wait: true,
            useSuspense: false,
        },
        backend: {
            loadPath: backend_url + '/locales/{{lng}}/{{ns}}.json',
            // addPath: '/locales/add/{{lng}}/{{ns}}',  // TODO: https://www.i18next.com/overview/configuration-options#missing-keys
            allowMultiLoading: false,
            crossDomain: true, // TODO: false in production, true in dev
        },
    })

const replaceUITexts = (translationsBundle) => {
    const { language, translations } = translationsBundle
    i18n.addResourceBundle(language, 'common', translations, true, true)
    i18n.changeLanguage(language)
}

// i18n.on('missingKey', function (lngs, namespace, key, res) {
//     console.log(key)
// })

export default i18n
export { replaceUITexts }
