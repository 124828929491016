import React from 'react'

const envColors = {
    '{{environment}}': '#06c258',
    dev: '#fee227',
    staging: '#ff7400',
    resisto: '#3cdfff',
    prod: 'transparent',
}

const env = window.environment

export default function EnvBar() {
    return (
        <div
            style={{
                width: '100vw',
                height: 5,
                background: envColors[env],
                position: 'fixed',
                zIndex: 9999,
            }}
        />
    )
}
