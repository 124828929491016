import React from 'react'
import _ from 'lodash'
import { useMutation } from 'react-query'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { useTranslation } from 'react-i18next'
import Section from '../Section'
import { companiesQueries, queryClient } from '../../networking'
import AppForm from '../AppForm'
import { useForm } from 'react-hook-form'
import DynamicFormFields from '../FormItems/DynamicFormFields'
import { attachmentType, textType } from '../../FormConfigs/utils'
import { useUserProfileStore } from '../../stateManagement'

const companySchema = {
    name: textType,
    address: textType,
    telephone: textType,
    image: { ...attachmentType, useDialog: false, acceptedFiles: ['image/*'] },
    hidden_company_id: { _type: 'hidden' },
}

export default function DetailsSection({ company = {} }) {
    const { t } = useTranslation()
    const methods = useForm({
        defaultValues: { ...company, hidden_company_id: company.id },
    })
    const profile = useUserProfileStore((state) => state.profile)
    const isTranslationManager = useUserProfileStore((state) => state.isTranslationManager)

    React.useEffect(() => {
        methods.reset({ ...company, hidden_company_id: company.id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    const updateCompanyMutation = useMutation(
        (companyData) =>
            companiesQueries.updateCompany.fn(company.id, _.omit(companyData, 'hidden_company_id')),
        {
            onSuccess: () =>
                queryClient.invalidateQueries([companiesQueries.getCompany.name, company.id]),
        }
    )
    const isReader = company?.readers.map((reader) => reader.id).includes(profile.id)
    function sendCompanyData(data) {
        updateCompanyMutation.mutate(data)
    }

    return (
        <Section xs={12} direction="column" innerSpacing={4}>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1" align="center">
                    {company.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <AppForm onSubmit={sendCompanyData} methods={methods}>
                    <DynamicFormFields
                        fieldsKey={''}
                        formFields={companySchema}
                        disableAll={isReader}
                        editableInfos={isTranslationManager}
                        formId={`edit_company_data`}
                    />
                    {!isReader && (
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{ marginTop: 15 }}
                            disabled={updateCompanyMutation.isLoading}
                            startIcon={
                                updateCompanyMutation.isLoading ? (
                                    <CircularProgress size={18} style={{ marginRight: 8 }} />
                                ) : (
                                    <SaveIcon />
                                )
                            }
                        >
                            {t('salva')}
                        </Button>
                    )}
                </AppForm>
            </Grid>
        </Section>
    )
}
