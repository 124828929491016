import React from 'react'
import FileSaver from 'file-saver'
import Fuse from 'fuse.js'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'react-query'
import AutoSizer from 'react-virtualized-auto-sizer'
import { areEqual, FixedSizeList as VirtualizedList } from 'react-window'

import {
    Container,
    Grid,
    Typography,
    Button,
    ListItem,
    Paper,
    IconButton,
    Avatar,
    ListItemText,
    Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
    Add as AddIcon,
    CheckCircle as CheckCircleIcon,
    Delete as DeleteIcon,
    Description as DescriptionIcon,
    Edit as EditIcon,
    Email as EmailIcon,
    Error as ErrorIcon,
    FileCopy as FileCopyIcon,
    GetApp as GetAppIcon,
    MenuBook as MenuBookIcon,
} from '@material-ui/icons'

import AppTooltip from '../components/AppTooltip'
import AlertDialog from '../components/Dialogs/AlertDialog'
import FormDialog from '../components/Dialogs/FormDialog'
import SearchBar from '../components/FormItems/SearchBar'
import LoadingSpinner from '../components/LoadingSpinner'
import { backend_url } from '../constants'
import {
    newUserFormModel,
    editUserFormModel,
    userBuiltiDocuments,
    userPersonalDocuments,
} from '../FormConfigs/user_config'
import { createSelect } from '../FormConfigs/utils'
import { usersQueries, rolesQueries, queryClient } from '../networking'
import { useSnackBarStore } from '../stateManagement'
import { getInitials } from '../utils'

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        height: '100%',
    },
    paper: {
        padding: theme.spacing(3),
        height: '100%',
    },
    button: {
        alignSelf: 'flex-end',
    },
    avatar: {
        background: theme.palette.background.paperLight,
        color: theme.palette.common.white,
        width: 40,
        height: 40,
    },
    successBadge: {
        color: theme.palette.success.main,
    },
    downloadIcon: {
        color: theme.palette.secondary.main,
    },
    mail: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
}))

const User = React.memo(({ index, style, data }) => {
    const user = data.users[index]
    const t = data.t
    const showSnackBar = useSnackBarStore((state) => state.show)

    const copyToClipBoard = (dataToCopy) => {
        navigator.clipboard.writeText(dataToCopy).then(
            () => {
                showSnackBar({
                    message: t('copied_email'),
                    severity: 'success',
                })
            },
            () => {
                showSnackBar({
                    message: t('error_clipboard'),
                    severity: 'error',
                })
            }
        )
    }
    return (
        <ListItem key={user?.id} divider style={style}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                spacing={2}
            >
                <Grid item>
                    <Avatar
                        src={user?.image?.depot_url && new URL(user.image.depot_url, backend_url)}
                        alt={`${user.first_name} ${user.last_name}`}
                        className={data.avatarClass}
                    >
                        {getInitials(user.first_name, user.last_name)}
                    </Avatar>
                </Grid>
                <Grid item container direction="column">
                    <Grid item container>
                        <Typography style={{ marginRight: 5 }}>{user?.first_name}</Typography>
                        <Typography>{user?.last_name}</Typography>
                    </Grid>
                    <Grid container alignItems="center">
                        <EmailIcon fontSize="small" color="disabled" style={{ marginRight: 5 }} />
                        <Typography color="textSecondary" variant="body2">
                            <span
                                className={data.mailClass}
                                onClick={() => {
                                    copyToClipBoard(user?.email)
                                }}
                            >
                                {user?.email}
                                <FileCopyIcon
                                    style={{
                                        height: '10px',
                                        verticalAlign: 'baseline',
                                        marginLeft: '-5px',
                                    }}
                                />
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    {user?.completed ? (
                        <AppTooltip
                            title={<Typography variant="body2">{t('profile_complete')}</Typography>}
                        >
                            <CheckCircleIcon className={data.successBadgeClass} />
                        </AppTooltip>
                    ) : (
                        <AppTooltip
                            title={
                                <Typography variant="body2">{t('profile_incomplete')}</Typography>
                            }
                        >
                            <ErrorIcon color="error" />
                        </AppTooltip>
                    )}
                </Grid>
                <Grid item>
                    {user?.all_educational_videos_seen ? (
                        <AppTooltip
                            title={
                                <Typography variant="body2">{t('education_complete')}</Typography>
                            }
                        >
                            <MenuBookIcon className={data.successBadgeClass} />
                        </AppTooltip>
                    ) : (
                        <AppTooltip
                            title={
                                <Typography variant="body2">{t('education_incomplete')}</Typography>
                            }
                        >
                            <MenuBookIcon color="error" />
                        </AppTooltip>
                    )}
                </Grid>
                <Grid item container justifyContent="flex-end">
                    <AppTooltip
                        title={
                            <Typography variant="body2">{t('documentazione_personale')}</Typography>
                        }
                    >
                        <IconButton onClick={() => data.onDocsCb(user)}>
                            <DescriptionIcon />
                        </IconButton>
                    </AppTooltip>
                    <AppTooltip title={<Typography variant="body2">{t('modifica')}</Typography>}>
                        <IconButton onClick={() => data.onEditCb(user)}>
                            <EditIcon />
                        </IconButton>
                    </AppTooltip>
                    <AppTooltip title={<Typography variant="body2">{t('delete')}</Typography>}>
                        <IconButton onClick={() => data.onDeleteCb(user?.id)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </AppTooltip>
                </Grid>
            </Grid>
        </ListItem>
    )
}, areEqual)

const fuseOptions = { keys: ['first_name', 'last_name', 'email'], threshold: 0.25 }
const fuse = new Fuse([], fuseOptions)

export default function UsersManagementPage() {
    const classes = useStyles()
    const { t } = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const [isCreateFormDialogOpen, setIsCreateFormDialogOpen] = React.useState(false)
    const [isEditFormDialogOpen, setIsEditFormDialogOpen] = React.useState(false)
    const [isCopyAlertDialogOpen, setIsCopyAlertDialogOpen] = React.useState(false)
    const [isDeleteAlertDialogOpen, setIsDeleteAlertDialogOpen] = React.useState(false)
    const [isUserDocsAlertDialogOpen, setIsUserDocsAlertDialogOpen] = React.useState(false)
    const [newUserInfo, setNewUserInfo] = React.useState({})
    const [userToBeDeleted, setUserToBeDeleted] = React.useState(null)
    const [userToBeEdited, setUserToBeEdited] = React.useState(null)
    const [userDataToBeEdited, setUserDataToBeEdited] = React.useState({})
    const [userDocs, setUserDocs] = React.useState({})
    const [searchInput, setSearchInput] = React.useState('')
    const [filteredUsers, setFilteredUsers] = React.useState([])

    const { data: allUsers = [], isFetching } = useQuery(
        usersQueries.getUsers.name,
        usersQueries.getUsers.fn
    )

    const { data: allRoles = [] } = useQuery(rolesQueries.getRoles.name, rolesQueries.getRoles.fn)

    const createUserMutation = useMutation(usersQueries.createUser.fn, {
        onSuccess: () => {
            setIsCreateFormDialogOpen(false)
            setIsCopyAlertDialogOpen(true)
            queryClient.invalidateQueries(usersQueries.getUsers.name)
        },
        onError: (error) => {
            const errorInfo = error.response.data
            showSnackBar({
                message: t(errorInfo.error || errorInfo.error_body),
                severity: 'error',
            })
        },
    })

    const deleteUserMutation = useMutation(usersQueries.deleteUser.fn, {
        onSuccess: () => {
            setIsDeleteAlertDialogOpen(false)
            setUserToBeDeleted(null)
            queryClient.invalidateQueries(usersQueries.getUsers.name)
        },
        onError: (error) => {
            const errorInfo = error.response.data
            showSnackBar({
                message: t(errorInfo.error || errorInfo.error_body),
                severity: 'error',
            })
        },
    })

    const updateUserMutation = useMutation(usersQueries.updateUser.fn, {
        onSuccess: () => {
            setIsEditFormDialogOpen(false)
            setUserDataToBeEdited({})
            queryClient.invalidateQueries(usersQueries.getUsers.name)
        },
        onError: (error) => {
            const errorInfo = error.response.data
            showSnackBar({
                message: t(errorInfo.error || errorInfo.error_body),
                severity: 'error',
            })
        },
    })

    function handleUserCreation(data) {
        setNewUserInfo(data)
        createUserMutation.mutate(data)
    }

    function handleCopyUserInfo() {
        const userInfo = `
        ${t('first_name')}: ${newUserInfo.first_name || ''}
        ${t('last_name')}: ${newUserInfo.last_name || ''}
        ${t('email')}: ${newUserInfo.email || ''}
        ${t('password')}: ${newUserInfo.password || ''}
        `
        navigator.clipboard.writeText(userInfo).then(
            () => {
                showSnackBar({
                    message: t('success_clipboard'),
                    severity: 'success',
                })
                // setIsCopyAlertDialogOpen(false)
            },
            () => {
                showSnackBar({
                    message: t('error_clipboard'),
                    severity: 'error',
                })
            }
        )
    }

    function onUserDelete(userId) {
        // ask confirmation
        setUserToBeDeleted(userId)
        setIsDeleteAlertDialogOpen(true)
    }

    function handleUserDelete() {
        if (userToBeDeleted) {
            deleteUserMutation.mutate(userToBeDeleted)
        }
    }

    function onUserEdit(userData) {
        setUserToBeEdited(userData.id)
        setUserDataToBeEdited({
            first_name: userData.first_name,
            last_name: userData.last_name,
            roles: userData.roles?.map((role) => role.name),
            builti_documents: {
                contratto_incarico_professionale:
                    userData.builti_documents.contratto_incarico_professionale,
                duvri_a1: userData.builti_documents.duvri_a1,
                duvri_a2: userData.builti_documents.duvri_a2,
                duvri_a3: userData.builti_documents.duvri_a3,
                duvri_a4: userData.builti_documents.duvri_a4,
                duvri_a5: userData.builti_documents.duvri_a5,
                duvri_a6: userData.builti_documents.duvri_a6,
                other_documents: userData.builti_documents.other_documents,
            },
        })
        setIsEditFormDialogOpen(true)
    }

    function handleUserEdit(data) {
        updateUserMutation.mutate({ userId: userToBeEdited, userData: data })
    }

    function onUserDocsClick(userData) {
        setUserDocs({
            iscrizione_ordine_ingegneri: userData.personal_documents.iscrizione_ordine_ingegneri,
            assicurazione_professionale: userData.personal_documents.assicurazione_professionale,
            documento_identita: userData.personal_documents.documento_identita,
            regolarita_contribuzione_inarcassa:
                userData.personal_documents.regolarita_contribuzione_inarcassa,
            pre_job_checks: userData.personal_documents.pre_job_checks,
            conferme_presa_visione: userData.personal_documents.conferme_presa_visione,
        })
        setIsUserDocsAlertDialogOpen(true)
    }

    function handleDownloadFile(file) {
        const previewUri = String(new URL(file.depot_url, backend_url))
        try {
            FileSaver.saveAs(previewUri, file.name)
        } catch (e) {
            showSnackBar({ message: t('download_failed'), severity: 'error' })
            console.error(e)
        }
    }

    React.useEffect(() => {
        if (!isCopyAlertDialogOpen) {
            setNewUserInfo({})
        }
    }, [isCopyAlertDialogOpen])

    React.useEffect(() => {
        if (!isDeleteAlertDialogOpen) {
            setUserToBeDeleted(null)
        }
    }, [isDeleteAlertDialogOpen])

    React.useEffect(() => {
        if (!isEditFormDialogOpen) {
            setUserToBeEdited(null)
            setUserDataToBeEdited({})
        }
    }, [isEditFormDialogOpen])

    React.useEffect(() => {
        if (!isUserDocsAlertDialogOpen) {
            setUserDocs({})
        }
    }, [isUserDocsAlertDialogOpen])

    const renderAttachments = (content) => {
        let whatToRender
        if (Array.isArray(content)) {
            // list of attachments
            if (content.length > 0) {
                whatToRender = (
                    <Grid container spacing={2}>
                        {content.map((file) => (
                            <Grid item key={file.name}>
                                <Chip
                                    variant="outlined"
                                    label={file?.name}
                                    // file cannot be deleted (dont know why they called it deleteIcon when it could be anything)
                                    deleteIcon={<GetAppIcon className={classes.downloadIcon} />}
                                    onDelete={() => handleDownloadFile(file)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )
            } else {
                whatToRender = <Typography variant="caption">{t('no_file_uploaded')}</Typography>
            }
        } else if (content) {
            // single attachment
            whatToRender = (
                <Chip
                    variant="outlined"
                    label={content?.name}
                    deleteIcon={<GetAppIcon className={classes.downloadIcon} />}
                    onDelete={() => handleDownloadFile(content)}
                />
            )
        } else {
            whatToRender = <Typography variant="caption">{t('no_file_uploaded')}</Typography>
        }
        return whatToRender
    }

    React.useEffect(() => {
        if (allUsers.length) {
            fuse.setCollection(allUsers)
        }
    }, [allUsers])

    React.useEffect(() => {
        if (allUsers.length) {
            if (searchInput) {
                setFilteredUsers(fuse.search(searchInput).map((u) => u.item))
            } else {
                setFilteredUsers(allUsers)
            }
        }
    }, [JSON.stringify(allUsers), searchInput])

    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper elevation={0} className={classes.paper}>
                <Grid container direction="column" style={{ height: '100%' }} spacing={2}>
                    <Grid item>
                        <Typography variant="h4" component="h1" align="center" gutterBottom>
                            {t('users_page.title')}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.button}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            onClick={() => setIsCreateFormDialogOpen(true)}
                        >
                            {t('nuovo_user')}
                        </Button>
                    </Grid>
                    <SearchBar item fullWidth input={searchInput} setInput={setSearchInput} />
                    {isFetching ? (
                        <LoadingSpinner />
                    ) : (
                        <Grid item style={{ flex: 1 }}>
                            {filteredUsers.length > 0 ? (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <VirtualizedList
                                            className="List"
                                            height={height}
                                            width={width - 1}
                                            itemCount={filteredUsers.length}
                                            itemSize={70}
                                            itemData={{
                                                users: _.sortBy(filteredUsers, [
                                                    'last_name',
                                                    'first_name',
                                                ]),
                                                mailClass: classes.mail,
                                                avatarClass: classes.avatar,
                                                successBadgeClass: classes.successBadge,
                                                onDeleteCb: onUserDelete,
                                                onEditCb: onUserEdit,
                                                onDocsCb: onUserDocsClick,
                                                t,
                                            }}
                                        >
                                            {User}
                                        </VirtualizedList>
                                    )}
                                </AutoSizer>
                            ) : (
                                <ListItem>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            align: 'center',
                                            color: 'textSecondary',
                                        }}
                                    >
                                        {t('no_results')}
                                    </ListItemText>
                                </ListItem>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <FormDialog
                open={isCreateFormDialogOpen}
                setIsOpen={setIsCreateFormDialogOpen}
                title="users_crea_utente_titolo"
                button="crea"
                formConfig={{
                    ...newUserFormModel,
                    roles: createSelect(allRoles, 'multiple'),
                    builti_documents: userBuiltiDocuments,
                }}
                defaultValues={{
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null,
                    roles: [],
                    builti_documents: {},
                    pec: null,
                    registration_order: null,
                    registration_number: null,
                }}
                fieldsKey={''}
                onSubmitCb={handleUserCreation}
            />

            <FormDialog
                open={isEditFormDialogOpen}
                setIsOpen={setIsEditFormDialogOpen}
                title="users_modifica_utente_titolo"
                button="modifica"
                formConfig={{
                    ...editUserFormModel,
                    roles: createSelect(allRoles, 'multiple'),
                    builti_documents: userBuiltiDocuments,
                }}
                defaultValues={userDataToBeEdited}
                fieldsKey={''}
                onSubmitCb={handleUserEdit}
            />

            <AlertDialog
                open={isCopyAlertDialogOpen}
                setIsOpen={setIsCopyAlertDialogOpen}
                title="copy_to_clipboard"
                primaryButton="copia"
                secondaryButton="close"
                onSubmitCb={handleCopyUserInfo}
            >
                <div style={{ minWidth: 300 }}>
                    <Grid container>
                        <Typography style={{ marginRight: 10 }} color="textSecondary">
                            {t('first_name')}:
                        </Typography>
                        <Typography>{newUserInfo.first_name}</Typography>
                    </Grid>
                    <Grid container>
                        <Typography style={{ marginRight: 10 }} color="textSecondary">
                            {t('last_name')}:
                        </Typography>
                        <Typography>{newUserInfo.last_name}</Typography>
                    </Grid>
                    <Grid container>
                        <Typography style={{ marginRight: 10 }} color="textSecondary">
                            {t('email')}:
                        </Typography>
                        <Typography>{newUserInfo.email}</Typography>
                    </Grid>
                    <Grid container>
                        <Typography style={{ marginRight: 10 }} color="textSecondary">
                            {t('password')}:
                        </Typography>
                        <Typography>{newUserInfo.password}</Typography>
                    </Grid>
                </div>
            </AlertDialog>

            <AlertDialog
                open={isDeleteAlertDialogOpen}
                setIsOpen={setIsDeleteAlertDialogOpen}
                title="user_delete_conferma_titolo"
                text="user_delete_conferma_testo"
                primaryButton="delete"
                onSubmitCb={handleUserDelete}
            />

            <AlertDialog
                open={isUserDocsAlertDialogOpen}
                setIsOpen={setIsUserDocsAlertDialogOpen}
                title="user_documents"
                secondaryButton="close"
            >
                <Grid container direction="column" spacing={2}>
                    {Object.keys(userPersonalDocuments).map((docName) => {
                        const content = userDocs[docName]
                        return (
                            <Grid item key={docName}>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    {t(docName)}
                                </Typography>
                                {renderAttachments(content)}
                            </Grid>
                        )
                    })}
                </Grid>
            </AlertDialog>
        </Container>
    )
}
