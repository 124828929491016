import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import _ from 'lodash'
import { levelsQueries, queryClient, proceduresQueries } from '../../../networking'
import { Paper, Fab, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import {
    mit_la_1_model_scheda_descrittiva,
    sections_to_render_scheda_descrittiva,
} from '../../../FormConfigs/mit_la_1_config'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import AppAccordion from '../../../components/AppAccordion'
import FormFillProgressIndicator from '../../../components/FormItems/FormFillProgressIndicator'
import AppForm from '../../../components/AppForm'
import { capitalizeFirstLetter, isSafeToUnmount } from '../../../utils'
import NetworkErrorOverlay from '../../../components/NetworkErrorOverlay'
import { RequiredLA2Info } from '../../../components/FormItems/RequiredLA2'
import MethodologyPage from '../../MethodologyPage'
import { tecnico_rilevatore } from '../../../FormConfigs/mit_la_1_difetto_config'
import useDisableForm from '../../../customHooks/useDisableForm'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    hide: {
        display: 'none',
    },
}))

export default function LGP_LA1_scheda_descrittiva() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const { data: procedure, isFetched: isProcedureFetched } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )

    const {
        data: dataLv0 = {},
        error: error0,
        refetch: refetch0,
    } = useQuery([levelsQueries.getMitLevel0.name, procedureId], () =>
        levelsQueries.getMitLevel0.fn(procedureId)
    )

    const {
        data = {},
        isLoading,
        error: error1,
        refetch: refetch1,
    } = useQuery(
        [levelsQueries.getMitLevel1.name, procedureId],
        () => levelsQueries.getMitLevel1.fn(procedureId),
        {
            enabled: isProcedureFetched,
        }
    )

    React.useEffect(() => {
        if (data && procedure) {
            methods.reset({ ...data, hidden_company_id: procedure.owner.id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(data)]) //performance optimization as it seems data was reassigned often, causing re-render of the whole form

    // leave them like this
    //https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    const updateLevelMutation = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'mit_guidelines',
                'level1',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getMitLevel1.name),
        }
    )

    function sendData(data) {
        if (isDirty && isValid) {
            delete data.hidden_company_id
            updateLevelMutation.mutate(data)
        }
    }

    // Only render scheda_speciale_cap_post_tesi if Level0 has it selected
    const sections_to_render = sections_to_render_scheda_descrittiva.filter(
        (s) => s !== 'scheda_speciale_cap_post_tesi'
    )
    let scheda_descrittiva = mit_la_1_model_scheda_descrittiva
    if (dataLv0?.tipologia_strutturale?.tipologia === 'TRAVATE_CAP_CAVI_POST_TESI') {
        sections_to_render.push('scheda_speciale_cap_post_tesi')
        // scheda_descrittiva.schema_statico.options = _.map(
        //     scheda_descrittiva.schema_statico.options,
        //     (option) =>
        //         option.value !== 'TRAVATE_APPOGGIATE'
        //             ? { ...option, disabled: true }
        //             : option
        // )
    }
    //--------------------------------------------------------------------

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    return (
        <MethodologyPage methodology="lgp" alert={alert}>
            {error0 || error1 ? (
                <NetworkErrorOverlay
                    actions={[
                        {
                            onClick: () => {
                                refetch0()
                                refetch1()
                            },
                            label: t('retry'),
                        },
                    ]}
                    areActionsPending={isLoading}
                />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {capitalizeFirstLetter(t('scheda_descrittiva'))}
                    </Typography>
                    <RequiredLA2Info />
                    <AppForm onSubmit={sendData} methods={methods}>
                        <DynamicFormFields
                            fieldsKey={''}
                            formFields={tecnico_rilevatore}
                            disableAll={isNotAllowedToWrite}
                        />
                        {sections_to_render.map((section, index) => {
                            return (
                                <AppAccordion
                                    TransitionProps={{
                                        unmountOnExit: isSafeToUnmount(scheda_descrittiva[section]),
                                    }}
                                    key={`${section}${index}`}
                                    accordionLabel={section}
                                    renderHeaderContent={() =>
                                        section.includes('elementi_strutturali') ? null : (
                                            <FormFillProgressIndicator
                                                currentPath={section}
                                                control={methods.control}
                                                formConfig={scheda_descrittiva[section]}
                                            />
                                        )
                                    }
                                >
                                    <DynamicFormFields
                                        fieldsKey={section}
                                        formFields={scheda_descrittiva[section]}
                                        disableAll={isNotAllowedToWrite}
                                    />
                                </AppAccordion>
                            )
                        })}
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={!isDirty || updateLevelMutation.isLoading}
                            className={`${classes.fab} ${isNotAllowedToWrite && classes.hide}`}
                        >
                            {updateLevelMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    </AppForm>
                </Paper>
            )}
        </MethodologyPage>
    )
}
