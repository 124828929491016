import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ isAllowed, redirectPath = '/', ...props }) =>
    isAllowed === true ? (
        <Route {...props} />
    ) : isAllowed === false ? (
        <Redirect to={{ pathname: redirectPath }} />
    ) : null

export default PrivateRoute
