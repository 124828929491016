import { useMutation } from "react-query";
import { availableCompaniesQueries, companiesQueries, duplicateProcedureQueries, proceduresQueries, queryClient } from "../../networking";
import useProcedureId from "../useProcedureId";
import { usePlaceStore, useSnackBarStore } from "../../stateManagement";
import { useQueryProcedure } from "../queryHooks/procedureQueries";

export function useProcedureMutation() {
    const {procedureId} = useProcedureId()
    const {data: procedure} = useQueryProcedure()
    const setPlace = usePlaceStore(state => state.setPlace)
    return useMutation(
        async (data) =>
            (await proceduresQueries.updateProcedure.fn({ id: procedureId, data })).data,
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([
                    proceduresQueries.getProcedure.name,
                    procedureId,
                ])
                await queryClient.invalidateQueries([
                    companiesQueries.getExtraFieldValues.name,
                    procedure?.owner?.id,
                ])
                setPlace(null)
                return data
            },
        }
    )
}

export function useDuplicateProcedureMutation({procedureId} = {}) {
    const {procedureId: _procedureId} = useProcedureId()
    procedureId = procedureId || _procedureId
    const showSnackBar = useSnackBarStore(state => state.show)
    
    return useMutation(
        async (destination_company_id = null) =>
            await duplicateProcedureQueries.createDuplicateProcedure.fn(
                procedureId,
                destination_company_id
            ),
        {
            onSuccess: () => {
                showSnackBar({
                    severity: 'success',
                    message: 'Pratica duplicata correttamente',
                })
                queryClient.invalidateQueries([availableCompaniesQueries.getMethodologyAnalytics.name])
            },
            onError: (e) => {
                showSnackBar({
                    message: e.message,
                    severity: 'error',
                })
            },
        }
    )
}

export function useProcedureNameMutation() {
    const {procedureId} = useProcedureId()
    return useMutation(
        async ({ procedureName }) =>
            await proceduresQueries.updateProcedureName.fn({ id: procedureId, procedureName }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([proceduresQueries.getProcedure.name, procedureId])
            },
        }
    )
}