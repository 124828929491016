import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { Grid, Container, Button, Typography, Divider, TextField } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { companiesQueries, profileQueries, queryClient } from '../networking'
import DetailsSection from '../components/Companies/DetailsSection'
import TeamSection from '../components/Companies/TeamSection'
import CustomCompanyProcedureFields from '../components/CustomCompanyProcedureFields'
import LoadingSpinner from '../components/LoadingSpinner'
import ModulePill from '../components/ModulePill'
import Section from '../components/Section'
import AlertDialog from '../components/Dialogs/AlertDialog'
import CompanyMethodologiesSettingsSection from '../components/Companies/CompanyMethodologiesSettingsSection'
import { useUserProfileStore } from '../stateManagement'
import _ from 'lodash'
import { capitalizeFirstLetter } from '../utils'
import { Controller, useForm } from 'react-hook-form'
import { useSnackBarStore } from '../stateManagement'
import useCompanyId from "../customHooks/useCompanyId"
import { useQueryCompany } from "../customHooks/queryHooks/companyQueries"

const DestructiveButton = withStyles((theme) => ({
    root: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            background: theme.palette.error.dark,
        },
        '&:disabled': {
            background: theme.palette.background.disabled,
        },
    },
}))(Button)

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
}))

export default function CompanyDetailsPage() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const {data: company, refetch: refetchCompany, isLoading: isLoadingCompany} = useQueryCompany()
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const profile = useUserProfileStore((state) => state.profile)
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const isModulesManager = useUserProfileStore((state) => state.isModulesManager)
    const isCompanyAdmin = React.useMemo(() => (company?.admins || []).includes(profile.id), [company, profile])
    const showSnackBar = useSnackBarStore((state) => state.show)
    
    const deleteCompanyMutation = useMutation(
        () => companiesQueries.deleteCompany.fn(company?.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(companiesQueries.getCompanies.name)
                history.replace('/companies')
            },
        }
    )

    const { data: memberInvitations, isLoading: isLoadingMemberInvitations } = useQuery(
        [companiesQueries.getMemberInvitations.name, company?.id],
        () => companiesQueries.getMemberInvitations.fn(company?.id),
        {
            enabled: isSuperAdmin || isCompanyAdmin,
        }
    )

    const { data: readerInvitations, isLoading: isLoadingReaderInvitations } = useQuery(
        [companiesQueries.getReaderInvitations.name, company?.id],
        () => companiesQueries.getReaderInvitations.fn(company?.id),
        {
            enabled: isSuperAdmin || isCompanyAdmin,
        }
    )

    const setExtraFieldsMutation = useMutation(
        (settings) => companiesQueries.setCompanyExtraFields.fn(company.id, settings),
        {
            onSuccess: refetchCompany,
        }
    )

    const setMethodologiesValuesMutation = useMutation(
        (settings) => companiesQueries.setCompanyMethodologiesValues.fn(company.id, settings),
        {
            onSuccess: refetchCompany,
        }
    )

    const handleCredits = useMutation(companiesQueries.handleCredits.fn, {
        onSuccess: () => {
            showSnackBar({ message: t('success'), severity: 'success' })
        },
        onError: () => {
            showSnackBar({ message: t('error'), severity: 'error' })
        },
    })

    const isLoading = isLoadingCompany || isLoadingMemberInvitations || isLoadingReaderInvitations

    const companyAdminsIds = company?.admins.map((admin) => admin?.id)

    const getMethodologyName = (methodology) => {
        switch (methodology) {
            case 'mit_guidelines':
                return 'Linee guida MIT'
            case 'sisma_bonus':
                return 'Sisma bonus'

            default:
                return capitalizeFirstLetter(methodology)
        }
    }

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            credit: {
                mit_guidelines: {
                    available: null,
                    methodology: null,
                },
                resisto: {
                    available: null,
                    methodology: null,
                },
                sisma_bonus: {
                    available: null,
                    methodology: null,
                },
                statico: {
                    available: null,
                    methodology: null,
                },
            },
        },
    })

    const onSubmit = (data) => {
        for (const [key, value] of Object.entries(data.credit)) {
            const currentCredit = company.credit[key].available
            const newCredit = Number(value.available)
            const finalCredit =
                newCredit < currentCredit
                    ? (currentCredit - newCredit) * -1
                    : newCredit - currentCredit

            if (newCredit !== currentCredit) {
                handleCredits.mutate({
                    action: 'add',
                    methodology: key,
                    company_id: company.id,
                    quantity: finalCredit,
                })
            }
        }
    }

    useEffect(() => {
        if (company) {
            reset({ credit: company.credit })
        }
    }, [company])

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <Container maxWidth="md" className={classes.container}>
            <Grid container direction="column" wrap="nowrap" spacing={3}>
                <DetailsSection company={company} />
                <TeamSection
                    admins={company.admins}
                    members={company.members}
                    readers={company.readers}
                    memberInvitations={memberInvitations}
                    readerInvitations={readerInvitations}
                    companyId={company.id}
                    refetchCompany={refetchCompany}
                />
                <CompanyMethodologiesSettingsSection
                    company={company}
                    mutation={setMethodologiesValuesMutation}
                />

                <Section xs={12} direction="column" innerSpacing={4}>
                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                        <Typography variant="h5" component="h2" align="center">
                            {t('company_configuratore_campi')}
                        </Typography>
                        <Typography variant="caption" component="h2" align="center">
                            {t('company_configuratore_campi_warning', {
                                procedures_count: company?.owned_procedures?.length || 'N/A',
                            })}
                        </Typography>
                    </Grid>
                    <CustomCompanyProcedureFields
                        company={company}
                        mutation={setExtraFieldsMutation}
                    />
                </Section>
                <Section xs={12} direction="column" innerSpacing={4}>
                    <Grid item>
                        <Typography variant="h5" component="h2" align="center">
                            Moduli
                        </Typography>
                        <ModulePill
                            company={company}
                            disableEdit={!isSuperAdmin && !isModulesManager}
                        />
                    </Grid>
                </Section>
                <Section innerSpacing={4}>
                    <Grid item container justifyContent="center">
                        <Grid item style={{ marginBottom: 40 }} xs={12}>
                            <Typography variant="h5" component="h2" align="center">
                                {t('gestione_crediti')}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <form onSubmit={handleSubmit(onSubmit)} id="credits">
                                {Object.keys(company?.credit).map((methodology, i) => (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ padding: 0 }}
                                        key={methodology}
                                    >
                                        <Grid item xs={10}>
                                            <Typography variant="h6">
                                                {getMethodologyName(methodology)}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Controller
                                                name={`credit.${methodology}.available`}
                                                control={control}
                                                defaultValue={null}
                                                render={(el) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        value={el.value}
                                                        onChange={(e) =>
                                                            el.onChange(e.target.value)
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {i !== Object.keys(company?.credit).length - 1 && (
                                            <Divider
                                                style={{
                                                    height: '1px',
                                                    width: '100%',
                                                    margin: '20px 0',
                                                }}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </form>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-start" style={{ marginTop: 16 }}>
                            <Button
                                form="credits"
                                variant="contained"
                                color="secondary"
                                style={{ marginTop: 16 }}
                                type="submit"
                            >
                                {t('conferma')}
                            </Button>
                        </Grid>
                    </Grid>
                </Section>
                <Grid item>
                    <DestructiveButton
                        disabled={
                            !(companyAdminsIds.includes(profile.id) || isSuperAdmin) ||
                            company?.owned_procedures?.length > 0
                        }
                        startIcon={<DeleteIcon />}
                        onClick={() => setIsDialogOpen(true)}
                    >
                        {t('elimina_studio')}
                    </DestructiveButton>
                </Grid>
            </Grid>
            <AlertDialog
                open={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                title="company_elimina_studio_titolo"
                text="company_elimina_studio_testo"
                primaryButton="delete"
                onSubmitCb={() => {
                    deleteCompanyMutation.mutate()
                    setIsDialogOpen(false)
                }}
            />
        </Container>
    )
}
