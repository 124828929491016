import React from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import {useTranslation} from "react-i18next";

const filter = createFilterOptions()

const emptyArray = Object.freeze([])
export default function SearchableTextField({
    onChange,
    value,
    type,
    autoCompleteValues = emptyArray,
    freeSolo = true,
    label,
    info,
    onClickInfo,
    autofocus,
    watchConfig,
    ...props
}) {
    const {t} = useTranslation()
    const [options, optionsByValue] = React.useMemo(() => {
        const compareValues = type === 'number' ? ((a, b) => a - b) : ((a, b) => a.localeCompare(b))
        const optionsByValue = new Map(autoCompleteValues
        .filter(value => value !== null)
        .sort(compareValues)
        .map(value => [value, {label: value.toString(), value: value}]))
        return [[...optionsByValue.values()], optionsByValue]
    }, [autoCompleteValues, type])
    
    const handleChange = (event, newOption, eventType) => {
        const value = newOption?.value || null
        if (eventType === 'select-option') {
            onChange(value)
        }
        else if (freeSolo && eventType === 'create-option') {
            onChange(value)
        }
        else if (eventType === 'clear') {
            onChange("")
        }
    }
    
    const getFilterOptions = (options, params) => {
        const {inputValue} = params
        const _value = type === 'number' ? parseFloat(inputValue) : inputValue
        if (inputValue !== "" && (_value !== value)) onChange(_value)
        // if (inputValue && !optionsByValue.has(value)) filtered.push({label: inputValue, value: value})
        return filter(options, params)
    }

    return (
        <Autocomplete
            options={options}
            freeSolo={freeSolo}
            selectOnFocus
            clearOnBlur
            // autoSelect
            fullWidth
            getOptionLabel={(option) => option.label}
            onChange={handleChange}
            filterOptions={getFilterOptions}
            value={optionsByValue.get(value) ?? { label: String(value ?? "") }}
            renderInput={(params) => <TextField {...props} {...params} label={t(label)} variant="outlined" type={type} />}
        />
    )
}
