import React from 'react'
import './DotsLoader.css'

export const DotsLoader = () => (
    <div className="dots-loader ">
        <div></div>
        <div></div>
        <div></div>
    </div>
)
