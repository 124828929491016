import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import FileSaver from 'file-saver'
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@material-ui/core'
import { Add as AddIcon, Done as DoneIcon, GetApp as GetAppIcon } from '@material-ui/icons'
import { backend_url } from '../constants'
import { useSnackBarStore } from '../stateManagement'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    pillAsButton: {
        borderRadius: '4px',
        padding: '18px 4px',
        fontSize: '13px',
        fontWeight: '500',
    },
    downloadHover: {
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}))

export default function DropzoneButton({
    handleChange,
    value,
    text,
    label,
    disabled,
    usePill = true,
    useDeleteIcon = false,
    handleDelete,
}) {
    const { t } = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [localFile, setLocalFile] = React.useState(null)
    const classes = useStyles()

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#202d40',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
    }
    const focusedStyle = {
        // borderColor: '#ffbe45',
    }

    const acceptStyle = {
        // borderColor: '#ffbe45',
    }

    const rejectStyle = {
        // borderColor: 'red',
    }

    const onDrop = React.useCallback((files) => setLocalFile(files[0]), [setLocalFile])

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
        useDropzone({
            // accept: filesToAccept,
            onDrop,
        })

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )

    const onSubmit = () => {
        if (localFile) {
            handleChange(localFile)
        } else {
            setLocalFile(null)
        }
        setIsDialogOpen(false)
    }

    const fullUri =
        localFile?.data ||
        (value?.depot_url ? String(new URL(value?.depot_url, backend_url)) : null) ||
        null

    function downloadFile() {
        try {
            FileSaver.saveAs(fullUri, localFile?.name || value?.name)
        } catch (e) {
            showSnackBar({ message: t('download_failed'), severity: 'error' })
            console.error(e)
        }
    }

    function closeDialog() {
        setLocalFile(null)
        setIsDialogOpen(false)
    }

    return (
        <>
            {value ? (
                <>
                    <Grid item>
                        <Chip
                            icon={<GetAppIcon />}
                            label={label.toUpperCase()}
                            clickable
                            color="secondary"
                            onClick={downloadFile}
                            className={`${!usePill && classes.pillAsButton} ${
                                classes.downloadHover
                            }`}
                            onDelete={useDeleteIcon ? handleDelete : undefined}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <Button
                            disabled={disabled}
                            variant="contained"
                            color="secondary"
                            startIcon={localFile ? <DoneIcon /> : <AddIcon />}
                            onClick={() => setIsDialogOpen(true)}
                        >
                            {text}
                        </Button>
                    </Grid>
                    <Dialog
                        open={isDialogOpen}
                        onClose={closeDialog}
                        maxWidth={'md'}
                        aria-labelledby="max-width-dialog-title"
                        fullWidth
                    >
                        <DialogTitle id="max-width-dialog-title">
                            {t('attachment_dialog_title')}
                        </DialogTitle>
                        <DialogContent>
                            <Grid item>
                                <div>
                                    <div {...getRootProps({ style })}>
                                        <input {...getInputProps()} />
                                        <p>{t('empty_dropzone_text')}</p>
                                    </div>
                                </div>
                            </Grid>
                            {localFile && (
                                <Grid item style={{ marginTop: '20px' }}>
                                    <Chip
                                        icon={<DoneIcon />}
                                        clickable
                                        label={acceptedFiles[0].name}
                                        color="secondary"
                                        onClick={() => {
                                            console.log('download')
                                        }}
                                        onDelete={() => {
                                            setLocalFile(null)
                                        }}
                                    />
                                </Grid>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={
                                    localFile
                                        ? onSubmit
                                        : () => {
                                              setIsDialogOpen(false)
                                          }
                                }
                                color="secondary"
                                variant="contained"
                            >
                                {localFile ? t('accept_and_close') : t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    )
}
