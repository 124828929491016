import React from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'

export default function AppSnackbar({ open, onClose, severity, message }) {
    const { t } = useTranslation()
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <MuiAlert elevation={6} variant="filled" severity={severity} onClose={onClose}>
                {t(message)}
            </MuiAlert>
        </Snackbar>
    )
}
