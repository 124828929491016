import React from 'react'
import { Typography, Grid, Tooltip, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const StyledToggleButton = withStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        borderRadius: '20px',
        borderColor: theme.palette.common.grey,
        color: theme.palette.text.secondary,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
    },
    selected: {}, // needed for the above pseudo-class to work
}))(ToggleButton)

const availablePresets = {
    estensioneIntensitaLA1: {
        container: {
            width: 135,
            flexDirection: 'column',
            marginRight: 20,
        },
        label: {
            marginRight: 0,
        },
    },
    columnInline: {
        container: {
            width: 'auto',
            flexDirection: 'column',
            marginRight: 20,
        },
        label: {
            marginRight: 0,
        },
    },
    columnInlineWide: {
        container: {
            flexDirection: 'row',
        },
        label: {
            minWidth: '350px',
        },
    },
    default: {
        container: {
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
        },
        label: {
            fontSize: '0.95rem',
        },
        buttonsContainer: {
            justifyContent: 'flex-end',
        },
    },
}

export default function AppToggleButtonGroup({
    onChange,
    buttons,
    label,
    className,
    styleVariant = 'default',
    onBlur,
    watchConfig,
    disableAll,
    info,
    onClickInfo,
    required,
    exclusive = true,
    valueWhenNotExclusive,
    ...props
}) {
    const { t } = useTranslation()
    const styleToUse = availablePresets[styleVariant]
    const methods = useFormContext()
    const [additionalProps, setAdditionalProps] = React.useState({})

    // if valueWhenNotExclusive is not passed, value={undefined} breaks the exclusive variant
    const exclusiveRelatedProps = exclusive
        ? {}
        : {
              value: valueWhenNotExclusive,
          }

    const handleChange = (event, newValue) => {
        // check if it's a scheda difetto
        if (props.name?.includes('difetti')) {
            // Prepare path
            // ex. schede_ispezione.spalle[0].difetti.ca/cap_1.motivo_assenza --> schede_ispezione.spalle[0].difetti.ca/cap_1
            const difettoPath = props.name.split('.').slice(0, -1).join('.')
            if (['NA', 'NR', 'NP'].includes(newValue)) {
                // clear estensione and intensita
                const estensionePath = difettoPath + '.estensione'
                const intensitaPath = difettoPath + '.intensita'
                methods.setValue(estensionePath, null)
                methods.setValue(intensitaPath, null)
            }
            if ([0.2, 0.5, 1].includes(newValue)) {
                // clear motivo_assenza
                const motivoAssenzaPath = difettoPath + '.motivo_assenza'
                methods.setValue(motivoAssenzaPath, null)
            }
        }
        onChange(newValue)
    }

    let watchedValue
    if (methods && methods.watch) {
        watchedValue = watchConfig?.name ? String(methods.watch(watchConfig?.name)) : null
    }
    const casesToWatch = watchConfig?.cases || undefined
    React.useEffect(() => {
        if (!casesToWatch) {
            if (!_.isEmpty(additionalProps)) {
                setAdditionalProps({})
            }
        } else {
            for (const [triggerValue, caseSettings] of Object.entries(casesToWatch)) {
                if (watchedValue === triggerValue) {
                    setAdditionalProps(caseSettings)
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casesToWatch, watchedValue])

    return (
        <>
            <Grid
                container
                alignItems="center"
                className={className}
                style={styleToUse.container}
                spacing={1}
            >
                <Grid item container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Typography
                            style={styleToUse.label}
                            dangerouslySetInnerHTML={{
                                __html: t(label) + (required ? ' *' : ''),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {info && (
                            <Tooltip placement="top-end" title={info}>
                                <IconButton size="small" onClick={onClickInfo}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <ToggleButtonGroup
                        {...props}
                        exclusive={exclusive}
                        {...exclusiveRelatedProps}
                        onChange={handleChange}
                        size="medium"
                        style={styleToUse.buttonsContainer}
                    >
                        {buttons.map((button, index) => (
                            <StyledToggleButton
                                key={'' + button.value + index}
                                value={button.value}
                                aria-label={button.value}
                                {...additionalProps}
                                disabled={
                                    additionalProps?.disabled || button.disabled || disableAll
                                }
                                style={{
                                    width:
                                        styleVariant === 'estensioneIntensitaLA1'
                                            ? 135 / buttons.length
                                            : styleVariant === 'columnInlineWide'
                                            ? '90px'
                                            : undefined,
                                }}
                            >
                                {t(button.label || button.value)}
                            </StyledToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </>
    )
}
