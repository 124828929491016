import React from 'react'
import { FormProvider } from 'react-hook-form'

export default function AppForm({ children, onSubmit, methods, ...props }) {
    function preventSubmitOnEnterPress(e) {
        if (e.key === 'Enter' && e.target.nodeName !== 'TEXTAREA') {
            // default behavior in textareas is adding a new line
            e.preventDefault()
        }
    }

    return (
        <FormProvider {...methods}>
            <form
                {...props}
                onSubmit={methods.handleSubmit(onSubmit)}
                onKeyDown={preventSubmitOnEnterPress}
            >
                {children}
            </form>
        </FormProvider>
    )
}
