import React from 'react'
import { Grid, CardActionArea, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Work as WorkIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import ModulePill from '../ModulePill'
import { backend_url } from '../../constants'
import { useUserProfileStore } from '../../stateManagement'
import {
    StarOutline as StarOutlineIcon,
    VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.background.default,
    },
    cardAsButton: {
        padding: theme.spacing(2),
    },
    img: {
        width: 100,
        height: 75,
        borderRadius: theme.shape.borderRadius,
        objectFit: 'contain',
        background: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    placeholderImg: {
        fontSize: 58,
        borderRadius: theme.shape.borderRadius,
    },
    secondaryIcon: {
        position: 'absolute',
        top: 5,
        left: 0,
        height: '15px',
        color: theme.palette.secondary.main,
    },
}))

export default function CompanyCard({ company }) {
    const classes = useStyles()
    const history = useHistory()
    const cardRef = React.createRef()
    const [profile, isSuperAdmin, isModulesManager] = useUserProfileStore(state => [
        state.profile,
        state.isSuperAdmin,
        state.isModulesManager
    ])
    const isReader = (company?.readers || []).includes(profile.id)
    const isAdmin = (company?.admins || []).includes(profile.id)

    function goToCompany(companyId) {
        history.push(`/companies/${companyId}`)
    }

    return (
        <Grid item>
            <Card className={classes.card}>
                <CardActionArea
                    ref={cardRef}
                    className={classes.cardAsButton}
                    onClick={(event) => {
                        if (
                            cardRef.current === event.target ||
                            cardRef.current.contains(event.target)
                        ) {
                            goToCompany(company.id)
                        }
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        {isAdmin && <StarOutlineIcon className={classes.secondaryIcon} />}
                        {isReader && <VisibilityOutlinedIcon className={classes.secondaryIcon} />}
                        <Grid item>
                            <Typography variant="h5" style={{ marginBottom: 10 }}>
                                {company.name}
                            </Typography>
                            <ModulePill
                                company={company}
                                disableEdit={!(isSuperAdmin || isModulesManager)}
                            />
                        </Grid>
                        <Grid item>
                            {company?.image?.depot_url ? (
                                <img
                                    src={String(new URL(company.image.depot_url, backend_url))}
                                    alt=""
                                    className={classes.img}
                                />
                            ) : (
                                <div className={classes.img}>
                                    <WorkIcon color="disabled" className={classes.placeholderImg} />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    )
}
