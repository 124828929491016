import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'

export default function SplitButton({ config }) {
    const splitButtonAnchorRef = React.useRef(null)
    const [isSplitButtonOpen, setSplitButtonOpen] = React.useState(false)
    const [selectionButtonIndex, setSelectedButtonIndex] = React.useState(0)
    const { t } = useTranslation()

    const handleClose = (event) => {
        if (splitButtonAnchorRef.current && splitButtonAnchorRef.current.contains(event.target)) {
            return
        }

        setSplitButtonOpen(false)
    }
    const handleMenuItemClick = (index) => (event) => {
        setSplitButtonOpen(false)
        setSelectedButtonIndex(index)
    }

    const handleToggle = () => setSplitButtonOpen((prevOpen) => !prevOpen)
    return (
        <>
            <ButtonGroup
                variant="contained"
                color="secondary"
                ref={splitButtonAnchorRef}
                aria-label="split button"
            >
                <Button
                    startIcon={config[selectionButtonIndex].startIcon}
                    disabled={config[selectionButtonIndex].disabled}
                    onClick={config[selectionButtonIndex].onClick}
                >
                    {t(config[selectionButtonIndex].label)}
                </Button>
                <Button
                    color="secondary"
                    size="small"
                    aria-controls={isSplitButtonOpen ? 'split-button-menu' : undefined}
                    aria-expanded={isSplitButtonOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={isSplitButtonOpen}
                anchorEl={splitButtonAnchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 1 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        timeout={100}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {config.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            selected={index === selectionButtonIndex}
                                            onClick={handleMenuItemClick(index)}
                                        >
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
