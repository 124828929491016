import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon } from '@material-ui/icons'

import UsersTeamSection from './UsersTeamSection'
import AlertDialog from '../Dialogs/AlertDialog'
import InputDialog from '../Dialogs/InputDialog'
import Section from '../Section'
import SplitButton from '../SplitButton'
import {
    companiesQueries,
    notificationQueries,
    queryClient,
    profileQueries,
} from '../../networking'
import { useSnackBarStore, useUserProfileStore } from '../../stateManagement'
import { backend_url } from '../../constants'
import useCompanyId from '../../customHooks/useCompanyId'

const snackbarConfig = Object.freeze({
    only_admin: {
        severity: 'error',
        message: 'attempt_to_remove_only_admin_error_message',
    },
    already_invited: {
        severity: 'warning',
        message: 'email_already_invited_error_message',
    },
    nonexistent_email: {
        severity: 'error',
        message: 'nonexistent_email_error_message',
    },
    already_member: {
        severity: 'warning',
        message: 'already_member_message',
    },
})

const useStyles = makeStyles((theme) => ({
    button: {
        alignSelf: 'flex-end',
    },
    divider: {
        backgroundColor: theme.palette.secondary.main,
    },
    sectionTitle: {
        position: 'absolute',
        // bottom: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.background.paper,
        paddingLeft: '15px',
        paddingRight: '15px',
        color: theme.palette.secondary.main,
    },
    sectionDividerContainer: {
        position: 'relative',
        marginTop: '30px',
        marginBottom: '15px',
    },
    cardBody: {
        minWidth: '252px',
    },
    teamContainer: {
        margin: 0,
    },
}))

/**
 * - Mostra gli admin, i membri e i reader.
 *     Gli admin sono identificati da una stella, i reader da un occhio
 * - Mostra i membri e i reader invitati.
 * - È possibile trasformare un admin, un membro e un reader in qualunque degli altri 2.
 * - È possibile rimuovere gli admin, i membri e i reader.
 * - È possibile rimuovere i membri e i reader invitati.
 */
export default function TeamSection({
    admins,
    members,
    readers,
    memberInvitations,
    readerInvitations,
    refetchCompany,
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const { companyId: paramsCompanyId } = useCompanyId()
    const [userInvitationEmail, setUserInvitationEmail] = React.useState('')
    const [isInputDialogOpen, setIsInputDialogOpen] = React.useState(false)
    const [isCompanyAdmin, setIsCompanyAdmin] = React.useState(false)
    const [selectedUser, setSelectedUser] = React.useState(null)
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const showSnackBar = useSnackBarStore((state) => state.show)

    const splitButtonConfig = Object.freeze([
        {
            label: 'company_invite_member_label',
            startIcon: <AddIcon />,
            disabled: !(isSuperAdmin || isCompanyAdmin),
            onClick: () =>
                setIsInputDialogOpen({
                    title: 'company_invite_member_title',
                    requestType: 'MEMBER',
                }),
        },
        {
            label: 'company_invita_reader',
            startIcon: <AddIcon />,
            disabled: !(isSuperAdmin || isCompanyAdmin),
            onClick: () =>
                setIsInputDialogOpen({
                    title: 'company_invita_reader_title',
                    requestType: 'READER',
                }),
        },
    ])

    useQuery(profileQueries.getUser.name, profileQueries.getUser.fn, {
        onSuccess: (currentUser) => {
            if (admins && _.find(admins, { id: currentUser.id })) {
                setIsCompanyAdmin(true)
            }
        },
    })

    const inviteUserMutation = useMutation(companiesQueries.inviteUser.fn, {
        onSuccess: (data, variables) => {
            return queryClient.invalidateQueries([
                variables.requestType === 'MEMBER'
                    ? companiesQueries.getMemberInvitations.name
                    : companiesQueries.getReaderInvitations.name,
                variables.companyId,
            ])
        },
        onError: (error, variables) => {
            // TODO: usare variables.requestType per mostrare il messaggio di errore corretto
            switch (error.response?.status) {
                case 400:
                    if (error.response.data.error_type === 'email_already_invited') {
                        console.error('User email already invited')
                        showSnackBar(snackbarConfig['already_invited'])
                    } else if (error.response.data.error_type === 'already_member') {
                        showSnackBar(snackbarConfig['already_member'])
                    }
                    break
                case 403:
                    console.error('Forbidden')
                    break
                case 404:
                    console.error('User email does not exist')
                    showSnackBar(snackbarConfig['nonexistent_email'])
                    break
                default:
                    console.error('Error inviting user to company', error)
                    break
            }
        },
    })

    const handleUserInvitation = (requestType) => async () => {
        inviteUserMutation.mutate({
            companyId: paramsCompanyId,
            userInvitationEmail,
            requestType,
        })
    }

    const handleDeleteMember = async (member, isAdmin) => {
        // TODO: chiedere conferma
        // TODO: convertire in mutation
        try {
            const response = await axios.patch(`/companies/${paramsCompanyId}`, {
                [isAdmin ? 'removeAdmin' : 'removeUser']: member.id,
            })
            if (response.status === 200) {
                queryClient.invalidateQueries([companiesQueries.getCompany.name, paramsCompanyId])
                queryClient.invalidateQueries(notificationQueries.getNotifications.name)
            }
        } catch (error) {
            switch (error.response?.status) {
                case 403:
                    console.error('Forbidden')
                    break
                case 404:
                    console.error('User email does not exist')
                    break
                default:
                    console.error('Error removing user from company', error)
                    if (admins.length === 1) {
                        showSnackBar(snackbarConfig['only_admin'])
                    }
                    break
            }
        }
    }

    const handleDeleteInvitation = async (member, invitationId) => {
        // TODO: chiedere conferma
        // console.log('handleDeleteInvitation', member)
        try {
            const response = await axios.delete(
                `/company_invitations/${paramsCompanyId}/${invitationId}`
            )
            if (response.status === 200) {
                queryClient.invalidateQueries([
                    companiesQueries.getMemberInvitations.name,
                    paramsCompanyId,
                ])
                queryClient.invalidateQueries([
                    companiesQueries.getReaderInvitations.name,
                    paramsCompanyId,
                ])
            }
        } catch (error) {
            if (error.response?.status === 403) {
                console.error('Forbidden')
            } else if (error.response?.status === 404) {
                console.error('User email does not exist')
            } else {
                console.error('Error inviting user to company', error)
            }
        }
    }

    return (
        <>
            <Section xs={12} direction="column" innerSpacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h2" align="center">
                        {t('team')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    spacing={3}
                    className={classes.teamContainer}
                >
                    {isSuperAdmin || isCompanyAdmin ? (
                        <Grid className={classes.button}>
                            <SplitButton config={splitButtonConfig} />
                        </Grid>
                    ) : null}
                    <UsersTeamSection
                        sectionTitleLabel="admin_section_title"
                        users={admins}
                        admins
                        classes={classes}
                        isSuperAdmin={isSuperAdmin}
                        isCompanyAdmin={isCompanyAdmin}
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                        handleDeleteMember={handleDeleteMember}
                        uniqueAdmin={admins?.length === 1}
                        refetchCompany={refetchCompany}
                    />
                    {members?.length > 0 && (
                        <UsersTeamSection
                            sectionTitleLabel="members_section_title"
                            users={members}
                            members
                            classes={classes}
                            isSuperAdmin={isSuperAdmin}
                            isCompanyAdmin={isCompanyAdmin}
                            setSelectedUser={setSelectedUser}
                            selectedUser={selectedUser}
                            handleDeleteMember={handleDeleteMember}
                            refetchCompany={refetchCompany}
                        />
                    )}
                    {readers?.length > 0 && (
                        <UsersTeamSection
                            sectionTitleLabel="readers_section_title"
                            users={readers}
                            readers
                            classes={classes}
                            isSuperAdmin={isSuperAdmin}
                            isCompanyAdmin={isCompanyAdmin}
                            setSelectedUser={setSelectedUser}
                            selectedUser={selectedUser}
                            handleDeleteMember={handleDeleteMember}
                            refetchCompany={refetchCompany}
                        />
                    )}
                    {memberInvitations?.length > 0 && (
                        <UsersTeamSection
                            sectionTitleLabel="ingegneri_invitati"
                            invitations={memberInvitations}
                            classes={classes}
                            isSuperAdmin={isSuperAdmin}
                            isCompanyAdmin={isCompanyAdmin}
                            setSelectedUser={setSelectedUser}
                            selectedUser={selectedUser}
                            handleDeleteMember={handleDeleteMember}
                            handleDeleteInvitation={handleDeleteInvitation}
                            refetchCompany={refetchCompany}
                        />
                    )}
                    {readerInvitations?.length > 0 && (
                        <UsersTeamSection
                            sectionTitleLabel="readers_invitati"
                            invitations={readerInvitations}
                            classes={classes}
                            isSuperAdmin={isSuperAdmin}
                            isCompanyAdmin={isCompanyAdmin}
                            setSelectedUser={setSelectedUser}
                            selectedUser={selectedUser}
                            handleDeleteMember={handleDeleteMember}
                            handleDeleteInvitation={handleDeleteInvitation}
                            refetchCompany={refetchCompany}
                        />
                    )}
                </Grid>
            </Section>
            <InputDialog
                open={Boolean(isInputDialogOpen)}
                setIsOpen={setIsInputDialogOpen}
                title={isInputDialogOpen.title}
                button="invita"
                inputType="email"
                inputLabel="email"
                value={userInvitationEmail}
                setValue={setUserInvitationEmail}
                onSubmitCb={handleUserInvitation(isInputDialogOpen.requestType)}
            />
        </>
    )
}
