import { CircularProgress, Fab, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import AppForm from '../../components/AppForm'
import DynamicFormFields from '../../components/FormItems/DynamicFormFields'
import LoadingSpinner from '../../components/LoadingSpinner'
import { levelsQueries, proceduresQueries } from '../../networking'
import { capitalizeFirstLetter } from '../../utils'
import MethodologyPage from '../MethodologyPage'
import SaveIcon from '@material-ui/icons/Save'
import {
    masonryBuildingStatusModel,
    prefabricatedBuildingStatusModel,
    reinforcedConcreteBuildingStatusModel,
} from '../../FormConfigs/stato_edificio_config'
import { replaceEmptyStringsWithNullWithin } from '../../FormConfigs/utils'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

const buildingStatusModelsByBuildingMaterial = {
    REINFORCED_CONCRETE: reinforcedConcreteBuildingStatusModel,
    PREFABRICATED: prefabricatedBuildingStatusModel,
    MASONRY: masonryBuildingStatusModel,
}

export const Stato_edificio = () => {
    const procedureId = useParams().procedureId
    const classes = useStyles()
    const { t } = useTranslation()

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })
    const { isDirty, isValid } = methods.formState

    const {
        data: procedure,
        isLoading: isProcedureLoading,
        refetch: refetchProcedure,
    } = useQuery([proceduresQueries.getProcedure.name, procedureId], () =>
        proceduresQueries.getProcedure.fn(procedureId)
    )

    const formModel = React.useMemo(
        function () {
            return buildingStatusModelsByBuildingMaterial[procedure?.building_material]
        },
        [procedure?.building_material]
    )

    useEffect(() => {
        if (procedure) {
            methods.reset({
                ...procedure.methodologies.resisto.workspace.building_status,
            })
            // console.log({ procedure })
        }
    }, [procedure])

    const { mutate: updateLevelMutation, isLoading: isUpdateLevelMutationLoading } = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'resisto',
                'building_status',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => {
                refetchProcedure()
            },
        }
    )

    const sendData = (data) => {
        updateLevelMutation(data)
    }

    return (
        <MethodologyPage methodology="resisto">
            {isProcedureLoading ? (
                <LoadingSpinner />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {capitalizeFirstLetter(t('Stato_edificio'))}
                    </Typography>
                    <AppForm onSubmit={sendData} methods={methods}>
                        <Grid container justifyContent="center" spacing={3}>
                            <DynamicFormFields
                                fieldsKey={''}
                                formFields={formModel}
                                formId={`workspace_resisto_building_status_${procedure?.building_material}`}
                                editableInfos={true}
                            />
                        </Grid>
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={
                                isProcedureLoading ||
                                isUpdateLevelMutationLoading ||
                                !isDirty ||
                                !isValid
                            }
                            className={classes.fab}
                        >
                            {isUpdateLevelMutationLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    </AppForm>
                </Paper>
            )}
        </MethodologyPage>
    )
}
