import { useGLTF } from '@react-three/drei'
import React from 'react'
export function usePlinto() {
    const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/assets/Pilone.gltf')
    // console.log({nodes, materials})
    return React.useMemo(() => ({
            models: {
                Plinto: {
                    mesh: nodes.Plinto,
                    // materiale: materials.Mat_fibra,
                    materiale: materials.Mat_Plinto,
                },
                
                Pilone: {
                    mesh: nodes.Pilone,
                    materiale: materials.Mat_pilone,
                },
                
                Fibra: {
                    mesh: nodes.Fibra,
                    materiale: materials.Mat_fibra,
                },
                Plinto_Wire: {
                    mesh: nodes.Plinto_wire,
                    materiale: materials.Mat_fibra,
                },
            }
        }
    ), [nodes, materials])
}