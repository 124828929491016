import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { levelsQueries, queryClient, proceduresQueries } from '../../../networking'
import { Paper, Fab, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import DynamicFormFields from '../../../components/FormItems/DynamicFormFields'
import {
    mit_la_1_model_fenomeni,
    sections_to_render_fenomeni_idraulico,
} from '../../../FormConfigs/mit_la_1_config'
import { replaceEmptyStringsWithNullWithin } from '../../../FormConfigs/utils'
import AppAccordion from '../../../components/AppAccordion'
import FormFillProgressIndicator from '../../../components/FormItems/FormFillProgressIndicator'
import AppForm from '../../../components/AppForm'
import { isSafeToUnmount } from '../../../utils'
import NetworkErrorOverlay from '../../../components/NetworkErrorOverlay'
import { RequiredLA2Info } from '../../../components/FormItems/RequiredLA2'
import MethodologyPage from '../../MethodologyPage'
import useDisableForm from '../../../customHooks/useDisableForm'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    hide: {
        display: 'none',
    },
}))

export default function LGP_LA1_fenomeni_idraulico() {
    const classes = useStyles()
    const { t } = useTranslation()
    const procedureId = useParams().procedureId
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {},
        shouldUnregister: false,
    })

    const { data: procedure, isFetched: isProcedureFetched } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId)
    )

    const { isLoading, error, refetch } = useQuery(
        [levelsQueries.getMitLevel1.name, procedureId],
        () => levelsQueries.getMitLevel1.fn(procedureId),
        {
            enabled: isProcedureFetched,
            onSuccess: (data) => methods.reset({ ...data, hidden_company_id: procedure.owner.id }),
        }
    )

    const enabledFenomeni = methods.watch('rischio_idraulico.tipologia_fenomeno.tipologia')

    React.useEffect(() => {
        const formState = methods.getValues()
        if (enabledFenomeni === null) {
            delete formState?.rischio_idraulico.rischio_sormonto
            delete formState?.rischio_idraulico.fenomeni_erosione
        } else if (enabledFenomeni === 'EROSIONE') {
            delete formState?.rischio_idraulico.rischio_sormonto
        } else if (enabledFenomeni === 'SORMONTO') {
            delete formState?.rischio_idraulico.fenomeni_erosione
        }
        methods.reset(formState, { isDirty: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabledFenomeni])

    // leave them like this
    //https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    const updateLevelMutation = useMutation(
        (data) =>
            levelsQueries.updateLevel.fn(
                procedureId,
                'mit_guidelines',
                'level1',
                replaceEmptyStringsWithNullWithin(data)
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(levelsQueries.getMitLevel1.name),
        }
    )

    function sendData(data) {
        if (isValid && isDirty) {
            delete data.hidden_company_id
            updateLevelMutation.mutate(data)
        }
    }

    let sections_to_render = sections_to_render_fenomeni_idraulico
    if (enabledFenomeni === null) {
        sections_to_render = _.filter(
            sections_to_render,
            (sectionName) =>
                sectionName !== 'rischio_sormonto' && sectionName !== 'fenomeni_erosione'
        )
    } else if (enabledFenomeni === 'EROSIONE') {
        sections_to_render = _.filter(
            sections_to_render,
            (sectionName) => sectionName !== 'rischio_sormonto'
        )
    } else if (enabledFenomeni === 'SORMONTO') {
        sections_to_render = _.filter(
            sections_to_render,
            (sectionName) => sectionName !== 'fenomeni_erosione'
        )
    }

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    return (
        <MethodologyPage methodology="lgp" alert={alert}>
            {error ? (
                <NetworkErrorOverlay
                    actions={[{ onClick: refetch, label: t('retry') }]}
                    areActionsPending={isLoading}
                />
            ) : (
                <Paper elevation={0} className={classes.paper}>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {t('rischio_idraulico')}
                    </Typography>
                    <RequiredLA2Info />
                    <AppForm onSubmit={sendData} methods={methods}>
                        {sections_to_render.map((group, index) => {
                            const formConfig = mit_la_1_model_fenomeni.rischio_idraulico[group]

                            return (
                                <AppAccordion
                                    TransitionProps={{
                                        unmountOnExit: isSafeToUnmount(formConfig),
                                    }}
                                    key={`${group}${index}`}
                                    accordionLabel={group}
                                    renderHeaderContent={() => {
                                        return (
                                            <FormFillProgressIndicator
                                                currentPath={`rischio_idraulico.${group}`}
                                                control={methods.control}
                                                formConfig={formConfig}
                                            />
                                        )
                                    }}
                                >
                                    <DynamicFormFields
                                        fieldsKey={'rischio_idraulico.' + group}
                                        formFields={formConfig}
                                        disableAll={isNotAllowedToWrite}
                                    />
                                </AppAccordion>
                            )
                        })}
                        <Fab
                            variant="extended"
                            color="secondary"
                            type="submit"
                            disabled={
                                !isDirty || !isValid || isLoading || updateLevelMutation.isLoading
                            }
                            className={`${classes.fab} ${isNotAllowedToWrite && classes.hide}}`}
                        >
                            {updateLevelMutation.isLoading ? (
                                <CircularProgress size={18} style={{ marginRight: 8 }} />
                            ) : (
                                <SaveIcon />
                            )}
                            {t('salva')}
                        </Fab>
                    </AppForm>
                </Paper>
            )}
        </MethodologyPage>
    )
}
